import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Airway } from "src/types/Types";

type DeleteAirwayDialogProps = {
  airway?: Airway;
  onConfirmDelete: () => void;
  onCancelDelete: () => void;
};

const DeleteAirwayDialog = (props: DeleteAirwayDialogProps) => {
  const { airway, onConfirmDelete, onCancelDelete } = props;
  return (
    <Dialog maxWidth="sm" fullWidth open={true}>
      <DialogTitle>Confirm Delete Airway</DialogTitle>
      <DialogContent dividers>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ marginLeft: "50px", marginTop: "20px" }}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "100px", marginRight: "30px", textAlign: "right" }}>Name</div>
              <Box sx={{ fontWeight: "medium" }}>{`${airway?.name}`}</Box>
            </div>
          </div>
          <div style={{ marginLeft: "50px", marginTop: "20px" }}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "100px", marginRight: "30px", textAlign: "right" }}>Full name</div>
              <Box sx={{ fontWeight: "medium" }}>{`${airway?.fullName ? airway.fullName : ""}`}</Box>
            </div>
          </div>
          <div style={{ marginLeft: "50px", marginTop: "20px", color: "red" }}>This airway and all airway points will be deleted</div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="contained" onClick={onConfirmDelete}>
          Delete
        </Button>
        <Button onClick={onCancelDelete}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAirwayDialog;
