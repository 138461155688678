import { ChangeEvent } from "react";

import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import InputMask from "react-input-mask";

import { TextField } from "@mui/material";
import { useHeliportDesignatorMask, useRunwayDesignatorMask } from "src/components/editors/hooks/useRunwayDesignatorMask";

const DataGridRunwayDesignatorMask = (params: GridRenderEditCellParams) => {
  const { id, value, field, error } = params;

  const runWayDesignatorMask = useRunwayDesignatorMask();
  const heliportDesignatorMask = useHeliportDesignatorMask();
  const apiRef = useGridApiContext();

  const handleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({ id, field, value: newValue.toUpperCase() });
  };

  const inputColor = error ? "red" : "";

  return (
    <div>
      <InputMask alwaysShowMask mask={params.row.heliport ? heliportDesignatorMask : runWayDesignatorMask} value={value} onChange={handleChangeValue}>
        <TextField
          size="small"
          autoComplete="off"
          variant="standard"
          sx={{
            input: {
              textAlign: "center",
              color: inputColor
            }
          }}
          error={error}
        />
      </InputMask>
    </div>
  );
};

export default DataGridRunwayDesignatorMask;
