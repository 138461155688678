import { GridPreProcessEditCellProps, GridRenderCellParams } from "@mui/x-data-grid";
import DataGridLatitudeMask from "src/components/editors/DataGridLatitudeMask";
import DataGridLongitudeMask from "src/components/editors/DataGridLongitudeMask";
import DataGridOrientationMask from "src/components/editors/DataGridOrientationMask";
import DataGridTextFieldEditor from "src/components/editors/DataGridTextFieldEditor";
import DataGridUserTagSelector from "src/components/selectors/usertagselector/DataGridUserTagSelector";
import { ColumnsGenerator } from "src/types/Types";
import { customUserTagFilteringOperators } from "./customUserTagFilteringOperators";
import { userTagGridComparator } from "./customUserTagGridComparator";

const latitudeOrLongitudeMaskEmpty = '___°__´__.___"_';
const orientationMaskEmpty = "___";

const useAirportsColumns = () => {
  const getColumns: ColumnsGenerator = (validator, onFieldDirty) => {
    return [
      {
        field: "name",
        headerName: "Name",
        editable: true,
        valueParser: (value: string) => {
          if (value) {
            return value.toUpperCase();
          }
          return value;
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("name", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "fullName",
        headerName: "Full name",
        width: 200,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        }
      },
      {
        field: "description",
        headerName: "Description",
        width: 200,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        }
      },
      {
        field: "dmsLatitude",
        headerName: "Latitude",
        width: 150,
        editable: true,
        valueParser: (value: string) => {
          if (value === latitudeOrLongitudeMaskEmpty) {
            return null;
          }
          return value;
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridLatitudeMask {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("dmsLatitude", params.props.value, params.otherFieldsProps);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "dmsLongitude",
        headerName: "Longitude",
        width: 150,
        editable: true,
        valueParser: (value: string) => {
          if (value === latitudeOrLongitudeMaskEmpty) {
            return null;
          }
          return value;
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridLongitudeMask {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("dmsLongitude", params.props.value, params.otherFieldsProps);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "orientation",
        headerName: "Orientation",
        width: 80,
        editable: true,
        valueParser: (value: string) => {
          if (value === orientationMaskEmpty) {
            return null;
          }
          return value;
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridOrientationMask {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("orientation", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "elevation",
        headerName: "Elevation",
        width: 80,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("elevation", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "points",
        headerName: "Points",
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        }
      },
      {
        field: "manuallyUpdated",
        headerName: "Manually updated",
        editable: true,
        type: "boolean",
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        }
      },
      {
        field: "type",
        headerName: "Type",
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        }
      },
      {
        field: "eurocontrolVersion",
        headerName: "Eurocontrol Version",
        editable: true,
        width: 150,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        }
      },
      {
        field: "userTags",
        headerName: "User tags",
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridUserTagSelector {...params} />;
        },
        filterOperators: customUserTagFilteringOperators,
        sortComparator: userTagGridComparator
      }
    ];
  };

  return { getColumns };
};

export default useAirportsColumns;
