import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useRef } from "react";
import FlightLevelEditor from "src/components/editors/FlightLevelEditor";
import LatitudeMask from "src/components/editors/LatitudeMask";
import LongitudeMask from "src/components/editors/LongitudeMask";
import TextFieldEditor from "src/components/editors/TextFieldEditor";
import VerticalBufferEditor from "src/components/editors/VerticalBufferEditor";
import AltitudeCorrectionsSelector from "src/components/selectors/altitudecorrectionselector/AltitudeCorrectionsSelector";
import AreaTypeSelector from "src/components/selectors/areatypeselector/AreaTypeSelector";
import DistributionListSelector from "src/components/selectors/distributionlistselector/DistributionListSelector";
import UserTagSelector from "src/components/selectors/usertagselector/UserTagSelector";
import { DamParameter } from "src/types/Types";
import useAddDamParameterFormHandler from "./useAddDamParameterFormHandler";

export type AddDamParameterFormHandle = {
  requestSubmit: () => void;
};

type AddDamParameterFormProps = {
  createDamParameter: (damParameter: DamParameter) => Promise<DamParameter>;
  onDamParameterCreated: () => void;
};

const AddDamParameterForm: React.ForwardRefRenderFunction<AddDamParameterFormHandle, AddDamParameterFormProps> = (props: AddDamParameterFormProps, forwardedRef) => {
  const { createDamParameter, onDamParameterCreated } = props;
  const { formHandler } = useAddDamParameterFormHandler(createDamParameter, onDamParameterCreated);
  const formRef = useRef<HTMLFormElement>(null);

  React.useImperativeHandle(forwardedRef, () => ({
    requestSubmit() {
      formRef.current?.requestSubmit();
    }
  }));

  return (
    <form ref={formRef} onSubmit={formHandler.handleSubmit}>
      <div style={{ width: "1200px", height: "600px", display: "flex", flexDirection: "row" }}>
        <div style={{ maxWidth: 400, marginLeft: 60 }}>
          <h3>General information</h3>
          <div>
            <TextFieldEditor
              label="Description"
              value={formHandler.values.description}
              showHelperTextAsTooltip={false}
              hasError={formHandler.touched.description && Boolean(formHandler.errors.description)}
              helperText={formHandler.touched.description && formHandler.errors.description}
              onChange={(newValue) => {
                formHandler.setFieldValue("description", newValue);
              }}
            />
          </div>
          <h3>Coordinates</h3>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <LatitudeMask
                value={formHandler.values.latitude}
                onChange={(newValue) => {
                  formHandler.setFieldValue("latitude", newValue);
                }}
                error={formHandler.touched.latitude && Boolean(formHandler.errors.latitude)}
                helperText={formHandler.touched.latitude && formHandler.errors.latitude}
              />
            </div>
            <div style={{ marginLeft: "15px" }}>
              <LongitudeMask
                value={formHandler.values.longitude}
                onChange={(newValue) => {
                  formHandler.setFieldValue("longitude", newValue);
                }}
                error={formHandler.touched.latitude && Boolean(formHandler.errors.longitude)}
                helperText={formHandler.touched.latitude && formHandler.errors.longitude}
              />
            </div>
          </div>
          <h3>Flight levels</h3>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>
              <FlightLevelEditor
                label={"Lower level"}
                value={formHandler.values.lowerFlightLevel}
                error={formHandler.touched.lowerFlightLevel && Boolean(formHandler.errors.lowerFlightLevel)}
                helperText={formHandler.touched.lowerFlightLevel && formHandler.errors.lowerFlightLevel}
                onChange={(newValue) => {
                  formHandler.setFieldValue("lowerFlightLevel", newValue);
                }}
                showHelperTextAsTooltip={false}
              />
              <FormControlLabel
                onChange={(event, checked) => {
                  formHandler.setFieldValue("lowerLevelInFeet", checked);
                }}
                control={<Checkbox />}
                label="lft"
              />
            </div>
            <div style={{ marginLeft: "15px" }}>
              <FlightLevelEditor
                label={"Upper level"}
                value={formHandler.values.higherFlightLevel}
                error={formHandler.touched.latitude && Boolean(formHandler.errors.higherFlightLevel)}
                helperText={formHandler.touched.latitude && formHandler.errors.higherFlightLevel}
                onChange={(newValue) => {
                  formHandler.setFieldValue("higherFlightLevel", newValue);
                }}
                showHelperTextAsTooltip={false}
              />
              <FormControlLabel
                onChange={(event, checked) => {
                  formHandler.setFieldValue("upperLevelInFeet", checked);
                }}
                control={<Checkbox />}
                label="uft"
              />
            </div>
          </div>
          <h3>Times</h3>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <FormControlLabel
              onChange={(event, checked) => {
                formHandler.setFieldValue("startTime", checked);
              }}
              control={<Checkbox />}
              label="Start time"
            />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <FormControlLabel
                onChange={(event, checked) => {
                  formHandler.setFieldValue("endTime", checked);
                }}
                control={<Checkbox />}
                label="End time"
              />
            </div>
          </div>
          <h3>Area type</h3>
          <div style={{ marginTop: "10px" }}>
            <AreaTypeSelector
              label={"PRD"}
              value={formHandler.values.areaType}
              onChange={(newValue) => {
                formHandler.setFieldValue("areaType", newValue);
              }}
            />
          </div>
        </div>
        <div style={{ marginLeft: 100, width: 500 }}>
          <h3>Other</h3>
          <div>
            <VerticalBufferEditor
              label={"Vertical buffer"}
              value={formHandler.values.verticalBuffer}
              onChange={(newValue) => {
                formHandler.setFieldValue("verticalBuffer", newValue);
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <AltitudeCorrectionsSelector value={formHandler.values.altitudeCorrections} onChange={(newValue) => formHandler.setFieldValue("altitudeCorrections", newValue)} />
          </div>
          <div style={{ marginTop: 10 }}>
            <DistributionListSelector value={formHandler.values.distributionList} onChange={(newValue) => formHandler.setFieldValue("distributionList", newValue)} />
          </div>
          <div style={{ marginTop: 10 }}>
            <UserTagSelector currentSelection={formHandler.values.userTags} onChange={(newValue) => formHandler.setFieldValue("userTags", newValue)} />
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}>
            <FormControlLabel
              onChange={(event, checked) => {
                formHandler.setFieldValue("trainingArea", checked);
              }}
              control={<Checkbox />}
              label="tra"
            />
            <FormControlLabel
              onChange={(event, checked) => {
                formHandler.setFieldValue("displayLevel", checked);
              }}
              control={<Checkbox />}
              label="dl"
            />
            <FormControlLabel
              onChange={(event, checked) => {
                formHandler.setFieldValue("damStatic", checked);
              }}
              control={<Checkbox />}
              label="DAM static"
            />
          </div>
          <div></div>
        </div>
      </div>
    </form>
  );
};
export default React.forwardRef(AddDamParameterForm);
