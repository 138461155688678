import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import WaypointIcon from "@mui/icons-material/ChangeHistory";
import { Waypoint } from "src/types/Types";
import { Marker, Tooltip } from "react-leaflet";

type WaypointMarkerProps = {
  waypoint: Waypoint;
};

const WaypointMarker = (props: WaypointMarkerProps) => {
  const { waypoint } = props;
  const divIcon = L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(
      <div style={{ color: "#1565C0" }}>
        {waypoint.symbolImage ? (
          <div style={{ width: "15px", height: "15px", fontWeight: "bold" }}>
            <img src={`data:image/png;base64,${waypoint.symbolImage}`} />
          </div>
        ) : (
          <WaypointIcon style={{ width: "15px", height: "15px", fontWeight: "bold" }} />
        )}
      </div>
    )
  });

  return (
    <Marker icon={divIcon} position={[waypoint.wgs84Latitude, waypoint.wgs84Longitude]}>
      <Tooltip direction="left">{`[${waypoint.dmsLatitude} ${waypoint.dmsLongitude}]`}</Tooltip>
    </Marker>
  );
};

export default WaypointMarker;
