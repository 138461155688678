import { ChangeEvent } from "react";

import { Checkbox, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { CoordinateSystem, DmsOptions } from "src/types/Types";

type CoordinateSystemSelectorProps = {
  format: CoordinateSystem;
  formatExample: string;
  dmsOptions: DmsOptions;
  onCoordinateSystemChange: (value: CoordinateSystem, dmsOptions?: DmsOptions) => void;
};

const CoordinateSystemSelector = (props: CoordinateSystemSelectorProps) => {
  const { format, dmsOptions, onCoordinateSystemChange, formatExample } = props;

  const _onCoordinateSystemChange = (event: ChangeEvent<HTMLInputElement>) => {
    switch (event.target.value) {
      case "dms":
        onCoordinateSystemChange("dms");
        break;
      case "luciad":
        onCoordinateSystemChange("luciad");
        break;
      case "decimal":
        onCoordinateSystemChange("decimal");
        break;
      default:
        onCoordinateSystemChange("decimal");
    }
  };

  const _OnDmsOptionChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onCoordinateSystemChange("dms", { ...dmsOptions, [event.target.value]: checked });
  };

  return (
    <div>
      <RadioGroup value={format} onChange={_onCoordinateSystemChange}>
        <FormControlLabel label={"DECIMAL"} value={"decimal"} control={<Radio size={"small"} />} />
        <FormControlLabel label={"LUCIAD"} value={"luciad"} control={<Radio size={"small"} />} />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <FormControlLabel label={"DMS"} value={"dms"} control={<Radio size={"small"} />} />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <FormControlLabel
                value={"fixed"}
                label={"fixed"}
                disabled={format !== "dms"}
                checked={dmsOptions.fixed}
                control={<Checkbox onChange={_OnDmsOptionChange} size={"small"} />}
              />
              <FormControlLabel
                label={"oriented"}
                value={"oriented"}
                disabled={format !== "dms"}
                checked={dmsOptions.oriented}
                control={<Checkbox onChange={_OnDmsOptionChange} size={"small"} />}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <FormControlLabel
                value={"delimited"}
                label={"delimited"}
                disabled={format !== "dms"}
                checked={dmsOptions.delimited}
                control={<Checkbox onChange={_OnDmsOptionChange} size={"small"} />}
              />
              <FormControlLabel
                label={"precise"}
                value={"precise"}
                disabled={format !== "dms"}
                checked={dmsOptions.precise}
                control={<Checkbox onChange={_OnDmsOptionChange} size={"small"} />}
              />
            </div>
          </div>
        </div>
      </RadioGroup>
      <FormLabel>{`Sample: ${formatExample}`}</FormLabel>
    </div>
  );
};

export default CoordinateSystemSelector;
