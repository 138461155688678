import { TextField, Tooltip } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import { ChangeEvent } from "react";

const TopicEditor = (params: GridRenderEditCellParams) => {
  const { id, value, field, error } = params;
  const apiRef = useGridApiContext();

  console.log("error: " + error);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <Tooltip
      open={error !== false}
      title={error}
      componentsProps={{
        tooltip: {
          sx: {
            color: "white",
            backgroundColor: "red"
          }
        }
      }}
    >
      <TextField
        id="name"
        size="small"
        name="name"
        variant="standard"
        value={value}
        onChange={onChange}
        autoComplete="off"
        sx={{ input: { color: error ? "red" : "" } }}
        error={error}
      />
    </Tooltip>
  );
};

export default TopicEditor;
