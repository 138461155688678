import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import BufferMask from "./BufferMask";

const DataGridBufferMask = (params: GridRenderEditCellParams) => {
  const { id, value, field, error } = params;
  const apiRef = useGridApiContext();

  const handleChangeValue = (value: string) => {
    const newValue = value; // The new value entered by the user
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <div>
      <BufferMask onChange={handleChangeValue} value={value} error={error} />
    </div>
  );
};

export default DataGridBufferMask;
