import ReactDOMServer from "react-dom/server";
import { useEffect, useRef, useState } from "react";

import L, { LatLng, LeafletMouseEvent } from "leaflet";
import { Polyline as LeafletPolyline } from "leaflet";
import { Marker, Polyline, Tooltip } from "react-leaflet";

import { Line as LineType, LinePoint } from "src/types/Types";
import useArc from "src/apis/lines/useArc";

type LineProps = {
  line: LineType;
  editable?: boolean;
  selected?: boolean;
  highlighted?: boolean;
  onClick?: (line: LineType) => void;
  onLinePointMoved?: (linePoint: LinePoint) => void;
  showTooltip?: boolean;
};

export const Line = (props: LineProps) => {
  const { line, editable, onClick, onLinePointMoved, highlighted, showTooltip } = props;

  const { expandedLinePoints, setSortedLinePointsForExpansion } = useArc(100);
  const defaultColor = "#3388ff";

  const [linePoints, setLinePoints] = useState<LinePoint[]>([]);

  // Ref for the polyline layer
  const polylineRef = useRef<LeafletPolyline>(null);

  useEffect(() => {
    const linePoints = line?.linePoints || [];
    setLinePoints(linePoints.slice().sort((a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0)) || []);
  }, [line]);

  useEffect(() => {
    setSortedLinePointsForExpansion(linePoints);
  }, [linePoints]);

  const rectangleIcon = L.divIcon({
    iconSize: [4, 4],
    html: ReactDOMServer.renderToString(<div style={{ color: "#1565C0", width: "4px", height: "4px", backgroundColor: "yellow" }}></div>)
  });

  const _onClick = (event: LeafletMouseEvent) => {
    onClick?.(line);
  };

  const updateVertexPosition = (sequence: number, newPosition: LatLng) => {
    const updatedLinePoints = [...linePoints];
    const updatedPoint = updatedLinePoints.filter((lp) => lp.sequence === sequence)[0];
    updatedPoint.wgs84Latitude = newPosition.lat;
    updatedPoint.wgs84Longitude = newPosition.lng;

    // by passing through the ref we update the polyline coordinates without rerendering
    // the whole map
    if (polylineRef.current) {
      polylineRef.current.setLatLngs(updatedLinePoints.map((lp) => new LatLng(lp.wgs84Latitude, lp.wgs84Longitude)));
    }
    onLinePointMoved?.(updatedPoint);
  };

  return (
    <>
      <Polyline
        key={`${line.id}${line.color ? line.color : ""}`}
        ref={polylineRef}
        interactive={true}
        pathOptions={{ color: line.color ? line.color : defaultColor, dashArray: line.dashArray, weight: editable ? 2 : highlighted ? 2 : 0.7 }}
        positions={expandedLinePoints.map((lp) => {
          return new LatLng(lp.wgs84Latitude, lp.wgs84Longitude);
        })}
        eventHandlers={{
          click: (e) => {
            _onClick(e);
          }
        }}
      >
        {" "}
        {showTooltip && <Tooltip className="leaflet-tooltip">{line.name}</Tooltip>}
      </Polyline>
      {editable &&
        linePoints.map((position) => (
          <Marker
            key={position.sequence}
            position={new LatLng(position.wgs84Latitude, position.wgs84Longitude)}
            icon={rectangleIcon}
            draggable={true}
            eventHandlers={{
              drag: (event) => {
                updateVertexPosition(position.sequence, event.target.getLatLng());
              }
            }}
          ></Marker>
        ))}
    </>
  );
};

export default Line;
