import { useEffect, useState } from "react";
import { getAPIClient } from "src/apis/apiClient";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { Airway } from "src/types/Types";

export type FieldError = {
  id: string;
  message: string;
};

export type AirwaysResponse = {
  data: Airway[];
  errorMessage: string;
  fieldErrors: FieldError[];
};

export const useAirways = () => {
  const versionTag = useVersionTag();
  const [airways, setAirways] = useState<Airway[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const apiClient = getAPIClient();
    setLoading(true);
    apiClient
      ?.get<AirwaysResponse>("/tables/airway", { headers: { "x-air-version": versionTag } })
      .then((response) => {
        setAirways(response.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [versionTag]);
  return { loading, airways, versionTag };
};
