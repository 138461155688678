import { useState } from "react";

import { Autocomplete, Button, Popover, TextField } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";

const DataGridDistributionListSelector = (params: GridRenderEditCellParams) => {
  const { id, value, field } = params;

  const groupBy = (code: string) => {
    switch (code) {
      case "LSAG-MEZ":
      case "LSAG-SIO":
        return "LSAG";
      case "LSAZ-ARF":
      case "LSAZ-BRN":
      case "LSAZ-STG":
      case "LSAZ-DUB":
      case "LSAZ-EMM":
      case "LSAZ-BUO":
      case "LSAZ-LUG":
        return "LSAZ";
      default: {
        if (code.startsWith("LSAG")) {
          return "Skyvisu GVA";
        } else if (code.startsWith("LSAZ")) {
          return "Skyvisu ZRH";
        } else {
          return "";
        }
      }
    }
  };

  const distributionList = [
    "LSAG-UAC",
    "LSAG-LAC",
    "LSAG-APP",
    "LSAG-TWR",
    "LSAG-FIC",
    "LSAZ-UAC",
    "LSAZ-LAC",
    "LSAZ-APP",
    "LSAZ-TWR",
    "LSAZ-FIC",
    "LSAG-MEZ",
    "LSAG-SIO",
    "LSAZ-ARF",
    "LSAZ-BRN",
    "LSAZ-STG",
    "LSAZ-DUB",
    "LSAZ-EMM",
    "LSAZ-BUO",
    "LSAZ-LUG"
  ];

  const apiRef = useGridApiContext();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  const handleChangeValue = (event: any, value: string[]) => {
    const newValue = value; // The new value entered by the user
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <div style={{ width: 500 }}>
      <Button fullWidth onClick={handleClick}>
        {value.length > 0 ? value.join(",") : "DL"}
      </Button>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <div style={{ width: 500 }}>
          <Autocomplete
            size="small"
            fullWidth
            value={value}
            options={distributionList}
            groupBy={groupBy}
            multiple
            onChange={handleChangeValue}
            renderInput={(params) => <TextField multiline {...params} />}
          />
        </div>
      </Popover>
    </div>
  );
};

export default DataGridDistributionListSelector;
