import { useEffect, useState } from "react";
import { MapElementReference, MenuItem } from "src/types/Types";
import {
  searchAvailableReferences as _searchReferences,
  getMenuItems,
  createMap as _createMap,
  updateMap as _updateMap,
  deleteMap as _deleteMap,
  createMenu as _createMenu,
  moveMenuItemUp as _moveMenuItemUp,
  moveMenuItemDown as _moveMenuItemDown,
  deleteMenu as _deleteMenu,
  moveMenuItemTo as _moveMenuItemTo,
  getNextSequenceValue,
  getPaths,
  SearchCriteria,
  deleteMapReference as _deleteMapReference,
  getMapReferences
} from "./mapsApi";

import { useVersionTag } from "../hooks/useVersionTag";
import { getClientTypes } from "../clienttypes/clientTypesApi";
import { ClientType } from "src/pages/clienttypes/ClientTypes";

import * as _ from "lodash";

const useMaps = () => {
  const versionTag = useVersionTag();
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [paths, setPaths] = useState<string[]>([]);
  const [clientTypes, setClientTypes] = useState<ClientType[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentMenuItem, setCurrentMenuItem] = useState<MenuItem>();

  useEffect(() => {
    setLoading(true);
    getMenuItems(versionTag)
      .then((result) => {
        setMenuItems(result);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [versionTag]); /* todo fix this */

  useEffect(() => {
    getPaths(versionTag).then((result) => {
      setPaths(result);
    });
  }, [versionTag]);

  useEffect(() => {
    getClientTypes(versionTag).then((result) => {
      setClientTypes(result);
    });
  }, [versionTag]);

  const createMap = async (map: MenuItem) => {
    const newMap = await _createMap(versionTag, map);
    // The sequence of the menuItems has been updated by the backend. Reload maps
    const newItems = await getMenuItems(versionTag);
    setMenuItems(newItems);
    const currentItem = newItems.filter((i) => i.id === newMap.id)[0];
    currentItem.mapElementReferences = await getMapReferences(versionTag, newMap);
    setCurrentMenuItem(currentItem);
    return newMap;
  };

  const updateMap = async (map: MenuItem) => {
    const upadatedMap = await _updateMap(versionTag, map);
    const updatedItems = await getMenuItems(versionTag);
    setMenuItems(updatedItems);
    const currentItem = updatedItems.filter((i) => i.id === map.id)[0];
    currentItem.mapElementReferences = await getMapReferences(versionTag, map);
    setCurrentMenuItem(currentItem);

    return Promise.resolve(map);
  };

  const createMenu = async (path: string, menuName: string) => {
    const newMenu = await _createMenu(versionTag, {
      menuItemType: "MENU",
      id: -1,
      parentId: null,
      code: -1,
      name: menuName,
      path: path === "/" ? [] : path.split("/"),
      mapElementReferences: [],
      sequence: -1,
      userTags: [],
      clientTypes: []
    });
    // The sequence of the menuItems has been updated by the backend. Reload maps
    const newItems = await getMenuItems(versionTag);
    const paths = await getPaths(versionTag);
    setMenuItems(newItems);
    setPaths(paths);
    setCurrentMenuItem(newItems.filter((i) => i.id === newMenu.id)[0]);
    return newMenu;
  };

  const deleteMap = async (map: MenuItem) => {
    return _deleteMap(versionTag, map).then((result) => {
      setMenuItems([...menuItems.filter((m) => m.id !== result)]);
    });
  };

  const deleteMenu = async (menu: MenuItem) => {
    setLoading(true);
    const result = await _deleteMenu(versionTag, menu);
    const menuItems = await getMenuItems(versionTag);
    const paths = await getPaths(versionTag);
    setMenuItems(menuItems);
    setCurrentMenuItem(undefined);
    setPaths(paths);

    setLoading(false);
    return result;
  };

  const moveSelectedMenuItemUp = async () => {
    setLoading(true);
    await _moveMenuItemUp(versionTag, currentMenuItem!);
    const menuItems = await getMenuItems(versionTag);
    const currentItem = menuItems.filter((i) => i.id === currentMenuItem!.id)[0];
    currentItem.mapElementReferences = await getMapReferences(versionTag, currentItem!);

    setMenuItems(menuItems);
    setCurrentMenuItem(currentItem);

    setLoading(false);
  };

  const moveSelectedMenuItemDown = async () => {
    setLoading(true);
    await _moveMenuItemDown(versionTag, currentMenuItem!);
    const menuItems = await getMenuItems(versionTag);
    const currentItem = menuItems.filter((i) => i.id === currentMenuItem!.id)[0];
    currentItem.mapElementReferences = await getMapReferences(versionTag, currentItem!);

    setMenuItems(menuItems);
    setCurrentMenuItem(currentItem);

    setLoading(false);
  };

  const moveSelectedMenuItemTo = async (path: string, moveItemToTop: boolean) => {
    setLoading(true);
    await _moveMenuItemTo(versionTag, currentMenuItem!, path, moveItemToTop);
    const menuItems = await getMenuItems(versionTag);
    const currentItem = menuItems.filter((i) => i.id === currentMenuItem!.id)[0];
    currentItem.mapElementReferences = await getMapReferences(versionTag, currentItem!);
    setMenuItems(menuItems);
    setCurrentMenuItem(currentItem);
    setLoading(false);
  };

  const deleteMapReference = async (mapElement: MapElementReference) => {
    const copy = _.cloneDeep(currentMenuItem!);
    await _deleteMapReference(versionTag, currentMenuItem!, mapElement);
    copy.mapElementReferences = copy.mapElementReferences.filter((ref) => ref.id !== mapElement.id);
    setMenuItems([...menuItems.filter((m) => m.id !== currentMenuItem!.id), copy]);
    setCurrentMenuItem(copy);
  };

  const selectMenuItem = async (rowId: number | string) => {
    const menuItem = menuItems.filter((i) => i.id === rowId)[0];
    if (menuItem.menuItemType === "MAP") {
      const mapElementReferences = await getMapReferences(versionTag, menuItem);
      menuItem.mapElementReferences = mapElementReferences;
    }
    setCurrentMenuItem(menuItem);
  };

  const searchReferences = async (searchCriteria: SearchCriteria) => {
    const result = await _searchReferences(versionTag, searchCriteria);
    return result;
  };

  return {
    loading,
    menuItems,
    currentMenuItem,
    paths,
    clientTypes,
    createMap,
    updateMap,
    deleteMap,
    createMenu,
    deleteMenu,
    moveSelectedMenuItemUp,
    moveSelectedMenuItemDown,
    moveSelectedMenuItemTo,
    searchReferences,
    getNextSequenceValue,
    selectMenuItem,
    deleteMapReference
  };
};

export default useMaps;
