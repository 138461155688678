import { AdditionalInformation, Point, Xref } from "src/types/Types";
import { getAPIClient } from "../apiClient";

type PointsResponse = {
  data: Point[];
  errorMessage: string | null;
};

type PointXrefsResponse = {
  data: Xref[];
  errorMessage: string | null;
};

type AddPointResponse = {
  data: Point;
  errorMessage: string | null;
};

type UpdatePointResponse = {
  data: Point;
  errorMessage: string | null;
};

type DeletePointResponse = {
  data: number;
  errorMessage: string;
};

type GetInformationResponse = {
  data: AdditionalInformation[];
  errorMessage: string;
};

type AddInformationResponse = {
  data: AdditionalInformation;
  errorMessage: string;
};

type DeleteInformationResponse = {
  data: AdditionalInformation[];
  errorMessage: string;
};

export const getPoints = async (versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<PointsResponse>("/tables/point", { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const getPointsFromIds = async (versionTag: string, pointIds: string) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.get<PointsResponse>(`/tables/point?pointIds=${pointIds}`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const getPointXrefs = async (versionTag: string, point: Point) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<PointXrefsResponse>(`/tables/point/${point.id}/x-ref`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const addPoint = async (versionTag: string, point: Point) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<AddPointResponse>("/tables/point", point, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const updatePoint = async (versionTag: string, point: Point) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.put<UpdatePointResponse>(`/tables/point/${point.id}`, point, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const deletePoint = async (versionTag: string, point: Point) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.delete<DeletePointResponse>(`/tables/point/${point.id}`, { headers: { "x-air-version": versionTag } });
};

export const getInformation = async (versionTag: string, point: Point) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<GetInformationResponse>(`/tables/point/${point.id}/additional-information`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const addInformation = async (versionTag: string, point: Point, info: AdditionalInformation) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<AddInformationResponse>(`/tables/point/${point.id}/additional-information`, info, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const deleteInformation = async (versionTag: string, point: Point, info: AdditionalInformation) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.delete<DeleteInformationResponse>(`/tables/point/${point.id}/additional-information/${info.id}`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const updateInformation = async (versionTag: string, point: Point, info: AdditionalInformation) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.put<AddInformationResponse>(`/tables/point/${point.id}/additional-information/${info.id}`, info, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};
