import { Autocomplete, TextField } from "@mui/material";

type AreaTypeSelectorProps = {
  value?: string | null;
  onChange?: (newValue: string | null) => void;
  label?: string;
};

const AreaTypeSelector = (props: AreaTypeSelectorProps) => {
  const { label, value, onChange } = props;
  return (
    <Autocomplete
      fullWidth
      size="small"
      options={["prohibited", "restricted", "danger", "glider", "parachute", "other"]}
      value={value}
      onChange={(event, value) => {
        onChange?.(value);
      }}
      renderInput={(params) => <TextField variant="standard" {...params} label={label} value={value} />}
    />
  );
};

export default AreaTypeSelector;
