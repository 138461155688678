import { useEffect, useState } from "react";
import { MapSymbol } from "src/types/Types";
import { useVersionTag } from "../hooks/useVersionTag";
import { getSymbols, getSymbolWithColor as _getSymbolWithColor, createSymbol as _createSymbol, updateSymbol as _updateSymbol, deleteSymbol as _deleteSymbol } from "./symbolsApi";

export const useMapSymbols = () => {
  const versionTag = useVersionTag();
  const [symbols, setSymbols] = useState<MapSymbol[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedSymbol, setSelectedSymbol] = useState<MapSymbol>();

  useEffect(() => {
    setLoading(true);
    getSymbols(versionTag)
      .then((response) => {
        setSymbols(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [versionTag]);

  const getSymbolWithColor = (symbolName: string, color: string) => {
    return _getSymbolWithColor(versionTag, symbolName, color);
  };

  const selectSymbol = (symbol: MapSymbol) => {
    setSelectedSymbol(symbol);
  };

  const createSymbol = async (symbol: MapSymbol) => {
    const result = await _createSymbol(versionTag, symbol);
    setSymbols([...symbols, result]);
    setSelectedSymbol(result);
    return result;
  };

  const updateSymbol = async (symbol: MapSymbol) => {
    const result = await _updateSymbol(versionTag, symbol);
    setSymbols([...symbols.filter((s) => s.id !== symbol.id), result]);
    setSelectedSymbol(result);
    return result;
  };

  const deleteSymbol = async (symbol: MapSymbol) => {
    await _deleteSymbol(versionTag, symbol);
    setSymbols([...symbols.filter((s) => s.id !== symbol.id)]);
  };

  return { loading, symbols, selectedSymbol, getSymbolWithColor, selectSymbol, createSymbol, updateSymbol, deleteSymbol };
};
