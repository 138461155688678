import { useEffect, useState } from "react";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { getHighways } from "src/apis/swisstopo/highwaysApi";
import { PolyLineWithAttributes } from "src/types/Types";

const useHighways = () => {
  const versionTag = useVersionTag();
  const [highways, setHighways] = useState<PolyLineWithAttributes[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getHighways(versionTag)
      .then((response) => {
        setHighways(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [versionTag]);

  return {
    loading,
    highways: highways
  };
};

export default useHighways;
