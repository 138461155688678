import * as _ from "lodash";
import { PolyLineWithAttributes } from "src/types/Types";
import { getAPIClient } from "../apiClient";

type RailwaysResponse = {
  data: PolyLineWithAttributes[];
  errorMessage: string | null;
};

export const getRailways = async (versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<RailwaysResponse>("/swisstopo/railways", { headers: { "x-air-version": versionTag } });
  return response.data.data.map((co) => {
    return {
      ...co,
      id: _.uniqueId()
    };
  });
};

export const getRailwaysFromIds = async (versionTag: string, ids: number[]) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<RailwaysResponse>("/swisstopo/railways/search", { searchIds: ids }, { headers: { "x-air-version": versionTag } });
  return response.data.data.map((rw) => {
    return {
      ...rw,
      id: rw.attributes.id!
    };
  });
};
