import { useEffect, useState } from "react";
import { AdditionalInformation, MapText } from "src/types/Types";
import { useVersionTag } from "../hooks/useVersionTag";
import { getWgs84LatLng } from "../waypoints/waypointsApi";
import {
  createAdditionalInformation as _createAdditionalInformation,
  createMapText as _createMapText,
  deleteAdditionalInformation as _deleteAdditionalInformation,
  deleteMapText as _deleteMapText,
  updateAdditionalInformation as _updateAdditionalInformation,
  updateMapText as _updateMapText,
  getMapTexts
} from "./mapTextsApi";

import * as _ from "lodash";

export const useMapTexts = () => {
  const versionTag = useVersionTag();
  const [mapTexts, setMapTexts] = useState<MapText[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedMapText, setSelectedMapText] = useState<MapText>();

  const selectMapText = (mapText: MapText) => {
    setSelectedMapText(mapText);
  };

  const createMapText = async (mapText: MapText) => {
    let lastIdValue = 0;
    if (mapTexts.length > 0) {
      const sortedByIdTexts = [...mapTexts].sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0));
      lastIdValue = +sortedByIdTexts[sortedByIdTexts.length - 1].id;
    }
    mapText.id = lastIdValue + 1;

    const newMapText = await _createMapText(versionTag, mapText);
    setMapTexts([...mapTexts, newMapText]);
    setSelectedMapText(newMapText);
    return newMapText;
  };

  const updateMapText = async (mapText: MapText) => {
    const latLng = await getWgs84LatLng(mapText.dmsLatitude, mapText.dmsLongitude);
    mapText.wgs84Latitude = latLng.wgs84Latitude;
    mapText.wgs84Longitude = latLng.wgs84Longitude;
    const updatedMapText = await _updateMapText(versionTag, mapText);
    setMapTexts([...mapTexts.filter((mt) => mt.id !== mapText.id), updatedMapText]);
    setSelectedMapText(updatedMapText);
    return updatedMapText;
  };

  const deleteMapText = async (mapText: MapText) => {
    await _deleteMapText(versionTag, mapText);
    setMapTexts(mapTexts.filter((t) => t.id !== mapText.id));
  };

  const createAdditionalInformation = async (info: AdditionalInformation) => {
    const result = await _createAdditionalInformation(versionTag, selectedMapText!, info);
    const copy = _.cloneDeep(selectedMapText!);
    copy.additionalInformation.push(result);
    setMapTexts([...mapTexts.filter((l) => l.id !== selectedMapText!.id), copy]);
    setSelectedMapText(copy);
    return result;
  };

  const updateAdditionalInformation = async (info: AdditionalInformation) => {
    const result = await _updateAdditionalInformation(versionTag, selectedMapText!, info);
    const copy = _.cloneDeep(selectedMapText!);
    copy.additionalInformation = [...copy.additionalInformation.filter((i) => i.id !== result.id), result];
    setMapTexts([...mapTexts.filter((l) => l.id !== selectedMapText!.id), copy]);
    setSelectedMapText(copy);
    return result;
  };

  const deleteAdditionalInformation = async (info: AdditionalInformation) => {
    const result = await _deleteAdditionalInformation(versionTag, selectedMapText!, info);
    const copy = _.cloneDeep(selectedMapText!);
    copy.additionalInformation = result;
    setMapTexts([...mapTexts.filter((l) => l.id !== selectedMapText!.id), copy]);
    setSelectedMapText(copy);
  };

  useEffect(() => {
    setLoading(true);
    getMapTexts(versionTag)
      .then((response) => {
        setMapTexts(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [versionTag]);
  return {
    loading,
    mapTexts,
    selectMapText,
    selectedMapText,
    createMapText,
    updateMapText,
    deleteMapText,
    createAdditionalInformation,
    updateAdditionalInformation,
    deleteAdditionalInformation
  };
};
