import { Autocomplete, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useUserTags } from "src/apis/usertags/useUserTags";
import LatitudeMask from "src/components/editors/LatitudeMask";
import LongitudeMask from "src/components/editors/LongitudeMask";
import { useDmsToWgs64 } from "src/components/editors/hooks/useLatLngConversions";
import MapViewer from "src/components/mapviewer/MapViewer";
import { Waypoint } from "src/types/Types";
import useAddWaypointFormHandler from "./useAddWaypointFormHandler";

export type AddWaypointFormHandle = {
  requestSubmit: () => void;
};

type AddWaypointFormProps = {
  createWaypoint: (waypoint: Waypoint) => Promise<Waypoint>;
  onWaypointCreated: () => void;
};

const AddWaypointForm: React.ForwardRefRenderFunction<AddWaypointFormHandle, AddWaypointFormProps> = (props: AddWaypointFormProps, forwardedRef) => {
  const { createWaypoint, onWaypointCreated } = props;
  const formRef = useRef<HTMLFormElement>(null);

  React.useImperativeHandle(forwardedRef, () => ({
    requestSubmit() {
      formRef.current?.requestSubmit();
    }
  }));

  const { formHandler } = useAddWaypointFormHandler(createWaypoint, onWaypointCreated);

  const { latLng } = useDmsToWgs64(formHandler.values.latitude, formHandler.values.longitude);
  const [waypointName, setWaypointName] = useState<string>("");
  const { userTagNames } = useUserTags();

  const onRadioChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    console.log("value: " + value);
    switch (value) {
      case "wgs84":
        console.log("converting from dms to wgs84");
        break;
      case "dms":
        console.log("converting from wgs84 to decimal");
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    setWaypointName(formHandler.values.name);
  }, [formHandler.values.name]);

  return (
    <form ref={formRef} onSubmit={formHandler.handleSubmit}>
      <div style={{ width: "1200px", height: "600px", display: "flex", flexDirection: "row" }}>
        {/*<div style={{ color: "red", height: "5%" }}>{"General error message"}</div>*/}
        <div style={{ flex: 1.1 }}>
          <h3>General information</h3>
          <div style={{ display: "flex" }}>
            <div>
              <TextField
                id="name"
                name="name"
                size="small"
                variant="standard"
                value={formHandler.values.name}
                label="Name"
                autoComplete="off"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  formHandler.setFieldValue("name", event.target.value.toUpperCase());
                }}
                error={formHandler.touched.name && Boolean(formHandler.errors.name)}
                helperText={formHandler.touched.name && formHandler.errors.name}
              />
            </div>
            <div style={{ marginLeft: "15px", flex: 1 }}>
              <TextField
                id="shortName"
                name="shortName"
                size="small"
                variant="standard"
                fullWidth
                value={formHandler.values.shortName}
                label="Short name"
                autoComplete="off"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  formHandler.setFieldValue("shortName", event.target.value.toUpperCase());
                }}
                error={formHandler.touched.shortName && Boolean(formHandler.errors.shortName)}
                helperText={formHandler.touched.shortName && formHandler.errors.shortName}
              />
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            <TextField
              size="small"
              variant="standard"
              fullWidth
              id="description"
              name="description"
              label="description"
              autoComplete="off"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                formHandler.setFieldValue("description", event.target.value);
              }}
            />
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div>
              <TextField
                id="area"
                name="area"
                size="small"
                variant="standard"
                value={formHandler.values.area}
                label="Area"
                autoComplete="off"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  formHandler.setFieldValue("area", event.target.value.toUpperCase());
                }}
                error={formHandler.touched.area && Boolean(formHandler.errors.area)}
                helperText={formHandler.touched.area && formHandler.errors.area}
              />
            </div>
            <div style={{ marginLeft: "15px", flex: 1 }}>
              <Autocomplete
                size="small"
                options={["LSAZ", "LSAG"]}
                multiple
                onChange={(event, value) => {
                  formHandler.setFieldValue("aor", value);
                }}
                renderInput={(params) => <TextField variant="standard" {...params} label="A.o.R" value={formHandler.values.userTags} onChange={formHandler.handleChange} />}
              />
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ marginTop: "10px", flex: 1 }}>
              <Autocomplete
                fullWidth
                size="small"
                options={userTagNames}
                multiple
                onChange={(event, value) => {
                  formHandler.setFieldValue("userTags", value);
                }}
                renderInput={(params) => <TextField variant="standard" {...params} label="User tags" value={formHandler.values.userTags} onChange={formHandler.handleChange} />}
              />
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div>
              <TextField
                id="frequency"
                name="frequency"
                size="small"
                variant="standard"
                value={formHandler.values.frequency}
                label="Frequency"
                autoComplete="off"
                onChange={formHandler.handleChange}
                error={formHandler.touched.frequency && Boolean(formHandler.errors.frequency)}
                helperText={formHandler.touched.frequency && formHandler.errors.frequency}
              />
            </div>
          </div>
          <h3>Coordinates</h3>
          <div style={{ display: "flex" }}>
            <div>
              <LatitudeMask
                value={formHandler.values.latitude}
                onChange={(newValue) => {
                  formHandler.setFieldValue("latitude", newValue);
                }}
                error={formHandler.touched.latitude && Boolean(formHandler.errors.latitude)}
                helperText={formHandler.touched.latitude && formHandler.errors.latitude}
              />
            </div>
            <div style={{ marginLeft: "15px" }}>
              <LongitudeMask
                value={formHandler.values.longitude}
                onChange={(newValue) => {
                  formHandler.setFieldValue("longitude", newValue);
                }}
                error={formHandler.touched.latitude && Boolean(formHandler.errors.longitude)}
                helperText={formHandler.touched.latitude && formHandler.errors.longitude}
              />
            </div>
          </div>
          <div style={{ marginTop: "5px" }}>
            <RadioGroup defaultValue="dms" row onChange={onRadioChange}>
              <FormControlLabel value="dms" control={<Radio />} label="dms" />
              <FormControlLabel value="wgs84" control={<Radio />} label="decimal" disabled />
            </RadioGroup>
          </div>
        </div>
        <div style={{ flex: 1.5, height: "100%", marginLeft: "20px" }}>
          <MapViewer namedCoordinate={latLng ? { wgs84LatLng: latLng, name: waypointName } : undefined} />
        </div>
      </div>
    </form>
  );
};

export default React.forwardRef(AddWaypointForm);
