import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { Xref } from "src/types/Types";

type XRefGridProps = {
  xrefs: Xref[];
};

const XRefGrid = (props: XRefGridProps) => {
  const { xrefs } = props;

  const columns: GridColumns<Xref> = [
    {
      field: "sourceType",
      headerName: "Source Type",
      width: 200,
      editable: false
    },
    {
      field: "sourceName",
      headerName: "Source Name",
      width: 200,
      editable: false
    },
    {
      field: "destinationType",
      headerName: "Destination Type",
      width: 200,
      editable: false
    },
    {
      field: "destinationName",
      headerName: "Destination Name",
      width: 200,
      editable: false
    }
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <DataGridPro density="compact" columns={columns} rows={xrefs} getRowId={(row) => `${row.sourceId}/${row.destinationId}`} />
    </div>
  );
};

export default XRefGrid;
