import { Version } from "src/pages/versions/Versions";
import { AppState, initialState, UserAuthentication } from "./AppState";

export type Action =
  | { type: "setVersions"; payload: Version[] }
  | { type: "setVersion"; payload: Version | undefined }
  | { type: "setUserAuthentication"; payload: UserAuthentication }
  | { type: "logout" }
  | { type: "newMenusGridRowCreated"; payload: number };

export const appReducer = (prevState: AppState, action: Action): AppState => {
  switch (action.type) {
    case "setVersions":
      return { ...prevState, versions: action.payload };
    case "setVersion":
      return { ...prevState, currentVersion: action.payload };
    case "setUserAuthentication":
      return {
        ...prevState,
        versions: action.payload.versions,
        authenticationToken: { token: action.payload.token },
        apiClient: action.payload.apiClient,
        ws: action.payload.ws
      };
    case "logout":
      return initialState;
    case "newMenusGridRowCreated":
      return { ...prevState, newMenuGridRowId: action.payload };
    default:
      return prevState;
  }
};
