import { NamedPolygons } from "src/types/Types";
import { getAPIClient } from "../apiClient";

type UrbanAreasResponse = {
  data: NamedPolygons[];
  errorMessage: string | null;
};

export const getUrbanAreas = async (versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<UrbanAreasResponse>("/swisstopo/urban_areas", { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const getUrbanAreasFromIds = async (versionTag: string, urbanAreasIds: number[]) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<UrbanAreasResponse>(`/swisstopo/urban_areas/search`, { searchIds: urbanAreasIds }, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};
