import { FieldsValidator } from "./Validator";

export class EmptyValidator implements FieldsValidator {
  hasValidationErrors() {
    return false;
  }
  validateField = (fieldName: string, fieldValue: any) => {
    return "";
  };
}
