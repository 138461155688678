import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import OrientationMask from "./OrientationMask";

const DataGridOrientationMask = (params: GridRenderEditCellParams) => {
  const { id, value, field, error } = params;
  const apiRef = useGridApiContext();

  const onOrientationChange = (newOrientation: string) => {
    apiRef.current.setEditCellValue({ id, field, value: newOrientation });
  };

  return <OrientationMask value={value} error={error} onOrientationChanged={onOrientationChange} />;
};

export default DataGridOrientationMask;
