import { Autocomplete, TextField } from "@mui/material";

type PathSelectorProps = {
  paths: string[];
  value?: string | null;
  onChange?: (newPath: string | null) => void;
  error?: boolean;
  helperText?: string | false;
};

const PathSelector = (props: PathSelectorProps) => {
  const { paths, value, onChange, error, helperText } = props;
  return (
    <Autocomplete
      options={paths}
      value={value || null}
      onChange={(event, value) => {
        onChange?.(value);
      }}
      renderInput={(params) => <TextField {...params} label="Path" variant="standard" size="small" error={error} helperText={helperText} />}
    />
  );
};

export default PathSelector;
