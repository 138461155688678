import { Autocomplete, TextField } from "@mui/material";
import { GraphicAttribute, GraphicAttributeType } from "src/types/Types";

type GraphicAttributeSelectorProps = {
  label?: string;
  graphicAttributes: GraphicAttribute[];
  selection?: string | null;
  attributeType?: GraphicAttributeType;
  disabled?: boolean;
  onChange?: (newValue: GraphicAttribute) => void;
};

const GraphicAttributeSelector = (props: GraphicAttributeSelectorProps) => {
  const { label, selection, attributeType, disabled, onChange, graphicAttributes } = props;

  return (
    <Autocomplete
      disabled={disabled ? disabled : false}
      fullWidth
      size="small"
      options={graphicAttributes.filter((ga) => ga.type === attributeType).map((ga) => ga.name)}
      value={selection}
      onChange={(event, value) => {
        const graphicAttribute = graphicAttributes?.filter((g) => g.name === value)[0];
        onChange?.(graphicAttribute);
      }}
      renderInput={(params) => <TextField variant="standard" {...params} label={label} />}
    />
  );
};

export default GraphicAttributeSelector;
