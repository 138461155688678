import { useRef } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import AirportIcon from "@mui/icons-material/FlightTakeoff";

import { Airport } from "src/types/Types";
import AddAirportForm, { AddAirportFormHandle } from "src/pages/airports/forms/AddAirportForm";

type AddAirportDialogProps = {
  createAirport: (airport: Airport) => Promise<Airport>;
  onCancel: () => void;
  onAirportCreated: () => void;
};

const AddAirportDialog = (props: AddAirportDialogProps) => {
  const { createAirport, onCancel, onAirportCreated } = props;
  const addAirportFormRef = useRef<AddAirportFormHandle>(null);

  return (
    <Dialog maxWidth="xl" open={true}>
      <DialogTitle>
        <div style={{ display: "flex" }}>
          <div>
            <AirportIcon />
          </div>
          <div style={{ marginLeft: "10px" }}>Add Airport</div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <AddAirportForm createAirport={createAirport} onAirportCreated={onAirportCreated} ref={addAirportFormRef} />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="contained"
          onClick={() => {
            addAirportFormRef.current?.requestSubmit();
          }}
        >
          Add
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAirportDialog;
