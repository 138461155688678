import { CatUser } from "src/types/Types";
import { getAPIClient } from "../apiClient";

type UsersResponse = {
  data: Array<CatUser>;
};

type CreateCatUserResponse = {
  data: CatUser;
  errorMessage: string;
};

export const getCatUsers = (versionTag: string) => {
  const apiClient = getAPIClient();
  return apiClient!.get<UsersResponse>("/users", { headers: { "x-air-version": versionTag } }).then((response) => {
    return response.data.data;
  });
};

export const createCatUser = async (versionTag: string, user: CatUser) => {
  const apiClient = getAPIClient();
  const result = await apiClient!.post<CreateCatUserResponse>("/users", user, { headers: { "x-air-version": versionTag } });
  return result.data.data;
};

export const deleteCatUser = async (user: CatUser) => {
  const apiClient = getAPIClient();
  await apiClient!.delete<CreateCatUserResponse>("/users/" + user.id);
};
