import React from "react";
import { forwardRef, Ref, useEffect, useState } from "react";

import {
  DataGridPro,
  GridActionsCellItem,
  GridEventListener,
  GridPinnedColumns,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowOrderChangeParams,
  GridRowParams,
  GridSelectionModel,
  GridSlotsComponent,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  MuiEvent,
  useGridApiRef
} from "@mui/x-data-grid-pro";

import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { Button } from "@mui/material";

import { isUndefined } from "lodash";
import { toast } from "react-toastify";
import { ColumnsGenerator, Identifiable } from "src/types/Types";
import { FieldsValidator } from "src/validators/Validator";
import DeleteRowsDialog from "./DeleteRowsDialog";

export type GenericDataGridHandle = {
  isInEditMode: () => boolean;
  cancelEdit: () => void;
};

type GenericDataGridProps<T extends Identifiable> = {
  data: T[];
  loading?: boolean;
  columnsGenerator: ColumnsGenerator;
  validator: FieldsValidator;
  createEmptyRow?: () => T;
  createDialogFactory?: (onSuccess: () => void, onCancel: () => void) => JSX.Element;
  updateDialogFactory?: (onSuccess: () => void, onCancel: () => void) => JSX.Element;
  onCreateRow?: (row: T) => Promise<T>;
  onUpdateRow?: (row: T) => Promise<T>;
  onDeleteRow?: (row: T) => Promise<void>;
  onDeleteRows?: (rows: T[]) => Promise<void>;
  onSelectRow?: (row: T) => void;
  onSelectedRows?: (rows: T[]) => void;
  onRowOrderChange?: (params: GridRowOrderChangeParams) => void;
  canInsertRow?: boolean;
  selectedRow?: T;
  deleteDialogTitle?: string;
  deleteDialogDisplayModel?: [{ objectKey: string; objectLabel: string }];
  sortModel?: GridSortModel;
  pinnedColumns?: GridPinnedColumns;
  disableActions?: boolean;
  customComponent?: Partial<GridSlotsComponent>;
  additionalToolbarComponents?: JSX.Element;
};

const GenericDataGrid = <T extends Identifiable>(props: GenericDataGridProps<T>, forwardedRef: React.ForwardedRef<GenericDataGridHandle>) => {
  const {
    data,
    loading,
    columnsGenerator,
    validator,
    onCreateRow,
    onUpdateRow,
    onDeleteRow,
    onDeleteRows,
    onSelectRow,
    onSelectedRows,
    onRowOrderChange,
    createEmptyRow,
    canInsertRow,
    selectedRow,
    sortModel,
    createDialogFactory: createRowDialogFactory,
    updateDialogFactory,
    deleteDialogTitle,
    pinnedColumns,
    disableActions,
    customComponent,
    additionalToolbarComponents
  } = props;

  React.useImperativeHandle(forwardedRef, () => ({
    isInEditMode() {
      return isInEdit;
    },
    cancelEdit() {
      setInEdit(false);
    }
  }));

  const apiRef = useGridApiRef();

  const [rows, setRows] = useState<T[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const [isInEdit, setInEdit] = useState(false);
  const [isRowDirty, setRowDirty] = useState(false);

  const [rowToDelete, setRowToDelete] = useState<T>();
  const [rowsToDelete, setRowsToDelete] = useState<T[]>();

  const [showCreateRowDialog, setShowCreateRowDialog] = useState(false);
  const [showUpdateRowDialog, setShowUpdateRowDialog] = useState(false);

  const onFieldDirty = () => {
    setRowDirty(true);
  };

  const lastUpdate = {
    field: "lastUpdate",
    headerName: "Last update",
    width: 200,
    hide: true
  };

  const lastUserUpdate = {
    field: "lastUserUpdate",
    headerName: "Last user update",
    hide: true
  };

  const actions = {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    width: 100,
    cellClassName: "actions",
    getActions: ({ id }: { id: number | string }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
      if (isInEditMode) {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            onResizeCapture={() => {}}
            onResize={() => {}}
            onClick={handleSaveClick(id)}
            disabled={!isRowDirty || validator.hasValidationErrors()}
          />,
          <GridActionsCellItem
            icon={<CancelIcon />}
            label="Cancel"
            onResizeCapture={() => {}}
            onResize={() => {}}
            className="textPrimary"
            onClick={handleCancelClick(id)}
            color="inherit"
          />
        ];
      }

      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onResizeCapture={() => {}}
          onResize={() => {}}
          disabled={disableActions === true}
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onResizeCapture={() => {}}
          onResize={() => {}}
          disabled={disableActions === true}
          onClick={handleDeleteClick(id)}
          color="inherit"
        />
      ];
    }
  };

  const columns = disableActions
    ? [...columnsGenerator(validator, onFieldDirty), lastUpdate, lastUserUpdate]
    : [...columnsGenerator(validator, onFieldDirty), lastUpdate, lastUserUpdate, actions];

  useEffect(() => {
    setRows(data);
  }, [data]);

  useEffect(() => {
    if (selectedRow) {
      setSelectionModel([selectedRow.id]);
      setTimeout(() => {
        apiRef.current.scrollToIndexes({ rowIndex: apiRef.current.getRowIndex(selectedRow.id) });
      }, 500);
    } else {
      setSelectionModel([]);
    }
  }, [selectedRow]);

  useEffect(() => {
    if (onSelectedRows) {
      onSelectedRows(rows.filter((r) => selectionModel.includes(r.id)));
    }
  }, [selectionModel]);

  /* prevent double click to go in edit mode */
  const preventRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  /* prevent ESC or ENTER key */
  const preventRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const onInsertRow = () => {
    if (createRowDialogFactory) {
      setShowCreateRowDialog(true);
    } else if (createEmptyRow) {
      setInEdit(true);
      const id = -1;
      setRows((oldRows) => [...oldRows, createEmptyRow()]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit }
      }));
      setTimeout(() => {
        apiRef.current.scrollToIndexes({ rowIndex: apiRef.current.getRowIndex(-1) });
      }, 500);
    } else {
      toast.error("You must either define createDialogFactory or createEmptyRowFactory");
    }
  };

  const onRowClick = (rowParams: GridRowParams<T>) => {
    if (onSelectRow) {
      onSelectRow(rowParams.row);
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    if (updateDialogFactory) {
      setShowUpdateRowDialog(true);
    } else {
      setInEdit(true);
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    }
  };

  const handleSaveClick = (id: GridRowId) => () => {
    if (!validator.hasValidationErrors()) {
      setInEdit(false);
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
      setRowDirty(false);
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setInEdit(false);
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });
    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.id === -1) {
      setRows(rows.filter((row) => row.id !== id));
    }
    setRowDirty(false);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    if (selectionModel.length > 1) {
      setRowsToDelete(rows.filter((r) => selectionModel.includes(r.id)));
      setRowToDelete(undefined);
    } else {
      setRowToDelete(rows.filter((r) => r.id === id)[0]);
    }
  };

  const onConfirmDelete = () => {
    onDeleteRows &&
      !isUndefined(rowsToDelete) &&
      onDeleteRows(rowsToDelete)
        .then(() => {
          setRowsToDelete(undefined);
          toast.success("Rows deleted");
        })
        .catch((error) => {
          if (error.errorMessage) {
            toast.error("Failed to delete rows: " + error.errorMessage);
          } else if (typeof error === "string") {
            toast.error("Failed to delete rows: " + error);
          } else {
            toast.error("Failed to delete rows");
          }
        });

    onDeleteRow &&
      !isUndefined(rowToDelete) &&
      onDeleteRow(rowToDelete)
        .then(() => {
          setRowToDelete(undefined);
          toast.success("Row deleted");
        })
        .catch((error) => {
          if (error.errorMessage) {
            toast.error("Failed to delete row: " + error.errorMessage);
          } else if (typeof error === "string") {
            toast.error("Failed to delete row: " + error);
          } else {
            toast.error("Failed to delete row");
          }
        });
  };

  const onCancelDelete = () => {
    setRowToDelete(undefined);
    setRowsToDelete(undefined);
  };

  const processRowUpdate = async (newRow: T, oldRow: T) => {
    if (onUpdateRow === undefined) return Promise.reject(newRow);
    if (newRow.id === -1) {
      if (onCreateRow) {
        return onCreateRow(newRow)
          .then((row) => {
            toast.success("Row created");
            return row;
          })
          .catch((error) => {
            if (error.errorMessage) {
              toast.error(error.errorMessage);
            } else {
              console.log(error);
              toast.error("Failed to create row.");
            }
            return Promise.reject(newRow);
          });
      } else {
        toast.error("onCreateRow is undefined");
        return Promise.reject(newRow);
      }
    } else {
      if (JSON.stringify(newRow) === JSON.stringify(oldRow)) {
        return Promise.resolve(newRow);
      }
      return onUpdateRow(newRow)
        .then((row) => {
          toast.success("Row updated");
          return row;
        })
        .catch((error) => {
          if (typeof error === "object") {
            toast.error(error.errorMessage);
          } else {
            toast.error(error);
          }
          return Promise.reject(newRow);
        });
    }
  };

  const onProcessRowUpdateError = (row: T) => {
    // the following code should not be necessary but it is the only way to make sure the
    // row stays in edit mode
    setRowModesModel({ ...rowModesModel, [row.id]: { mode: GridRowModes.Edit } });
    setRowDirty(true);
  };

  const Toolbar = () => {
    return (
      <GridToolbarContainer>
        <div style={{ marginRight: 15 }}>
          <GridToolbarQuickFilter debounceMs={600} />
        </div>
        <Button color="primary" startIcon={<AddIcon />} onClick={onInsertRow} disabled={isInEdit || canInsertRow === false}>
          Add Row
        </Button>
        {additionalToolbarComponents}
      </GridToolbarContainer>
    );
  };

  const onSuccess = () => {
    setShowCreateRowDialog(false);
    toast.success("Row created");
  };

  const onUpdateSuccess = () => {
    setShowUpdateRowDialog(false);
    toast.success("Row updated");
  };

  const onCancel = () => {
    setShowCreateRowDialog(false);
  };

  const onCancelUpdate = () => {
    setShowUpdateRowDialog(false);
  };

  const handleRowOrderChange = (params: GridRowOrderChangeParams) => {
    if (onRowOrderChange) {
      //console.log(params.oldIndex);
      //console.log(params.targetIndex);
      onRowOrderChange(params);
      apiRef.current.setSelectionModel([]);
    }
  };

  return (
    <>
      {showCreateRowDialog && createRowDialogFactory?.(onSuccess, onCancel)}
      {showUpdateRowDialog && updateDialogFactory?.(onUpdateSuccess, onCancelUpdate)}
      {rowToDelete && <DeleteRowsDialog title={deleteDialogTitle} rows={[rowToDelete]} onConfirmDelete={onConfirmDelete} onCancelDelete={onCancelDelete} />}
      {rowsToDelete && <DeleteRowsDialog title={deleteDialogTitle} rows={rowsToDelete} onConfirmDelete={onConfirmDelete} onCancelDelete={onCancelDelete} />}
      <DataGridPro
        apiRef={apiRef}
        experimentalFeatures={{ newEditingApi: true }}
        editMode="row"
        density="compact"
        columns={columns}
        pinnedColumns={pinnedColumns ? pinnedColumns : { left: [] }}
        rows={rows}
        loading={loading}
        components={isUndefined(customComponent) ? { Toolbar: Toolbar } : customComponent}
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        onRowEditStart={preventRowEditStart}
        onRowEditStop={preventRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={onProcessRowUpdateError}
        onRowClick={onRowClick}
        initialState={{
          sorting: {
            sortModel: sortModel
          }
        }}
        rowReordering={onRowOrderChange ? true : undefined}
        onRowOrderChange={handleRowOrderChange}
      />
    </>
  );
};

export default forwardRef(GenericDataGrid) as <T extends Identifiable>(props: GenericDataGridProps<T> & { ref?: Ref<GenericDataGridHandle> }) => ReturnType<typeof GenericDataGrid>;
