import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import { DataGridPro, GridApi, GridColumns, GridRenderCellParams, GridToolbarContainer } from "@mui/x-data-grid-pro";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ImportResult } from "src/types/ImporterApiTypes";
import { v4 as uuidv4 } from "uuid";

type ImportReportGridProps = {
  importResults: ImportResult[];
};

const ImportReportGrid = (props: ImportReportGridProps) => {
  const { importResults } = props;
  const [importResultsCSV, setImportResultsCSV] = useState<Array<Object>>([]);
  const csvLinkRef = useRef<any>();

  const columns: GridColumns = [
    { field: "dataType", headerName: "Data type", width: 100 },
    {
      field: "message",
      headerName: "Message",
      width: 1000,
      renderCell: (props: GridRenderCellParams) => {
        const { value } = props;
        return (
          <Tooltip title={value}>
            <div>{value}</div>
          </Tooltip>
        );
      }
    }
  ];

  useEffect(() => {
    if (importResultsCSV.length > 0) {
      csvLinkRef.current.link.click();
      setImportResultsCSV([]); // Required otherwise grid starts lagging. Understand why!
    }
  }, [importResultsCSV]);

  type ToolbarProps = {
    apiRef: React.MutableRefObject<GridApi>;
  };

  const Toolbar = (props: ToolbarProps) => {
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const open = Boolean(menuAnchor);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
      setMenuAnchor(event.currentTarget);
    };

    const onExportResults = () => {
      setImportResultsCSV(
        importResults.map((a) => {
          return { "Data type": a.dataType, Message: a.message };
        })
      );
      setMenuAnchor(null);
    };

    const onClose = () => {
      setMenuAnchor(null);
    };

    return (
      <GridToolbarContainer>
        <CSVLink data={importResultsCSV} target="_blank" ref={csvLinkRef} className="hidden" />
        <Button color="primary" startIcon={<FileDownloadIcon />} onClick={handleOpenMenu}>
          Export
        </Button>
        <Menu id="basic-menu" anchorEl={menuAnchor} open={open} onClose={onClose}>
          <MenuItem onClick={onExportResults}>Export logs</MenuItem>
        </Menu>
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <DataGridPro density="compact" getRowId={() => uuidv4()} columns={columns} rows={importResults} components={{ Toolbar }} />
    </div>
  );
};

export default ImportReportGrid;
