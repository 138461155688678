import TextFieldEditor from "./TextFieldEditor";

type FrequencyEditorProps = {
  label?: string;
  value?: string;
  onChange?: (newValue: string) => void;
  error?: boolean;
  helperText?: string | false;
  showHelperTextAsTooltip?: boolean;
};

const FrequencyEditor = (props: FrequencyEditorProps) => {
  const { value, onChange, error, helperText, showHelperTextAsTooltip } = props;
  return <TextFieldEditor label="Frequency" value={value} onChange={onChange} hasError={error} helperText={helperText} showHelperTextAsTooltip={showHelperTextAsTooltip} />;
};

export default FrequencyEditor;
