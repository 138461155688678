import { GridColumns, GridPreProcessEditCellProps, GridRenderCellParams, GridRenderEditCellParams } from "@mui/x-data-grid-pro";
import DataGridLatitudeMask from "src/components/editors/DataGridLatitudeMask";
import DataGridLongitudeMask from "src/components/editors/DataGridLongitudeMask";
import DataGridTextFieldEditor from "src/components/editors/DataGridTextFieldEditor";
import DatagridAorSelector from "src/components/selectors/aorselector/DatagridAorSelector";
import DataGridReferenceSelector from "src/components/selectors/referencetypeselector/DataGridReferenceSelector";
import DataGridUserTagSelector from "src/components/selectors/usertagselector/DataGridUserTagSelector";
import { ColumnsGenerator } from "src/types/Types";
import { customUserTagFilteringOperators } from "./customUserTagFilteringOperators";
import { userTagGridComparator } from "./customUserTagGridComparator";

const usePointColumns = () => {
  const getColumns: ColumnsGenerator = (validator, onFieldDirty) => {
    const columns: GridColumns = [
      {
        field: "name",
        headerName: "Name",
        editable: true,
        width: 120,
        renderEditCell: (params: GridRenderEditCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("name", params.props.value, params.id);
          return { ...params.props, error: hasError };
        }
      },
      { field: "description", headerName: "Description", editable: true, width: 200 },
      { field: "frequency", headerName: "Frequency", editable: true },
      {
        field: "referenceType",
        headerName: "Reference type",
        editable: true,
        hide: true
      },
      {
        field: "reference",
        headerName: "Reference",
        width: 300,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("reference", params.props.value, params.row);
          return { ...params.props, error: hasError };
        },
        renderCell: (params: GridRenderCellParams) => {
          return params.row.referenceType ? <div>{`${params.row.referenceType} - ${params.row.reference}`}</div> : <div />;
        },
        renderEditCell: (params: GridRenderEditCellParams) => {
          return <DataGridReferenceSelector {...params} />;
        }
      },
      {
        field: "dmsLatitude",
        headerName: "Latitude",
        editable: true,
        width: 120,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("dmsLatitude", params.props.value, params.id);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderEditCellParams) => {
          return <DataGridLatitudeMask {...params} />;
        }
      },
      {
        field: "dmsLongitude",
        headerName: "Longitude",
        editable: true,
        width: 120,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("dmsLongitude", params.props.value, params.id);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridLongitudeMask {...params} />;
        }
      },
      {
        field: "orientation",
        headerName: "Orientation",
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("orientation", params.props.value, params.id);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} showHelperTextAsTooltip />;
        }
      },
      {
        field: "areasOfResponsibility",
        headerName: "A.o.R",
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = false;
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DatagridAorSelector {...params} />;
        }
      },
      { field: "exportShortName", headerName: "Export short name", type: "boolean", editable: true, width: 150 },
      {
        field: "userTags",
        headerName: "User tags",
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = false;
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridUserTagSelector {...params} />;
        },
        filterOperators: customUserTagFilteringOperators,
        sortComparator: userTagGridComparator
      }
    ];
    return columns;
  };
  return { getColumns };
};

export default usePointColumns;
