import * as _ from "lodash";
import { PolyLineWithAttributes } from "src/types/Types";
import { getAPIClient } from "../apiClient";

type HighwayRampsResponse = {
  data: PolyLineWithAttributes[];
  errorMessage: string | null;
};

export const getHighwayRamps = async (versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<HighwayRampsResponse>("/swisstopo/highway_ramps", { headers: { "x-air-version": versionTag } });
  return response.data.data.map((co) => {
    return {
      ...co,
      id: _.uniqueId()
    };
  });
};

export const getHighwayRampsFromIds = async (versionTag: string, ids: number[]) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<HighwayRampsResponse>("/swisstopo/highway_ramps/search", { searchIds: ids }, { headers: { "x-air-version": versionTag } });
  return response.data.data.map((hr) => {
    return {
      ...hr,
      id: hr.attributes.id!
    };
  });
};
