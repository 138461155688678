import { LatLng } from "leaflet";
import { useEffect, useState } from "react";
import { Polygon } from "react-leaflet";
import { getComputedAreaPoints } from "src/apis/computedareas/computedAreasApis";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { ComputedArea, LinePoint } from "src/types/Types";

type ComputedAreaProps = {
  computedArea: ComputedArea;
};

export const ComputedAreaMarker = (props: ComputedAreaProps) => {
  const { computedArea } = props;
  const [computedAreaPoints, setComputedAreaPoints] = useState<LinePoint[]>([]);
  const versionTag = useVersionTag();

  useEffect(() => {
    getComputedAreaPoints(computedArea, versionTag).then((result) => {
      setComputedAreaPoints(result.sort((a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0)));
    });
  }, [computedArea, versionTag]);

  return (
    <Polygon
      key={`${computedArea.id} ${computedArea.color} ${computedArea.fillColor}`}
      pathOptions={{
        color: computedArea.color,
        weight: computedArea.color ? 0.6 : 0,
        fillColor: computedArea.fillColor,
        fill: computedArea.fillColor ? true : false,
        fillOpacity: computedArea.fillColor ? 1 : 0
      }}
      positions={computedAreaPoints.map((lp) => {
        return new LatLng(lp.wgs84Latitude, lp.wgs84Longitude);
      })}
    />
  );
};

export default ComputedAreaMarker;
