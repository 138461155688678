import { GridColumns } from "@mui/x-data-grid-pro";
import { ColumnsGenerator } from "src/types/Types";

const useCatUserColumns = () => {
  const getColumns: ColumnsGenerator = (validator, onFieldDirty) => {
    const columns: GridColumns = [
      {
        field: "username",
        headerName: "Username",
        editable: true,
        width: 200
      },
      {
        field: "firstname",
        headerName: "Firstname",
        editable: true,
        width: 200
      },
      {
        field: "lastname",
        headerName: "Lastname",
        editable: true,
        width: 200
      }
    ];
    return columns;
  };
  return { getColumns };
};

export default useCatUserColumns;
