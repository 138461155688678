import { ChangeEvent } from "react";
import { useOrientationMask } from "src/components/editors/hooks/useOrientationMask";
import InputMask from "react-input-mask";
import { TextField } from "@mui/material";

type OrientationMaskProps = {
  label?: string;
  value?: string;
  onOrientationChanged?: (airportOrientation: string) => void;
  error?: boolean;
  helperText?: string | false;
};

const OrientationMask = (props: OrientationMaskProps) => {
  const { onOrientationChanged, label, value, error, helperText } = props;
  const orientationMask = useOrientationMask();

  return (
    <div>
      <InputMask
        alwaysShowMask
        mask={orientationMask}
        value={value}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          onOrientationChanged?.(event.target.value);
        }}
      >
        <TextField
          size="small"
          variant="standard"
          sx={{ input: { color: error ? "red" : "" } }}
          inputProps={{ min: 0, style: { textAlign: "center" } }}
          id="orientation"
          name="orientation"
          label={label}
          autoComplete="off"
          error={error ? error : false}
          helperText={helperText}
        />
      </InputMask>
    </div>
  );
};

export default OrientationMask;
