import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Line } from "src/types/Types";
import AddLineForm, { AddLineFormHandle } from "../forms/AddLineForm";
import { useRef } from "react";
import PolylineIcon from "@mui/icons-material/Polyline";

type AddLineDialogProps = {
  createLine: (line: Line) => Promise<Line>;
  onCancel: () => void;
  onSuccess: () => void;
};

const AddLineDialog = (props: AddLineDialogProps) => {
  const { createLine, onCancel, onSuccess } = props;
  const addLineFormRef = useRef<AddLineFormHandle>(null);

  return (
    <Dialog maxWidth="xl" open={true}>
      <DialogTitle>
        <div style={{ display: "flex" }}>
          <div>
            <PolylineIcon />
          </div>
          <div style={{ marginLeft: "10px" }}>Add line</div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <AddLineForm ref={addLineFormRef} createLine={createLine} onSuccess={onSuccess} />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="contained"
          onClick={() => {
            addLineFormRef.current?.requestSubmit();
          }}
        >
          Add
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddLineDialog;
