import GenericDataGrid from "src/components/datagrids/GenericDataGrid";
import { EmptyValidator } from "src/validators/EmptyValidator";

import { CatUser } from "src/types/Types";
import useCatUsers from "src/apis/users/useCatUsers";
import useCatUsersColumns from "src/columnsgenerators/useCatUsersColumns";
import AddUserDialog from "./dialogs/AddUserDialog";

const CatUsers = () => {
  const { getColumns } = useCatUsersColumns();
  const { catUsers, createCatUser, deleteCatUser } = useCatUsers();

  const createUserDialogFactory = (onSuccess: () => void, onCancel: () => void) => {
    return <AddUserDialog createUser={createCatUser} onUserCreated={onSuccess} onCancel={onCancel} />;
  };

  const onDeleteUser = (user: CatUser) => {
    return deleteCatUser(user);
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Users</h2>
      <div style={{ height: "90%" }}>
        <GenericDataGrid<CatUser>
          data={catUsers}
          columnsGenerator={getColumns}
          validator={new EmptyValidator()}
          onDeleteRow={onDeleteUser}
          createDialogFactory={createUserDialogFactory}
          updateDialogFactory={createUserDialogFactory}
        />
      </div>
    </div>
  );
};

export default CatUsers;
