import { Validator } from "./Validator";

class DamParameterValidator extends Validator {
  validateFlightLevel = (fieldName: string, fieldValue: string) => {
    if (fieldValue.length === 0) {
      this.validationMap.set(fieldName, true);
      return fieldName + " cannot be empty";
    }
    if (fieldValue.length < 3) {
      this.validationMap.set(fieldName, true);
      return "Invalid value";
    }
    if (fieldValue.length > 4) {
      this.validationMap.set(fieldName, true);
      return "Invalid value";
    }
    if (fieldValue.length === 3) {
      if (Number.isNaN(+fieldValue)) {
        this.validationMap.set(fieldName, true);
        return "Invalid value";
      }
    }
    if (fieldValue.length === 4) {
      if (fieldValue[0] !== "a" || Number.isNaN(+fieldValue.substring(1))) {
        this.validationMap.set(fieldName, true);
        return "Invalid value";
      }
    }
    this.validationMap.set(fieldName, false);
    return "";
  };

  validateVerticalBuffer = (fieldName: string, fieldValue: any) => {
    if (Number.isNaN(+fieldValue)) {
      this.validationMap.set(fieldName, true);
      return "Invalid value";
    }
    this.validationMap.set(fieldName, false);
    return "";
  };

  validateDescription = (fieldName: string, fieldValue: string) => {
    if (fieldValue === null || fieldValue === undefined || fieldValue.trim().length === 0) {
      this.validationMap.set(fieldName, true);
      return "Invalid description";
    }
    this.validationMap.set(fieldName, false);
    return "";
  };

  validateField = (fieldName: string, fieldValue: any) => {
    switch (fieldName) {
      case "description":
        return this.validateDescription(fieldName, fieldValue);
      case "dmsLatitude":
        return this.validateLatitude(fieldName, fieldValue);
      case "dmsLongitude":
        return this.validateLongitude(fieldName, fieldValue);

      case "lowerFlightLevel":
      case "higherFlightLevel":
        return this.validateFlightLevel(fieldName, fieldValue);
      case "verticalBuffer":
        return this.validateVerticalBuffer(fieldName, fieldValue);

      default:
        return "";
    }
  };
}

export default DamParameterValidator;
