import { Tooltip } from "@mui/material";
import { GridPreProcessEditCellProps, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { CurvatureEditionInfoTooltip, CurvatureTooltip } from "src/components/datagrids/CurvatureTooltips";
import DataGridLatitudeMask from "src/components/editors/DataGridLatitudeMask";
import DataGridLongitudeMask from "src/components/editors/DataGridLongitudeMask";
import DataGridTextFieldEditor from "src/components/editors/DataGridTextFieldEditor";
import { ColumnsGenerator } from "src/types/Types";
import { FieldsValidator } from "src/validators/Validator";

const latitudeOrLongitudeMaskEmpty = '___°__´__.___"_';

const useLinePointsColumns = (showBufferColumn: boolean = false) => {
  const getColumns: ColumnsGenerator = (validator: FieldsValidator, onFieldDirty) => {
    let fields = [
      { field: "sequence", headerName: "Sequence", editable: false, width: 80 },
      {
        field: "dmsLatitude",
        headerName: "Latitude",
        editable: true,
        width: 120,
        valueParser: (value: string) => {
          if (value === latitudeOrLongitudeMaskEmpty) {
            return null;
          }
          return value;
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridLatitudeMask {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("dmsLatitude", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "dmsLongitude",
        headerName: "Longitude",
        editable: true,
        width: 120,
        valueParser: (value: string) => {
          if (value === latitudeOrLongitudeMaskEmpty) {
            return null;
          }
          return value;
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridLongitudeMask {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("dmsLongitude", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "curvature",
        headerName: "Curvature",
        editable: true,
        width: 80,
        renderEditCell: (params: GridRenderCellParams) => {
          return (
            <Tooltip
              title={<CurvatureEditionInfoTooltip />}
              placement="bottom-start"
              componentsProps={{
                tooltip: {
                  sx: {
                    maxWidth: "50rem"
                  }
                }
              }}
            >
              <div>
                <DataGridTextFieldEditor {...params} />;
              </div>
            </Tooltip>
          );
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("curvature", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderCell: (props: GridRenderCellParams) => {
          const {
            value,
            row: { distanceToNextPointNm }
          } = props;

          return (
            <Tooltip title={<CurvatureTooltip curvature={value} distanceToNextPointNm={distanceToNextPointNm} />} placement="bottom-start">
              <div>{value}</div>
            </Tooltip>
          );
        }
      },
      {
        field: "distanceToNextPointNm",
        headerName: "Distance to next point",
        width: 140,
        editable: false,
        hide: true
      },
      { field: "inverted", headerName: "Inverted", editable: true, width: 80, type: "boolean" }
    ];
    if (showBufferColumn === true) {
      fields.push({ field: "buffer", headerName: "Buffer", editable: true, width: 60 });
    }
    return fields;
  };
  return { getColumns };
};

export default useLinePointsColumns;
