import { useEffect, useState } from "react";
import { AdditionalInformation, Point } from "src/types/Types";
import { useVersionTag } from "../hooks/useVersionTag";
import {
  getPoints,
  addPoint as _addPoint,
  updatePoint as _updatePoint,
  deletePoint as _deletePoint,
  getInformation,
  addInformation as _addInformation,
  deleteInformation as _deleteInformation,
  updateInformation as _updateInformation,
  getPointXrefs
} from "./pointsApi";

import * as _ from "lodash";

const usePoints = () => {
  const versionTag = useVersionTag();
  const [points, setPoints] = useState<Point[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPoint, setCurrentPoint] = useState<Point>();

  useEffect(() => {
    setLoading(true);
    getPoints(versionTag)
      .then((result) => {
        setPoints(result);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [versionTag]);

  const selectPoint = async (point: Point) => {
    const copy = _.cloneDeep(point);
    copy.additionalInformation = await getInformation(versionTag, point);
    copy.xrefs = await getPointXrefs(versionTag, point);

    setPoints([...points.filter((p) => p.id !== point.id), copy]);
    setCurrentPoint(copy);
  };

  const addPoint = async (point: Point) => {
    const result = await _addPoint(versionTag, point);
    setPoints([...points, result]);
    setCurrentPoint(result);
    return result;
  };

  const updatePoint = async (point: Point) => {
    const result = await _updatePoint(versionTag, point);
    setPoints([...points.filter((p) => p.id !== point.id), result]);
    setCurrentPoint(result);
    return result;
  };

  const deletePoint = async (point: Point) => {
    await _deletePoint(versionTag, point);
    setPoints(points.filter((p) => p.id !== point.id));
  };

  const addInformation = async (info: AdditionalInformation) => {
    return _addInformation(versionTag, currentPoint!, info).then((info) => {
      const copy = _.cloneDeep(currentPoint!);
      copy.additionalInformation.push(info);
      setPoints([...points.filter((p) => p.id !== currentPoint!.id), copy]);
      setCurrentPoint(copy);
      return info;
    });
  };

  const updateInformation = async (info: AdditionalInformation) => {
    return _updateInformation(versionTag, currentPoint!, info).then((info) => {
      const copy = _.cloneDeep(currentPoint!);
      copy.additionalInformation = copy.additionalInformation.filter((i) => i.id !== info.id);
      copy.additionalInformation.push(info);
      setPoints([...points.filter((p) => p.id !== currentPoint!.id), copy]);
      setCurrentPoint(copy);
      return info;
    });
  };

  const deleteInformation = async (info: AdditionalInformation) => {
    return _deleteInformation(versionTag, currentPoint!, info).then((result) => {
      const copy = _.cloneDeep(currentPoint!);
      copy.additionalInformation = result;
      setPoints([...points.filter((p) => p.id !== copy.id), copy]);
      setCurrentPoint(copy);
    });
  };

  return { loading, points, currentPoint, selectPoint, addPoint, updatePoint, deletePoint, addInformation, deleteInformation, updateInformation };
};

export default usePoints;
