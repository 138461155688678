import { useEffect, useState } from "react";
import Split from "react-split";

import { useVersionTag } from "src/apis/hooks/useVersionTag";

import { useAirports } from "src/apis/airports/useAirports";
import useAirportsColumns from "src/columnsgenerators/useAirportsColumns";

import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import { isUndefined } from "lodash";
import { toast } from "react-toastify";
import { getWgs84LatLng } from "src/apis/waypoints/waypointsApi";
import useAdditionalInformationColumns from "src/columnsgenerators/useAdditionalInformationColumns";
import useFrequencyColumns from "src/columnsgenerators/useFrequencyColumns";
import useRunwaysColumns from "src/columnsgenerators/useRunwaysColumns";
import GenericDataGrid from "src/components/datagrids/GenericDataGrid";
import { AdditionalInformation, Airport, AirportFrequency, ArrivalProcedure, DepartureProcedure, ProceduralPoint, Runway } from "src/types/Types";
import AdditionalInformationValidator from "src/validators/AdditionalInformationValidator";
import AirportValidator from "src/validators/AirportValidator";
import FrequencyValidator from "src/validators/FrequencyValidator";
import RunwayValidator from "src/validators/RunwayValidator";
import AirportArrivalProcedures from "./datagrids/AirportArrivalProcedures";
import AirportDepartureProcedures from "./datagrids/AirportDepartureProcedures";
import AirportSIDs from "./datagrids/AirportSIDs";
import AirportSTARs from "./datagrids/AirportSTARs";
import AddAirportDialog from "./dialogs/AddAirportDialog";

const RUNWAYS_TAB = "runways";
const SIDS_TAB = "sids";
const STARS_TAB = "stars";
const DEPARTURE_PROCEDURES_TAB = "depProcedures";
const ARRIVAL_PROCEDURES_TAB = "arrProcedures";
const FREQUENCIES_TAB = "frequencies";
const ADDITIONAL_INFORMATION_TAB = "additionalInformation";

const Airports = () => {
  const versionTag = useVersionTag();
  const {
    errorMessage,
    loading,
    airports,
    currentAirport,
    currentDepProcedure,
    currentArrProcedure,
    selectAirport,
    createAirport,
    deleteAirport,
    updateAirport,
    addRunway,
    updateRunway,
    deleteRunway,
    selectDepartureProcedure,
    selectArrivalProcedure,
    addDepartureProcedure,
    updateDepartureProcedure,
    deleteDepartureProcedure,
    addDepartureProcedurePoint,
    updateDepartureProcedurePoint,
    deleteDepartureProcedurePoint,
    moveDepartureProcedurePoints,
    moveArrivalProcedurePoints,
    addArrivalProcedurePoint,
    updateArrivalProcedurePoint,
    deleteArrivalProcedurePoint,
    addArrivalProcedure,
    updateArrivalProcedure,
    deleteArrivalProcedure,
    addFrequency,
    updateFrequency,
    deleteFrequency,
    createAdditionalInformation,
    updateAdditionalInformation,
    deleteAdditionalInformation
  } = useAirports();

  const [showAddAirportDialog, setShowAddAirportDialog] = useState(false);

  const [currentTab, setCurrentTab] = useState(RUNWAYS_TAB);

  const { getColumns: airportsColumnsGenerator } = useAirportsColumns();

  const { getColumns: columnsGenerator } = useFrequencyColumns();
  const { getColumns: additionalInformationColumnsGenerator } = useAdditionalInformationColumns();
  const { getColumns: runwaysColumsGenerator } = useRunwaysColumns();

  const createEmptyFrequencyRow = () => {
    return {
      id: -1,
      airportId: currentAirport!.id,
      sequence: currentAirport!.frequencies.length + 1,
      name: null,
      frequency: null,
      remarks: null,
      onSameLine: false
    };
  };

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (currentTab !== DEPARTURE_PROCEDURES_TAB) {
      selectDepartureProcedure(undefined);
    }
    if (currentTab !== ARRIVAL_PROCEDURES_TAB) {
      selectArrivalProcedure(undefined);
    }
  }, [currentTab]);

  const createDialogFactory = (onSuccess: () => void, onCancel: () => void) => {
    return <AddAirportDialog createAirport={createAirport} onAirportCreated={onSuccess} onCancel={onCancel} />;
  };

  const onCancelAddAirport = () => {
    setShowAddAirportDialog(false);
  };

  const onAirportCreated = () => {
    toast.success("Airport created");
    setShowAddAirportDialog(false);
  };

  const onAirportSelected = (airport: Airport) => {
    selectAirport(airport).catch((error) => {
      toast.error(error.errorMessage);
    });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  const onUpdateAirport = async (airport: Airport) => {
    if (airport.dmsLatitude && airport.dmsLongitude) {
      const latLng = await getWgs84LatLng(airport.dmsLatitude, airport.dmsLongitude);
      airport.wgs84Latitude = latLng.wgs84Latitude;
      airport.wgs84Longitude = latLng.wgs84Longitude;
    } else {
      airport.wgs84Latitude = null;
      airport.wgs84Longitude = null;
    }
    return updateAirport(airport);
  };

  const onDeleteAirport = (airport: Airport) => {
    return deleteAirport(airport);
  };

  const onAddRunway = async (runway: Runway) => {
    if (runway.dmsThresholdLatitude && runway.dmsThresholdLongitude) {
      const latLng = await getWgs84LatLng(runway.dmsThresholdLatitude, runway.dmsThresholdLongitude);
      runway.thresholdLatitude = latLng.wgs84Latitude;
      runway.thresholdLongitude = latLng.wgs84Longitude;
    } else {
      runway.thresholdLatitude = null;
      runway.thresholdLongitude = null;
    }
    return addRunway(runway);
  };

  const onUpdateRunway = async (runway: Runway) => {
    if (runway.dmsThresholdLatitude && runway.dmsThresholdLongitude) {
      const latLng = await getWgs84LatLng(runway.dmsThresholdLatitude, runway.dmsThresholdLongitude);
      runway.thresholdLatitude = latLng.wgs84Latitude;
      runway.thresholdLongitude = latLng.wgs84Longitude;
    } else {
      runway.thresholdLatitude = null;
      runway.thresholdLongitude = null;
    }
    return updateRunway(runway);
  };

  const onDeleteRunway = (runway: Runway) => {
    return deleteRunway(runway);
  };

  const onDepProcedureSelected = (dp?: DepartureProcedure) => {
    selectDepartureProcedure(dp!);
  };

  const onArrProcedureSelected = (ap?: ArrivalProcedure) => {
    selectArrivalProcedure(ap!);
  };

  const onAddDepProcedure = (dp: DepartureProcedure) => {
    return addDepartureProcedure(dp);
  };

  const onAddArrProcedure = (ap: ArrivalProcedure) => {
    return addArrivalProcedure(ap);
  };

  const onUpdateDepProcedure = (dp: DepartureProcedure) => {
    return updateDepartureProcedure(dp);
  };

  const onUpdateArrProcedure = (ap: ArrivalProcedure) => {
    return updateArrivalProcedure(ap);
  };

  const onDeleteDepProcedure = (dp: DepartureProcedure) => {
    return deleteDepartureProcedure(dp);
  };

  const onDeleteArrProcedure = (ap: ArrivalProcedure) => {
    return deleteArrivalProcedure(ap);
  };

  const onAddDepProcedurePoint = (p: ProceduralPoint) => {
    return addDepartureProcedurePoint(p);
  };

  const onAddArrProcedurePoint = (p: ProceduralPoint) => {
    return addArrivalProcedurePoint(p);
  };

  const onUpdateDepProcedurePoint = (p: ProceduralPoint) => {
    return updateDepartureProcedurePoint(p);
  };

  const onUpdateArrProcedurePoint = (p: ProceduralPoint) => {
    return updateArrivalProcedurePoint(p);
  };

  const onDeleteDepProcedurePoint = (p: ProceduralPoint) => {
    return deleteDepartureProcedurePoint(p);
  };

  const onDepProcedurePointsOrderChange = (dp: DepartureProcedure, fromIndex: number, toIndex: number, selected: number[]) => {
    moveDepartureProcedurePoints(dp, fromIndex, toIndex, selected).catch((error: any) => {
      if (error.errorMessage) {
        toast.error(error.errorMessage);
      } else {
        toast.error(error);
      }
      // reset dep procedure
      selectDepartureProcedure(currentDepProcedure);
    });
  };

  const onArrProcedurePointsOrderChange = (ap: ArrivalProcedure, fromIndex: number, toIndex: number, selected: number[]) => {
    moveArrivalProcedurePoints(ap, fromIndex, toIndex, selected).catch((error: any) => {
      if (error.errorMessage) {
        toast.error(error.errorMessage);
      } else {
        toast.error(error);
      }
      // reset arr procedure
      selectArrivalProcedure(currentArrProcedure);
    });
  };

  const onDeleteArrProcedurePoint = (p: ProceduralPoint) => {
    return deleteArrivalProcedurePoint(p);
  };

  const onAddFrequency = (frequency: AirportFrequency) => {
    frequency.airportId = currentAirport!.id;
    return addFrequency(frequency);
  };

  const onUpdateFrequency = (frequency: AirportFrequency) => {
    return updateFrequency(frequency);
  };

  const onDeleteFrequency = (frequency: AirportFrequency) => {
    return deleteFrequency(frequency);
  };

  const onAddInformation = (information: AdditionalInformation) => {
    return createAdditionalInformation(information).then((result) => {
      return result;
    });
  };

  const onUpdateInformation = (information: AdditionalInformation) => {
    return updateAdditionalInformation(information);
  };

  const onDeleteInformation = (information: AdditionalInformation) => {
    return deleteAdditionalInformation(information);
  };

  const createEmptyAdditionalInformationRow = () => {
    let sequence = 1;
    if (currentAirport) {
      sequence = currentAirport?.infos.length! + 1;
    }
    return {
      id: -1,
      sequence: sequence,
      topic: null,
      contents: null,
      onSameLine: false
    };
  };

  const createEmptyRunwayRow = (): Runway => {
    return {
      airportId: currentAirport!.id,
      id: -1,
      designator: null,
      thresholdLatitude: null,
      thresholdLongitude: null,
      dmsThresholdLatitude: null,
      dmsThresholdLongitude: null,
      surface: null,
      availableLandingDistance: null,
      width: null,
      remarks: null,
      heliport: false
    };
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {showAddAirportDialog && <AddAirportDialog createAirport={createAirport} onCancel={onCancelAddAirport} onAirportCreated={onAirportCreated} />}
      <h2>Airports - {versionTag}</h2>
      <Split style={{ height: "calc(100vh - 230px)" }} direction="vertical">
        <div style={{ height: "100%" }}>
          <GenericDataGrid<Airport>
            loading={loading}
            data={airports}
            columnsGenerator={airportsColumnsGenerator}
            validator={new AirportValidator()}
            createDialogFactory={createDialogFactory}
            onUpdateRow={onUpdateAirport}
            selectedRow={currentAirport}
            onSelectRow={onAirportSelected}
            onDeleteRow={onDeleteAirport}
            deleteDialogTitle="Delete airport?"
            sortModel={[{ field: "name", sort: "asc" }]}
          />
        </div>
        <div style={{ height: "100%" }}>
          <TabContext value={currentTab}>
            <Tabs value={currentTab} onChange={handleChange}>
              <Tab value={RUNWAYS_TAB} label={"Runways"} />
              <Tab value={SIDS_TAB} label="SIDs" />
              <Tab value={STARS_TAB} label="STARs" />
              <Tab value={DEPARTURE_PROCEDURES_TAB} label="DEPARTURE PROCEDURES" />
              <Tab value={ARRIVAL_PROCEDURES_TAB} label="ARRIVAL PROCEDURES" />
              <Tab value={FREQUENCIES_TAB} label="Frequencies" />
              <Tab value={ADDITIONAL_INFORMATION_TAB} label="Additional information" />
            </Tabs>
            <TabPanel value={RUNWAYS_TAB} style={{ height: "100%" }}>
              <div style={{ height: "90%" }}>
                <GenericDataGrid<Runway>
                  data={currentAirport ? currentAirport.runways : []}
                  columnsGenerator={runwaysColumsGenerator}
                  validator={new RunwayValidator()}
                  createEmptyRow={createEmptyRunwayRow}
                  onCreateRow={onAddRunway}
                  onUpdateRow={onUpdateRunway}
                  onDeleteRow={onDeleteRunway}
                  deleteDialogTitle="Delete runway?"
                  sortModel={[{ field: "designator", sort: "asc" }]}
                  canInsertRow={!isUndefined(currentAirport)}
                />
              </div>
            </TabPanel>
            <TabPanel value={SIDS_TAB} style={{ height: "100%" }}>
              <div style={{ height: "100%" }}>
                <AirportSIDs sids={currentAirport ? currentAirport.sids : []} />
              </div>
            </TabPanel>
            <TabPanel value={STARS_TAB} style={{ height: "100%" }}>
              <div style={{ height: "100%" }}>
                <AirportSTARs stars={currentAirport ? currentAirport.stars : []} />
              </div>
            </TabPanel>
            <TabPanel value={DEPARTURE_PROCEDURES_TAB} style={{ height: "100%" }}>
              <div style={{ height: "100%" }}>
                <AirportDepartureProcedures
                  selectedAirport={currentAirport}
                  selectedDepProcedure={currentDepProcedure}
                  onDepProcedureSelected={onDepProcedureSelected}
                  onAddDepartureProcedure={onAddDepProcedure}
                  onUpdateDepartureProcedure={onUpdateDepProcedure}
                  onDeleteDepartureProcedure={onDeleteDepProcedure}
                  onAddDepartureProcedurePoint={onAddDepProcedurePoint}
                  onUpdateDepartureProcedurePoint={onUpdateDepProcedurePoint}
                  onDeleteDepartureProcedurePoint={onDeleteDepProcedurePoint}
                  onDepartureProcedurePointsOrderChange={onDepProcedurePointsOrderChange}
                />
              </div>
            </TabPanel>
            <TabPanel value={ARRIVAL_PROCEDURES_TAB} style={{ height: "100%" }}>
              <div style={{ height: "100%" }}>
                <AirportArrivalProcedures
                  selectedAirport={currentAirport}
                  selectedArrProcedure={currentArrProcedure}
                  onArrProcedureSelected={onArrProcedureSelected}
                  onAddArrivalProcedure={onAddArrProcedure}
                  onUpdateArrivalProcedure={onUpdateArrProcedure}
                  onDeleteArrivalProcedure={onDeleteArrProcedure}
                  onAddArrivalProcedurePoint={onAddArrProcedurePoint}
                  onUpdateArrivalProcedurePoint={onUpdateArrProcedurePoint}
                  onDeleteArrivalProcedurePoint={onDeleteArrProcedurePoint}
                  onArrivalProcedurePointsOrderChange={onArrProcedurePointsOrderChange}
                />
              </div>
            </TabPanel>
            <TabPanel value={FREQUENCIES_TAB} style={{ height: "100%" }}>
              <div style={{ height: "90%" }}>
                <GenericDataGrid<AirportFrequency>
                  data={currentAirport ? currentAirport.frequencies : []}
                  columnsGenerator={columnsGenerator}
                  validator={new FrequencyValidator()}
                  onCreateRow={onAddFrequency}
                  onUpdateRow={onUpdateFrequency}
                  onDeleteRow={onDeleteFrequency}
                  createEmptyRow={createEmptyFrequencyRow}
                  sortModel={[{ field: "sequence", sort: "asc" }]}
                  deleteDialogTitle={"Delete frequency?"}
                  canInsertRow={!isUndefined(currentAirport)}
                />
              </div>
            </TabPanel>
            <TabPanel value={ADDITIONAL_INFORMATION_TAB} style={{ height: "100%" }}>
              <div style={{ height: "90%" }}>
                <GenericDataGrid<AdditionalInformation>
                  data={currentAirport ? currentAirport.infos : []}
                  columnsGenerator={additionalInformationColumnsGenerator}
                  validator={new AdditionalInformationValidator()}
                  createEmptyRow={createEmptyAdditionalInformationRow}
                  onCreateRow={onAddInformation}
                  onUpdateRow={onUpdateInformation}
                  onDeleteRow={onDeleteInformation}
                  sortModel={[{ field: "sequence", sort: "asc" }]}
                  deleteDialogTitle={"Delete additional information?"}
                  canInsertRow={!isUndefined(currentAirport)}
                />
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </Split>
    </div>
  );
};

export default Airports;
