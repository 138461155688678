import AlertIcon from "@mui/icons-material/ErrorOutline";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { AirwayPoint } from "src/types/Types";

type DeleteAirwayPointDialog = {
  airwayPoint?: AirwayPoint;
  onConfirmDelete: () => void;
  onCancelDelete: () => void;
};

const DeleteAirwayPointDialog = (props: DeleteAirwayPointDialog) => {
  const { airwayPoint, onConfirmDelete, onCancelDelete } = props;
  return (
    <Dialog maxWidth="sm" fullWidth open={true}>
      <DialogTitle>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ color: "orange", fontSize: "32px" }}>
            <AlertIcon style={{ fontSize: "inherit" }} />
          </div>
          <div style={{ marginLeft: "10px", marginBottom: "10px" }}>Delete airway point ?</div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ marginLeft: "20px", marginTop: "20px", marginBottom: "20px" }}>
            {Object.keys(airwayPoint!).map((key) => {
              if (typeof airwayPoint![key as keyof Object] === "object") return;
              return (
                <div style={{ display: "flex" }}>
                  <div style={{ width: "200px", marginRight: "30px", textAlign: "right" }}>{key}</div>
                  <Box sx={{ fontWeight: "medium" }}>{airwayPoint![key as keyof Object]}</Box>
                </div>
              );
            })}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="contained" onClick={onConfirmDelete}>
          Delete
        </Button>
        <Button onClick={onCancelDelete}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAirwayPointDialog;
