import { LatLng } from "leaflet";
import { Polyline } from "react-leaflet";
import { Highway } from "src/types/Types";

type HighwaysProps = {
  highways: Highway[];
};

const Highways = (props: HighwaysProps) => {
  const { highways } = props;
  const defaultColor = "#1565C0";
  return (
    <>
      {highways.map((h) => {
        return h.sections?.map((s) => {
          return (
            <Polyline
              pathOptions={{ color: h.color ? h.color : defaultColor, weight: 1, fill: false }}
              positions={s.slice().map((sp) => {
                return new LatLng(sp.wgs84Latitude, sp.wgs84Longitude);
              })}
            />
          );
        });
      })}
    </>
  );
};

export default Highways;
