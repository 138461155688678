import L from "leaflet";
import { useMemo, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import { Marker, Tooltip } from "react-leaflet";
import { DamParameter } from "src/types/Types";

type DamParameterMarkerProps = {
  damParameter: DamParameter;
  tooltipOn?: boolean;
  editable?: boolean;
  selected?: boolean;
  onMove?: (newPosition: L.LatLng) => void;
  onClick?: () => void;
};

const DamParameterMarker = (props: DamParameterMarkerProps) => {
  const { damParameter, onMove, onClick, tooltipOn = false, editable = false, selected = false } = props;
  const markerRef = useRef<L.Marker>(null);
  const defaultColor = "#1565C0";

  const eventHandlers = useMemo(
    () => ({
      click() {
        onClick?.();
      },
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          onMove?.(marker.getLatLng());
        }
      }
    }),
    []
  );

  const divIcon = L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(
      <div style={{ color: damParameter.color ? damParameter.color : defaultColor }}>
        <div style={{ fontFamily: "Inter, sans-serif", fontSize: 10, width: "500px", border: selected ? "1px dashed" : "" }}>{damParameter.remarks}</div>
      </div>
    )
  });

  return (
    <Marker ref={markerRef} icon={divIcon} position={[damParameter.wgs84Latitude, damParameter.wgs84Longitude]} draggable={editable} eventHandlers={eventHandlers}>
      {tooltipOn && <Tooltip direction="right">{`[${damParameter.dmsLatitude} ${damParameter.dmsLongitude}]`}</Tooltip>}
    </Marker>
  );
};

export default DamParameterMarker;
