import { DefaultGraphicAttribute, GraphicAttribute } from "src/types/Types";
import { getAPIClient } from "../apiClient";

type GraphicAttributesResponse = {
  data: GraphicAttribute[];
  errorMessage: string;
};

type DefaultGraphicAttributesResponse = {
  data: DefaultGraphicAttribute[];
  errorMessage: string;
};

type CreateGraphicAttributeResponse = {
  data: GraphicAttribute;
  errorMessage: string;
};

type UpdateGraphicAttributeResponse = {
  data: GraphicAttribute;
  errorMessage: string;
};

type DeleteGraphicAttributeResponse = {
  data: number;
  errorMessage: string;
};

export const getGraphicAttributes = async (versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<GraphicAttributesResponse>("/tables/graphic-attribute", { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const getDefaultGraphicAttributes = async (versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<DefaultGraphicAttributesResponse>("/tables/graphic-attribute/default-attributes", { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const createGraphicAttribute = async (versionTag: string, graphicAttribute: GraphicAttribute) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<CreateGraphicAttributeResponse>("/tables/graphic-attribute", graphicAttribute, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const updateGraphicAttribute = async (versionTag: string, graphicAttribute: GraphicAttribute) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.put<UpdateGraphicAttributeResponse>(`/tables/graphic-attribute/${graphicAttribute.id}`, graphicAttribute, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const deleteGraphicAttribute = async (versionTag: string, graphicAttribute: GraphicAttribute) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.delete<DeleteGraphicAttributeResponse>(`/tables/graphic-attribute/${graphicAttribute.id}`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};
