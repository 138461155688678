import { Validator } from "./Validator";

class BoundaryPointsValidator extends Validator {
  validationMap = new Map<string, boolean>();

  hasValidationErrors() {
    const values = this.validationMap.values();
    return Array.from(values).includes(true);
  }

  validateField = (fieldName: string, fieldValue: any) => {
    switch (fieldName) {
      case "dmsLatitude":
        return this.validateLatitude(fieldName, fieldValue);

      case "dmsLongitude":
        return this.validateLongitude(fieldName, fieldValue);

      case "curvature":
        return this.validateCurvature(fieldName, fieldValue);

      default:
        return "";
    }
  };
}

export default BoundaryPointsValidator;
