import { AdditionalInformation } from "src/types/Types";

import AdditionalInformationValidator from "src/validators/AdditionalInformationValidator";
import useAdditionalInformationColumns from "../../columnsgenerators/useAdditionalInformationColumns";
import GenericDataGrid from "./GenericDataGrid";

type AdditionalInformationGridProps = {
  canInsertRow?: boolean;
  onAddInformation: (information: AdditionalInformation) => Promise<AdditionalInformation>;
  onUpdateInformation: (information: AdditionalInformation) => Promise<AdditionalInformation>;
  onDeleteInformation: (information: AdditionalInformation) => Promise<void>;
  infos: AdditionalInformation[];
};

const AdditionalInformationGrid = (props: AdditionalInformationGridProps) => {
  const { infos, canInsertRow, onAddInformation, onUpdateInformation, onDeleteInformation } = props;
  const { getColumns } = useAdditionalInformationColumns();

  const createEmptyAdditionalInformationRow = () => {
    return {
      id: -1,
      sequence: infos.length + 1,
      topic: null,
      contents: null,
      onSameLine: false
    };
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <GenericDataGrid<AdditionalInformation>
        data={infos}
        columnsGenerator={getColumns}
        validator={new AdditionalInformationValidator()}
        createEmptyRow={createEmptyAdditionalInformationRow}
        onCreateRow={onAddInformation}
        onUpdateRow={onUpdateInformation}
        onDeleteRow={onDeleteInformation}
        sortModel={[{ field: "sequence", sort: "asc" }]}
        deleteDialogTitle={"Delete additional information?"}
        canInsertRow={canInsertRow}
      />
    </div>
  );
};

export default AdditionalInformationGrid;
