import { useEffect, useState } from "react";
import { DefaultGraphicAttribute, GraphicAttribute } from "src/types/Types";
import { useVersionTag } from "../hooks/useVersionTag";
import {
  getGraphicAttributes,
  getDefaultGraphicAttributes,
  createGraphicAttribute as _createGraphicAttribute,
  deleteGraphicAttribute as _deleteGraphicAttribute,
  updateGraphicAttribute as _updateGraphicAttribute
} from "./graphicAttributesApi";

const useGraphicAttributes = () => {
  const versionTag = useVersionTag();
  const [graphicAttributes, setGraphicAttributes] = useState<GraphicAttribute[]>([]);
  const [defaultGraphicAttributes, setDefaultGraphicAttributes] = useState<DefaultGraphicAttribute[]>([]);
  const [selectedGraphicAttribute, setSelectedGraphicAttribute] = useState<undefined | GraphicAttribute>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (versionTag !== undefined && versionTag !== null) {
      getGraphicAttributes(versionTag)
        .then((result) => {
          setGraphicAttributes(result);
          getDefaultGraphicAttributes(versionTag).then((result) => {
            setDefaultGraphicAttributes(result);
          });
        })
        .finally(() => setLoading(false));
    }
  }, [versionTag]);

  const createGraphicAttribute = async (graphicAttribute: GraphicAttribute) => {
    const result = await _createGraphicAttribute(versionTag, graphicAttribute);
    setGraphicAttributes([...graphicAttributes, result]);
    setSelectedGraphicAttribute(result);
    return result;
  };

  const updateGraphicAttribute = async (graphicAttribute: GraphicAttribute) => {
    const result = await _updateGraphicAttribute(versionTag, graphicAttribute);
    setGraphicAttributes([...graphicAttributes.filter((ga) => ga.id !== graphicAttribute.id), result]);
    setSelectedGraphicAttribute(result);
    return result;
  };

  const deleteGraphicAttribute = async (graphicAttribute: GraphicAttribute) => {
    const result = await _deleteGraphicAttribute(versionTag, graphicAttribute);
    setGraphicAttributes([...graphicAttributes.filter((ga) => ga.id !== graphicAttribute.id)]);
  };

  const selectGraphicAttribute = (graphicAttribute: GraphicAttribute) => {
    setSelectedGraphicAttribute(graphicAttribute);
  };

  return {
    loading,
    graphicAttributes,
    defaultGraphicAttributes,
    selectedGraphicAttribute,
    selectGraphicAttribute,
    createGraphicAttribute,
    updateGraphicAttribute,
    deleteGraphicAttribute
  };
};

export default useGraphicAttributes;
