import { LatLng } from "leaflet";
import { Polyline } from "react-leaflet";
import { CountryOutline } from "src/types/Types";

type CountryOutlinesProps = {
  countryOutlines: CountryOutline[];
};

const CountryOutlines = (props: CountryOutlinesProps) => {
  const { countryOutlines } = props;
  const defaultColor = "#1565C0";
  // @todo: why is the outline sometimes in sections and others in coordinates?
  return (
    <>
      {countryOutlines.map((co) => {
        return co.sections?.map((s) => {
          return (
            <Polyline
              pathOptions={{ color: co.color ? co.color : defaultColor, weight: 1 }}
              positions={s.slice().map((sp) => {
                return new LatLng(sp.wgs84Latitude, sp.wgs84Longitude);
              })}
            />
          );
        });
      })}
      {countryOutlines.map((co) => {
        return (
          co.coordinates && (
            <Polyline
              pathOptions={{ color: co.color ? co.color : defaultColor, weight: 1 }}
              positions={co.coordinates.map((c) => {
                return new LatLng(c.wgs84Latitude, c.wgs84Longitude);
              })}
            />
          )
        );
      })}
    </>
  );
};

export default CountryOutlines;
