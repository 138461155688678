import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import AorSelector from "./AorSelector";

const DatagridAorSelector = (params: GridRenderEditCellParams) => {
  const { id, value, field, error } = params;
  const apiRef = useGridApiContext();

  const handleChangeValue = (newValue: string[] | null) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };
  return <AorSelector value={value} onChange={handleChangeValue} />;
};
export default DatagridAorSelector;
