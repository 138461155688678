import TextFieldEditor from "./TextFieldEditor";

type VerticalBufferEditorProps = {
  label?: string;
  value?: string | null;
  onChange?: (newValue: string) => void;
  error?: string;
  helperText?: string | false;
  showHelperTextAsTooltip?: boolean;
};

const VerticalBufferEditor = (props: VerticalBufferEditorProps) => {
  const { label, value, onChange, error, helperText, showHelperTextAsTooltip } = props;
  return (
    <div>
      <TextFieldEditor label={label} value={value ? value : ""} onChange={onChange} hasError={Boolean(error)} helperText={helperText} showHelperTextAsTooltip />
    </div>
  );
};

export default VerticalBufferEditor;
