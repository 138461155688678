import { Tooltip } from "@mui/material";
import { GridPreProcessEditCellProps, GridRenderCellParams, GridValueSetterParams } from "@mui/x-data-grid-pro";
import { CurvatureEditionInfoTooltip, CurvatureTooltip } from "src/components/datagrids/CurvatureTooltips";
import DataGridLatitudeMask from "src/components/editors/DataGridLatitudeMask";
import DataGridLongitudeMask from "src/components/editors/DataGridLongitudeMask";
import DataGridTextFieldEditor from "src/components/editors/DataGridTextFieldEditor";
import DataGridUserTagSelector from "src/components/selectors/usertagselector/DataGridUserTagSelector";
import DatagridWaypointSelector from "src/components/selectors/waypointselector/DatagridWaypointSelector";
import { ColumnsGenerator } from "src/types/Types";
import { customUserTagFilteringOperators } from "./customUserTagFilteringOperators";
import { userTagGridComparator } from "./customUserTagGridComparator";

const latitudeOrLongitudeMaskEmpty = '___°__´__.___"_';

const useProcedurePointsColumns = () => {
  const getColumns: ColumnsGenerator = (validator, onFieldDirty) => {
    const columns = [
      { field: "sequence", headerName: "Sequence", editable: false },
      {
        field: "waypointName",
        headerName: "Waypoint",
        width: 120,
        editable: true,
        valueSetter: (params: GridValueSetterParams) => {
          let waypointName = params?.value;
          if (params.value && params.value.includes("(")) {
            const v = params.value;
            waypointName = v.substring(0, v.indexOf("(")).trim();
            const startIndex = v.indexOf("(") + 1;
            const endIndex = v.indexOf(")");
            const waypointArea = v.substring(startIndex, endIndex);
            return { ...params.row, waypointName, waypointArea };
          }

          return { ...params.row, waypointName };
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("waypointName", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DatagridWaypointSelector {...params} />;
        }
      },
      {
        field: "waypointArea",
        headerName: "Area",
        width: 150,
        editable: false,
        hide: true
      },
      {
        field: "dmsLatitude",
        headerName: "Latitude",
        width: 150,
        editable: true,
        valueParser: (value: string) => {
          if (value === latitudeOrLongitudeMaskEmpty) {
            return null;
          }
          return value;
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridLatitudeMask {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("dmsLatitude", params.props.value, params.otherFieldsProps);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "dmsLongitude",
        headerName: "Longitude",
        width: 150,
        editable: true,
        valueParser: (value: string) => {
          if (value === latitudeOrLongitudeMaskEmpty) {
            return null;
          }
          return value;
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridLongitudeMask {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("dmsLongitude", params.props.value, params.otherFieldsProps);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "curvature",
        headerName: "Curvature",
        width: 120,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return (
            <Tooltip
              title={<CurvatureEditionInfoTooltip />}
              placement="bottom-start"
              componentsProps={{
                tooltip: {
                  sx: {
                    maxWidth: "50rem"
                  }
                }
              }}
            >
              <div>
                <DataGridTextFieldEditor {...params} />;
              </div>
            </Tooltip>
          );
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("curvature", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderCell: (props: GridRenderCellParams) => {
          const {
            value,
            row: { distanceToNextPointNm }
          } = props;

          return (
            <Tooltip title={<CurvatureTooltip curvature={value} distanceToNextPointNm={distanceToNextPointNm} />} placement="bottom-start">
              <div>{value}</div>
            </Tooltip>
          );
        }
      },
      {
        field: "distanceToNextPointNm",
        headerName: "Distance to next point",
        width: 140,
        editable: false,
        hide: true
      },
      {
        field: "inverted",
        headerName: "Inverted",
        editable: true,
        type: "boolean",
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        }
      },
      {
        field: "userTags",
        headerName: "User tags",
        width: 200,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridUserTagSelector {...params} />;
        },
        filterOperators: customUserTagFilteringOperators,
        sortComparator: userTagGridComparator
      }
    ];
    return columns;
  };
  return { getColumns };
};

export default useProcedurePointsColumns;
