import { useRef } from "react";
import Split from "react-split";

import { useWaypoints } from "src/apis/waypoints/useWaypoints";
import { AdditionalInformation, Waypoint } from "src/types/Types";

import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import AddWaypointDialog from "./dialogs/AddWaypointDialog";

import { isUndefined } from "lodash";
import useWaypointsColumns from "src/columnsgenerators/useWaypointsColumns";
import AdditionalInformationGrid from "src/components/datagrids/AdditionalInformationGrid";
import GenericDataGrid from "src/components/datagrids/GenericDataGrid";
import MapViewer, { MapViewerHandle } from "src/components/mapviewer/MapViewer";
import WaypointValidator from "src/validators/WaypointValidator";

const Waypoints = () => {
  const versionTag = useVersionTag();
  const { getColumns } = useWaypointsColumns();

  const { loading, waypoints, currentWaypoint, selectWaypoint, createWaypoint, updateWaypoint, deleteWaypoint, addInformation, deleteInformation, updateInformation } =
    useWaypoints();
  const radarMapRef = useRef<MapViewerHandle>(null);

  const onWaypointSelected = (waypoint: Waypoint) => {
    selectWaypoint(waypoint);
  };

  const onUpdateWaypoint = (waypoint: Waypoint) => {
    return updateWaypoint(waypoint);
  };

  const createDialogFactory = (onSuccess: () => void, onCancel: () => void) => {
    return <AddWaypointDialog createWaypoint={createWaypoint} onWaypointCreated={onSuccess} onCancel={onCancel} />;
  };

  const onAddInformation = (information: AdditionalInformation) => {
    return addInformation(information);
  };

  const onUpdateInformation = (information: AdditionalInformation) => {
    return updateInformation(information);
  };

  const onDeleteInformation = (information: AdditionalInformation) => {
    return deleteInformation(information);
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Way points - {versionTag}</h2>
      <Split
        style={{ height: "calc(100vh - 230px)" }}
        direction="vertical"
        onDragEnd={() => {
          if (radarMapRef !== null) {
            radarMapRef.current?.invalidateSize();
          }
        }}
      >
        <div style={{ height: "100%" }}>
          <GenericDataGrid<Waypoint>
            data={waypoints}
            loading={loading}
            columnsGenerator={getColumns}
            validator={new WaypointValidator()}
            createDialogFactory={createDialogFactory}
            onUpdateRow={onUpdateWaypoint}
            onDeleteRow={deleteWaypoint}
            sortModel={[{ field: "name", sort: "asc" }]}
            deleteDialogTitle={"Delete waypoint?"}
            onSelectRow={onWaypointSelected}
            selectedRow={currentWaypoint}
          />
        </div>
        <div style={{ height: "100%" }}>
          <TabContext value={"additionalInformation"}>
            <Tabs value={"additionalInformation"}>
              <Tab value="additionalInformation" label="Additional information" />
            </Tabs>
            <TabPanel value="additionalInformation" style={{ height: "100%" }}>
              <div style={{ height: "90%", display: "flex", flexDirection: "row" }}>
                <div style={{ height: "90%", flex: 1, marginRight: "5px" }}>
                  <AdditionalInformationGrid
                    infos={currentWaypoint ? currentWaypoint.additionalInformation : []}
                    onAddInformation={onAddInformation}
                    onUpdateInformation={onUpdateInformation}
                    onDeleteInformation={onDeleteInformation}
                    canInsertRow={!isUndefined(currentWaypoint)}
                  />
                </div>
                <div style={{ height: "90%", flex: 1 }}>
                  <MapViewer ref={radarMapRef} waypoints={currentWaypoint ? [currentWaypoint] : []} />
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </Split>
    </div>
  );
};

export default Waypoints;
