import { Autocomplete, TextField } from "@mui/material";

export type Distribution = {
  distribution: string;
  code: string;
};

type DistributionListSelectorProps = {
  value?: Distribution[];
  onChange?: (newValue: Distribution[]) => void;
};

const DistributionListSelector = (props: DistributionListSelectorProps) => {
  const { value, onChange } = props;
  const distributionLists = [
    { distribution: "Skyvisu GVA", code: "LSAG-UAC" },
    { distribution: "Skyvisu GVA", code: "LSAG-LAC" },
    { distribution: "Skyvisu GVA", code: "LSAG-APP" },
    { distribution: "Skyvisu GVA", code: "LSAG-TWR" },
    { distribution: "Skyvisu GVA", code: "LSAG-FIC" },
    { distribution: "Skyvisu ZRH", code: "LSAZ-UAC" },
    { distribution: "Skyvisu ZRH", code: "LSAZ-LAC" },
    { distribution: "Skyvisu ZRH", code: "LSAZ-APP" },
    { distribution: "Skyvisu ZRH", code: "LSAZ-TWR" },
    { distribution: "Skyvisu ZRH", code: "LSAZ-FIC" },
    { distribution: "LSAG", code: "LSAG-MEZ" },
    { distribution: "LSAG", code: "LSAG-SIO" },
    { distribution: "LSAZ", code: "LSAZ-ARF" },
    { distribution: "LSAZ", code: "LSAZ-BRN" },
    { distribution: "LSAZ", code: "LSAZ-STG" },
    { distribution: "LSAZ", code: "LSAZ-DUB" },
    { distribution: "LSAZ", code: "LSAZ-EMM" },
    { distribution: "LSAZ", code: "LSAZ-BUO" },
    { distribution: "LSAZ", code: "LSAZ-LUG" }
  ];

  return (
    <Autocomplete
      size="small"
      fullWidth
      options={distributionLists}
      isOptionEqualToValue={(v1, v2) => v1.code === v2.code && v1.distribution === v2.distribution}
      getOptionLabel={(option) => option.code}
      groupBy={(option) => option.distribution}
      multiple
      value={value}
      onChange={(event, value) => {
        onChange?.(value);
      }}
      renderInput={(params) => <TextField {...params} variant="standard" label={"Distribution list"} />}
    />
  );
};

export default DistributionListSelector;
