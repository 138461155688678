import * as yup from "yup";

export const lowerFlightLevelSchemaValidation = yup
  .string()
  .required("Lower level cannot be empty")
  .test("lowerFlightLevel", "Invalid value", (value) => {
    if (value === undefined) return false;
    if (value.length < 3) {
      return false;
    }
    return true;
  })
  .test("lowerFlightLevel", "Invalid value", (value) => {
    if (value === undefined) return false;
    if (value.length > 4) {
      return false;
    }
    return true;
  })
  .test("lowerFlightLevel", "Invalid value", (value) => {
    if (value === undefined) return false;
    if (value.length === 3) {
      if (Number.isNaN(+value!)) {
        return false;
      }
    }
    return true;
  })
  .test("lowerFlightLevel", "Invalid value", (value) => {
    if (value === undefined) return false;
    if (value.length === 4) {
      if (value[0] !== "a" || Number.isNaN(+value.substring(1))) {
        return false;
      }
    }
    return true;
  });

export const higherFlightLevelSchemaValidation = yup
  .string()
  .required("Upper level cannot be empty")
  .test("higherFlightLevel", "Invalid value", (value) => {
    if (value === undefined) return false;
    if (value.length < 3) {
      return false;
    }
    return true;
  })
  .test("higherFlightLevel", "Invalid value", (value) => {
    if (value === undefined) return false;
    if (value.length > 4) {
      return false;
    }
    return true;
  })
  .test("higherFlightLevel", "Invalid value", (value) => {
    if (value === undefined) return false;
    if (value.length === 3) {
      if (Number.isNaN(+value!)) {
        return false;
      }
    }
    return true;
  })
  .test("higherFlightLevel", "Invalid value", (value) => {
    if (value === undefined) return false;
    if (value.length === 4) {
      if (value[0] !== "a" || Number.isNaN(+value.substring(1))) {
        return false;
      }
    }
    return true;
  });
