import { Autocomplete, TextField } from "@mui/material";
import { MapElementType } from "src/types/Types";

type MapElementTypesSelectorProps = {
  currentSelection?: MapElementType[];
  onChange?: (newValue: MapElementType[]) => void;
};

const MapElementTypesSelector = (props: MapElementTypesSelectorProps) => {
  const { currentSelection, onChange } = props;
  const elementTypes: MapElementType[] = [
    "WAYPOINT",
    "POINT",
    "AIRPORT",
    "AIRWAY",
    "AREA",
    "COMPUTED_AREA",
    "LINE",
    "SID",
    "STAR",
    "HOLDING",
    "TEXT",
    "DAM_PARAMETER",
    "DEPARTURE_PROCEDURE",
    "ARRIVAL_PROCEDURE",
    "SWISSTOPO_LAKES",
    "SWISSTOPO_URBAN_AREAS",
    "SWISSTOPO_RAILWAYS",
    "SWISSTOPO_MAJOR_ROADS",
    "SWISSTOPO_RIVERS",
    "SWISSTOPO_COUNTRY_OUTLINES",
    "SWISSTOPO_HIGHWAYS",
    "SWISSTOPO_HIGHWAY_RAMPS",
    "SWISSTOPO_HIGHWAY_TUNNELS"
  ];

  return (
    <Autocomplete
      size="small"
      multiple
      options={elementTypes}
      value={currentSelection ? currentSelection : []}
      onChange={(event, value) => {
        onChange?.(value ? value : []);
      }}
      renderInput={(params) => <TextField {...params} label="Element type" variant="standard" />}
    />
  );
};

export default MapElementTypesSelector;
