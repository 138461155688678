import { useEffect, useState } from "react";
import {
  createAdditionalInformation as _createAdditionalInformation,
  createAirport as _createAirport,
  createArrivalProcedure as _createArrivalProcedure,
  createArrivalProcedurePoint as _createArrivalProcedurePoint,
  createDepartureProcedure as _createDepartureProcedure,
  createDepartureProcedurePoint as _createDepartureProcedurePoint,
  createFrequency as _createFrequency,
  createRunway as _createRunway,
  deleteAdditionalInformation as _deleteAdditionalInformation,
  deleteAirport as _deleteAirport,
  deleteArrivalProcedure as _deleteArrivalProcedure,
  deleteArrivalProcedurePoint as _deleteArrivalProcedurePoint,
  deleteDepartureProcedure as _deleteDepartureProcedure,
  deleteDepartureProcedurePoint as _deleteDepartureProcedurePoint,
  deleteFrequency as _deleteFrequency,
  deleteRunway as _deleteRunway,
  updateAdditionalInformation as _updateAdditionalInformation,
  updateAirport as _updateAirport,
  updateArrivalProcedure as _updateArrivalProcedure,
  updateArrivalProcedurePoint as _updateArrivalProcedurePoint,
  updateDepartureProcedure as _updateDepartureProcedure,
  updateDepartureProcedurePoint as _updateDepartureProcedurePoint,
  moveDepartureProcedurePoints as _moveDepartureProcedurePoints,
  moveArrivalProcedurePoints as _moveArrivalProcedurePoints,
  updateFrequency as _updateFrequency,
  updateRunway as _updateRunway,
  getAdditionalInformationList,
  getAirports,
  getArrivalProcedurePoints,
  getArrivalProcedures,
  getDepartureProcedurePoints,
  getDepartureProcedures,
  getFrequencies,
  getRunways,
  getSids,
  getStars
} from "src/apis/airports/airportsApi";
import { AdditionalInformation, Airport, AirportFrequency, ArrivalProcedure, DepartureProcedure, ProceduralPoint, Runway } from "src/types/Types";

import * as _ from "lodash";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { getWgs84LatLng } from "../waypoints/waypointsApi";

export type AirportsApi = {
  errorMessage: string;
  selectAirport: (airport: Airport) => void;
  createAirport: (airport: Airport) => Promise<Airport>;
  updateAirport: (airport: Airport) => Promise<Airport>;
};

export const useAirports = () => {
  const versionTag = useVersionTag();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [airports, setAirports] = useState<Airport[]>([]);
  const [currentAirport, setCurrentAirport] = useState<Airport>();
  const [currentDepProcedure, setCurrentDepProcedure] = useState<DepartureProcedure>();
  const [currentArrProcedure, setCurrentArrProcedure] = useState<ArrivalProcedure>();
  const [loading, setLoading] = useState(false);

  const selectAirport = async (airport: Airport) => {
    const copy = _.cloneDeep(airport);

    copy.sids = await getSids(airport, versionTag);
    copy.stars = await getStars(airport, versionTag);
    copy.departureProcedures = await getDepartureProcedures(airport, versionTag);
    copy.arrivalProcedures = await getArrivalProcedures(airport, versionTag);
    copy.runways = await getRunways(airport, versionTag);
    copy.frequencies = await getFrequencies(airport, versionTag);

    copy.infos = await getAdditionalInformationList(airport, versionTag);

    setAirports([...airports.filter((a) => a.id !== airport.id), copy]);
    setCurrentAirport(copy);
  };

  const selectDepartureProcedure = async (depProcedure?: DepartureProcedure) => {
    if (!_.isUndefined(depProcedure)) {
      const copy = _.cloneDeep(depProcedure);
      copy.points = await getDepartureProcedurePoints(currentAirport!, depProcedure, versionTag);
      setCurrentDepProcedure(copy);
    } else setCurrentDepProcedure(undefined);
  };

  const selectArrivalProcedure = async (arrProcedure?: ArrivalProcedure) => {
    if (!_.isUndefined(arrProcedure)) {
      const copy = _.cloneDeep(arrProcedure);
      copy.points = await getArrivalProcedurePoints(currentAirport!, arrProcedure, versionTag);
      setCurrentArrProcedure(copy);
    } else setCurrentArrProcedure(undefined);
  };

  const createAirport = (airport: Airport) => {
    return _createAirport(airport, versionTag).then((airport) => {
      setAirports([...airports, airport]);
      setCurrentAirport(airport);
      return airport;
    });
  };

  const deleteAirport = async (airport: Airport) => {
    await _deleteAirport(airport, versionTag);
    setAirports(airports.filter((a) => a.id !== airport.id));
    if (currentAirport!.id === airport.id) {
      setCurrentAirport(undefined);
    }
  };

  const updateAirport = async (airport: Airport) => {
    const a = await _updateAirport(airport, versionTag);
    a.points = airport.points;
    a.runways = airport.runways;
    a.sids = airport.sids;
    a.stars = airport.stars;
    setAirports([...airports.filter((ap) => ap.id !== a.id), a]);
    setCurrentAirport(a);
    return a;
  };

  const addRunway = (runway: Runway) => {
    runway.airportId = currentAirport!.id;
    return _createRunway(currentAirport!, runway, versionTag).then((result) => {
      const copy = _.cloneDeep(currentAirport!);
      copy.runways.push(result);
      setAirports([...airports.filter((a) => a.id !== currentAirport!.id), copy]);
      setCurrentAirport(copy);
      return result;
    });
  };

  const updateRunway = (runway: Runway) => {
    return _updateRunway(currentAirport!, runway, versionTag).then((result) => {
      const copy = _.cloneDeep(currentAirport!);
      copy.runways = copy.runways.filter((r) => r.id !== result.id);
      copy.runways.push(result);
      setAirports([...airports.filter((a) => a.id !== copy.id), copy]);
      setCurrentAirport(copy);
      return result;
    });
  };

  const deleteRunway = (runway: Runway) => {
    return _deleteRunway(currentAirport!, runway, versionTag).then(() => {
      const copy = _.cloneDeep(currentAirport!);
      copy.runways = copy.runways.filter((r) => r.id !== runway.id);
      setAirports([...airports.filter((a) => a.id !== copy.id), copy]);
      setCurrentAirport(copy);
    });
  };

  const enrichWithWgsCoordinates = async (p: ProceduralPoint) => {
    if (!_.isEmpty(p.dmsLatitude) && !_.isEmpty(p.dmsLongitude)) {
      p.dmsLatitude = p.dmsLatitude?.replace('"', "");
      p.dmsLongitude = p.dmsLongitude?.replace('"', "");
      const latlng = await getWgs84LatLng(p.dmsLatitude || "", p.dmsLongitude || "");
      p.wgs84Latitude = latlng.wgs84Latitude;
      p.wgs84Longitude = latlng.wgs84Longitude;
    }
    return p;
  };

  const addDepartureProcedure = (dp: DepartureProcedure) => {
    dp.airportId = currentAirport!.id;
    return _createDepartureProcedure(currentAirport!, dp, versionTag).then((result) => {
      const copy = _.cloneDeep(currentAirport!);
      copy.departureProcedures.push(result);
      setAirports([...airports.filter((a) => a.id !== currentAirport!.id), copy]);
      setCurrentAirport(copy);
      selectDepartureProcedure(undefined);
      return result;
    });
  };

  const updateDepartureProcedure = (dp: DepartureProcedure) => {
    return _updateDepartureProcedure(currentAirport!, dp, versionTag).then((result) => {
      const copy = _.cloneDeep(currentAirport!);
      copy.departureProcedures = copy.departureProcedures.filter((p) => p.id !== dp.id);
      copy.departureProcedures.push(result);
      setAirports([...airports.filter((a) => a.id !== copy.id), copy]);
      setCurrentAirport(copy);
      selectDepartureProcedure(result);
      return result;
    });
  };

  const deleteDepartureProcedure = (dp: DepartureProcedure) => {
    return _deleteDepartureProcedure(currentAirport!, dp, versionTag).then(() => {
      const copy = _.cloneDeep(currentAirport!);
      copy.departureProcedures = copy.departureProcedures.filter((p) => p.id !== dp.id);
      setAirports([...airports.filter((a) => a.id !== copy.id), copy]);
      setCurrentAirport(copy);
      selectDepartureProcedure(undefined);
    });
  };

  const addDepartureProcedurePoint = async (p: ProceduralPoint) => {
    return _createDepartureProcedurePoint(currentAirport!, currentDepProcedure!, await enrichWithWgsCoordinates(p), versionTag).then((result) => {
      const copy = _.cloneDeep(currentDepProcedure!);
      copy.points = result;
      setCurrentDepProcedure(copy);
      return result[result.length - 1];
    });
  };

  const deleteDepartureProcedurePoint = (point: ProceduralPoint) => {
    return _deleteDepartureProcedurePoint(currentAirport!, currentDepProcedure!, point, versionTag).then((remainingPoints) => {
      const copy = _.cloneDeep(currentDepProcedure!);
      copy.points = remainingPoints;
      setCurrentDepProcedure(copy);
    });
  };

  const updateDepartureProcedurePoint = async (p: ProceduralPoint) => {
    return _updateDepartureProcedurePoint(currentAirport!, currentDepProcedure!, await enrichWithWgsCoordinates(p), versionTag).then((result) => {
      const copy = _.cloneDeep(currentDepProcedure!);
      copy.points = result;
      setCurrentDepProcedure(copy);
      return result.filter((_p) => _p.id === p.id)[0];
    });
  };

  const moveDepartureProcedurePoints = async (dp: DepartureProcedure, fromIndex: number, toIndex: number, selectedPointsSequenceNumbers: number[]) => {
    return _moveDepartureProcedurePoints(currentAirport!, dp, fromIndex, toIndex, selectedPointsSequenceNumbers, versionTag).then((result) => {
      const copy = _.cloneDeep(currentDepProcedure!);
      copy.points = result;
      setCurrentDepProcedure(copy);
      return result;
    });
  };

  const addArrivalProcedure = (ap: ArrivalProcedure) => {
    ap.airportId = currentAirport!.id;
    return _createArrivalProcedure(currentAirport!, ap, versionTag).then((result) => {
      const copy = _.cloneDeep(currentAirport!);
      copy.arrivalProcedures.push(result);
      setAirports([...airports.filter((a) => a.id !== currentAirport!.id), copy]);
      setCurrentAirport(copy);
      selectArrivalProcedure(undefined);
      return result;
    });
  };

  const updateArrivalProcedure = (ap: ArrivalProcedure) => {
    return _updateArrivalProcedure(currentAirport!, ap, versionTag).then((result) => {
      const copy = _.cloneDeep(currentAirport!);
      copy.arrivalProcedures = copy.arrivalProcedures.filter((p) => p.id !== ap.id);
      copy.arrivalProcedures.push(result);
      setAirports([...airports.filter((a) => a.id !== copy.id), copy]);
      setCurrentAirport(copy);
      selectArrivalProcedure(result);
      return result;
    });
  };

  const deleteArrivalProcedure = (ap: ArrivalProcedure) => {
    return _deleteArrivalProcedure(currentAirport!, ap, versionTag).then(() => {
      const copy = _.cloneDeep(currentAirport!);
      copy.arrivalProcedures = copy.arrivalProcedures.filter((p) => p.id !== ap.id);
      setAirports([...airports.filter((a) => a.id !== copy.id), copy]);
      setCurrentAirport(copy);
      selectArrivalProcedure(undefined);
    });
  };

  const addArrivalProcedurePoint = async (p: ProceduralPoint) => {
    return _createArrivalProcedurePoint(currentAirport!, currentArrProcedure!, await enrichWithWgsCoordinates(p), versionTag).then((result) => {
      const copy = _.cloneDeep(currentArrProcedure!);
      copy.points = result;
      setCurrentArrProcedure(copy);
      return result[result.length - 1];
    });
  };

  const deleteArrivalProcedurePoint = (point: ProceduralPoint) => {
    return _deleteArrivalProcedurePoint(currentAirport!, currentArrProcedure!, point, versionTag).then((remainingPoints) => {
      const copy = _.cloneDeep(currentArrProcedure!);
      copy.points = remainingPoints;
      setCurrentArrProcedure(copy);
    });
  };

  const updateArrivalProcedurePoint = async (p: ProceduralPoint) => {
    return _updateArrivalProcedurePoint(currentAirport!, currentArrProcedure!, await enrichWithWgsCoordinates(p), versionTag).then((result) => {
      const copy = _.cloneDeep(currentArrProcedure!);
      copy.points = result;
      setCurrentArrProcedure(copy);
      return result.filter((_p) => _p.id === p.id)[0];
    });
  };

  const moveArrivalProcedurePoints = async (ap: ArrivalProcedure, fromIndex: number, toIndex: number, selectedPointsSequenceNumbers: number[]) => {
    return _moveArrivalProcedurePoints(currentAirport!, ap, fromIndex, toIndex, selectedPointsSequenceNumbers, versionTag).then((result) => {
      const copy = _.cloneDeep(currentArrProcedure!);
      copy.points = result;
      setCurrentArrProcedure(copy);
      return result;
    });
  };

  const addFrequency = (frequency: AirportFrequency) => {
    return _createFrequency(currentAirport!, frequency, versionTag).then((frequency) => {
      const copy = _.cloneDeep(currentAirport!);
      copy.frequencies.push(frequency);
      setAirports([...airports.filter((a) => a.id !== copy.id), copy]);
      setCurrentAirport(copy);
      return frequency;
    });
  };

  const updateFrequency = (frequency: AirportFrequency) => {
    return _updateFrequency(currentAirport!, frequency, versionTag).then((frequency) => {
      const copy = _.cloneDeep(currentAirport!);
      copy.frequencies = copy.frequencies.filter((f) => f.id !== frequency.id);
      copy.frequencies.push(frequency);
      setAirports([...airports.filter((a) => a.id !== currentAirport!.id), copy]);
      setCurrentAirport(copy);
      return frequency;
    });
  };

  const deleteFrequency = (frequency: AirportFrequency) => {
    return _deleteFrequency(currentAirport!, frequency, versionTag).then((frequencies) => {
      const copy = _.cloneDeep(currentAirport!);
      copy.frequencies = frequencies;
      setAirports([...airports.filter((a) => a.id !== copy.id), copy]);
      setCurrentAirport(copy);
    });
  };

  const createAdditionalInformation = (additionalInformnation: AdditionalInformation) => {
    return _createAdditionalInformation(currentAirport!, additionalInformnation, versionTag).then((info) => {
      const copy = _.cloneDeep(currentAirport!);
      copy.infos.push(info);
      setAirports([...airports.filter((a) => a.id !== copy.id), copy]);
      setCurrentAirport(copy);
      return info;
    });
  };

  const updateAdditionalInformation = (additionalInformation: AdditionalInformation) => {
    return _updateAdditionalInformation(currentAirport!, additionalInformation, versionTag).then((info) => {
      const copy = _.cloneDeep(currentAirport!);
      copy.infos = copy.infos.filter((i) => i.id !== info.id);
      copy.infos.push(info);
      setAirports([...airports.filter((a) => a.id !== copy.id), copy]);
      setCurrentAirport(copy);
      return info;
    });
  };

  const deleteAdditionalInformation = (additionalInformation: AdditionalInformation) => {
    return _deleteAdditionalInformation(currentAirport!, additionalInformation, versionTag).then((data) => {
      const copy = _.cloneDeep(currentAirport!);
      copy.infos = data;
      setAirports([...airports.filter((a) => a.id !== copy.id, copy)]);
      setCurrentAirport(copy);
    });
  };

  useEffect(() => {
    setLoading(true);
    getAirports(versionTag)
      .then((results) => {
        setAirports(results);
      })
      .catch((error) => {
        setErrorMessage(error.errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [versionTag]);

  return {
    loading,
    errorMessage,
    airports,
    currentAirport,
    currentDepProcedure,
    currentArrProcedure,
    selectDepartureProcedure,
    selectArrivalProcedure,
    selectAirport,
    createAirport,
    deleteAirport,
    updateAirport,
    addRunway,
    deleteRunway,
    updateRunway,
    addArrivalProcedure,
    addDepartureProcedure,
    deleteArrivalProcedure,
    deleteDepartureProcedure,
    addDepartureProcedurePoint,
    addArrivalProcedurePoint,
    updateDepartureProcedurePoint,
    updateArrivalProcedurePoint,
    deleteDepartureProcedurePoint,
    moveDepartureProcedurePoints,
    moveArrivalProcedurePoints,
    deleteArrivalProcedurePoint,
    updateArrivalProcedure,
    updateDepartureProcedure,
    addFrequency,
    updateFrequency,
    deleteFrequency,
    createAdditionalInformation,
    updateAdditionalInformation,
    deleteAdditionalInformation
  };
};
