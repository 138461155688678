import { LatLng } from "leaflet";
import { useEffect, useState } from "react";
import { Polyline } from "react-leaflet";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { ArrivalProcedure, DepartureProcedure, ProceduralPoint } from "src/types/Types";

type DepartureOrArrivalProcedureMarkerProps = {
  instrumentProcedure: DepartureProcedure | ArrivalProcedure;
  getInstrumentProcedurePoints: (airportId: number, instrumentProcedureId: number, versionTag: string) => Promise<ProceduralPoint[]>;
};

const DepartureOrArrivalProcedureMarker = (props: DepartureOrArrivalProcedureMarkerProps) => {
  const { instrumentProcedure, getInstrumentProcedurePoints } = props;
  const versionTag = useVersionTag();
  const defaultColor = "#3388ff";
  const [instrumentProcedurePoints, setInstrumentProcedurePoints] = useState<ProceduralPoint[]>([]);

  useEffect(() => {
    if (instrumentProcedure)
      getInstrumentProcedurePoints(instrumentProcedure.airportId, instrumentProcedure.id, versionTag).then((result) => {
        setInstrumentProcedurePoints(result.slice().sort((a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0)));
      });
  }, [instrumentProcedure]);

  return (
    <Polyline
      key={`${instrumentProcedure.id}${instrumentProcedure.color ? instrumentProcedure.color : ""}`}
      pathOptions={{ color: instrumentProcedure.color ? instrumentProcedure.color : defaultColor, dashArray: instrumentProcedure.dashArray, weight: 1 }}
      positions={instrumentProcedurePoints.map((p) => {
        return new LatLng(p.wgs84Latitude, p.wgs84Longitude);
      })}
    />
  );
};

export default DepartureOrArrivalProcedureMarker;
