import { Validator } from "./Validator";

class HoldingValidator extends Validator {
  validateField = (fieldName: string, fieldValue: any, row?: Object) => {
    switch (fieldName) {
      case "name":
        return this.validateName(fieldName, fieldValue);
      case "fix":
        return this.validateName(fieldName, fieldValue);
      case "inboundTrack":
        return this.validateAngle("inboundTrack", fieldValue);
      case "radial":
        return this.validateAngle("radial", fieldValue);
      case "legTimeMinuteSeconds":
        return this.validateMinuteSeconds("legTimeMinuteSeconds", fieldValue);
      case "minimumLevel":
        return this.validateAltitude("minimumLevel", fieldValue);
      case "maximumLevel":
        return this.validateAltitude("maximumLevel", fieldValue);
      case "maximumAirspeed":
        return this.validatePositiveNumber("maximumAirspeed", fieldValue);
      case "minimumReceptionAltitude":
        return this.validatePositiveNumber("minimumReceptionAltitude", fieldValue);
      case "frequency":
        return this.validateFrequency("frequency", fieldValue);

      default:
        return "";
    }
  };
}

export default HoldingValidator;
