import axios, { AxiosError } from "axios";
import { AdditionalInformation, NameAndArea, Waypoint } from "src/types/Types";
import { getAPIClient } from "../apiClient";

type WaypointResponse = {
  data: Waypoint;
  errorMessage: string;
};

type WaypointsResponse = {
  data: Waypoint[];
  errorMessage: string;
};

export type AddWaypointResponse = {
  data: Waypoint;
  errorMessage: string;
  fieldErrors: FieldError[];
};

export type UpdateWaypointResponse = {
  data: Waypoint;
  errorMessage: string;
  fieldErrors: FieldError[];
};

type DeleteWaypointResponse = {
  data: number;
  errorMessage: string;
};

export type Wgs84LatLngResponse = {
  data: Wgs84LatLng;
  errorMessage: string;
  fieldErrors: FieldError[];
};

type Wgs84LatitudeResponse = {
  data: number;
  errorMessage: string;
};

export type Wgs84LatLng = {
  wgs84Latitude: number;
  wgs84Longitude: number;
};

type FieldError = {
  fieldId: string;
  message: string;
};

type AdditionalInformationResponse = {
  data: AdditionalInformation;
  errorMessage: string;
};

type AdditionalInformationListResponse = {
  data: AdditionalInformation[];
  errorMessage: string;
};

type UpdateAdditionalInformationResponse = {
  data: AdditionalInformation;
  errorMessage: string;
};

type DeleteAdditionalInformationResponse = {
  data: AdditionalInformation[];
  errorMessage: string;
};

export const getWgs84Latitude = (latitude: string) => {
  const normalizedLatitude = latitude.replace('"', "");
  const apiClient = getAPIClient();
  return apiClient!.get<Wgs84LatitudeResponse>(`/dmsToWgs84Latitude/${normalizedLatitude}`).then((result) => {
    return result.data.data;
  });
};

export const getWgs84Longitude = (longitude: string) => {
  const normalizedLongitude = longitude.replace('"', "");
  const apiClient = getAPIClient();
  return apiClient!.get<Wgs84LatitudeResponse>(`/dmsToWgs84Longitude/${normalizedLongitude}`).then((result) => {
    return result.data.data;
  });
};

export const getWgs84LatLng = (latitude: string, longitude: string) => {
  const apiClient = getAPIClient();
  const normalizedLatitude = latitude.replace('"', "");
  const normalizedLongitude = longitude.replace('"', "");
  return apiClient!.get<Wgs84LatLngResponse>(`/dmsToWgs84/${normalizedLatitude}/${normalizedLongitude}`).then((result) => {
    return result.data.data;
  });
};

export const getWaypoints = (versionTag: string) => {
  const apiClient = getAPIClient();
  return apiClient!.get<WaypointsResponse>("/tables/waypoint", { headers: { "x-air-version": versionTag } }).then((response) => {
    return response.data.data;
  });
};

export const getWaypointsFromIds = async (versionTag: string, waypointIds: string) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.get<WaypointsResponse>(`/tables/waypoint?waypointIds=${waypointIds}`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const getWaypointsFromNameAndArea = async (versionTag: string, nameAndAreas: NameAndArea[]) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.put<WaypointsResponse>(`/tables/waypoint/search`, { nameAreaPairs: nameAndAreas }, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const getWaypoint = (versionTag: string, waypointId: number) => {
  const apiClient = getAPIClient();
  return apiClient!.get<WaypointResponse>(`/tables/waypoint/${waypointId}`, { headers: { "x-air-version": versionTag } }).then((response) => {
    return response.data.data;
  });
};

export const updateWaypoint = (waypoint: Waypoint, versionTag: string) => {
  const apiClient = getAPIClient();
  return apiClient!.put<UpdateWaypointResponse>(`/tables/waypoint/${waypoint.id}`, waypoint, { headers: { "x-air-version": versionTag } }).then((response) => {
    return response.data.data;
  });
};

export const createWaypoint = (waypoint: Waypoint, versionTag: string) => {
  const apiClient = getAPIClient();
  return apiClient!.post<AddWaypointResponse>("tables/waypoint", waypoint, { headers: { "x-air-version": versionTag } }).then((response) => {
    return response.data.data;
  });
};

export const deleteWaypoint = (waypoint: Waypoint, versionTag: string) => {
  const apiClient = getAPIClient();
  return apiClient!.delete<DeleteWaypointResponse>(`/tables/waypoint/${waypoint?.id}`, { headers: { "x-air-version": versionTag } }).then((response) => {
    return response.data.data;
  });
};

export const getAdditionalInformation = async (waypoint: Waypoint, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<AdditionalInformationListResponse>(`/tables/waypoint/${waypoint.id}/additional-information/`, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const addAdditionalInformation = async (waypoint: Waypoint, info: AdditionalInformation, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<AdditionalInformationResponse>(`/tables/waypoint/${waypoint.id}/additional-information/`, info, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const updateAdditionalInformation = async (waypoint: Waypoint, info: AdditionalInformation, versionTag: string) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.put<UpdateAdditionalInformationResponse>(`/tables/waypoint/${waypoint?.id}/additional-information/${info.id}`, info, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const deleteAdditionalInformation = async (waypoint: Waypoint, info: AdditionalInformation, versionTag: string) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.delete<DeleteAdditionalInformationResponse>(`/tables/waypoint/${waypoint?.id}/additional-information/${info.id}`, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};
