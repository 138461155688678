import { Autocomplete, TextField } from "@mui/material";

type CategoriesSelectorProps = {
  value?: string | null;
  onChange?: (newValue: string | null) => void;
  showLabel?: boolean;
};
const CategoriesSelector = (props: CategoriesSelectorProps) => {
  const { value, onChange, showLabel = true } = props;
  const categories = ["Nav. warnings", "Airspace", "Geographical", "Misc."];
  return (
    <Autocomplete
      fullWidth
      options={categories}
      value={value}
      onChange={(event, value) => {
        onChange?.(value);
      }}
      renderInput={(params) => <TextField {...params} label={showLabel === true ? "Categories" : undefined} size="small" variant="standard" />}
    />
  );
};

export default CategoriesSelector;
