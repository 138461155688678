import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import { Marker, Tooltip } from "react-leaflet";
import { Holding } from "src/types/Types";

import WaypointIcon from "@mui/icons-material/ChangeHistory";

type HoldingMarkerProps = {
  holding: Holding;
};

const HoldingMarker = (props: HoldingMarkerProps) => {
  const { holding } = props;

  const divIcon = L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(
      <div style={{ color: "#1565C0" }}>
        {holding.symbolImage ? (
          <div style={{ width: "15px", height: "15px", fontWeight: "bold" }}>
            <img src={`data:image/png;base64,${holding.symbolImage}`} />
          </div>
        ) : (
          <WaypointIcon style={{ width: "15px", height: "15px", fontWeight: "bold" }} />
        )}
      </div>
    )
  });

  return (
    <>
      {holding.fixWgs84Latitude && holding.fixWgs84Longitude ? (
        <Marker icon={divIcon} position={[holding.fixWgs84Latitude, holding.fixWgs84Longitude]}>
          <Tooltip direction="left">{`[${holding.fixWgs84Latitude} ${holding.fixWgs84Longitude}]`}</Tooltip>
        </Marker>
      ) : (
        <></>
      )}
    </>
  );
};

export default HoldingMarker;
