import { Validator } from "./Validator";

class ComputedAreaValidator extends Validator {
  validateNotEmptyArray = (fieldName: string, fieldValue: any) => {
    const mergedAreas = fieldValue as string[];
    if (mergedAreas.length === 0) {
      this.validationMap.set(fieldName, true);
      return "Invalid value";
    }
    this.validationMap.set(fieldName, false);
    return "";
  };

  validateField = (fieldName: string, fieldValue: any) => {
    switch (fieldName) {
      case "name":
        return this.validateName(fieldName, fieldValue);
      case "mergedAreas":
        return this.validateNotEmptyArray(fieldName, fieldValue);
      case "smoothing":
        return this.validatePositiveNumber(fieldName, fieldValue);
      default:
        return "";
    }
  };
}

export default ComputedAreaValidator;
