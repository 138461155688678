import { Autocomplete, Button, Popover, TextField } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useAreas } from "src/apis/areas/useAreas";
import { MergedArea } from "src/types/Types";
import useComputedAreas from "src/apis/computedareas/useComputedAreas";
import { parseMergedArea, renderMergedArea } from "src/columnsgenerators/useComputedAreaColumns";

const DataGridMergedAreasSelector = (params: GridRenderEditCellParams<MergedArea[]>) => {
  const { id, value, field, error } = params;
  const { computedAreas } = useComputedAreas();
  const { areas } = useAreas();
  const [mergedAreas, setMergedAreas] = useState<MergedArea[]>([]);

  useEffect(() => {
    let a: MergedArea[] = areas.map((a) => {
      return { id: a.id, name: a.name, areaType: "AREA" };
    });
    let ca: MergedArea[] = computedAreas.map((ca) => {
      return { id: ca.id, name: ca.name, areaType: "COMPUTED_AREA" };
    });

    setMergedAreas([...a, ...ca]);
  }, [areas, computedAreas]);

  const apiRef = useGridApiContext();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  const handleChangeValue = (event: any, values: string[]) => {
    const newValues = values.map((v) => {
      return parseMergedArea(v);
    }); // The new value entered by the user
    apiRef.current.setEditCellValue({ id, field, value: newValues });
  };
  const style = error ? { color: "red" } : {};

  return (
    <div style={{ width: 500 }}>
      <Button onClick={handleClick} style={style}>
        {value !== undefined && value.length > 0
          ? value
              .map((v: MergedArea) => {
                return renderMergedArea(v);
              })
              .toString()
              .replace(",", "|")
          : "Merged areas"}
      </Button>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <div style={{ width: 500 }}>
          <Autocomplete
            size="small"
            fullWidth
            options={mergedAreas.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0)).map((ma) => renderMergedArea(ma))}
            multiple
            value={value?.map((v: MergedArea) => renderMergedArea(v))}
            onChange={handleChangeValue}
            renderInput={(params) => <TextField error={error} multiline {...params} />}
          />
        </div>
      </Popover>
    </div>
  );
};

export default DataGridMergedAreasSelector;
