import { useEffect, useState } from "react";

import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import * as _ from "lodash";
import useArrProceduresColumns from "src/columnsgenerators/useArrProceduresColumns";
import useProcedurePointsColumns from "src/columnsgenerators/useProcedurePointsColumns";
import GenericDataGrid from "src/components/datagrids/GenericDataGrid";
import { Airport, ArrivalProcedure, ProceduralPoint } from "src/types/Types";
import ArrDepProcedureValidator from "src/validators/ArrDepProcedureValidator";
import ProcedurePointValidator from "src/validators/ProcedurePointValidator";
import { GridRowOrderChangeParams } from "@mui/x-data-grid-pro";

type AirportArrivalProcedureProps = {
  selectedAirport?: Airport;
  selectedArrProcedure?: ArrivalProcedure;

  onArrProcedureSelected: (ap?: ArrivalProcedure) => void;
  onAddArrivalProcedure: (ap: ArrivalProcedure) => Promise<ArrivalProcedure>;
  onUpdateArrivalProcedure: (ap: ArrivalProcedure) => Promise<ArrivalProcedure>;
  onDeleteArrivalProcedure: (ap: ArrivalProcedure) => Promise<void>;

  onAddArrivalProcedurePoint: (p: ProceduralPoint) => Promise<ProceduralPoint>;
  onUpdateArrivalProcedurePoint: (p: ProceduralPoint) => Promise<ProceduralPoint>;
  onDeleteArrivalProcedurePoint: (p: ProceduralPoint) => Promise<void>;
  onArrivalProcedurePointsOrderChange: (ap: ArrivalProcedure, fromIndex: number, toIndex: number, selected: number[]) => void;
};

const AirportArrivalProcedures = (props: AirportArrivalProcedureProps) => {
  const {
    selectedAirport,
    selectedArrProcedure,
    onArrProcedureSelected,
    onAddArrivalProcedure,
    onUpdateArrivalProcedure,
    onDeleteArrivalProcedure,
    onAddArrivalProcedurePoint,
    onUpdateArrivalProcedurePoint,
    onDeleteArrivalProcedurePoint,
    onArrivalProcedurePointsOrderChange
  } = props;

  const [selectedAirportId, setSelectedAirportId] = useState(selectedAirport?.id);

  const [arrivalProcedurePoints, setArrivalProcedurePoints] = useState<ProceduralPoint[]>([]);
  const [selectedArrivalProcedurePoints, setSelectedArrivalProcedurePoints] = useState<ProceduralPoint[]>([]);

  const { getColumns: arrProceduresColumsGenerator } = useArrProceduresColumns();
  const { getColumns: procedurePointsColumnsGenerator } = useProcedurePointsColumns();

  const setSortedArrProcedurePoints = () => {
    setArrivalProcedurePoints(selectedArrProcedure?.points.slice().sort((a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0)) || []);
  };

  useEffect(() => {
    if (_.isUndefined(selectedArrProcedure)) {
      setSelectedArrivalProcedurePoints([]);
    }

    setSortedArrProcedurePoints();
  }, [selectedArrProcedure]);

  useEffect(() => {
    if (selectedAirport?.id !== selectedAirportId) {
      onArrProcedureSelected(undefined);
      setSelectedAirportId(selectedAirport?.id);
    }
  }, [selectedAirport]);

  const createEmptyArrProcedureRow = (): ArrivalProcedure => {
    return {
      id: -1,
      airportId: selectedAirport!.id,
      name: "",
      runwayDesignator: "",
      description: "",
      points: [],
      userTags: []
    };
  };

  const createEmptyArrProcedurePointRow = (): ProceduralPoint => {
    let sequence = 1;
    if (selectedArrProcedure) {
      sequence = selectedArrProcedure.points.length + 1;
    }
    return {
      id: -1,
      sequence: sequence,
      inverted: false,
      curvature: "0",
      wgs84Latitude: 0,
      wgs84Longitude: 0,
      distanceToNextPointNm: 0
    };
  };

  const onSelectedPointsOrderChange = (params: GridRowOrderChangeParams) => {
    onArrivalProcedurePointsOrderChange(
      selectedArrProcedure!,
      params.oldIndex,
      params.targetIndex,
      selectedArrivalProcedurePoints.map((lp) => lp.sequence)
    );
  };

  const onSelectedArrivalProcedurePoints = (dpp: ProceduralPoint[]) => {
    let selectedPoints = [...dpp];
    selectedPoints.sort((a, b) => a.sequence - b.sequence);
    setSelectedArrivalProcedurePoints(selectedPoints);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{ width: "100%", flex: 1 }}>
        <GenericDataGrid<ArrivalProcedure>
          data={selectedAirport ? selectedAirport.arrivalProcedures : []}
          columnsGenerator={arrProceduresColumsGenerator}
          createEmptyRow={createEmptyArrProcedureRow}
          onCreateRow={onAddArrivalProcedure}
          onUpdateRow={onUpdateArrivalProcedure}
          onSelectRow={onArrProcedureSelected}
          onDeleteRow={onDeleteArrivalProcedure}
          deleteDialogTitle="Delete arrival procedure ?"
          sortModel={[{ field: "name", sort: "asc" }]}
          canInsertRow={!_.isUndefined(selectedAirport)}
          validator={new ArrDepProcedureValidator()}
        />
      </div>
      <div style={{ flex: 1.1, marginBottom: "25px" }}>
        <TabContext value="arrivalProcedurePoints">
          <Tabs value="arrivalProcedurePoints">
            <Tab value="arrivalProcedurePoints" label={"Arrival procedure points"} />
          </Tabs>
          <TabPanel value="arrivalProcedurePoints" style={{ height: "80%" }}>
            <GenericDataGrid<ProceduralPoint>
              data={arrivalProcedurePoints}
              columnsGenerator={procedurePointsColumnsGenerator}
              validator={new ProcedurePointValidator()}
              createEmptyRow={createEmptyArrProcedurePointRow}
              onCreateRow={onAddArrivalProcedurePoint}
              onUpdateRow={onUpdateArrivalProcedurePoint}
              onSelectedRows={onSelectedArrivalProcedurePoints}
              onDeleteRow={onDeleteArrivalProcedurePoint}
              deleteDialogTitle="Delete arrival procedure point ?"
              canInsertRow={!_.isUndefined(selectedArrProcedure)}
              onRowOrderChange={onSelectedPointsOrderChange}
            />
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
};

export default AirportArrivalProcedures;
