import { FormikHelpers, useFormik } from "formik";
import { toast } from "react-toastify";
import { getWgs84LatLng } from "src/apis/waypoints/waypointsApi";
import { Area, AreaPoint } from "src/types/Types";
import { latitudeSchemaValidation, longitudeSchemaValidation } from "src/validators/yup/latlngValidation";
import * as yup from "yup";

type AddAreaFormTypes = {
  name: string;
  description: string | null;
  category: string | null;
  buffer: string;
  emdisCenterDmsLatitude: string;
  emdisCenterDmsLongitude: string;
  points: AreaPoint[];
  userTags: string[];
};

const useAddAreaFormHandler = (createArea: (area: Area) => Promise<Area>, onSuccess: () => void) => {
  const validationSchema = yup.object({
    name: yup.string().required("Name cannot be empty"),
    description: yup.string().nullable(),
    category: yup.string().nullable(),
    buffer: yup.string().test("buffer", "Invalid buffer", (value) => {
      if (Number.isNaN(+value!)) {
        return false;
      }
      return true;
    }),
    userTags: yup.array(),
    emdisCenterDmsLatitude: latitudeSchemaValidation,
    emdisCenterDmsLongitude: longitudeSchemaValidation
  });

  const mapFormValues = async (values: AddAreaFormTypes): Promise<Area> => {
    const latLng = await getWgs84LatLng(values.emdisCenterDmsLatitude, values.emdisCenterDmsLongitude);
    return Promise.resolve({
      id: -1,
      name: values.name,
      description: values.description,
      category: values.category,
      buffer: +values.buffer,
      emdisCenterWgs84Latitude: latLng.wgs84Latitude,
      emdisCenterWgs84Longitude: latLng.wgs84Longitude,
      emdisCenterDmsLatitude: values.emdisCenterDmsLatitude,
      emdisCenterDmsLongitude: values.emdisCenterDmsLongitude,
      userTags: values.userTags,
      areaPoints: values.points,
      additionalInformation: [],
      xrefs: []
    });
  };

  const formik = useFormik<AddAreaFormTypes>({
    initialValues: {
      name: "",
      description: "",
      category: "",
      buffer: "0.0",
      emdisCenterDmsLatitude: '000°00´00.000"N',
      emdisCenterDmsLongitude: '000°00´00.000"E',
      userTags: [],
      points: []
    },
    validationSchema: validationSchema,
    onSubmit: async (values, helpers: FormikHelpers<AddAreaFormTypes>) => {
      const area = await mapFormValues(values);
      return createArea(area)
        .then(() => {
          onSuccess();
        })
        .catch((error) => {
          if (error.fieldErrors) {
            error.fieldErrors.map((f: any) => {
              helpers.setFieldError(f.fieldId, f.message);
              if (f.fieldId === "areaPoints") {
                toast.error(f.message);
              }
            });
          } else {
            if (error.errorMessage) {
              toast.error(error.errorMessage);
            }
          }
        });
    }
  });

  return { formHandler: formik };
};

export default useAddAreaFormHandler;
