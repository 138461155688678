import { LatLng } from "leaflet";
import { Polygon } from "react-leaflet";
import { Lake } from "src/types/Types";

type LakesProps = {
  lakes: Lake[];
};

const Lakes = (props: LakesProps) => {
  const { lakes } = props;
  const defaultColor = "#1565C0";
  return (
    <>
      {lakes.map((l) => {
        return l.polygons.map((p) => {
          return (
            <Polygon
              pathOptions={{ color: l.color ? l.color : defaultColor, weight: 1, fill: false }}
              positions={p.coordinates.slice().map((c) => {
                return new LatLng(c.wgs84Latitude, c.wgs84Longitude);
              })}
            />
          );
        });
      })}
    </>
  );
};

export default Lakes;
