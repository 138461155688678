import React, { createContext, ReactNode, useContext, useEffect, useReducer, useState } from "react";
import { Action, appReducer } from "./appReducer";
import { AppState, initialState } from "./AppState";

export type AirApi = {
  state: AppState;
  dispatch: React.Dispatch<Action>;
};

const AppContext = createContext<AirApi>({ state: initialState, dispatch: (action: Action) => {} });

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};
