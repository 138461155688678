import { GridPreProcessEditCellProps, GridRenderCellParams, GridRenderEditCellParams } from "@mui/x-data-grid-pro";
import DataGridLatitudeMask from "src/components/editors/DataGridLatitudeMask";
import DataGridLongitudeMask from "src/components/editors/DataGridLongitudeMask";
import DataGridRunwayDesignatorMask from "src/components/editors/DataGridRunwayDesignatorMask";
import DatagridSurfaceSelector from "src/components/selectors/surfaceselector/DatagridSurfaceSelector";
import { ColumnsGenerator } from "src/types/Types";

const useRunwaysColumns = () => {
  const latitudeOrLongitudeMaskEmpty = '___°__´__.___"_';

  const getColumns: ColumnsGenerator = (validator, onFieldDirty) => {
    const columns = [
      {
        field: "designator",
        headerName: "Designator",
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridRunwayDesignatorMask {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          let hasError = validator.validateField("designator", params.props.value, params.otherFieldsProps);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "dmsThresholdLatitude",
        headerName: "THR. Latitude",
        width: 120,
        editable: true,
        valueParser: (value: string) => {
          if (value === latitudeOrLongitudeMaskEmpty) {
            return null;
          }
          return value;
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridLatitudeMask {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          let hasError = validator.validateField("dmsThresholdLatitude", params.props.value, params.otherFieldsProps);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "dmsThresholdLongitude",
        headerName: "THR. Longitude",
        width: 120,
        editable: true,
        valueParser: (value: string) => {
          if (value === latitudeOrLongitudeMaskEmpty) {
            return null;
          }
          return value;
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridLongitudeMask {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          let hasError = validator.validateField("dmsThresholdLongitude", params.props.value, params.otherFieldsProps);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "width",
        headerName: "Width",
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          let hasError = validator.validateField("width", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "surface",
        headerName: "Surface",
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          let hasError = validator.validateField("surface", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderEditCellParams) => {
          return <DatagridSurfaceSelector {...params} />;
        }
      },
      {
        field: "availableLandingDistance",
        headerName: "LDA",
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          let hasError = validator.validateField("availableLandingDistance", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "remarks",
        headerName: "Remarks",
        width: 400,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          let hasError = false;
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "heliport",
        headerName: "Heliport",
        editable: true,
        type: "boolean",
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          let hasError = false;
          return { ...params.props, error: hasError };
        }
      }
    ];
    return columns;
  };
  return { getColumns };
};

export default useRunwaysColumns;
