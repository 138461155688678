import { Autocomplete, TextField } from "@mui/material";

type AorSelectorProps = {
  value?: string[];
  onChange?: (newValue: string[] | null) => void;
  label?: string;
};

const AorSelector = (props: AorSelectorProps) => {
  const { label, value, onChange } = props;
  return (
    <Autocomplete
      fullWidth
      size="small"
      options={["LSAZ", "LSAG"]}
      value={value}
      multiple
      onChange={(event, value) => {
        onChange?.(value);
      }}
      renderInput={(params) => <TextField variant="standard" {...params} label={label} value={value} />}
    />
  );
};

export default AorSelector;
