import { UserTag, Xref } from "src/types/Types";
import { getAPIClient } from "src/apis/apiClient";

type UserTagsResponse = {
  data: UserTag[];
  errorMessage: string;
};

type CreateUserTagResponse = {
  data: UserTag;
  errorMessage: string;
};

type DeleteUserTagResponse = {
  data: number;
  errorMessage: string;
};

type UserTagXrefsResponse = {
  data: Xref[];
  errorMessage: string;
};

type UpdateUserTagResponse = CreateUserTagResponse;

export const getUserTags = (versionTag: string) => {
  const apiClient = getAPIClient();
  return apiClient!.get<UserTagsResponse>("/tables/user_tag", { headers: { "x-air-version": versionTag } }).then((response) => {
    return response.data.data;
  });
};

export const getUserTagXrefs = (versionTag: string, userTag: UserTag) => {
  const apiClient = getAPIClient();
  return apiClient!.get<UserTagXrefsResponse>(`/tables/user_tag/${userTag.id}/x-ref`, { headers: { "x-air-version": versionTag } }).then((response) => {
    return response.data.data;
  });
};

export const createUserTag = async (versionTag: string, userTag: UserTag) => {
  const apiClient = getAPIClient();
  const result = await apiClient!.post<CreateUserTagResponse>("/tables/user_tag", userTag, { headers: { "x-air-version": versionTag } });
  return result.data.data;
};

export const updateUserTag = async (versionTag: string, userTag: UserTag) => {
  const apiClient = getAPIClient();
  const result = await apiClient!.put<UpdateUserTagResponse>(`/tables/user_tag/${userTag.id}`, userTag, { headers: { "x-air-version": versionTag } });
  return result.data.data;
};

export const deleteUserTag = async (versionTag: string, userTag: UserTag) => {
  const apiClient = getAPIClient();
  const result = await apiClient!.delete<DeleteUserTagResponse>(`/tables/user_tag/${userTag.id}`, {
    headers: { "x-air-version": versionTag }
  });
  return result.data.data;
};
