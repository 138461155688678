import TextFieldEditor from "./TextFieldEditor";

type FlightLevelEditorProps = {
  label?: string;
  value?: string;
  onChange?: (newValue: string) => void;
  error?: boolean | string;
  helperText?: string | false;
  showHelperTextAsTooltip?: boolean;
};

const FligtLevelEditor = (props: FlightLevelEditorProps) => {
  const { label, value, onChange, error, helperText, showHelperTextAsTooltip } = props;
  return (
    <div>
      <TextFieldEditor
        label={label}
        value={value ? value : ""}
        onChange={onChange}
        hasError={Boolean(error)}
        helperText={helperText}
        showHelperTextAsTooltip={showHelperTextAsTooltip}
      />
    </div>
  );
};

export default FligtLevelEditor;
