import { Validator } from "./Validator";

class ArrDepProcedureValidator extends Validator {
  validateField = (fieldName: string, fieldValue: any, otherFields: any) => {
    this.validationMap.set(fieldName, false);
    switch (fieldName) {
      case "name": {
        if (fieldValue.length === 0 || fieldValue.length > 50) {
          this.validationMap.set(fieldName, true);
          return "Invalid name";
        }
        return "";
      }
      case "runwayDesignator": {
        if (fieldValue.length < 2 || fieldValue.length === 4 || fieldValue.length > 5) {
          this.validationMap.set(fieldName, true);
          return "Invalid runway designator";
        } else if (/^HP*/.test(fieldValue)) {
          console.debug(fieldValue);
          if (!/^HP[0-9]?$/.test(fieldValue)) {
            this.validationMap.set(fieldName, true);
            return "Invalid runway designator";
          }
        } else if ((fieldValue.length === 2 && !/^[0-9]{2}/.test(fieldValue)) || fieldValue.length === 3) {
          this.validationMap.set(fieldName, true);
          return "Invalid runway designator";
        } else if (fieldValue.length === 5 && !/^[0-9]{2}\/[0-9]{2}/.test(fieldValue)) {
          this.validationMap.set(fieldName, true);
          return "Invalid runway designator";
        }

        return "";
      }
      case "curvature": {
        if (isNaN(fieldValue)) {
          this.validationMap.set(fieldName, true);
          return "Invalid curvature";
        }

        return "";
      }

      default:
        return "";
    }
  };
}

export default ArrDepProcedureValidator;
