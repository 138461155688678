import { LatLng } from "leaflet";
import { Polyline } from "react-leaflet";
import { HighwayRamp } from "src/types/Types";

type HighwayRampsProps = {
  highwayRamps: HighwayRamp[];
};

const HighwayRamps = (props: HighwayRampsProps) => {
  const { highwayRamps } = props;
  const defaultColor = "#1565C0";

  return (
    <>
      {highwayRamps.map((hr) => {
        return hr.sections?.map((s) => {
          return (
            <Polyline
              pathOptions={{ color: hr.color ? hr.color : defaultColor, weight: 1, fill: false }}
              positions={s.slice().map((sp) => {
                return new LatLng(sp.wgs84Latitude, sp.wgs84Longitude);
              })}
            />
          );
        });
      })}
    </>
  );
};

export default HighwayRamps;
