import { TextField } from "@mui/material";

type MapCodeEditorProps = {
  value?: string;
  onChange?: (newCode: string) => void;
  error?: boolean;
  helperText?: string | false;
};

const MapCodeFieldEditor = (props: MapCodeEditorProps) => {
  const { value, onChange, error, helperText } = props;
  return (
    <TextField
      value={value || ""}
      onChange={(event) => {
        onChange?.(event.target.value);
      }}
      id="code"
      size="small"
      sx={{ input: { color: error ? "red" : "" } }}
      name="code"
      variant="standard"
      label="Code"
      autoComplete="off"
      error={error}
      helperText={helperText}
    />
  );
};

export default MapCodeFieldEditor;
