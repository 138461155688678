import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useAppContext } from "src/appcontext/AppContext";

import Sidebar from "src/components/sidebar/Sidebar";
import AdmImporter from "src/pages/admimporter/AdmImporter";
import Airports from "src/pages/airports/Airports";
import ClientTypes from "src/pages/clienttypes/ClientTypes";
import Home from "src/pages/home/Home";
import Holdings from "src/pages/maps/holdings/Holdings";
import MapTexts from "src/pages/maps/texts/MapTexts";
import Symbols from "src/pages/symbols/Symbols";
import CatUsers from "src/pages/users/CatUsers";
import Roles from "src/pages/users/Roles";
import UserTags from "src/pages/usertags/UserTags";
import Versions from "src/pages/versions/Versions";
import Waypoints from "src/pages/waypoints/Waypoints";

import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";

import logo from "src/images/CAT.png";
import Airways from "src/pages/airways/Airways";
import GeoImporter from "src/pages/geoimporter/GeoImporter";
import Areas from "src/pages/maps/areas/Areas";
import ComputedAreas from "src/pages/maps/computedareas/ComputedAreas";
import DamParameters from "src/pages/maps/damparameters/DamParameters";
import GraphicAttributes from "src/pages/maps/graphicattributes/GraphicAttributes";
import Lines from "src/pages/maps/lines/Lines";
import Menus from "src/pages/maps/menus/Menus";
import Points from "src/pages/maps/points/Points";
import CountryOutlines from "src/pages/maps/swisstopo/CountryOutlines";
import HighwayRamps from "src/pages/maps/swisstopo/HighwayRamps";
import HighwayTunnels from "src/pages/maps/swisstopo/HighwayTunnels";
import Highways from "src/pages/maps/swisstopo/Highways";
import Lakes from "src/pages/maps/swisstopo/Lakes";
import MajorRoads from "src/pages/maps/swisstopo/MajorRoads";
import Railways from "src/pages/maps/swisstopo/Railways";
import Rivers from "src/pages/maps/swisstopo/Rivers";
import UrbanAreas from "src/pages/maps/swisstopo/UrbanAreas";
import styles from "./Layout.module.css";

const Layout = () => {
  const appContext = useAppContext();
  const currentUser = appContext.state.authenticationToken?.token;
  const versions = appContext.state.versions;
  const currentVersion = appContext.state.currentVersion;

  if (appContext.state.authenticationToken === undefined) {
    return <Redirect to="/login" />;
  }

  const onLogout = () => {
    appContext.dispatch({ type: "logout" });
  };

  return (
    <>
      <div className={styles.sidebar}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "12px"
          }}
        >
          <div className={styles.airLogo}>
            <img style={{ width: "80px" }} src={logo} />
            <div className={styles.cat}>CAT</div>
          </div>
          <div style={{ marginLeft: "16px", fontSize: "0.86em", paddingBottom: "1em" }}>v. %APP_VERSION%</div>
        </div>
        <div>
          <Sidebar />
        </div>
      </div>
      <div className={styles.mainarea}>
        <ToastContainer />
        <div>
          <div
            style={{
              marginTop: "10px",
              height: "50px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: "10px"
            }}
          >
            <div className={styles.airTitle}>Configuration Application Tool</div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "flex-end"
              }}
            >
              <div style={{ marginRight: "50px", marginTop: "5px", width: "200px" }}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="version">Active Version</InputLabel>
                  <Select
                    labelId="version"
                    id="version"
                    label="Active Version"
                    value={currentVersion?.versionTag ? currentVersion.versionTag : "trunk"}
                    onChange={(event: SelectChangeEvent) => {
                      const newValue = event.target.value;
                      const selectedVersion = versions.filter((v) => v.versionTag === newValue);
                      appContext.dispatch({
                        type: "setVersion",
                        payload: selectedVersion.length > 0 ? selectedVersion[0] : undefined
                      });
                    }}
                  >
                    {versions.map((v) => {
                      return <MenuItem value={v.versionTag}>{v.versionTag}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
              <div>
                <PersonIcon />
              </div>
              <div style={{ marginBottom: "5px", marginLeft: "5px" }}>{currentUser}</div>
              <div style={{ marginLeft: "10px", marginRight: "100px" }}>
                <IconButton onClick={onLogout}>
                  <LogoutIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.mainareacontainer}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/versions" component={Versions} />
            <Route path="/airports" component={Airports} />
            <Route path="/airways" component={Airways} />
            <Route path="/user-tags" component={UserTags} />
            <Route path="/client-types" component={ClientTypes} />
            <Route path="/maps/points" component={Points} />
            <Route path="/maps/graphicattributes" component={GraphicAttributes} />
            <Route path="/symbols" component={Symbols} />
            <Route path="/waypoints" component={Waypoints} />
            <Route path="/maps/menus" component={Menus} />
            <Route path="/maps/holdings" component={Holdings} />
            <Route path="/maps/areas" component={Areas} />
            <Route path="/maps/computed-areas" component={ComputedAreas} />
            <Route path="/maps/lines" component={Lines} />
            <Route path="/maps/texts" component={MapTexts} />
            <Route path="/maps/dam-parameters" component={DamParameters} />

            <Route path="/swisstopo/lakes" component={Lakes} />
            <Route path="/swisstopo/rivers" component={Rivers} />
            <Route path="/swisstopo/urban-areas" component={UrbanAreas} />
            <Route path="/swisstopo/country-outlines" component={CountryOutlines} />
            <Route path="/swisstopo/railways" component={Railways} />
            <Route path="/swisstopo/major-roads" component={MajorRoads} />
            <Route path="/swisstopo/highways" component={Highways} />
            <Route path="/swisstopo/highway-ramps" component={HighwayRamps} />
            <Route path="/swisstopo/highway-tunnels" component={HighwayTunnels} />

            <Route path="/users" component={CatUsers} />
            <Route path="/roles" component={Roles} />
            <Route path="/adm-importer" component={AdmImporter} />
            <Route path="/geo-importer" component={GeoImporter} />
          </Switch>
        </div>
      </div>
    </>
  );
};

export default Layout;
