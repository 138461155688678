import { useEffect, useState } from "react";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { NamedPolyLineWithAttributes } from "src/types/Types";
import { getRivers, normalizeRivers } from "./riversApi";

const useRivers = () => {
  const versionTag = useVersionTag();
  const [rivers, setRivers] = useState<NamedPolyLineWithAttributes[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRiver, setSelectedRiver] = useState<NamedPolyLineWithAttributes>();

  useEffect(() => {
    setLoading(true);
    getRivers(versionTag)
      .then((response) => {
        setRivers(normalizeRivers(response));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [versionTag]);

  const selectRiver = (river: NamedPolyLineWithAttributes | undefined) => {
    setSelectedRiver(river);
  };

  return {
    loading,
    rivers: rivers,
    selectedRiver: selectedRiver,
    selectRiver: selectRiver
  };
};

export default useRivers;
