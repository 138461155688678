import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";

import CreateMenuIcon from "@mui/icons-material/PlaylistAdd";
import PathSelector from "src/components/selectors/pathselector/PathSelector";

type CreateMenuDialogProps = {
  paths: string[];
  initialPath: string;
  onCancel: () => void;
  onCreateMenu: (path: string, menuName: string) => void;
};

const CreateMenuDialog = (props: CreateMenuDialogProps) => {
  const { paths, initialPath, onCancel, onCreateMenu } = props;

  const [menuName, setMenuName] = useState<string>("");
  const [currentPath, setCurrentPath] = useState<string | null>(initialPath);

  const [nameError, setNameError] = useState(false);
  const [pathError, setPathError] = useState(false);

  const onMenuChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      setNameError(true);
    } else {
      setNameError(false);
    }
    setMenuName(event.target.value);
  };

  const onChangePath = (newPath: string | null) => {
    if (newPath === "" || newPath === null) {
      setPathError(true);
    } else {
      setPathError(false);
    }
    setCurrentPath(newPath);
  };

  return (
    <Dialog open={true} maxWidth="xl">
      <DialogTitle>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <CreateMenuIcon />
          </div>
          <div>
            <Typography style={{ marginLeft: "10px" }}>Create menu</Typography>
          </div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ display: "flex", width: "400px", height: "160px", flexDirection: "column" }}>
          <div>
            <div>
              <PathSelector paths={paths} value={currentPath} onChange={onChangePath} error={pathError} helperText={pathError ? "Path cannot be empty" : ""} />
            </div>
            <div style={{ marginTop: "20px" }}>
              <TextField
                fullWidth
                label="Menu name"
                variant="standard"
                value={menuName}
                onChange={onMenuChange}
                error={nameError}
                helperText={nameError ? "Name cannot be empty" : ""}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          autoFocus
          variant="contained"
          onClick={() => {
            if (currentPath === null || currentPath === "") {
              setPathError(true);
            } else if (menuName === "") {
              setNameError(true);
            } else {
              onCreateMenu(currentPath, menuName);
            }
          }}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateMenuDialog;
