import { useRef } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Waypoint } from "src/types/Types";
import AddWaypointForm, { AddWaypointFormHandle } from "../forms/AddWaypointForm";

type AddWaypointDialogProps = {
  createWaypoint: (waypoint: Waypoint) => Promise<Waypoint>;
  onCancel: () => void;
  onWaypointCreated: () => void;
};

const AddWaypointDialog = (props: AddWaypointDialogProps) => {
  const { createWaypoint, onCancel, onWaypointCreated } = props;
  const addWaypointFormRef = useRef<AddWaypointFormHandle>(null);

  return (
    <Dialog maxWidth="xl" open={true}>
      <DialogTitle>Create Waypoint</DialogTitle>
      <DialogContent>
        <AddWaypointForm ref={addWaypointFormRef} createWaypoint={createWaypoint} onWaypointCreated={onWaypointCreated} />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="contained"
          onClick={() => {
            addWaypointFormRef.current?.requestSubmit();
          }}
        >
          Add
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddWaypointDialog;
