import { ChangeEvent, useEffect, useState } from "react";
import { TextField, Tooltip } from "@mui/material";
import InputMask from "react-input-mask";
import { useLatitudeMask } from "src/components/editors/hooks/useLatLngConversions";

type LatitudeMaskProps = {
  value?: string;
  onChange?: (latitude: string) => void;
  error?: boolean;
  helperText?: string | false;
  showHelperTextAsTooltip?: boolean;
  showLabel?: boolean;
};

const LatitudeMask = (props: LatitudeMaskProps) => {
  const { onChange, error, helperText, value, showHelperTextAsTooltip, showLabel = true } = props;
  const latitudeMask = useLatitudeMask();

  return (
    <Tooltip
      open={error === true && showHelperTextAsTooltip}
      title={helperText ? helperText : "Error"}
      componentsProps={{
        tooltip: {
          sx: {
            color: "white",
            backgroundColor: "red"
          }
        }
      }}
    >
      <div>
        <InputMask
          alwaysShowMask
          mask={latitudeMask}
          value={value}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            onChange?.(event.target.value.toUpperCase());
          }}
        >
          <TextField
            id="latitude"
            size="small"
            variant="standard"
            name="latitude"
            label={showLabel === true ? "Latitude" : undefined}
            autoComplete="off"
            sx={{ input: { color: error ? "red" : "" } }}
            error={error ? error : false}
            helperText={showHelperTextAsTooltip === true ? undefined : helperText}
          />
        </InputMask>
      </div>
    </Tooltip>
  );
};

export default LatitudeMask;
