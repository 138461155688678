import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Wgs84LatLng, getWgs84LatLng } from "src/apis/waypoints/waypointsApi";

const digit = /[0-9]/;
const eastOrWest = /[E|e|W|w]/;
const northOrSouth = /[N|n|S|s]/;

export const useLatitudeMask = () => {
  return [digit, digit, digit, "°", digit, digit, "´", digit, digit, ".", digit, digit, digit, '"', northOrSouth];
};

export const useLongitudeMask = () => {
  return [digit, digit, digit, "°", digit, digit, "´", digit, digit, ".", digit, digit, digit, '"', eastOrWest];
};

export const useDmsToWgs64 = (dmsLatitude: string, dmsLongitude: string) => {
  const [error, setError] = useState(false);
  const [latLng, setLatLng] = useState<Wgs84LatLng>();

  useEffect(() => {
    if (!isEmpty(dmsLatitude) && !dmsLatitude.includes("_") && !isEmpty(dmsLongitude) && !dmsLongitude.includes("_")) {
      getWgs84LatLng(dmsLatitude, dmsLongitude)
        .then((latLng) => {
          setLatLng(latLng);
        })
        .catch((error) => {
          setError(true);
        });
    }
  }, [dmsLatitude, dmsLongitude]);
  return { error, latLng };
};
