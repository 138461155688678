import { useState } from "react";

import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  GridEventListener,
  GridRenderCellParams,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridSelectionModel,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridValueFormatterParams,
  MuiEvent
} from "@mui/x-data-grid-pro";

import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import HighlightIcon from "@mui/icons-material/Adjust";

import { toast } from "react-toastify";
import GraphicAttributeSelector from "src/components/selectors/graphicalattributeselector/GraphicAttributeSelector";

import useGraphicAttributes from "src/apis/graphicattributes/useGraphicAttributes";
import DeleteRowsDialog from "src/components/datagrids/DeleteRowsDialog";
import DatagridAreaStretchSelector from "src/components/selectors/airwaystretchselector/DatagridAirwayStretchSelector";
import { GraphicAttribute, GraphicAttributeType, MapElementReference } from "src/types/Types";

type MapReferencesGridProps = {
  loading: boolean;
  mapElementReferences: MapElementReference[];
  showAttributes: boolean;
  checkBoxSelection?: boolean;
  selectionModel?: GridSelectionModel;
  showToolbar?: boolean;
  showNameFilter?: boolean;
  isEditable?: boolean;
  showGraphicAttributeSelector?: boolean;
  graphicAttributeType?: GraphicAttributeType;
  auxiliaryGraphicAttributeType?: GraphicAttributeType;
  onGraphicAttributeChange?: (newGraphicAttribute: GraphicAttribute) => void;
  onAuxiliaryGraphicAttributeChange?: (newGraphicAttribute: GraphicAttribute) => void;
  onSelectionModelChange?: (newSelectionModel: GridSelectionModel) => void;
  onRowDoubleClick?: (params: GridRowParams<MapElementReference>) => void;
  onDelete?: (rowId: GridRowId) => Promise<void>;
  onAirwayStretchChange?: (rowId: string | number, airwayStretch: string[]) => void;
  onRowEditStart?: () => void;
  onRowEditStop?: () => void;
  onHighlightedMapElementReference?: (elementId: string | number) => void;
};

const MapReferencesGrid = (props: MapReferencesGridProps) => {
  const {
    mapElementReferences,
    loading,
    showAttributes,
    selectionModel,
    checkBoxSelection = false,
    showToolbar = false,
    showNameFilter = false,
    showGraphicAttributeSelector = false,
    graphicAttributeType = "NONE",
    auxiliaryGraphicAttributeType = "NONE",
    isEditable = false,
    onGraphicAttributeChange,
    onAuxiliaryGraphicAttributeChange,
    onSelectionModelChange,
    onRowDoubleClick,
    onDelete,
    onAirwayStretchChange,
    onRowEditStart,
    onRowEditStop,
    onHighlightedMapElementReference
  } = props;

  const { graphicAttributes } = useGraphicAttributes();

  const columns: GridColumns<MapElementReference> = showAttributes
    ? [
        { field: "name", headerName: "Element", width: 200, editable: false },
        { field: "referenceType", headerName: "Type", width: 100, editable: false },
        { field: "description", headerName: "Description", width: 150, editable: false },
        { field: "attributes", headerName: "Attributes", width: 150, editable: false },
        { field: "auxiliaryAttributes", headerName: "Aux. attributes", width: 150, editable: false },
        { field: "merged", headerName: "Merged", width: 100, editable: false, hide: true },
        {
          field: "airwayStretch",
          headerName: "Stretch",
          editable: isEditable,
          width: 120,

          valueFormatter: (params: GridValueFormatterParams<string[]>) => {
            if (params.value.length === 0) {
              return "";
            }
            return `[${params.value[0]}...${params.value[params.value.length - 1]}]`;
          },
          renderEditCell: (params: GridRenderCellParams) => {
            return <DatagridAreaStretchSelector {...params} />;
          }
        },
        {
          field: "actions",
          type: "actions",
          headerName: "Actions",
          width: 100,
          cellClassName: "actions",
          getActions: ({ id }: { id: number | string }) => {
            const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
            if (isInEditMode) {
              return [
                <GridActionsCellItem icon={<SaveIcon />} label="Save" onResizeCapture={() => {}} onResize={() => {}} onClick={handleSaveClick(id)} />,
                <GridActionsCellItem
                  icon={<CancelIcon />}
                  label="Cancel"
                  className="textPrimary"
                  onResizeCapture={() => {}}
                  onResize={() => {}}
                  onClick={handleCancelClick(id)}
                  color="inherit"
                />
              ];
            }
            if (isEditable) {
              return [
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onResizeCapture={() => {}}
                  onResize={() => {}}
                  onClick={handleEditClick(id)}
                  color="inherit"
                />,
                <GridActionsCellItem icon={<HighlightIcon />} label={"Select"} onResizeCapture={() => {}} onResize={() => {}} onClick={handleHighlightClick(id)} color="inherit" />,
                <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onResizeCapture={() => {}} onResize={() => {}} onClick={handleDeleteClick(id)} color="inherit" />
              ];
            } else {
              return [<GridActionsCellItem icon={<DeleteIcon />} label="Delete" onResizeCapture={() => {}} onResize={() => {}} onClick={handleDeleteClick(id)} color="inherit" />];
            }
          }
        }
      ]
    : [
        { field: "name", headerName: "Element", width: 200, editable: false },
        { field: "referenceType", headerName: "Type", width: 100, editable: false },
        { field: "description", headerName: "Description", width: 300, editable: false },
        { field: "merged", headerName: "Merged", width: 100, editable: false, hide: true }
      ];

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rowToDelete, setRowToDelete] = useState<MapElementReference>();

  /* prevent double click to go in edit mode */
  const preventRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  /* prevent ESC or ENTER key */
  const preventRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    onRowEditStart?.();
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRowToDelete(mapElementReferences.filter((ref) => ref.id === id)[0]);
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    onRowEditStop?.();
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });
  };

  const handleHighlightClick = (id: GridRowId) => () => {
    onHighlightedMapElementReference?.(id);
  };

  const onConfirmDelete = () => {
    onDelete?.(rowToDelete!.id)
      .then(() => {
        setRowToDelete(undefined);
        toast.success("Row deleted");
      })
      .catch((error) => {
        if (error.errorMessage) {
          toast.error("Failed to delete row: " + error.errorMessage);
        } else if (typeof error === "string") {
          toast.error("Failed to delete row: " + error);
        } else {
          toast.error("Failed to delete row");
        }
      });
  };

  const onCancelDelete = () => {
    setRowToDelete(undefined);
  };

  const _onGraphicAttributeChange = (newGraphicAttribute: GraphicAttribute) => {
    onGraphicAttributeChange?.(newGraphicAttribute);
  };

  const _onAuxiliaryGraphicAttributeChange = (newGraphicAttribute: GraphicAttribute) => {
    onAuxiliaryGraphicAttributeChange?.(newGraphicAttribute);
  };

  const Toolbar = () => {
    const sortedAttributes = graphicAttributes.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
    return showToolbar ? (
      <GridToolbarContainer>
        {showNameFilter && (
          <div style={{ marginLeft: 15, marginTop: 16 }}>
            <GridToolbarQuickFilter />
          </div>
        )}
        {showGraphicAttributeSelector && (
          <>
            <div style={{ width: 200, marginLeft: 10 }}>
              <GraphicAttributeSelector
                label="Graphic Attribute"
                disabled={graphicAttributeType === "NONE"}
                attributeType={graphicAttributeType}
                onChange={_onGraphicAttributeChange}
                graphicAttributes={sortedAttributes}
              />
            </div>
            <div style={{ width: 200, marginLeft: 10 }}>
              <GraphicAttributeSelector
                label="Auxiliary Graphic Attribute"
                disabled={auxiliaryGraphicAttributeType !== "LINE"}
                attributeType={auxiliaryGraphicAttributeType}
                onChange={_onAuxiliaryGraphicAttributeChange}
                graphicAttributes={sortedAttributes}
              />
            </div>
          </>
        )}
      </GridToolbarContainer>
    ) : (
      <div></div>
    );
  };

  return (
    <div style={{ height: "100%" }}>
      {rowToDelete && <DeleteRowsDialog onConfirmDelete={onConfirmDelete} onCancelDelete={onCancelDelete} rows={[rowToDelete]} />}
      <DataGridPro
        editMode="row"
        experimentalFeatures={{ newEditingApi: true }}
        density="compact"
        disableColumnFilter
        checkboxSelection={checkBoxSelection}
        columns={columns}
        rows={mapElementReferences}
        loading={loading}
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        selectionModel={selectionModel}
        onSelectionModelChange={(newSelectionModel) => {
          onSelectionModelChange?.(newSelectionModel);
        }}
        onRowDoubleClick={onRowDoubleClick}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }]
          }
        }}
        components={{
          Toolbar
        }}
        processRowUpdate={(updatedRow) => {
          if (updatedRow.airwayStretch.length > 0 && updatedRow.airwayStretch.length < 2) {
            return Promise.reject(updatedRow);
          } else {
            onAirwayStretchChange?.(updatedRow.id, updatedRow.airwayStretch);
            onRowEditStop?.();
            return Promise.resolve(updatedRow);
          }
        }}
        onProcessRowUpdateError={(row) => {
          toast.error("Invalid stretch: " + JSON.stringify(row.airwayStretch) + ". The stretch must be defined by 2 points");
          setRowModesModel({ ...rowModesModel, [row.id]: { mode: GridRowModes.Edit } });
          onRowEditStart?.();
        }}
        onRowEditStart={preventRowEditStart}
        onRowEditStop={preventRowEditStop}
      />
    </div>
  );
};

export default MapReferencesGrid;
