import { useVersionTag } from "src/apis/hooks/useVersionTag";
import useRailways from "src/apis/swisstopo/useRailways";
import MapViewer from "src/components/mapviewer/MapViewer";

const Railways = () => {
  const versionTag = useVersionTag();
  const { railways } = useRailways();

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Railways - {versionTag}</h2>
      <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
        <div style={{ height: "100%", width: "75%" }}>
          <div style={{ height: "90%", minWidth: "60%", marginLeft: "5px", marginRight: "5px" }}>
            <MapViewer geoPolylines={railways} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Railways;
