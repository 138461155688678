import { useFormik } from "formik";
import { toast } from "react-toastify";
import { getWgs84LatLng } from "src/apis/waypoints/waypointsApi";
import { Distribution } from "src/components/selectors/distributionlistselector/DistributionListSelector";
import { DamParameter } from "src/types/Types";
import { higherFlightLevelSchemaValidation, lowerFlightLevelSchemaValidation } from "src/validators/yup/flightLevelValidation";
import { latitudeSchemaValidation, longitudeSchemaValidation } from "src/validators/yup/latlngValidation";
import * as yup from "yup";

type DamParameterValueTypes = {
  description: string;
  latitude: string;
  longitude: string;
  lowerFlightLevel: string;
  higherFlightLevel: string;
  startTime: string | null;
  endTime: string | null;
  trainingArea: string | null;
  areaType: string | null;
  displayLevel: boolean | null;
  lowerLevelInFeet: boolean | null;
  upperLevelInFeet: boolean | null;
  verticalBuffer: string | null;
  damStatic: boolean | null;
  altitudeCorrections: [];
  distributionList: Distribution[];
  userTags: string[];
};

const useAddDamParameterFormHandler = (createDamParameter: (damParameter: DamParameter) => Promise<DamParameter>, onDamParameterCreated: () => void) => {
  const validationSchema = yup.object({
    description: yup.string().required("Description cannot be empty"),
    latitude: latitudeSchemaValidation.required("Latitude cannot be empty"),
    longitude: longitudeSchemaValidation.required("Longitude cannot be empty"),
    lowerFlightLevel: lowerFlightLevelSchemaValidation,
    higherFlightLevel: higherFlightLevelSchemaValidation
  });

  const mapFormValues = async (values: DamParameterValueTypes): Promise<DamParameter> => {
    const latLng = await getWgs84LatLng(values.latitude, values.longitude);
    return Promise.resolve({
      id: -1,
      description: values.description,
      wgs84Latitude: latLng.wgs84Latitude,
      wgs84Longitude: latLng.wgs84Longitude,
      lowerFlightLevel: values.lowerFlightLevel,
      lowerLevelInFeet: values.lowerLevelInFeet,
      higherFlightLevel: values.higherFlightLevel,
      upperLevelInFeet: values.upperLevelInFeet,
      altitudeCorrections: values.altitudeCorrections,
      areaType: values.areaType,
      damStatic: values.damStatic,
      dmsLatitude: values.latitude,
      dmsLongitude: values.longitude,
      startTime: values.startTime,
      endTime: values.endTime,
      trainingArea: values.trainingArea,
      displayLevel: values.displayLevel,
      verticalBuffer: values.verticalBuffer ? +values.verticalBuffer : null,
      userTags: values.userTags,
      distributionList: values.distributionList.map((dl) => dl.code),
      additionalInformation: []
    });
  };

  const formik = useFormik<DamParameterValueTypes>({
    validationSchema: validationSchema,
    initialValues: {
      description: "",
      latitude: "",
      longitude: "",
      lowerFlightLevel: "",
      higherFlightLevel: "",
      startTime: null,
      endTime: null,
      trainingArea: null,
      areaType: null,
      displayLevel: null,
      lowerLevelInFeet: null,
      upperLevelInFeet: null,
      verticalBuffer: null,
      damStatic: null,
      altitudeCorrections: [],
      distributionList: [],
      userTags: []
    },
    onSubmit: async (values) => {
      const damParameter = await mapFormValues(values);
      createDamParameter(damParameter)
        .then((result) => {
          onDamParameterCreated();
        })
        .catch((error) => {
          toast.error("failed to create dam parameter");
        });
    }
  });

  return { formHandler: formik };
};

export default useAddDamParameterFormHandler;
