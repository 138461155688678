import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";

import LongitudeMask from "./LongitudeMask";

const DataGridLongitudeMask = (params: GridRenderEditCellParams) => {
  const { id, value, field, error } = params;

  const apiRef = useGridApiContext();

  const handleChangeValue = (newValue: string) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return <LongitudeMask value={value} onChange={handleChangeValue} error={Boolean(error)} helperText={error} showHelperTextAsTooltip showLabel={false} />;
};

export default DataGridLongitudeMask;
