import { useFormik } from "formik";
import { create } from "lodash";
import { toast } from "react-toastify";
import { CatUser } from "src/types/Types";
import * as yup from "yup";

export type FormValueTypes = {
  username: string;
  firstname: string;
  lastname: string;
  password: string;
  confirmpassword: string;
};

const useAddCatUserFormHandler = (createCatUser: (catUser: CatUser) => Promise<CatUser>, onCatUserCreated: () => void) => {
  const validationSchema = yup.object({
    username: yup.string().required("Username cannot be empty"),
    firstname: yup.string(),
    lastname: yup.string(),
    password: yup.string().required("Password cannot be empty"),
    confirmpassword: yup
      .string()
      .required("Confirm password cannot be empty")
      .oneOf([yup.ref("password")], "Passwords must match")
  });

  const initialValues: FormValueTypes = {
    username: "",
    firstname: "",
    lastname: "",
    password: "",
    confirmpassword: ""
  };

  const mapFormValues = (values: FormValueTypes): CatUser => {
    return {
      id: -1,
      username: values.username,
      firstname: values.firstname,
      lastname: values.lastname,
      password: values.password,
      roleName: "Admin"
    };
  };

  const formik = useFormik<FormValueTypes>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => {
      createCatUser(mapFormValues(values))
        .then(() => {
          onCatUserCreated();
        })
        .catch((error) => {
          if (typeof error === "object") {
            toast.error(error.errorMessage);
          } else {
            toast.error("Failed to create airport");
          }
        });
    }
  });
  return { formHandler: formik };
};

export default useAddCatUserFormHandler;
