import { GridColumns, GridPreProcessEditCellProps, GridRenderCellParams } from "@mui/x-data-grid-pro";

import FrequencyEditor from "src/components/editors/DataGridFrequencyEditor";
import FrequencyNameEditor from "src/components/editors/FrequencyNameEditor";
import { ColumnsGenerator } from "src/types/Types";

const useFrequencyColumns = () => {
  const getColumns: ColumnsGenerator = (validator, onFieldDirty) => {
    const columns: GridColumns = [
      { field: "sequence", headerName: "Sequence", editable: false },
      {
        field: "name",
        headerName: "Name",
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("name", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <FrequencyNameEditor {...params} />;
        }
      },
      {
        field: "frequency",
        headerName: "Frequency",
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("frequency", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <FrequencyEditor {...params} />;
        }
      },
      {
        field: "remarks",
        headerName: "Remarks",
        editable: true,
        width: 300,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        }
      },
      {
        field: "onSameLine",
        headerName: "Same line",
        editable: true,
        type: "boolean",
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        }
      }
    ];
    return columns;
  };
  return { getColumns };
};

export default useFrequencyColumns;
