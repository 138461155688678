import React, { ChangeEvent, useRef } from "react";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";

import ElevationFieldEditor from "src/components/editors/ElevationFieldEditor";
import LatitudeMask from "src/components/editors/LatitudeMask";
import LongitudeMask from "src/components/editors/LongitudeMask";
import OrientationMask from "src/components/editors/OrientationMask";
import UserTagSelector from "src/components/selectors/usertagselector/UserTagSelector";
import WaypointSelector from "src/components/selectors/waypointselector/WaypointSelector";
import useAddAirportFormHandler from "src/pages/airports/forms/useAddAirportFormHandler";
import { Airport } from "src/types/Types";
import AirportTypeSelector from "src/components/selectors/airporttypeselector/AirportTypeSelector";

export type AddAirportFormHandle = {
  requestSubmit: () => void;
};

type AddAirportFormProps = {
  createAirport: (airport: Airport) => Promise<Airport>;
  onAirportCreated: () => void;
};

const AddAirportForm: React.ForwardRefRenderFunction<AddAirportFormHandle, AddAirportFormProps> = (props, forwardedRef) => {
  const { createAirport, onAirportCreated } = props;
  const formRef = useRef<HTMLFormElement>(null);
  const { formHandler } = useAddAirportFormHandler(createAirport, onAirportCreated);

  React.useImperativeHandle(forwardedRef, () => ({
    requestSubmit() {
      formRef.current?.requestSubmit();
    }
  }));

  const onLatitudeChanged = (latitude: string) => {
    formHandler.setFieldValue("latitude", latitude);
  };

  const onLongitudeChanged = (longitude: string) => {
    formHandler.setFieldValue("longitude", longitude);
  };

  const onOrientationChanged = (orientation: string) => {
    formHandler.setFieldValue("orientation", orientation);
  };

  const onElevationChanged = (elevation: string | null) => {
    formHandler.setFieldValue("elevation", elevation);
  };

  const onUserTagSelectionChanged = (userTags: string[]) => {
    formHandler.setFieldValue("userTags", userTags);
  };

  const onWaypointSelectionChanged = (waypoints: string[]) => {
    formHandler.setFieldValue("points", waypoints);
  };

  return (
    <form ref={formRef} onSubmit={formHandler.handleSubmit}>
      <div style={{ display: "flex", flexDirection: "column", height: "650px", width: "600px" }}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <h3>General information</h3>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ width: "100px" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="standard"
                  id="name"
                  name="name"
                  value={formHandler.values.name}
                  label="Name"
                  autoComplete="off"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    formHandler.setFieldValue("name", event.target.value.toUpperCase());
                  }}
                  error={formHandler.touched.name && Boolean(formHandler.errors.name)}
                  helperText={formHandler.touched.name && formHandler.errors.name}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="standard"
                  id="fullName"
                  name="fullName"
                  value={formHandler.values.fullName}
                  label="Full name"
                  autoComplete="off"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    formHandler.setFieldValue("fullName", event.target.value);
                  }}
                />
              </div>
            </div>
            <div style={{ flex: "2", marginTop: "10px" }}>
              <TextField
                fullWidth
                id="description"
                size="small"
                variant="standard"
                name="description"
                value={formHandler.values.description}
                label="Description"
                autoComplete="off"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  formHandler.setFieldValue("description", event.target.value);
                }}
                error={formHandler.touched.name && Boolean(formHandler.errors.description)}
                helperText={formHandler.touched.name && formHandler.errors.description}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
              <div style={{ flex: "1" }}>
                <AirportTypeSelector
                  onChange={(newValue) => {
                    formHandler.setFieldValue("type", newValue);
                  }}
                />
              </div>
              <div style={{ flex: "1", marginLeft: "10px", marginTop: "10px" }}>
                <FormControlLabel
                  onChange={(event, checked) => {
                    formHandler.setFieldValue("manuallyUpdated", checked);
                  }}
                  control={<Checkbox />}
                  label="Manually updated"
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <h3>Location</h3>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ width: 135 }}>
                <LatitudeMask
                  onChange={onLatitudeChanged}
                  value={formHandler.values.latitude}
                  error={formHandler.touched.latitude && Boolean(formHandler.errors.latitude)}
                  helperText={formHandler.touched.latitude && formHandler.errors.latitude}
                />
              </div>
              <div style={{ marginLeft: "10px", width: 135 }}>
                <LongitudeMask
                  onChange={onLongitudeChanged}
                  value={formHandler.values.longitude}
                  error={formHandler.touched.longitude && Boolean(formHandler.errors.longitude)}
                  helperText={formHandler.touched.longitude && formHandler.errors.longitude}
                />
              </div>
              <div style={{ marginLeft: "25px", width: "70px" }}>
                <OrientationMask
                  label="Orientation"
                  onOrientationChanged={onOrientationChanged}
                  error={formHandler.touched.orientation && Boolean(formHandler.errors.orientation)}
                  helperText={formHandler.touched.orientation && formHandler.errors.orientation}
                />
              </div>
              <div style={{ marginLeft: "10px", width: "80px" }}>
                <ElevationFieldEditor
                  onElevationChanged={onElevationChanged}
                  error={formHandler.touched.elevation && Boolean(formHandler.errors.elevation)}
                  helperText={formHandler.touched.elevation && formHandler.errors.elevation}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <h3>Airport points</h3>
            <div>
              <WaypointSelector width="100%" onSelectionChanged={onWaypointSelectionChanged} />
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <h3>User tags</h3>
            <div>
              <UserTagSelector currentSelection={formHandler.values.userTags} width="100%" onChange={onUserTagSelectionChanged} />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default React.forwardRef(AddAirportForm);
