import { useEffect, useState } from "react";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { getMajorRoads } from "src/apis/swisstopo/majorRoadsApi";
import { PolyLineWithAttributes } from "src/types/Types";

const useMajorRoads = () => {
  const versionTag = useVersionTag();
  const [majorRoads, setMajorRoads] = useState<PolyLineWithAttributes[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getMajorRoads(versionTag)
      .then((response) => {
        setMajorRoads(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [versionTag]);

  return {
    loading,
    majorRoads: majorRoads
  };
};

export default useMajorRoads;
