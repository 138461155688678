import L from "leaflet";
import { useEffect } from "react";
import { useMap } from "react-leaflet";
import "./ShowTooltipControl.css";

type ShowTooltipControlProps = {
  checked?: boolean;
  onChange?: () => void;
};

const ShowTooltipControl = (props: ShowTooltipControlProps) => {
  const { onChange } = props;
  const map = useMap();

  useEffect(() => {
    const customControlDiv = L.DomUtil.create("div", "custom-control");

    // Create the checkbox element
    const checkbox = L.DomUtil.create("input", "", customControlDiv);
    checkbox.type = "checkbox";
    checkbox.id = "showTooltips";
    checkbox.checked = false; // initial state
    checkbox.onchange = () => {
      //const target = e.target as HTMLInputElement;
      //const isChecked = target.checked;
      onChange?.();
    };

    // Create label for the checkbox
    const label = L.DomUtil.create("label", "show-tooltip-label", customControlDiv);
    label.htmlFor = "showTooltips";
    label.innerHTML = "Show Tooltips";

    // Create a new Leaflet control
    const customControl = L.Control.extend({
      onAdd: function () {
        return customControlDiv;
      }
    });

    // Add the custom control to the map
    const controlInstance = new customControl({ position: "bottomleft" });
    map.addControl(controlInstance);

    // Cleanup control on unmount
    return () => {
      map.removeControl(controlInstance);
    };
  }, [map]);
  return null;
};

export default ShowTooltipControl;
