import { useVersionTag } from "src/apis/hooks/useVersionTag";
import useHighwayRamps from "src/apis/swisstopo/useHighwayRamps";
import MapViewer from "src/components/mapviewer/MapViewer";

const HighwayRamps = () => {
  const versionTag = useVersionTag();
  const { highwayRamps } = useHighwayRamps();

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Highway ramps - {versionTag}</h2>
      <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
        <div style={{ height: "100%", width: "75%" }}>
          <div style={{ height: "90%", minWidth: "60%", marginLeft: "5px", marginRight: "5px" }}>
            <MapViewer geoPolylines={highwayRamps} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HighwayRamps;
