import { isNull } from "lodash";
import { Fragment } from "react/jsx-runtime";

const NAUTICAL_TO_KM = 1.852;

interface CurvatureTooltipProperties {
  curvature: string;
  distanceToNextPointNm: string;
}
export const CurvatureTooltip = (props: CurvatureTooltipProperties) => {
  const { curvature, distanceToNextPointNm: distanceToNext } = props;
  const _curvature = !isNull(curvature) ? Number.parseFloat(curvature) : 0;
  const _distanceNm = !isNull(distanceToNext) ? Number.parseFloat(distanceToNext) : 0;

  const radius = _curvature * _distanceNm;
  return (
    <Fragment>
      {(radius * NAUTICAL_TO_KM).toFixed(2)} km <br /> {radius.toFixed(2)} nm
    </Fragment>
  );
};

export const CurvatureEditionInfoTooltip = () => {
  return (
    <Fragment>
      Enter one of the following:
      <ul>
        <li>0 for no curvature (straight segment)</li>
        <li>a relative signed curvature (no unit, absolute value must be &gt;= 0.5)</li>
        <li>
          a signed radius of curvature postfixed with the desired unit (<b>nm</b> or <b>km</b>)
        </li>
        <li>
          the center of the arc (prefixed with <b>C</b>, followed by the WGS64 latitude and longitude of the center (DDMMSS&#123;.sss&#125;, separated with one space)
        </li>
        <li>
          a point of the arc (prefixed with <b>P</b>, followed by the WGS64 latitude and longitude of the point (DDMMSS&#123;.sss&#125;, separated with one space)
        </li>
      </ul>
      <i>Note:</i> for the last 2 cases, it may be necessary to invert the arc after editing.
    </Fragment>
  );
};
