import Split from "react-split";
import { ComputedArea } from "src/types/Types";

import useComputedAreas from "src/apis/computedareas/useComputedAreas";
import useComputedAreaColumns from "src/columnsgenerators/useComputedAreaColumns";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import ComputedAreaValidator from "src/validators/ComputedAreaValidator";

import GenericDataGrid from "src/components/datagrids/GenericDataGrid";
import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import MapViewer from "src/components/mapviewer/MapViewer";
import XRefGrid from "src/components/datagrids/XRefGrid";

export type ComputedAreasResponse = {
  data: ComputedArea[];
  errorMessage: string;
};

const ComputedAreas = () => {
  const versionTag = useVersionTag();
  const { loading, computedAreas, selectedComputedArea, computedAreaPoints, selectComputedArea, addComputedArea, updateComputedArea, deleteComputedArea } = useComputedAreas();
  const { getColumns } = useComputedAreaColumns();

  const [currentTab, setCurrentTab] = useState("preview");

  const onComputedAreaSelected = (computedArea: ComputedArea) => {
    selectComputedArea(computedArea);
  };

  const createEmptyRow = () => {
    return {
      id: -1,
      name: "",
      description: "",
      mergedAreas: [],
      closures: [],
      smoothing: 0,
      userTags: [],
      xrefs: []
    };
  };

  const onCreateComputedArea = (computedArea: ComputedArea) => {
    return addComputedArea(computedArea);
  };

  const onUpdateComputedArea = (computedArea: ComputedArea) => {
    return updateComputedArea(computedArea);
  };

  const onDeleteComputedArea = (computedArea: ComputedArea) => {
    return deleteComputedArea(computedArea);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Computed areas - {versionTag}</h2>
      <Split
        style={{ height: "calc(100vh - 230px)" }}
        direction="vertical"
        onDragEnd={() => {
          /*if (radarMapRef !== null) {
            radarMapRef.current?.invalidateSize();
          }*/
        }}
      >
        <div style={{ height: "90%" }}>
          <GenericDataGrid<ComputedArea>
            loading={loading}
            sortModel={[{ field: "name", sort: "asc" }]}
            columnsGenerator={getColumns}
            createEmptyRow={createEmptyRow}
            data={computedAreas}
            selectedRow={selectedComputedArea}
            validator={new ComputedAreaValidator()}
            onSelectRow={onComputedAreaSelected}
            onCreateRow={onCreateComputedArea}
            onUpdateRow={onUpdateComputedArea}
            onDeleteRow={onDeleteComputedArea}
          />
        </div>
        <div style={{ height: "100%" }}>
          <TabContext value={currentTab}>
            <Tabs value={currentTab} onChange={handleChange}>
              <Tab value="preview" label="Preview" />
              <Tab value="xref" label="Xref" />
            </Tabs>
            <TabPanel value="preview" style={{ height: "95%" }}>
              <div style={{ height: "100%", display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <MapViewer
                    areaPoints={computedAreaPoints.map((lp) => {
                      return { ...lp, buffer: 0 };
                    })}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel value="xref" style={{ height: "100%" }}>
              <div style={{ height: "100%" }}>
                <XRefGrid xrefs={selectedComputedArea && selectedComputedArea.xrefs ? selectedComputedArea.xrefs : []} />
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </Split>
    </div>
  );
};

export default ComputedAreas;
