import { Autocomplete, CircularProgress, createFilterOptions, LinearProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getAirports, getAirportsAndSidsOnly } from "src/apis/airports/airportsApi";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { getWaypoints } from "src/apis/waypoints/waypointsApi";
import { Airport, Waypoint } from "src/types/Types";

export type MapElementReference = {
  name: string;
  referenceType: "AIRPORT" | "WAYPOINT" | "SID" | null;
  dmsLatitude: string | null;
  dmsLongitude: string | null;
};

type ReferenceTypeSelectorProps = {
  showLabels?: boolean;
  referenceTypeValue?: string;
  referenceValue?: string;
  onChange?: (newValue: MapElementReference | undefined) => void;
  error?: boolean;
};

const ReferenceTypeSelector = (props: ReferenceTypeSelectorProps) => {
  const { onChange, showLabels, referenceTypeValue, referenceValue, error } = props;
  const referenceTypes = ["AIRPORT", "WAYPOINT", "SID"];
  const versionTag = useVersionTag();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [airports, setAirports] = useState<Airport[]>([]);
  const [waypoints, setWaypoints] = useState<Waypoint[]>([]);

  const [referenceType, setReferenceType] = useState<string | null>(referenceTypeValue ? referenceTypeValue : null);
  const [options, setOptions] = useState<string[]>([]);
  const [optionValue, setOptionValue] = useState<string | null>(referenceValue ? referenceValue : null);

  useEffect(() => {
    if (open) {
      switch (referenceType) {
        case "AIRPORT":
          setLoading(true);
          getAirportsAndSidsOnly(versionTag)
            .then((results) => {
              setAirports(results);
              setOptions(results.map((a) => a.name).sort((a, b) => (a > b ? 1 : a < b ? -1 : 0)));
            })
            .finally(() => {
              setLoading(false);
            });
          break;
        case "WAYPOINT":
          setLoading(true);
          getWaypoints(versionTag)
            .then((results) => {
              setWaypoints(results);
              setOptions(results.map((w) => (w.area ? `${w.name}/${w.area}` : w.name)).sort((a, b) => (a > b ? 1 : a < b ? -1 : 0)));
            })
            .finally(() => {
              setLoading(false);
            });
          break;
        case "SID":
          setLoading(true);
          getAirportsAndSidsOnly(versionTag)
            .then((results) => {
              setAirports(results);
              setOptions(results.flatMap((a) => a.sids.map((s) => a.name + "/" + s.name)).sort((a, b) => (a > b ? 1 : a < b ? -1 : 0)));
            })
            .finally(() => {
              setLoading(false);
            });
          break;
        default:
          setOptions([]);
      }
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const _onChangeReferenceType = (event: any, value: string | null) => {
    setReferenceType(value);
    setOptionValue(null);
    onChange?.(undefined);
  };

  const findWaypoint = (option: string) => {
    const nameAreaPair = option.split("/");
    if (nameAreaPair.length == 2) {
      return waypoints.filter((wp) => wp.name === nameAreaPair[0] && wp.area === nameAreaPair[1])[0];
    } else {
      return waypoints.filter((a) => a.name === option)[0];
    }
  };

  const _onChangeOption = (event: any, option: string | null) => {
    if (option) {
      switch (referenceType) {
        case "AIRPORT":
          const airport = airports.filter((a) => a.name === option)[0];
          if (airport) {
            onChange?.({ referenceType: "AIRPORT", name: airport.name, dmsLatitude: airport.dmsLatitude, dmsLongitude: airport.dmsLongitude });
          }
          break;
        case "WAYPOINT":
          const waypoint = findWaypoint(option);
          if (waypoint) {
            onChange?.({
              referenceType: "WAYPOINT",
              name: waypoint.area ? `${waypoint.name}/${waypoint.area}` : waypoint.name,
              dmsLatitude: waypoint.dmsLatitude,
              dmsLongitude: waypoint.dmsLongitude
            });
          }
          break;
        case "SID":
          const sidAirport = airports.filter((a) => a.name === option.substring(0, 4))[0];
          if (sidAirport) {
            onChange?.({ referenceType: "SID", name: option, dmsLatitude: sidAirport.dmsLatitude, dmsLongitude: sidAirport.dmsLongitude });
          }
          break;
        default:
          break;
      }
    } else {
      onChange?.(undefined);
    }
    setOptionValue(option);
  };

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option: string) => option
  });

  return (
    <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center" }}>
      <div style={{ flex: 1 }}>
        <Autocomplete
          onChange={_onChangeReferenceType}
          value={referenceType}
          options={referenceTypes}
          size="small"
          fullWidth
          renderInput={(params) => <TextField variant="standard" {...params} label={showLabels === true ? "Reference type" : undefined} />}
        />
      </div>
      <div style={{ flex: 1.3, marginLeft: "10px" }}>
        <Autocomplete
          filterOptions={filterOptions}
          value={optionValue}
          onChange={_onChangeOption}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          size="small"
          fullWidth
          options={options}
          renderInput={(params) => (
            <TextField
              error={error ? error : false}
              variant="standard"
              {...params}
              label={showLabels === true ? "Reference" : undefined}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

export default ReferenceTypeSelector;
