import { Autocomplete, TextField } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import { useLatitudeMask, useLongitudeMask } from "src/components/editors/hooks/useLatLngConversions";
import { Point } from "src/types/Types";
import ReferenceTypeSelector, { MapElementReference } from "./ReferenceTypeSelector";

const DataGridReferenceSelector = (props: GridRenderEditCellParams<Point>) => {
  const { id, field, row, error } = props;
  const apiRef = useGridApiContext();

  const _onChange = async (newValue: MapElementReference | undefined) => {
    if (newValue) {
      await apiRef.current.setEditCellValue({ id, field: field, value: newValue.name });
      await apiRef.current.setEditCellValue({ id, field: "referenceType", value: newValue.referenceType });
      await apiRef.current.setEditCellValue({ id, field: "dmsLatitude", value: newValue.dmsLatitude });
      await apiRef.current.setEditCellValue({ id, field: "dmsLongitude", value: newValue.dmsLongitude });
    } else {
      await apiRef.current.setEditCellValue({ id, field: field, value: null });
      await apiRef.current.setEditCellValue({ id, field: "referenceType", value: null });
      await apiRef.current.setEditCellValue({ id, field: "dmsLatitude", value: "" });
      await apiRef.current.setEditCellValue({ id, field: "dmsLongitude", value: "" });
    }
  };

  return <ReferenceTypeSelector onChange={_onChange} referenceTypeValue={row.referenceType} referenceValue={row.reference} error={Boolean(error)} />;
};

export default DataGridReferenceSelector;
