import { Airway } from "src/types/Types";
import { getAPIClient } from "../apiClient";

type AirwaysResponse = {
  data: Airway[];
  errorMessage: string;
};

export const getAirwaysFromIds = async (versionTag: string, ids: number[]) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.put<AirwaysResponse>(`/tables/airway/search`, { searchIds: ids }, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};
