import { Autocomplete, TextField } from "@mui/material";

type AltitudeCorrectionSelectorProps = {
  value?: string[];
  onChange?: (newValue: string[]) => void;
};

const AltitudeCorrectionsSelector = (props: AltitudeCorrectionSelectorProps) => {
  const { value, onChange } = props;
  const altitudeCorrections = ["qnh", "flc", "uhbu", "lhbu", "unbu", "lnbu"];
  return (
    <Autocomplete
      size="small"
      fullWidth
      options={altitudeCorrections}
      multiple
      value={value}
      onChange={(event, value) => {
        onChange?.(value);
      }}
      renderInput={(params) => <TextField {...params} variant="standard" label={"Altitude corrections"} />}
    />
  );
};

export default AltitudeCorrectionsSelector;
