import { CircularProgress } from "@mui/material";
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAppContext } from "src/appcontext/AppContext";
import ImportReportGrid from "src/components/datagrids/ImportReportGrid";
import { appConfiguration } from "src/types/appconfig";

import useWebSocket from "react-use-websocket";
import { ImportApiResponse, ImportResult, ImportStatus, ImportStatusResponse } from "src/types/ImporterApiTypes";

const GeoImporter = () => {
  const appContext = useAppContext();
  const { sendMessage, lastMessage, readyState } = useWebSocket(appConfiguration.REACT_APP_WS_URL);

  const authToken = appContext.state.authenticationToken?.token;
  const apiUrl = appConfiguration.REACT_APP_API_URL;
  const importTimeout = 360 * 10 * 1000; // 1h minutes

  const [selectedFile, setSelectedFile] = useState<string | Blob>("");
  const [loading, setLoading] = useState(false);
  const [importStatus, setImportStatus] = useState<ImportStatus | null>(null);
  const apiClient = axios.create({
    baseURL: `${apiUrl}`,
    timeout: importTimeout,
    headers: { Authorization: `Bearer ${authToken}` }
  });

  const [importResults, setImportResults] = useState<ImportResult[]>([]);

  useEffect(() => {
    if (lastMessage?.data) {
      //setImportResults([...importResults, {dataType: "", message: JSON.stringify(lastMessage?.data)}]);
    }
  }, [lastMessage]);

  const onChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    apiClient?.get<ImportStatusResponse>("/swisstopo/import-status").then((response) => {
      setImportStatus(response.data.data);
    });
  }, []);

  const onSubmit = () => {
    if (importStatus === "GEO_DATA_IMPORTED" || importStatus === "GEO_DATA_IMPORTING") {
      toast.error("GEO data already imported or import in progress");
    } else {
      setLoading(true);
      setImportResults([]);
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };

      const data = new FormData();
      data.append("file", selectedFile);
      apiClient
        ?.post<ImportApiResponse>("/swisstopo/import", data, config)
        .then((response) => {
          setImportResults(response.data.data.results);
          setLoading(false);
          toast("File uploaded!", { type: "success" });
          setImportStatus(null);
        })
        .catch((error: Error | AxiosError) => {
          setLoading(false);
          setImportStatus(null);
          toast(error.message ? error.message : "Upload failed!", { type: "error" });
        });
    }
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Geodata (swisstopo) import</h2>
      <div style={{ marginBottom: "40px", height: "70%" }}>
        <div style={{ marginTop: "20px", display: "flex", flexDirection: "row" }}>
          <div>
            <input type="file" name="file" onChange={onChange} accept=".zip" />
          </div>
          <div>
            <button name="file" onClick={onSubmit}>
              Upload
            </button>
          </div>
          <div style={{ marginLeft: "10px" }}>{loading && <CircularProgress size="1.5rem" />}</div>
        </div>
        {importStatus === "GEO_DATA_IMPORTED" && <div style={{ color: "red", marginTop: "20px" }}>GEO data already imported. Reimporting will result in data corruption</div>}
        {importStatus === "GEO_DATA_IMPORTING" && (
          <div style={{ display: "flex", flexDirection: "row", marginTop: "20px" }}>
            <CircularProgress size="1.3rem" style={{ marginRight: "10px", color: "red" }} />
            <div style={{ color: "red" }}>Import in progress</div>
          </div>
        )}
        {importStatus === "GEO_DATA_IMPORTING" && <div style={{ marginTop: "10px", overflowY: "scroll" }}>{lastMessage?.data}</div>}
        <h3 style={{ marginTop: "40px" }}>Import log</h3>
        <div style={{ height: "100%" }}>
          <ImportReportGrid importResults={importResults} />
        </div>
      </div>
      <div style={{ maxHeight: "500px", overflowY: "scroll" }}></div>
    </div>
  );
};

export default GeoImporter;
