import { FormikHelpers, useFormik } from "formik";
import { toast } from "react-toastify";
import { Line, LinePoint } from "src/types/Types";
import * as yup from "yup";

type LineValueTypes = {
  name: string;
  description: string | null;
  category: string | null;
  userTags: string[];
  points: LinePoint[];
};

const useAddLineFormHandler = (createLine: (line: Line) => Promise<Line>, onSuccess: () => void) => {
  const validationSchema = yup.object({
    name: yup.string().required("Name cannot be empty")
  });

  const initialValues = {
    name: "",
    description: null,
    category: null,
    userTags: [],
    points: []
  };

  const mapFormValuesToLine = (values: LineValueTypes): Line => {
    return {
      id: -1,
      name: values.name,
      description: values.description,
      category: values.category,
      userTags: values.userTags,
      linePoints: values.points,
      additionalInformation: []
    };
  };

  const formik = useFormik<LineValueTypes>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, helpers: FormikHelpers<any>) => {
      return createLine(mapFormValuesToLine(values))
        .then(() => {
          onSuccess();
        })
        .catch((error) => {
          if (error.fieldErrors) {
            error.fieldErrors.map((f: any) => {
              helpers.setFieldError(f.fieldId, f.message);
              if (f.fieldId === "linePoints") {
                toast.error(f.message);
              }
            });
          } else {
            if (error.errorMessage) {
              toast.error(error.errorMessage);
            }
          }
        });
    }
  });

  return { formHandler: formik };
};

export default useAddLineFormHandler;
