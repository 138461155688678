import { LatLng } from "leaflet";
import { Polyline } from "react-leaflet";
import { River } from "src/types/Types";

type RiverProps = {
  rivers: River[];
};

const Rivers = (props: RiverProps) => {
  const { rivers } = props;
  const defaultColor = "#1565C0";

  return (
    <>
      {rivers.map((r) => {
        return r.sections?.map((s) => {
          return (
            <Polyline
              pathOptions={{ color: r.color ? r.color : defaultColor, weight: 1 }}
              positions={s.slice().map((sp) => {
                return new LatLng(sp.wgs84Latitude, sp.wgs84Longitude);
              })}
            />
          );
        });
      })}
      {rivers.map((r) => {
        return (
          r.coordinates && (
            <Polyline
              pathOptions={{ color: r.color ? r.color : defaultColor, weight: 1 }}
              positions={r.coordinates.map((c) => {
                return new LatLng(c.wgs84Latitude, c.wgs84Longitude);
              })}
            />
          )
        );
      })}
    </>
  );
};

export default Rivers;
