import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import { Airport } from "src/types/Types";

import FlightIcon from "@mui/icons-material/Flight";
import { Marker, Tooltip } from "react-leaflet";
import { useEffect, useState } from "react";
import { getOrientationSymbol } from "src/apis/symbols/symbolsApi";
import { useVersionTag } from "src/apis/hooks/useVersionTag";

type AirportMarkerProps = {
  airport: Airport;
};

const AirportMarker = (props: AirportMarkerProps) => {
  const { airport } = props;
  const versionTag = useVersionTag();

  const [orientationImage, setOrientationImage] = useState<string>();

  useEffect(() => {
    if (airport && airport.orientation && airport.color) {
      const firstOrientationValue = airport.orientation.split("/")[0];
      getOrientationSymbol(versionTag, firstOrientationValue, airport.color).then((symbol) => {
        if (symbol.symbolImage) {
          setOrientationImage(symbol.symbolImage);
        }
      });
    }
  }, [airport]);

  const divIcon = L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(
      <div>
        {airport.symbolImage ? (
          <div style={{ width: "15px", height: "15px", position: "relative" }}>
            {orientationImage && (
              <div style={{ width: "100%", height: "100%", position: "absolute", top: 1, left: 1 }}>
                <img src={`data:image/png;base64,${orientationImage}`} />
              </div>
            )}
            <div style={{ width: "100%", height: "100%", position: "absolute", top: 0, left: 0.15 }}>
              <img src={`data:image/png;base64,${airport.symbolImage}`} />
            </div>
          </div>
        ) : (
          <FlightIcon style={{ width: "15px", height: "15px", fontWeight: "bold" }} />
        )}
      </div>
    )
  });

  return (
    <>
      {airport.wgs84Latitude && airport.wgs84Longitude ? (
        <Marker icon={divIcon} position={[airport.wgs84Latitude, airport.wgs84Longitude]}>
          <Tooltip direction="left">{`[${airport.dmsLatitude} ${airport.dmsLongitude}]`}</Tooltip>
        </Marker>
      ) : (
        <></>
      )}
    </>
  );
};

export default AirportMarker;
