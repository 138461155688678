import { FormikHelpers, useFormik } from "formik";
import { toast } from "react-toastify";
import { getWgs84LatLng } from "src/apis/waypoints/waypointsApi";
import { Point } from "src/types/Types";
import { latitudeSchemaValidation, longitudeSchemaValidation } from "src/validators/yup/latlngValidation";
import { orientationSchemaValidation } from "src/validators/yup/orientationValidation";
import * as yup from "yup";

type AddPointValueTypes = {
  name: string;
  referenceType: "WAYPOINT" | "AIRPORT" | "SID" | null;
  reference: string;
  description: string;
  latitude: string;
  longitude: string;
  frequency: string;
  orientation: string;
  exportShortName: boolean | null;
  areasOfResponsibility: string[];
  userTags: string[];
};

const useAddWaypointFormHandler = (createPoint: (point: Point) => Promise<Point>, onPointCreated: () => void) => {
  const validationSchema = yup.object({
    name: yup.string().required("Name cannot be empty"),
    latitude: latitudeSchemaValidation.required(),
    longitude: longitudeSchemaValidation.required(),
    orientation: orientationSchemaValidation,
    frequency: yup.string().test("frequency", "Invalid frequency", (value) => {
      if (value !== undefined && value !== "") {
        return !isNaN(+value);
      }
      return true;
    })
  });

  const mapFormValues = async (values: AddPointValueTypes): Promise<Point> => {
    const latLng = await getWgs84LatLng(values.latitude, values.longitude);
    return {
      id: -1,
      name: values.name,
      referenceType: values.referenceType,
      reference: values.referenceType === null ? null : values.reference,
      description: values.description,
      wgs84Latitude: latLng.wgs84Latitude,
      wgs84Longitude: latLng.wgs84Longitude,
      dmsLatitude: values.latitude,
      dmsLongitude: values.longitude,
      frequency: values.frequency,
      orientation: values.orientation.length > 0 ? +values.orientation : null,
      userTags: values.userTags,
      areasOfResponsibility: values.areasOfResponsibility,
      exportShortName: values.exportShortName,
      additionalInformation: [],
      xrefs: []
    };
  };

  const formik = useFormik<AddPointValueTypes>({
    validationSchema: validationSchema,
    initialValues: {
      name: "",
      referenceType: null,
      reference: "",
      description: "",
      latitude: "",
      longitude: "",
      frequency: "",
      orientation: "",
      exportShortName: null,
      areasOfResponsibility: [],
      userTags: []
    },
    onSubmit: async (values, formikHelpers: FormikHelpers<any>) => {
      const point = await mapFormValues(values);
      createPoint(point)
        .then(() => {
          onPointCreated();
        })
        .catch((error) => {
          console.log("error: " + JSON.stringify(error));
          if (typeof error === "object") toast.error(error.errorMessage);
        });
    }
  });

  return { formHandler: formik };
};

export default useAddWaypointFormHandler;
