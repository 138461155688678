import { TabContext, TabPanel } from "@mui/lab";
import { Button, Divider, Menu, MenuItem as MuiMenuItem, Tab, Tabs } from "@mui/material";
import {
  DataGridPro,
  DataGridProProps,
  GridActionsCellItem,
  GridColumns,
  GridFilterModel,
  GridRowId,
  GridRowParams,
  GridSelectionModel,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  gridFilteredSortedRowIdsSelector,
  useGridApiRef
} from "@mui/x-data-grid-pro";
import { useEffect, useRef, useState } from "react";

import ExportIcon from "@mui/icons-material/DownloadForOffline";
import MapIcon from "@mui/icons-material/Map";
import MenuOpenIcon from "@mui/icons-material/PlaylistAdd";
import { CSVLink } from "react-csv";
import { useAppContext } from "src/appcontext/AppContext";
import { MenuItem } from "src/types/Types";
import MapReferencesGrid from "./MapReferencesGrid";

import MoveToIcon from "@mui/icons-material/MenuOpen";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import MoveDownIcon from "@mui/icons-material/MoveDown";

import { MapElementReference } from "src/types/Types";

import DeleteRowsDialog from "src/components/datagrids/DeleteRowsDialog";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { isNull, isUndefined } from "lodash";
import MapEditorPreview from "../mapeditor/MapEditorPreview";
import useGraphicAttributes from "src/apis/graphicattributes/useGraphicAttributes";

type MenusGridProps = {
  loading: boolean;
  menuItems: MenuItem[];
  selectedMenuItem: MenuItem | undefined;
  onMenuItemSelected: (rowId: GridRowId) => void;
  onCreateMap: () => void;
  onCreateMenu: () => void;
  onUpdateMap: (radarMap: MenuItem) => void;
  onDeleteMap: (radarMap: MenuItem) => Promise<void>;
  onDeleteMenu: (menu: MenuItem) => Promise<void>;
  onMoveSelectedMenuItemUp: () => Promise<void>;
  onMoveSelectedMenuItemDown: () => Promise<void>;
  onDisplayMoveMenuItemTo: () => void;
  onDeleteMapElementReference: (MapElementReference: MapElementReference) => Promise<void>;
};

type MapElementReferencesResponse = {
  data: MapElementReference[];
  errorMessage: string;
};

const groupingColDef: DataGridProProps["groupingColDef"] = {
  headerName: "Path",
  hideDescendantCount: true,
  width: 300
};

const MenusGrid = (props: MenusGridProps) => {
  const {
    menuItems,
    loading,
    selectedMenuItem,
    onMenuItemSelected,
    onCreateMap,
    onUpdateMap,
    onDeleteMap,
    onDeleteMapElementReference,
    onCreateMenu,
    onDeleteMenu,
    onMoveSelectedMenuItemUp,
    onMoveSelectedMenuItemDown,
    onDisplayMoveMenuItemTo: onMoveSelectedMenuItemTo
  } = props;

  const appContext = useAppContext();
  const apiClient = appContext.state.apiClient;
  const versionTag = appContext.state.currentVersion?.versionTag ? appContext.state.currentVersion.versionTag : "trunk";
  const { graphicAttributes } = useGraphicAttributes();

  const newMenuGridRowId = appContext.state.newMenuGridRowId;

  const [mapElementReferences, setMapElementReferences] = useState<MapElementReference[]>([]);
  const [loadingReferences, setLoadingReferences] = useState<boolean>(false);

  const [menusCSV, setMenusCSV] = useState<Array<Object>>([]);
  const csvLinkRef = useRef<any>();

  const [mapToDelete, setMapToDelete] = useState<MenuItem>();
  const [menuToDelete, setMenuToDelete] = useState<MenuItem>();

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const [filterModel, setFilterModel] = useState<GridFilterModel>();

  const apiRef = useGridApiRef();
  const [quickFilterValue, setQuickFilterValue] = useState("");

  useEffect(() => {
    if (menusCSV.length > 0) {
      csvLinkRef.current.link.click();
      setMenusCSV([]); // Required otherwise grid starts lagging. Understand why!
    }
  }, [menusCSV]);

  useEffect(() => {
    const rowIds = gridFilteredSortedRowIdsSelector(apiRef);
    if (rowIds.length > 0 && !isUndefined(newMenuGridRowId)) {
      const ids = [newMenuGridRowId];
      let parent = apiRef.current.getRow(newMenuGridRowId).parentId;
      while (!isNull(parent)) {
        ids.push(parent);
        parent = apiRef.current.getRow(parent).parentId;
      }
      ids.forEach((id) => apiRef.current.setRowChildrenExpansion(id, true));

      setTimeout(() => {
        apiRef.current.scrollToIndexes({ rowIndex: apiRef.current.getRowIndexRelativeToVisibleRows(newMenuGridRowId) });
      }, 300);
    }
  }, [apiRef, newMenuGridRowId]);

  useEffect(() => {
    if (selectedMenuItem) {
      setSelectionModel([selectedMenuItem.id]);
    }
  }, [selectedMenuItem]);

  useEffect(() => {
    //console.log("debounce..");
    const rowIds = gridFilteredSortedRowIdsSelector(apiRef);

    if (quickFilterValue.length > 0) {
      rowIds.forEach((id) => apiRef.current.setRowChildrenExpansion(id, true));
    } else {
      rowIds.forEach((id) => apiRef.current.setRowChildrenExpansion(id, false));
    }
  }, [apiRef, quickFilterValue]);

  const Toolbar = () => {
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const open = Boolean(menuAnchor);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
      setMenuAnchor(event.currentTarget);
    };

    const onClose = () => {
      setMenuAnchor(null);
    };

    return (
      <GridToolbarContainer>
        <div style={{ marginRight: 15 }}>
          <GridToolbarQuickFilter debounceMs={600} />
        </div>
        <Button color="primary" startIcon={<MenuOpenIcon />} onClick={onCreateMenu}>
          Menu
        </Button>
        <Button style={{ marginRight: 5 }} color="primary" startIcon={<MapIcon />} onClick={onCreateMap}>
          + Map
        </Button>
        <Divider orientation="vertical" flexItem />
        <Button style={{ marginLeft: 5 }} color="primary" startIcon={<MoveUpIcon />} onClick={onMoveSelectedMenuItemUp} disabled={selectedMenuItem === undefined}>
          Move up
        </Button>
        <Button color="primary" startIcon={<MoveDownIcon />} onClick={onMoveSelectedMenuItemDown} disabled={selectedMenuItem === undefined}>
          Move down
        </Button>
        <Button color="primary" style={{ marginRight: 5 }} startIcon={<MoveToIcon />} onClick={onMoveSelectedMenuItemTo}>
          Move To...
        </Button>
      </GridToolbarContainer>
    );
  };

  const _onMenuItemSelected = (rowParams: GridRowParams<MenuItem>) => {
    onMenuItemSelected(rowParams.row.id);
  };

  useEffect(() => {
    if (selectedMenuItem) {
      setLoadingReferences(true);
      apiClient
        ?.get<MapElementReferencesResponse>("/tables/map/" + selectedMenuItem.id + "/references", {
          headers: { "x-air-version": versionTag }
        })
        .then((response) => {
          setMapElementReferences(response.data.data);
        })
        .finally(() => {
          setLoadingReferences(false);
        });
    }
  }, [selectedMenuItem]);

  const _onDeleteMapElementReference = (rowId: GridRowId) => {
    const mapElementReference = selectedMenuItem?.mapElementReferences.filter((ref) => ref.id === rowId)[0];
    if (mapElementReference) {
      return onDeleteMapElementReference(mapElementReference);
    }
    return Promise.reject();
  };

  const columns: GridColumns = [
    { field: "sequence", headerName: "Sequence", hide: false },
    { field: "menuItemType", headerName: "Type", width: 60 },
    { field: "name", headerName: "Name", width: 200, editable: true },
    { field: "code", headerName: "Code", width: 200, editable: true },
    { field: "clientTypes", headerName: "Client types", width: 300, editable: true },
    { field: "userTags", headerName: "User tags", width: 300, editable: true },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }: { id: number | string }) => {
        const menuItem = menuItems.filter((m) => m.id === id)[0];
        return menuItem.menuItemType === "MAP"
          ? menuItem.id === selectedMenuItem?.id
            ? [
                <GridActionsCellItem icon={<EditIcon />} label="Edit" onResizeCapture={() => {}} onResize={() => {}} color="inherit" onClick={() => onUpdateMap(menuItem)} />,
                <GridActionsCellItem icon={<DeleteIcon />} onResizeCapture={() => {}} onResize={() => {}} onClick={() => _onDeleteMap(menuItem)} label="Delete" color="inherit" />
              ]
            : [<GridActionsCellItem icon={<DeleteIcon />} onResizeCapture={() => {}} onResize={() => {}} onClick={() => _onDeleteMap(menuItem)} label="Delete" color="inherit" />]
          : [<GridActionsCellItem icon={<DeleteIcon />} onResizeCapture={() => {}} onResize={() => {}} onClick={() => _onDeleteMenu(menuItem)} label="Delete" color="inherit" />];
      }
    }
  ];

  const _onDeleteMap = (menuItem: MenuItem) => {
    setMapToDelete(menuItem);
  };

  const onConfirmDeleteMap = () => {
    onDeleteMap(mapToDelete!).then(() => {
      setMapToDelete(undefined);
      setMapElementReferences([]);
    });
  };

  const onCancelDeleteMap = () => {
    setMapToDelete(undefined);
  };

  const _onDeleteMenu = (menuItem: MenuItem) => {
    setMenuToDelete(menuItem);
  };

  const onConfirmDeleteMenu = () => {
    if (menuToDelete) {
      onDeleteMenu(menuToDelete).then(() => {
        setMenuToDelete(undefined);
      });
    }
  };

  const onCancelDeleteMenu = () => {
    setMenuToDelete(undefined);
  };

  return (
    <div style={{ height: "100%" }}>
      {menuToDelete && (
        <DeleteRowsDialog title={"Delete folder and all its content?"} rows={[menuToDelete]} onConfirmDelete={onConfirmDeleteMenu} onCancelDelete={onCancelDeleteMenu} />
      )}
      {mapToDelete && <DeleteRowsDialog title={"Delete map?"} rows={[mapToDelete]} onConfirmDelete={onConfirmDeleteMap} onCancelDelete={onCancelDeleteMap} />}
      <div style={{ height: "50%" }}>
        <DataGridPro
          groupingColDef={groupingColDef}
          experimentalFeatures={{ newEditingApi: true }}
          apiRef={apiRef}
          loading={loading}
          filterModel={filterModel}
          treeData
          getTreeDataPath={(row) => row.path}
          density="compact"
          columns={columns}
          rows={menuItems}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          onFilterModelChange={(newFilterModel) => {
            setQuickFilterValue(newFilterModel.quickFilterValues ? newFilterModel.quickFilterValues.join(" ") : "");
            setFilterModel(newFilterModel);
          }}
          selectionModel={selectionModel}
          onRowClick={_onMenuItemSelected}
          editMode="row"
          components={{ Toolbar }}
          initialState={{
            sorting: {
              sortModel: [{ field: "sequence", sort: "asc" }]
            }
          }}
        />
      </div>
      <div style={{ height: "35%" }}>
        <TabContext value={"mapElements"}>
          <Tabs value={"mapElements"}>
            <Tab value="mapElements" label="Map Elements" />
          </Tabs>
          <TabPanel value="mapElements" style={{ height: "100%" }}>
            <div style={{ height: "100%", display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1 }}>
                <MapReferencesGrid
                  mapElementReferences={selectedMenuItem ? selectedMenuItem.mapElementReferences : []}
                  loading={loadingReferences}
                  showAttributes={true}
                  onDelete={_onDeleteMapElementReference}
                />
              </div>
              <div style={{ height: "100%", flex: 1, marginLeft: "5px" }}>
                <MapEditorPreview mapElementReferences={selectedMenuItem ? selectedMenuItem.mapElementReferences : []} graphicAttributes={graphicAttributes} />
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
};

export default MenusGrid;
