import { GridColumns, GridPreProcessEditCellProps, GridRenderCellParams } from "@mui/x-data-grid-pro";
import DataGridFlightLevelEditor from "src/components/editors/DataGridFlightLevelEditor";
import DataGridLatitudeMask from "src/components/editors/DataGridLatitudeMask";
import DataGridLongitudeMask from "src/components/editors/DataGridLongitudeMask";
import DataGridTextFieldEditor from "src/components/editors/DataGridTextFieldEditor";
import DataGridVerticalBufferEditor from "src/components/editors/DataGridVerticalBufferEditor";
import DataGridAltitudeCorrectionSelector from "src/components/selectors/altitudecorrectionselector/DataGridAltitudeCorrectionSelector";
import DatagridAreaTypeSelector from "src/components/selectors/areatypeselector/DatagridAreaTypeSelector";
import DataGridDistributionListSelector from "src/components/selectors/distributionlistselector/DataGridDistributionListSelector";
import DataGridUserTagSelector from "src/components/selectors/usertagselector/DataGridUserTagSelector";
import { ColumnsGenerator } from "src/types/Types";
import { customUserTagFilteringOperators } from "./customUserTagFilteringOperators";
import { userTagGridComparator } from "./customUserTagGridComparator";

const useDamParameterColumns = () => {
  const getColumns: ColumnsGenerator = (validator, onFieldDirty) => {
    const columns: GridColumns = [
      {
        field: "id",
        headerName: "Id",
        width: 100,
        editable: false
      },
      {
        field: "description",
        headerName: "Description",
        width: 200,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("description", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "dmsLatitude",
        headerName: "Latitude",
        width: 120,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridLatitudeMask {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("dmsLatitude", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "dmsLongitude",
        headerName: "Longitude",
        width: 120,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridLongitudeMask {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          const hasError = validator.validateField("dmsLongitude", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "lowerFlightLevel",
        headerName: "Lower level",
        width: 120,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridFlightLevelEditor {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          const hasError = validator.validateField("lowerFlightLevel", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "higherFlightLevel",
        headerName: "Higher level",
        width: 120,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridFlightLevelEditor {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          const hasError = validator.validateField("higherFlightLevel", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      { field: "startTime", headerName: "Start time", width: 80, editable: true, type: "boolean" },
      { field: "endTime", headerName: "End time", width: 80, editable: true, type: "boolean" },
      { field: "trainingArea", headerName: "tra", width: 60, editable: true, type: "boolean" },
      {
        field: "areaType",
        headerName: "PRD",
        width: 120,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DatagridAreaTypeSelector {...params} />;
        }
      },
      { field: "displayLevel", headerName: "dl", width: 60, editable: true, type: "boolean" },
      { field: "lowerLevelInFeet", headerName: "lft", width: 60, editable: true, type: "boolean" },
      { field: "upperLevelInFeet", headerName: "uft", width: 60, editable: true, type: "boolean" },
      {
        field: "altitudeCorrections",
        headerName: "AC",
        width: 120,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridAltitudeCorrectionSelector {...params} />;
        }
      },
      {
        field: "verticalBuffer",
        headerName: "Vertical buffer",
        width: 120,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridVerticalBufferEditor {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          const hasError = validator.validateField("verticalBuffer", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      { field: "damStatic", headerName: "DAM static", width: 80, editable: true, type: "boolean" },
      {
        field: "distributionList",
        headerName: "DL",
        width: 120,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridDistributionListSelector {...params} />;
        }
      },
      {
        field: "userTags",
        headerName: "User tags",
        width: 120,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridUserTagSelector {...params} />;
        },
        filterOperators: customUserTagFilteringOperators,
        sortComparator: userTagGridComparator
      }
    ];
    return columns;
  };
  return { getColumns };
};

export default useDamParameterColumns;
