import * as _ from "lodash";
import { useEffect, useState } from "react";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import {
  createAdditionalInformation as _createAdditionalInformation,
  createLine as _createLine,
  createLinePoint as _createLinePoint,
  createLinePoints as _createLinePoints,
  deleteAdditionalInformation as _deleteAdditionalInformation,
  deleteLine as _deleteLine,
  deleteLinePoint as _deleteLinePoint,
  deleteLinePoints as _deleteLinePoints,
  moveSelectedLinePoints as _moveSelectedLinePoints,
  updateAdditionalInformation as _updateAdditionalInformation,
  updateLine as _updateLine,
  updateLinePoint as _updateLinePoint,
  getLines
} from "src/apis/lines/linesApi";
import { AdditionalInformation, Line, LinePoint } from "src/types/Types";
import { getWgs84LatLng } from "../waypoints/waypointsApi";

const useLines = () => {
  const versionTag = useVersionTag();
  const [lines, setLines] = useState<Line[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedLine, setSelectedLine] = useState<Line>();

  useEffect(() => {
    setLoading(true);
    getLines(versionTag)
      .then((response) => {
        setLines(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [versionTag]);

  const selectLine = (line: Line) => {
    setSelectedLine(line);
  };

  const createLine = async (line: Line) => {
    const newLine = await _createLine(versionTag, line);
    setLines([...lines, newLine]);
    setSelectedLine(newLine);
    return newLine;
  };

  const updateLine = async (line: Line) => {
    const updatedLine = await _updateLine(versionTag, line);
    setLines([...lines.filter((l) => l.id !== line.id), updatedLine]);
    setSelectedLine(updatedLine);
    return updatedLine;
  };

  const deleteLine = async (line: Line) => {
    await _deleteLine(versionTag, line);
    setLines([...lines.filter((l) => l.id !== line.id)]);
  };

  const enrichWithWgsCoordinates = async (p: LinePoint) => {
    if (!_.isEmpty(p.dmsLatitude) && !_.isEmpty(p.dmsLongitude)) {
      p.dmsLatitude = p.dmsLatitude?.replace('"', "");
      p.dmsLongitude = p.dmsLongitude?.replace('"', "");
      const latlng = await getWgs84LatLng(p.dmsLatitude || "", p.dmsLongitude || "");
      p.wgs84Latitude = latlng.wgs84Latitude;
      p.wgs84Longitude = latlng.wgs84Longitude;
    }
    return p;
  };

  const createLinePoint = async (linePoint: LinePoint) => {
    return _createLinePoint(versionTag, selectedLine!, await enrichWithWgsCoordinates(linePoint)).then((result) => {
      const copy = _.cloneDeep(selectedLine!);
      copy.linePoints = result;
      setSelectedLine(copy);
      return result[result.length - 1];
    });
  };

  const createLinePoints = async (line: Line, afterSequence: number, newPoints: LinePoint[]) => {
    const allPoints = await _createLinePoints(versionTag, line, afterSequence, newPoints);
    const newLine = _.clone(line);
    newLine.linePoints = allPoints;
    setLines([...lines.filter((l) => l.id !== line.id), newLine]);
    setSelectedLine(newLine);
    return allPoints;
  };

  const updateLinePoint = async (linePoint: LinePoint) => {
    return _updateLinePoint(versionTag, selectedLine!, await enrichWithWgsCoordinates(linePoint)).then((result) => {
      const copy = _.cloneDeep(selectedLine!);
      copy.linePoints = result;
      setLines([...lines.filter((l) => l.id !== copy.id), copy]);
      setSelectedLine(copy);
      return result.filter((_lp) => _lp.id === linePoint.id)[0];
    });
  };

  const deleteLinePoint = async (line: Line, linePoint: LinePoint) => {
    return _deleteLinePoint(versionTag, line, linePoint).then((result) => {
      const copy = _.cloneDeep(selectedLine!);
      copy.linePoints = result;
      setLines([...lines.filter((l) => l.id !== copy.id), copy]);
      setSelectedLine(copy);
    });
  };

  const deleteLinePoints = async (line: Line, linePoints: LinePoint[]) => {
    return _deleteLinePoints(versionTag, line, linePoints).then((result) => {
      const copy = _.cloneDeep(selectedLine!);
      copy.linePoints = result;
      setLines([...lines.filter((l) => l.id !== copy.id), copy]);
      setSelectedLine(copy);
    });
  };

  const moveSelectedLinePoints = async (line: Line, fromIndex: number, toIndex: number, selectedPointsSequenceNumbers: number[]) => {
    return _moveSelectedLinePoints(versionTag, line, fromIndex, toIndex, selectedPointsSequenceNumbers).then((result) => {
      const copy = _.cloneDeep(selectedLine!);
      copy.linePoints = result;
      setLines([...lines.filter((l) => l.id !== copy.id), copy]);
      setSelectedLine(copy);
    });
  };

  const createAdditionalInformation = async (info: AdditionalInformation) => {
    const result = await _createAdditionalInformation(versionTag, selectedLine!, info);
    const copy = _.cloneDeep(selectedLine!);
    copy.additionalInformation.push(result);
    setLines([...lines.filter((l) => l.id !== selectedLine!.id), copy]);
    setSelectedLine(copy);
    return result;
  };

  const updateAdditionalInformation = async (info: AdditionalInformation) => {
    const result = await _updateAdditionalInformation(versionTag, selectedLine!, info);
    const copy = _.cloneDeep(selectedLine!);
    copy.additionalInformation = [...copy.additionalInformation.filter((i) => i.id !== result.id), result];
    setLines([...lines.filter((l) => l.id !== selectedLine!.id), copy]);
    setSelectedLine(copy);
    return result;
  };

  const deleteAdditionalInformation = async (info: AdditionalInformation) => {
    const result = await _deleteAdditionalInformation(versionTag, selectedLine!, info);
    const copy = _.cloneDeep(selectedLine!);
    copy.additionalInformation = result;
    setLines([...lines.filter((l) => l.id !== selectedLine!.id), copy]);
    setSelectedLine(copy);
  };

  return {
    loading,
    lines,
    selectedLine,
    selectLine,
    createLine,
    updateLine,
    deleteLine,
    createLinePoint,
    createLinePoints,
    updateLinePoint,
    deleteLinePoint,
    deleteLinePoints,
    moveSelectedLinePoints,
    createAdditionalInformation,
    updateAdditionalInformation,
    deleteAdditionalInformation
  };
};

export default useLines;
