import { isUndefined } from "lodash";
import * as yup from "yup";

export const latitudeOrLongitudeMaskEmpty = '___°__´__.___"_';

export const latitudeSchemaValidation = yup
  .string()
  .test("latitude", "Latitude cannot be empty", (value, context) => {
    if (value === latitudeOrLongitudeMaskEmpty && context.parent.longitude !== latitudeOrLongitudeMaskEmpty) {
      return false;
    }
    return true;
  })
  .test("latitude", "Invalid Latitude", (value) => {
    if (value === latitudeOrLongitudeMaskEmpty) {
      return true;
    }
    return !isUndefined(value) && !value.includes("_");
  })
  .test("latitude", "Degrees must be between 0 and 90", (value) => {
    const degrees = value?.substring(0, 3);
    if (!degrees?.includes("_") && !isUndefined(degrees)) {
      return +degrees >= 0 && +degrees <= 90;
    }
    return true;
  })
  .test("latitude", "Minutes must be between 0 and 59", (value) => {
    const minutes = value?.substring(4, 6);
    if (!minutes?.includes("_") && !isUndefined(minutes)) {
      return +minutes >= 0 && +minutes <= 59;
    }
    return true;
  })
  .test("latitude", "Latitude cannot be empty", (value, context) => {
    if (context.parent.longitude !== "") {
      if (value === "") {
        return true;
      }
    }
    return true;
  })
  .test("latitude", "Seconds must be between 0 and 59", (value) => {
    const minutes = value?.substring(7, 9);
    if (!minutes?.includes("_") && !isUndefined(minutes)) {
      return +minutes >= 0 && +minutes <= 59;
    }
    return true;
  });

export const longitudeSchemaValidation = yup
  .string()
  .test("longitude", "Longitude cannot be empty", (value, context) => {
    if (value === latitudeOrLongitudeMaskEmpty && context.parent.latitude !== latitudeOrLongitudeMaskEmpty) {
      return false;
    }
    return true;
  })
  .test("longitude", "Invalid longitude", (value) => {
    if (value === latitudeOrLongitudeMaskEmpty) {
      return true;
    }
    return !isUndefined(value) && !value.includes("_");
  })
  .test("longitude", "Degrees must be between 0 and 180", (value) => {
    const degrees = value?.substring(0, 3);
    if (!degrees?.includes("_") && !isUndefined(degrees)) {
      return +degrees >= 0 && +degrees <= 180;
    }
    return true;
  })
  .test("longitude", "Minutes must be between 0 and 59", (value) => {
    const minutes = value?.substring(4, 6);
    if (!minutes?.includes("_") && !isUndefined(minutes)) {
      return +minutes >= 0 && +minutes <= 59;
    }
    return true;
  })
  .test("longitude", "Seconds must be between 0 and 59", (value) => {
    const seconds = value?.substring(7, 9);
    if (!seconds?.includes("_") && !isUndefined(seconds)) {
      return +seconds >= 0 && +seconds <= 59;
    }
    return true;
  });
