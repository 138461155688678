import React, { useState } from "react";

import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import { DataGridPro, GridColDef, GridRowParams, useGridApiRef } from "@mui/x-data-grid-pro";
import { SidStarPoint, StandardArrival } from "src/types/Types";

type AirportSTARsProps = {
  stars: StandardArrival[];
};

const AirportSTARs = (props: AirportSTARsProps) => {
  const apiRef = useGridApiRef();
  const { stars } = props;
  const [points, setPoints] = useState<SidStarPoint[]>([]);

  const [currentTab, setCurrentTab] = useState("starpoints");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  const onShowPoints = (rowParams: GridRowParams<StandardArrival>) => {
    setPoints(rowParams.row.points);
  };

  const columns: GridColDef[] = [
    { field: "sequence", headerName: "Sequence", editable: true },
    { field: "name", headerName: "Name", editable: true },
    {
      field: "shortName",
      headerName: "Short name",
      width: 200,
      editable: true
    },
    { field: "runwayDesignator", headerName: "Runway", width: 120, editable: true },
    { field: "equipment", headerName: "Equipment", width: 150, editable: true },
    { field: "xfl", headerName: "XFL", width: 150, editable: true },
    { field: "xflAsMaxXfl", headerName: "MaxFL", width: 150, editable: true, type: "boolean" },
    { field: "dvoOn", headerName: "DVO on", width: 150, editable: true, type: "boolean" },
    { field: "iaf", headerName: "IAF", width: 150, editable: true },
    { field: "autoDiversion", headerName: "Diversion", width: 150, editable: true, type: "boolean" },
    { field: "notForRerouting", headerName: "Not for rerouting", width: 150, editable: true, type: "boolean" },
    { field: "userTags", headerName: "User tags", editable: true }
  ];

  const starPointColumns = [
    { field: "sequence", headerName: "Sequence", width: 200 },
    { field: "waypointName", headerName: "Way point", width: 200 },
    { field: "elevation", headerName: "Elevation", width: 200 },
    { field: "nearPointName", headerName: "Near point", width: 200 },
    { field: "userTags", headerName: "User tags", width: 200 }
  ];

  return (
    <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ width: "100%", flex: 1 }}>
        <DataGridPro
          apiRef={apiRef}
          density="compact"
          editMode="row"
          columns={columns}
          rows={stars}
          onRowClick={onShowPoints}
          initialState={{
            pinnedColumns: { left: ["sequence", "name"] },
            sorting: {
              sortModel: [{ field: "sequence", sort: "asc" }]
            }
          }}
        />
      </div>
      <div style={{ flex: 1.1, marginBottom: "25px" }}>
        <TabContext value={currentTab}>
          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab value="starpoints" label={"STAR points"} />
          </Tabs>
          <TabPanel value="starpoints" style={{ height: "80%" }}>
            <DataGridPro
              density="compact"
              columns={starPointColumns}
              rows={points}
              initialState={{
                sorting: {
                  sortModel: [{ field: "sequence", sort: "asc" }]
                }
              }}
            />
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
};

export default AirportSTARs;
