import { Autocomplete, TextField } from "@mui/material";
import { useUserTags } from "src/apis/usertags/useUserTags";

type UserTagSelectorProps = {
  onChange?: (newSelection: string[]) => void;
  currentSelection?: string[] | string | null;
  width?: string;
  multiple?: boolean;
};

const UserTagSelector = (props: UserTagSelectorProps) => {
  const { currentSelection, onChange, multiple = true } = props;
  const { userTagNames } = useUserTags();

  const _onChange = (event: React.SyntheticEvent, value: string[] | string | null) => {
    if (onChange) {
      if (value === null) {
        onChange([]);
      } else if (Array.isArray(value)) {
        onChange(value);
      } else if (typeof value === "string") {
        onChange([value]);
      }
    }
  };

  return (
    <Autocomplete
      size="small"
      fullWidth
      options={userTagNames}
      multiple={multiple}
      value={currentSelection === undefined ? null : currentSelection}
      onChange={_onChange}
      renderInput={(params) => <TextField {...params} label="User tags" variant="standard" size="small" />}
    />
  );
};

export default UserTagSelector;
