import { useEffect, useState } from "react";

import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import * as _ from "lodash";
import useDepProceduresColumns from "src/columnsgenerators/useDepProceduresColumns";
import useProcedurePointsColumns from "src/columnsgenerators/useProcedurePointsColumns";
import GenericDataGrid from "src/components/datagrids/GenericDataGrid";
import { Airport, DepartureProcedure, ProceduralPoint } from "src/types/Types";
import ArrDepProcedureValidator from "src/validators/ArrDepProcedureValidator";
import ProcedurePointValidator from "src/validators/ProcedurePointValidator";
import { GridRowOrderChangeParams } from "@mui/x-data-grid-pro";

type AirportDepartureProcedureProps = {
  selectedAirport?: Airport;
  selectedDepProcedure?: DepartureProcedure;

  onDepProcedureSelected: (dp?: DepartureProcedure) => void;
  onAddDepartureProcedure: (dp: DepartureProcedure) => Promise<DepartureProcedure>;
  onUpdateDepartureProcedure: (dp: DepartureProcedure) => Promise<DepartureProcedure>;
  onDeleteDepartureProcedure: (dp: DepartureProcedure) => Promise<void>;

  onAddDepartureProcedurePoint: (p: ProceduralPoint) => Promise<ProceduralPoint>;
  onUpdateDepartureProcedurePoint: (p: ProceduralPoint) => Promise<ProceduralPoint>;
  onDeleteDepartureProcedurePoint: (p: ProceduralPoint) => Promise<void>;
  onDepartureProcedurePointsOrderChange: (dp: DepartureProcedure, fromIndex: number, toIndex: number, selected: number[]) => void;
};

const AirportDepartureProcedures = (props: AirportDepartureProcedureProps) => {
  const {
    selectedAirport,
    selectedDepProcedure,
    onDepProcedureSelected,
    onAddDepartureProcedure,
    onUpdateDepartureProcedure,
    onDeleteDepartureProcedure,
    onAddDepartureProcedurePoint,
    onUpdateDepartureProcedurePoint,
    onDeleteDepartureProcedurePoint,
    onDepartureProcedurePointsOrderChange
  } = props;

  const [selectedAirportId, setSelectedAirportId] = useState(selectedAirport?.id);

  const [departureProcedurePoints, setDepartureProcedurePoints] = useState<ProceduralPoint[]>([]);
  const [selectedDepartureProcedurePoints, setSelectedDepartureProcedurePoints] = useState<ProceduralPoint[]>([]);

  const { getColumns: depProceduresColumsGenerator } = useDepProceduresColumns();
  const { getColumns: procedurePointsColumnsGenerator } = useProcedurePointsColumns();

  const setSortedDepProcedurePoints = () => {
    setDepartureProcedurePoints(selectedDepProcedure?.points.slice().sort((a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0)) || []);
  };

  useEffect(() => {
    if (_.isUndefined(selectedDepProcedure)) {
      setSelectedDepartureProcedurePoints([]);
    }

    setSortedDepProcedurePoints();
  }, [selectedDepProcedure]);

  useEffect(() => {
    if (selectedAirport?.id !== selectedAirportId) {
      onDepProcedureSelected(undefined);
      setSelectedAirportId(selectedAirport?.id);
    }
  }, [selectedAirport]);

  const createEmptyDepProcedureRow = (): DepartureProcedure => {
    return {
      id: -1,
      airportId: selectedAirport!.id,
      name: "",
      runwayDesignator: "",
      description: "",
      points: [],
      userTags: []
    };
  };

  const createEmptyDepProcedurePointRow = (): ProceduralPoint => {
    let sequence = 1;
    if (selectedDepProcedure) {
      sequence = selectedDepProcedure.points.length + 1;
    }
    return {
      id: -1,
      sequence: sequence,
      inverted: false,
      curvature: "0",
      wgs84Latitude: 0,
      wgs84Longitude: 0,
      distanceToNextPointNm: 0
    };
  };

  const onSelectedPointsOrderChange = (params: GridRowOrderChangeParams) => {
    onDepartureProcedurePointsOrderChange(
      selectedDepProcedure!,
      params.oldIndex,
      params.targetIndex,
      selectedDepartureProcedurePoints.map((lp) => lp.sequence)
    );
  };

  const onSelectedDepartureProcedurePoints = (dpp: ProceduralPoint[]) => {
    let selectedPoints = [...dpp];
    selectedPoints.sort((a, b) => a.sequence - b.sequence);
    setSelectedDepartureProcedurePoints(selectedPoints);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{ width: "100%", flex: 1 }}>
        <GenericDataGrid<DepartureProcedure>
          data={selectedAirport ? selectedAirport.departureProcedures : []}
          columnsGenerator={depProceduresColumsGenerator}
          createEmptyRow={createEmptyDepProcedureRow}
          onCreateRow={onAddDepartureProcedure}
          onUpdateRow={onUpdateDepartureProcedure}
          onSelectRow={onDepProcedureSelected}
          onDeleteRow={onDeleteDepartureProcedure}
          deleteDialogTitle="Delete departure procedure ?"
          sortModel={[{ field: "name", sort: "asc" }]}
          canInsertRow={!_.isUndefined(selectedAirport)}
          validator={new ArrDepProcedureValidator()}
        />
      </div>
      <div style={{ flex: 1.1, marginBottom: "25px" }}>
        <TabContext value="departureProcedurePoints">
          <Tabs value="departureProcedurePoints">
            <Tab value="departureProcedurePoints" label={"Departure procedure points"} />
          </Tabs>
          <TabPanel value="departureProcedurePoints" style={{ height: "80%" }}>
            <GenericDataGrid<ProceduralPoint>
              data={departureProcedurePoints}
              columnsGenerator={procedurePointsColumnsGenerator}
              validator={new ProcedurePointValidator()}
              onCreateRow={onAddDepartureProcedurePoint}
              onUpdateRow={onUpdateDepartureProcedurePoint}
              onSelectedRows={onSelectedDepartureProcedurePoints}
              onDeleteRow={onDeleteDepartureProcedurePoint}
              deleteDialogTitle="Delete departure procedure point ?"
              canInsertRow={!_.isUndefined(selectedDepProcedure)}
              onRowOrderChange={onSelectedPointsOrderChange}
              createEmptyRow={createEmptyDepProcedurePointRow}
            />
          </TabPanel>
        </TabContext>
      </div>
    </div>
  );
};

export default AirportDepartureProcedures;
