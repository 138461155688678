import { GridColumns, GridPreProcessEditCellProps, GridRenderCellParams } from "@mui/x-data-grid-pro";
import DataGridTextFieldEditor from "src/components/editors/DataGridTextFieldEditor";
import DataGridUserTagSelector from "src/components/selectors/usertagselector/DataGridUserTagSelector";
import { ColumnsGenerator } from "src/types/Types";
import { FieldsValidator } from "src/validators/Validator";
import { customUserTagFilteringOperators } from "./customUserTagFilteringOperators";
import { userTagGridComparator } from "./customUserTagGridComparator";

const useSymbolsColumns = () => {
  const getColumns: ColumnsGenerator = (validator: FieldsValidator, onFieldDirty: () => void) => {
    const columns: GridColumns = [
      {
        field: "name",
        headerName: "Name",
        width: 200,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("name", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      { field: "code", headerName: "Code", editable: true },
      {
        field: "symbol",
        headerName: "Symbol",
        width: 400,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("symbol", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderCell: (params: GridRenderCellParams) => {
          return (
            <div>
              <img src={`data:image/png;base64,${params.row.symbolImage}`} />
            </div>
          );
        }
      },
      {
        field: "userTags",
        headerName: "User tags",
        width: 350,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridUserTagSelector {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        },
        filterOperators: customUserTagFilteringOperators,
        sortComparator: userTagGridComparator
      }
    ];
    return columns;
  };
  return { getColumns };
};

export default useSymbolsColumns;
