import { TabContext, TabPanel } from "@mui/lab";
import { Button, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import Split from "react-split";
import { useAreas } from "src/apis/areas/useAreas";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import useAreaColumns from "src/columnsgenerators/useAreaColumns";
import usePointsColumns from "src/columnsgenerators/useBoundaryPointsColumns";
import AdditionalInformationGrid from "src/components/datagrids/AdditionalInformationGrid";
import GenericDataGrid from "src/components/datagrids/GenericDataGrid";
import PasteCoordinatesDialog from "src/components/dialogs/PasteCoordinatesDialog";
import MapViewer from "src/components/mapviewer/MapViewer";
import { AdditionalInformation, Area, AreaPoint, CoordinatesList } from "src/types/Types";
import AreaValidator from "src/validators/AreaValidator";
import BoundaryPointsValidator from "src/validators/BoundaryPointsValidator";
import AddAreaDialog from "./dialogs/AddAreaDialog";

import * as _ from "lodash";
import { toast } from "react-toastify";
import { GridRowOrderChangeParams } from "@mui/x-data-grid-pro";
import XRefGrid from "src/components/datagrids/XRefGrid";
import useArc from "src/apis/lines/useArc";

const Areas = () => {
  const versionTag = useVersionTag();
  const { getColumns: getAreaColumns } = useAreaColumns();
  const { getColumns: getPointsColumns } = usePointsColumns(true);

  const {
    loading,
    areas,
    selectArea,
    currentArea,
    createArea,
    updateArea,
    deleteArea,
    createAreaPoint,
    createAreaPoints,
    updateAreaPoint,
    deleteAreaPoint,
    deleteAreaPoints,
    moveSelectedAreaPoints,
    createAreaInfo,
    deleteAreaInfo,
    updateAreaInfo
  } = useAreas();

  const { expandedLinePoints, setSortedLinePointsForExpansion } = useArc();

  const [currentTab, setCurrentTab] = useState("areaPoints");
  const [showPasteCoordinatesDialog, setShowPasteCoordinatesDialog] = useState(false);

  const [areaPoints, setAreaPoints] = useState<AreaPoint[]>([]);
  const [selectedAreaPoints, setSelectedAreaPoints] = useState<AreaPoint[]>([]);

  const setSortedAreaPoints = () => {
    setAreaPoints(currentArea?.areaPoints.slice().sort((a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0)) || []);
  };

  useEffect(() => {
    setSortedLinePointsForExpansion(areaPoints);
  }, [areaPoints]);

  useEffect(() => {
    if (_.isUndefined(currentArea) || (!_.isEmpty(selectedAreaPoints) && selectedAreaPoints[0].lineId !== currentArea!.id)) {
      setSelectedAreaPoints([]);
    }

    setSortedAreaPoints();
  }, [currentArea]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  const onSelectArea = (area: Area) => {
    selectArea(area);
  };

  const createAreaDialogFactory = (onSuccess: () => void, onCancel: () => void) => {
    return <AddAreaDialog createArea={createArea} onSuccess={onSuccess} onCancel={onCancel} />;
  };

  const onUpdateArea = async (area: Area) => {
    return updateArea(area);
  };

  const onDeleteArea = (area: Area) => {
    return deleteArea(area);
  };

  const onCreateAreaPoint = (areaPoint: AreaPoint) => {
    return createAreaPoint(currentArea!, areaPoint);
  };

  const onUpdateAreaPoint = (areaPoint: AreaPoint) => {
    return updateAreaPoint(currentArea!, areaPoint);
  };

  const onDeleteAreaPoint = (areaPoint: AreaPoint) => {
    return deleteAreaPoint(currentArea!, areaPoint);
  };

  const onDeleteAreaPoints = (areaPoints: AreaPoint[]) => {
    return deleteAreaPoints(currentArea!, areaPoints);
  };

  const onAddInformation = (info: AdditionalInformation) => {
    return createAreaInfo(currentArea!, info);
  };

  const onUpdateInformation = (info: AdditionalInformation) => {
    return updateAreaInfo(currentArea!, info);
  };

  const onDeleteInformation = (info: AdditionalInformation) => {
    return deleteAreaInfo(currentArea!, info);
  };

  const onSelectedAreaPoints = (areaPoints: AreaPoint[]) => {
    let selectedPoints = [...areaPoints];
    selectedPoints.sort((a, b) => a.sequence - b.sequence);
    setSelectedAreaPoints(selectedPoints);
  };

  const onShowPasteCoordinatesDialog = () => {
    setShowPasteCoordinatesDialog(true);
  };

  const onCancelPasteCoordinates = () => {
    setShowPasteCoordinatesDialog(false);
  };

  const onAddPastedCoordinates = (coordinatesList: CoordinatesList) => {
    if (currentArea) {
      const areaPoints: AreaPoint[] = coordinatesList.coordinates.map((c) => {
        return {
          id: _.uniqueId(),
          lineId: -1,
          wgs84Latitude: c.wgs84Latitude,
          wgs84Longitude: c.wgs84Longitude,
          dmsLatitude: c.dmsLatitude,
          dmsLongitude: c.dmsLongitude,
          sequence: -1,
          curvature: "0",
          inverted: false,
          buffer: 0
        };
      });
      createAreaPoints(currentArea, selectedAreaPoints.length > 0 ? selectedAreaPoints[selectedAreaPoints.length - 1].sequence : 0, areaPoints)
        .then(() => {
          toast.success("Area points created");
          setShowPasteCoordinatesDialog(false);
        })
        .catch((error: any) => {
          if (error.errorMessage) {
            toast.error(error.errorMessage);
          }
        });
    }
  };

  const onCreateEmptyAreaPointRow = () => {
    return {
      id: -1 /* to make sure row is unique in grid */,
      lineId: -1,
      sequence: currentArea ? currentArea.areaPoints.length + 1 : 1,
      wgs84Latitude: 0,
      wgs84Longitude: 0,
      dmsLatitude: "",
      dmsLongitude: "",
      curvature: "0",
      buffer: 0,
      inverted: false
    };
  };

  const onSelectedLinePointsOrderChange = (params: GridRowOrderChangeParams) => {
    moveSelectedAreaPoints(
      currentArea!,
      params.oldIndex,
      params.targetIndex,
      selectedAreaPoints.map((ap) => ap.sequence)
    ).catch((error: any) => {
      if (error.errorMessage) {
        toast.error(error.errorMessage);
      } else {
        toast.error(error);
      }
      // reset points
      setSortedAreaPoints();
    });
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Areas - {versionTag}</h2>
      <Split
        style={{ height: "calc(100vh - 230px)" }}
        direction="vertical"
        onDragEnd={() => {
          /*if (radarMapRef !== null) {
            radarMapRef.current?.invalidateSize();
          }*/
        }}
      >
        <div style={{ height: "100%" }}>
          <GenericDataGrid<Area>
            loading={loading}
            data={areas}
            selectedRow={currentArea}
            columnsGenerator={getAreaColumns}
            validator={new AreaValidator()}
            createDialogFactory={createAreaDialogFactory}
            onUpdateRow={onUpdateArea}
            onDeleteRow={onDeleteArea}
            onSelectRow={onSelectArea}
            sortModel={[{ field: "name", sort: "asc" }]}
          />
        </div>
        <div style={{ height: "100%" }}>
          <TabContext value={currentTab}>
            <Tabs value={currentTab} onChange={handleChange}>
              <Tab value="areaPoints" label="Area points"></Tab>
              <Tab value="additionalInformation" label="Additional information" />
              <Tab value="xref" label="Xref" />
            </Tabs>
            <TabPanel value="areaPoints" style={{ height: "95%" }}>
              {showPasteCoordinatesDialog && <PasteCoordinatesDialog onCancel={onCancelPasteCoordinates} onAddCoordinates={onAddPastedCoordinates} />}
              <div style={{ height: "100%", display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <div style={{ marginBottom: "5px" }}>
                    <Button disabled={_.isUndefined(currentArea)} onClick={onShowPasteCoordinatesDialog}>
                      Paste coordinates...
                    </Button>
                  </div>
                  <GenericDataGrid<AreaPoint>
                    data={areaPoints}
                    columnsGenerator={getPointsColumns}
                    validator={new BoundaryPointsValidator()}
                    onCreateRow={onCreateAreaPoint}
                    onUpdateRow={onUpdateAreaPoint}
                    onDeleteRow={onDeleteAreaPoint}
                    onDeleteRows={onDeleteAreaPoints}
                    onSelectedRows={onSelectedAreaPoints}
                    onRowOrderChange={onSelectedLinePointsOrderChange}
                    createEmptyRow={onCreateEmptyAreaPointRow}
                    canInsertRow={!_.isUndefined(currentArea)}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <MapViewer
                    areaPoints={expandedLinePoints.map((lp) => {
                      return { ...lp, buffer: 0 };
                    })}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel value="additionalInformation" style={{ height: "100%" }}>
              <div style={{ height: "100%" }}>
                <AdditionalInformationGrid
                  infos={currentArea?.additionalInformation ? currentArea.additionalInformation : []}
                  onAddInformation={onAddInformation}
                  onUpdateInformation={onUpdateInformation}
                  onDeleteInformation={onDeleteInformation}
                  canInsertRow={!_.isUndefined(currentArea)}
                />
              </div>
            </TabPanel>
            <TabPanel value="xref" style={{ height: "100%" }}>
              <div style={{ height: "100%" }}>
                <XRefGrid xrefs={currentArea && currentArea.xrefs ? currentArea.xrefs : []} />
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </Split>
    </div>
  );
};

export default Areas;
