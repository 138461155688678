import { ComputedArea, LinePoint } from "src/types/Types";
import { getAPIClient } from "../apiClient";

type GetComputedAreasResponse = {
  data: ComputedArea[];
  errorMessage: string;
};

type AddComputedAreaResponse = {
  data: ComputedArea;
  errorMessage: string;
};

type UpdateComputedAreaResponse = {
  data: ComputedArea;
  errorMessage: string;
};

type DeleteComputedAreaResponse = {
  data: number;
  errorMessage: string;
};

type GetComputedAreaPointsResponse = {
  data: LinePoint[];
  errorMessage: string;
};

export const getMergedAreas = async (versionTag: string) => {
  const apiClient = getAPIClient();
  const result = await apiClient!.get<GetComputedAreasResponse>("/tables/computed-area/", { headers: { "x-air-version": versionTag } });
  return result.data.data;
};

export const getComputedAreas = async (versionTag: string) => {
  const apiClient = getAPIClient();
  const result = await apiClient!.get<GetComputedAreasResponse>("/tables/computed-area", { headers: { "x-air-version": versionTag } });
  return result.data.data;
};

export const getComputedAreasFromIds = async (versionTag: string, ids: number[]) => {
  const apiClient = getAPIClient();
  const result = await apiClient!.put<GetComputedAreasResponse>("/tables/computed-area/search", { searchIds: ids }, { headers: { "x-air-version": versionTag } });
  return result.data.data;
};

export const addComputedArea = async (computedArea: ComputedArea, versionTag: string) => {
  const apiClient = getAPIClient();
  const result = await apiClient!.post<AddComputedAreaResponse>("/tables/computed-area", computedArea, { headers: { "x-air-version": versionTag } });
  return result.data.data;
};

export const updateComputedArea = async (computedArea: ComputedArea, versionTag: string) => {
  const apiClient = getAPIClient();
  const result = await apiClient!.put<UpdateComputedAreaResponse>(`/tables/computed-area/${computedArea.id}`, computedArea, { headers: { "x-air-version": versionTag } });
  return result.data.data;
};

export const deleteComputedArea = async (computedArea: ComputedArea, versionTag: string) => {
  const apiClient = getAPIClient();
  const result = await apiClient!.delete<DeleteComputedAreaResponse>(`/tables/computed-area/${computedArea.id}`, { headers: { "x-air-version": versionTag } });
  return result.data.data;
};

export const getComputedAreaPoints = async (computedArea: ComputedArea, versionTag: string) => {
  const apiClient = getAPIClient();
  const result = await apiClient!.get<GetComputedAreaPointsResponse>(`/tables/computed-area/${computedArea.id}/points`, { headers: { "x-air-version": versionTag } });
  return result.data.data;
};
