import { GraphicAttributeTypeValues } from "src/types/Types";
import { Validator } from "./Validator";

class GraphicsAttributeValidator extends Validator {
  validateGraphicAttributeType = (fieldName: string, fieldValue: any) => {
    if (!GraphicAttributeTypeValues.includes(fieldValue)) {
      this.validationMap.set(fieldName, true);
      return "Invalid value";
    }
    this.validationMap.set(fieldName, false);
    return "";
  };

  validateField = (fieldName: string, fieldValue: any) => {
    switch (fieldName) {
      case "name":
        return this.validateName(fieldName, fieldValue);
      case "priority":
        return this.validatePositiveNumber("priority", fieldValue);
      case "emdisCode":
        return this.validatePositiveNumber("emdisCode", fieldValue);
      case "size":
        return this.validatePositiveNumber("size", fieldValue);
      case "legalLineStyle":
        return this.validatePositiveNumber("legalLineStyle", fieldValue);
      case "legalSymbol":
        return this.validatePositiveNumber("legalSymbol", fieldValue);
      case "type":
        return this.validateGraphicAttributeType("type", fieldValue);
      default:
        return "";
    }
  };
}

export default GraphicsAttributeValidator;
