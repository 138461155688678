import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import { isUndefined } from "lodash";
import { useState } from "react";
import Split from "react-split";
import useHoldings from "src/apis/holdings/useHoldings";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import useHoldingColumns from "src/columnsgenerators/useHoldingColumns";
import AdditionalInformationGrid from "src/components/datagrids/AdditionalInformationGrid";
import GenericDataGrid from "src/components/datagrids/GenericDataGrid";
import XRefGrid from "src/components/datagrids/XRefGrid";
import { AdditionalInformation, Holding } from "src/types/Types";
import HoldingValidator from "src/validators/HoldingValidator";

const Holdings = () => {
  const versionTag = useVersionTag();
  const { holdings, selectedHolding, selectHolding, createHolding, updateHolding, deleteHolding, addInformation, updateInformation, deleteInformation } = useHoldings();
  const { getColumns } = useHoldingColumns();

  const [currentTab, setCurrentTab] = useState("additionalInformation");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  const createEmptyRow = () => {
    return {
      id: -1,
      name: "",
      description: "",
      fix: "",
      inboundTrack: null,
      outboundLeg: null,
      radial: null,
      navAid: null,
      frequency: null,
      legTime: null,
      legTimeMinuteSeconds: null,
      turnClockWise: true,
      minimumLevel: null,
      maximumLevel: null,
      maximumAirSpeed: 0,
      minimumReceptionAltitude: null,
      additionalInformation: [],
      xrefs: []
    };
  };

  const onCreateHolding = async (holding: Holding) => {
    return createHolding(holding);
  };

  const onUpdateHolding = async (holding: Holding) => {
    return updateHolding(holding);
  };

  const onDeleteHolding = async (holding: Holding) => {
    return deleteHolding(holding);
  };

  const onSelectHolding = async (holding: Holding) => {
    selectHolding(holding);
  };

  const onAddInformation = async (info: AdditionalInformation) => {
    return addInformation(selectedHolding!, info);
  };

  const onUpdateInformation = async (info: AdditionalInformation) => {
    return updateInformation(selectedHolding!, info);
  };

  const onDeleteInformation = async (info: AdditionalInformation) => {
    return deleteInformation(info);
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Holdings - {versionTag}</h2>
      <Split style={{ height: "calc(100vh - 230px)" }} direction="vertical">
        <div style={{ height: "100%" }}>
          <GenericDataGrid<Holding>
            data={holdings}
            selectedRow={selectedHolding}
            columnsGenerator={getColumns}
            createEmptyRow={createEmptyRow}
            validator={new HoldingValidator()}
            onCreateRow={onCreateHolding}
            onUpdateRow={onUpdateHolding}
            onDeleteRow={onDeleteHolding}
            onSelectRow={onSelectHolding}
            sortModel={[{ field: "name", sort: "asc" }]}
          />
        </div>
        <div style={{ height: "100%" }}>
          <TabContext value={currentTab}>
            <Tabs value={currentTab} onChange={handleChange}>
              <Tab value="additionalInformation" label="Additional information" />
              <Tab value="xref" label="xref" />
            </Tabs>
            <TabPanel value="additionalInformation" style={{ height: "100%" }}>
              <div style={{ height: "90%", display: "flex", flexDirection: "row" }}>
                <div style={{ height: "90%", flex: 1, marginRight: "5px" }}>
                  <AdditionalInformationGrid
                    infos={selectedHolding ? selectedHolding.additionalInformation : []}
                    onAddInformation={onAddInformation}
                    onUpdateInformation={onUpdateInformation}
                    onDeleteInformation={onDeleteInformation}
                    canInsertRow={!isUndefined(selectedHolding)}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel value="xref" style={{ height: "100%" }}>
              <div style={{ height: "90%", display: "flex", flexDirection: "row" }}>
                <div style={{ height: "90%", flex: 1, marginRight: "5px" }}>
                  <XRefGrid xrefs={selectedHolding && selectedHolding.xrefs ? selectedHolding.xrefs : []} />
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </Split>
    </div>
  );
};

export default Holdings;
