import React, { useRef, useState } from "react";
import usePointsColumns from "src/columnsgenerators/useLinePointsColumns";
import GenericDataGrid from "src/components/datagrids/GenericDataGrid";
import TextFieldEditor from "src/components/editors/TextFieldEditor";
import MapViewer from "src/components/mapviewer/MapViewer";
import UserTagSelector from "src/components/selectors/usertagselector/UserTagSelector";
import { CoordinatesList, Line, LinePoint } from "src/types/Types";
import BoundaryPointsValidator from "src/validators/BoundaryPointsValidator";
import useAddLineFormHandler from "./useAddLineFormHandler";

import * as _ from "lodash";
import { getWgs84Latitude, getWgs84Longitude } from "src/apis/waypoints/waypointsApi";
import CategoriesSelector from "src/components/selectors/categoriesselector/CategoriesSelector";

import { Button } from "@mui/material";
import { toast } from "react-toastify";
import PasteCoordinatesDialog from "src/components/dialogs/PasteCoordinatesDialog";

export type AddLineFormHandle = {
  requestSubmit: () => void;
};

type AddLineProps = {
  createLine: (line: Line) => Promise<Line>;
  onSuccess: () => void;
};

const AddLineForm: React.ForwardRefRenderFunction<AddLineFormHandle, AddLineProps> = (props, forwardedRef) => {
  const { createLine, onSuccess } = props;

  const formRef = useRef<HTMLFormElement>(null);
  const { formHandler } = useAddLineFormHandler(createLine, onSuccess);

  const { getColumns: getPointsColumns } = usePointsColumns(false);

  const [linePoints, setLinePoints] = useState<LinePoint[]>([]);

  const [showPasteCoordinatesDialog, setShowPasteCoordinatesDialog] = useState(false);

  const onShowPasteCoordinatesDialog = () => {
    setShowPasteCoordinatesDialog(true);
  };

  const onCancelPasteCoordinates = () => {
    setShowPasteCoordinatesDialog(false);
  };

  React.useImperativeHandle(forwardedRef, () => ({
    requestSubmit() {
      if (formRef.current) {
        formHandler.setFieldValue("points", linePoints);
        formRef.current.requestSubmit();
      }
    }
  }));

  const onCreateRow = async (row: LinePoint) => {
    row.id = _.uniqueId(); /* to make sure row is unique in grid */
    row.wgs84Latitude = await getWgs84Latitude(row.dmsLatitude);
    row.wgs84Longitude = await getWgs84Longitude(row.dmsLongitude);

    setLinePoints([...linePoints, row]);
    return Promise.resolve(row);
  };

  const onUpdateRow = async (row: LinePoint) => {
    row.wgs84Latitude = await getWgs84Latitude(row.dmsLatitude);
    row.wgs84Longitude = await getWgs84Longitude(row.dmsLongitude);

    let copy = _.cloneDeep(linePoints);
    copy = copy.filter((lp) => lp.id !== row.id);
    copy = [...copy, row];
    setLinePoints(copy);
    return Promise.resolve(row);
  };

  const onDeleteRow = (row: LinePoint) => {
    let copy = _.cloneDeep(linePoints);
    copy = copy.filter((lp) => lp.id !== row.id);
    for (let i = 0; i < copy.length; i++) {
      copy[i].sequence = i + 1;
    }
    setLinePoints(copy);
    return Promise.resolve();
  };

  const onCreateEmptyLinePointsRow = () => {
    return {
      id: -1 /* to make sure row is unique in grid */,
      lineId: -1,
      sequence: linePoints.length + 1,
      wgs84Latitude: 0,
      wgs84Longitude: 0,
      dmsLatitude: "",
      dmsLongitude: "",
      curvature: "0",
      inverted: false
    };
  };

  const onAddPasteCoordinates = (coordinatesList: CoordinatesList) => {
    const pastedPoints: LinePoint[] = coordinatesList.coordinates.map((c) => {
      return {
        id: _.uniqueId(),
        lineId: -1,
        wgs84Latitude: c.wgs84Latitude,
        wgs84Longitude: c.wgs84Longitude,
        dmsLatitude: c.dmsLatitude,
        dmsLongitude: c.dmsLongitude,
        sequence: -1,
        curvature: "0",
        inverted: false,
        buffer: 0
      };
    });
    for (let i = 0; i < pastedPoints.length; i++) {
      pastedPoints[i].sequence = linePoints.length + i + 1;
    }
    setLinePoints([...linePoints, ...pastedPoints]);
    setShowPasteCoordinatesDialog(false);
    toast.success("Coordinates added");
  };

  return (
    <form ref={formRef} onSubmit={formHandler.handleSubmit}>
      {showPasteCoordinatesDialog && <PasteCoordinatesDialog onCancel={onCancelPasteCoordinates} onAddCoordinates={onAddPasteCoordinates} />}
      <div style={{ width: "1400px", height: "700px", display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1.2, display: "flex", flexDirection: "column" }}>
          <h3>General information</h3>
          <div style={{ display: "flex" }}>
            <div>
              <TextFieldEditor
                label={"Name"}
                hasError={formHandler.touched.name && Boolean(formHandler.errors.name)}
                helperText={formHandler.touched.name && formHandler.errors.name}
                onChange={(newValue) => {
                  formHandler.setFieldValue("name", newValue);
                }}
              />
            </div>
            <div style={{ marginLeft: 10, flex: 1.5 }}>
              <TextFieldEditor
                label={"Description"}
                onChange={(newValue) => {
                  formHandler.setFieldValue("description", newValue);
                }}
              />
            </div>
          </div>
          <div style={{ marginTop: 5 }}>
            <CategoriesSelector
              onChange={(newValue) => {
                formHandler.setFieldValue("category", newValue);
              }}
            />
          </div>
          <div style={{ marginTop: 5 }}>
            <UserTagSelector
              currentSelection={formHandler.values.userTags}
              onChange={(newValue) => {
                formHandler.setFieldValue("userTags", newValue);
              }}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h3>Line points</h3>
            <div style={{ marginBottom: "5px" }}>
              <Button onClick={onShowPasteCoordinatesDialog}>Paste coordinates...</Button>
            </div>
          </div>
          <div style={{ flexGrow: 1 }}>
            <GenericDataGrid<LinePoint>
              columnsGenerator={getPointsColumns}
              createEmptyRow={onCreateEmptyLinePointsRow}
              data={linePoints}
              validator={new BoundaryPointsValidator()}
              onCreateRow={onCreateRow}
              onUpdateRow={onUpdateRow}
              onDeleteRow={onDeleteRow}
              sortModel={[{ field: "sequence", sort: "asc" }]}
            />
          </div>
        </div>
        <div style={{ flex: 1, height: "100%", marginLeft: "20px" }}>
          <MapViewer linePoints={linePoints} />
        </div>
      </div>
    </form>
  );
};

export default React.forwardRef(AddLineForm);
