import { GridPreProcessEditCellProps, GridRenderCellParams } from "@mui/x-data-grid-pro";
import DataGridTextFieldEditor from "src/components/editors/DataGridTextFieldEditor";
import DatagridCategoriesSelector from "src/components/selectors/categoriesselector/DatagridCategoriesSelector";
import DataGridUserTagSelector from "src/components/selectors/usertagselector/DataGridUserTagSelector";
import { ColumnsGenerator } from "src/types/Types";
import { FieldsValidator } from "src/validators/Validator";
import { customUserTagFilteringOperators } from "./customUserTagFilteringOperators";
import { userTagGridComparator } from "./customUserTagGridComparator";

const useLineColumns = () => {
  const getColumns: ColumnsGenerator = (validator: FieldsValidator, onFieldDirty: () => void) => {
    return [
      {
        field: "name",
        headerName: "Name",
        editable: true,
        width: 200,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("name", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        }
      },
      { field: "description", headerName: "Description", editable: true, width: 200 },
      {
        field: "category",
        headerName: "Category",
        editable: true,
        width: 200,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DatagridCategoriesSelector {...params} />;
        }
      },
      {
        field: "userTags",
        headerName: "User tags",
        editable: true,
        width: 300,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridUserTagSelector {...params} />;
        },
        filterOperators: customUserTagFilteringOperators,
        sortComparator: userTagGridComparator
      }
    ];
  };
  return { getColumns };
};

export default useLineColumns;
