import { GridRowId } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { toast } from "react-toastify";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import useMaps from "src/apis/maps/useMaps";
import { useAppContext } from "src/appcontext/AppContext";
import { MenuItem } from "src/types/Types";
import MenusGrid from "./datagrids/MenusGrid";
import CreateMenuDialog from "./dialogs/CreateMenuDialog";
import MapEditor from "./mapeditor/MapEditor";
import MoveMenuItemToDialog from "./dialogs/MoveItemToDialog";

const Menus = () => {
  const versionTag = useVersionTag();
  const appContext = useAppContext();

  const {
    loading,
    clientTypes,
    paths,
    menuItems,
    currentMenuItem,
    selectMenuItem,
    getNextSequenceValue,
    createMap,
    updateMap,
    deleteMap,
    deleteMapReference,
    createMenu,
    deleteMenu,
    moveSelectedMenuItemUp,
    moveSelectedMenuItemDown,
    moveSelectedMenuItemTo
  } = useMaps();

  const [editedMap, setEditedMap] = useState<MenuItem>();
  const [displayMapEditor, setDisplayMapEditor] = useState(false);
  const [displayCreateMenuDialog, setDisplayCreateMenuDialog] = useState(false);
  const [displayMoveItemToDialog, setDisplayMoveItemToDialog] = useState(false);

  const onCreateMap = () => {
    setDisplayMapEditor(true);
  };

  const onUpdateMap = (radarMap: MenuItem) => {
    setEditedMap(radarMap);
    setDisplayMapEditor(true);
  };

  const onMenuItemSelected = (rowId: GridRowId) => {
    selectMenuItem(rowId);
  };

  const onDeleteMap = (radarMap: MenuItem) => {
    return deleteMap(radarMap)
      .then(() => {
        toast.success("Map deleted: " + radarMap.path + "/" + radarMap.name);
      })
      .catch((error) => {
        if (error) {
          toast.error("Failed to delete map: " + JSON.stringify(error));
        } else {
          toast.error("Failed to delete map: " + radarMap.id);
        }
      });
  };

  const onDeleteMenu = (menu: MenuItem) => {
    return deleteMenu(menu)
      .then(() => {
        toast.success("Menu deleted: " + menu.path.join("/"));
      })
      .catch((error) => {
        if (error) {
          toast.error("Failed to delete menu: " + JSON.stringify(error));
        } else {
          toast.error("Failed to delete menu: " + menu.id);
        }
      });
  };

  const onMoveSelectedMenuItemDown = () => {
    const confirmationMessage = currentMenuItem?.menuItemType === "MENU" ? "Menu has been moved" : "Map has been moved";
    const errorMessage = currentMenuItem?.menuItemType === "MENU" ? "Failed to move menu" : "Failed to move map";
    return moveSelectedMenuItemDown()
      .then(() => {
        toast.success(`${confirmationMessage}: ${currentMenuItem?.path.join("/")}`);
      })
      .catch((error: any) => {
        if (error) {
          toast.error(`${errorMessage}: ${JSON.stringify(error)}`);
        } else {
          toast.error(`${errorMessage}: ${currentMenuItem?.id}`);
        }
      });
  };

  const onMoveSelectedMenuItemUp = () => {
    const confirmationMessage = currentMenuItem?.menuItemType === "MENU" ? "Menu has been moved" : "Map has been moved";
    const errorMessage = currentMenuItem?.menuItemType === "MENU" ? "Failed to move menu" : "Failed to move map";
    return moveSelectedMenuItemUp()
      .then(() => {
        toast.success(`${confirmationMessage}: ${currentMenuItem?.path.join("/")}`);
      })
      .catch((error: any) => {
        if (error) {
          toast.error(`${errorMessage}: ${JSON.stringify(error)}`);
        } else {
          toast.error(`${errorMessage}: ${currentMenuItem?.id}`);
        }
      });
  };

  const onMapCreated = (map: MenuItem) => {
    setDisplayMapEditor(false);
    toast.success("Map saved");
  };

  const onMapUpdated = (map: MenuItem) => {
    setDisplayMapEditor(false);
    setEditedMap(undefined);
    toast.success("Map updated");
  };

  const onCancelMap = () => {
    setDisplayMapEditor(false);
    setEditedMap(undefined);
  };

  const onCreateMenu = () => {
    setDisplayCreateMenuDialog(true);
  };

  const onCancelCreateMenu = () => {
    setDisplayCreateMenuDialog(false);
  };

  const onConfirmCreateMenu = (path: string, menuName: string) => {
    createMenu(path, menuName)
      .then((response) => {
        appContext.dispatch({
          type: "newMenusGridRowCreated",
          payload: response.id
        });

        toast.success("Menu created");
        setDisplayCreateMenuDialog(false);
      })
      .catch((error) => {
        if (error.errorMessage) {
          toast.error(error.errorMessage);
        } else {
          toast.error("Failed to create menu");
        }
      });
  };

  const onMoveMenuItemTo = (destinationPath: string, moveItemToTop: boolean) => {
    return moveSelectedMenuItemTo(destinationPath, moveItemToTop)
      .then(() => {
        setDisplayMoveItemToDialog(false);
        toast.success("Item moved");
      })
      .catch((error) => {
        toast.error("Failed to move item");
      });
  };

  const onDisplayMoveMenuItemTo = () => {
    setDisplayMoveItemToDialog(true);
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Menus - {versionTag}</h2>
      {displayCreateMenuDialog && (
        <CreateMenuDialog
          paths={paths}
          initialPath={currentMenuItem?.menuItemType === "MENU" ? currentMenuItem.path.join("/") : "/"}
          onCancel={onCancelCreateMenu}
          onCreateMenu={onConfirmCreateMenu}
        />
      )}
      {displayMoveItemToDialog && (
        <MoveMenuItemToDialog
          paths={paths}
          initialPath={""}
          selectedMenuItem={currentMenuItem}
          onCancelMoveMenuItemTo={() => {
            setDisplayMoveItemToDialog(false);
          }}
          onMoveMenuItemTo={onMoveMenuItemTo}
        />
      )}
      <MapEditor
        paths={paths}
        clientTypes={clientTypes}
        createMap={createMap}
        updateMap={updateMap}
        getNextSequenceValue={getNextSequenceValue}
        onMapCreated={onMapCreated}
        onMapUpdated={onMapUpdated}
        onMapCanceled={onCancelMap}
        open={displayMapEditor}
        map={editedMap}
      />

      <div style={{ height: "90%" }}>
        <MenusGrid
          loading={loading}
          menuItems={menuItems}
          selectedMenuItem={currentMenuItem}
          onMenuItemSelected={onMenuItemSelected}
          onCreateMenu={onCreateMenu}
          onDeleteMenu={onDeleteMenu}
          onCreateMap={onCreateMap}
          onUpdateMap={onUpdateMap}
          onDeleteMap={onDeleteMap}
          onMoveSelectedMenuItemUp={onMoveSelectedMenuItemUp}
          onMoveSelectedMenuItemDown={onMoveSelectedMenuItemDown}
          onDisplayMoveMenuItemTo={onDisplayMoveMenuItemTo}
          onDeleteMapElementReference={deleteMapReference}
        />
      </div>
    </div>
  );
};

export default Menus;
