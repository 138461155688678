import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { CreateEntityForm, CreateEntityFormHandle, CreateEntityFormProps } from "src/components/dialogs/CreateEntityDialog";
import FrequencyEditor from "src/components/editors/FrequencyEditor";
import LatitudeMask from "src/components/editors/LatitudeMask";
import LongitudeMask from "src/components/editors/LongitudeMask";
import OrientationMask from "src/components/editors/OrientationMask";
import TextFieldEditor from "src/components/editors/TextFieldEditor";
import AorSelector from "src/components/selectors/aorselector/AorSelector";
import ReferenceTypeSelector, { MapElementReference } from "src/components/selectors/referencetypeselector/ReferenceTypeSelector";
import UserTagSelector from "src/components/selectors/usertagselector/UserTagSelector";
import { Point } from "src/types/Types";
import useAddPointFormHandler from "./useAddPointFormHandler";

const AddPointForm: CreateEntityForm<Point> = (props: CreateEntityFormProps<Point>, forwardedRef: React.ForwardedRef<CreateEntityFormHandle>) => {
  const { createEntity, onEntityCreated } = props;
  const formRef = useRef<HTMLFormElement>(null);

  const { formHandler } = useAddPointFormHandler(createEntity, onEntityCreated);

  React.useImperativeHandle(forwardedRef, () => ({
    requestSubmit() {
      formRef.current?.requestSubmit();
    }
  }));

  const onChangeReference = (reference: MapElementReference | undefined) => {
    if (reference) {
      formHandler.setFieldValue("referenceType", reference.referenceType);
      // strip the airport prefix
      if (reference.referenceType === "SID") {
        reference.name = reference.name.substring(5);
      }
      formHandler.setFieldValue("reference", reference.name);
      formHandler.setFieldValue("latitude", reference?.dmsLatitude ? reference.dmsLatitude : "");
      formHandler.setFieldValue("longitude", reference?.dmsLongitude ? reference.dmsLongitude : "");
    } else {
      formHandler.setFieldValue("referenceType", "");
      formHandler.setFieldValue("reference", "");
      formHandler.setFieldValue("latitude", "");
      formHandler.setFieldValue("longitude", "");
    }
  };

  // this is required to force an update of the fields when the
  // reference type is changed
  useEffect(() => {
    if (formHandler.values.latitude.length > 0) {
      formHandler.setTouched({ ["latitude"]: true });
    }
  }, [formHandler.values.latitude]);

  useEffect(() => {
    if (formHandler.values.longitude.length > 0) {
      formHandler.setTouched({ ["longitude"]: true });
    }
  }, [formHandler.values.longitude]);

  return (
    <form ref={formRef} onSubmit={formHandler.handleSubmit}>
      <div style={{ width: "600px", height: "600px", display: "flex", flexDirection: "row" }}>
        <div style={{ marginLeft: 60 }}>
          <h3>General information</h3>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ marginLeft: "10px", flex: 1 }}>
              <TextFieldEditor
                label="Name"
                showHelperTextAsTooltip={false}
                hasError={formHandler.touched.name && Boolean(formHandler.errors.name)}
                helperText={formHandler.touched.name && formHandler.errors.name}
                onChange={(newValue) => {
                  formHandler.setFieldValue("name", newValue);
                }}
              />
            </div>
            <div style={{ marginLeft: "10px", flex: 2 }}>
              <TextFieldEditor
                label="Description"
                value={formHandler.values.description}
                showHelperTextAsTooltip={false}
                onChange={(newValue) => {
                  formHandler.setFieldValue("description", newValue);
                }}
              />
            </div>
          </div>
          <h3>Reference</h3>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ marginLeft: "10px", flex: 1 }}>
              <ReferenceTypeSelector showLabels onChange={onChangeReference} />
            </div>
          </div>
          <h3>Coordinates</h3>
          <div style={{ display: "flex", marginLeft: "10px" }}>
            <div style={{ flex: 0.3 }}>
              <LatitudeMask
                value={formHandler.values.latitude}
                onChange={(newValue) => {
                  formHandler.setFieldValue("latitude", newValue);
                }}
                error={formHandler.touched.latitude && Boolean(formHandler.errors.latitude)}
                helperText={formHandler.touched.latitude && formHandler.errors.latitude}
                showHelperTextAsTooltip={false}
              />
            </div>
            <div style={{ marginLeft: "15px", flex: 0.3 }}>
              <LongitudeMask
                value={formHandler.values.longitude}
                onChange={(newValue) => {
                  formHandler.setFieldValue("longitude", newValue);
                }}
                error={formHandler.touched.longitude && Boolean(formHandler.errors.longitude)}
                helperText={formHandler.touched.longitude && formHandler.errors.longitude}
                showHelperTextAsTooltip={false}
              />
            </div>
          </div>
          <h3>Other</h3>
          <div style={{ display: "flex", marginLeft: "10px" }}>
            <div style={{ flex: 0.3 }}>
              <TextFieldEditor
                value={formHandler.values.orientation}
                label="Orientation"
                onChange={(newValue) => formHandler.setFieldValue("orientation", newValue)}
                hasError={formHandler.touched.longitude && Boolean(formHandler.errors.orientation)}
                helperText={formHandler.touched.longitude && formHandler.errors.orientation}
              />
            </div>
            <div style={{ marginLeft: "40px", flex: 0.6 }}>
              <AorSelector label="A.o.R" value={formHandler.values.areasOfResponsibility} onChange={(newValue) => formHandler.setFieldValue("areasOfResponsibility", newValue)} />
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "10px", marginLeft: "10px" }}>
            <div style={{ flex: 0.3 }}>
              <FrequencyEditor
                value={formHandler.values.frequency}
                onChange={(newValue) => {
                  formHandler.setFieldValue("frequency", newValue);
                }}
                error={formHandler.touched.frequency && Boolean(formHandler.errors.frequency)}
                helperText={formHandler.touched.frequency && formHandler.errors.frequency}
              />
            </div>
            <div style={{ flex: 0.5, marginLeft: "40px", marginTop: "10px" }}>
              <FormControlLabel
                onChange={(event, checked) => {
                  formHandler.setFieldValue("exportShortName", checked);
                }}
                control={<Checkbox value={formHandler.values.exportShortName} />}
                label="Export short name"
              />
            </div>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <UserTagSelector
              currentSelection={formHandler.values.userTags}
              onChange={(newValue) => {
                formHandler.setFieldValue("userTags", newValue);
              }}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default React.forwardRef(AddPointForm);
