import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import AlertIcon from "@mui/icons-material/ErrorOutline";

type RemoveMapElementReferencesDialogProps = {
  onConfirm: () => void;
  onCancel: () => void;
};

const RemoveMapElementReferencesDialog = (props: RemoveMapElementReferencesDialogProps) => {
  const { onConfirm, onCancel } = props;
  return (
    <Dialog maxWidth="xl" open={true}>
      <DialogTitle>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ color: "orange", fontSize: "32px" }}>
            <AlertIcon style={{ fontSize: "inherit" }} />
          </div>
          <div style={{ marginLeft: "10px", marginBottom: "10px" }}>{"Delete references?"}</div>
        </div>
      </DialogTitle>
      <DialogContent>Please confirm references removal</DialogContent>
      <DialogActions>
        <Button autoFocus variant="contained" onClick={onConfirm}>
          Remove
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveMapElementReferencesDialog;
