import { TextField } from "@mui/material";
import { GridFilterInputValueProps, GridFilterItem, GridFilterOperator } from "@mui/x-data-grid-pro";

const CustomFilterInputSingleSelect = (props: GridFilterInputValueProps) => {
  const { item, applyValue, apiRef, focusElementRef, ...others } = props;

  return (
    <div>
      <TextField
        id={`filter-input-${item.id}`}
        label="Value"
        placeholder="Filter value"
        value={item.value}
        onChange={(event) => applyValue({ ...item, value: event.target.value })}
        type={"text"}
        variant="standard"
        InputLabelProps={{
          shrink: true
        }}
        inputRef={focusElementRef}
        //select
        SelectProps={
          {
            // native: true
          }
        }
      ></TextField>
    </div>
  );
};

export const customUserTagFilteringOperators: GridFilterOperator[] = [
  {
    value: "contains",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
        return null;
      }
      return ({ value }) => {
        return value.some((cellValue: string) => cellValue.indexOf(filterItem.value.toUpperCase()) > -1);
      };
    },
    InputComponent: CustomFilterInputSingleSelect
  },
  {
    value: "equals",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
        return null;
      }
      return ({ value }): boolean => {
        return value.length === 1 && value.some((cellValue: string) => cellValue === filterItem.value.toUpperCase());
      };
    },
    InputComponent: CustomFilterInputSingleSelect
  },
  {
    label: "starts with",
    value: "startswith",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
        return null;
      }
      return ({ value }) => {
        return value.some((cellValue: string) => cellValue.startsWith(filterItem.value.toUpperCase()));
      };
    },
    InputComponent: CustomFilterInputSingleSelect
  },
  {
    label: "ends with",
    value: "endswith",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
        return null;
      }
      return ({ value }) => {
        return value.some((cellValue: string) => cellValue.endsWith(filterItem.value.toUpperCase()));
      };
    },
    InputComponent: CustomFilterInputSingleSelect
  },
  {
    label: "is empty",
    value: "empty",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      return ({ value }) => {
        return value.length === 0;
      };
    }
  },
  {
    label: "is not empty",
    value: "notempty",
    getApplyFilterFn: (filterItem) => {
      return ({ value }) => {
        return value.length > 0;
      };
    }
  }
];
