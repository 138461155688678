import { LatLng } from "leaflet";
import { useEffect, useState } from "react";
import { Polyline } from "react-leaflet";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { getWaypointsFromNameAndArea } from "src/apis/waypoints/waypointsApi";
import { Airway, Waypoint } from "src/types/Types";

type AirwayMarkerProps = {
  airway: Airway;
};

const AirwayMarker = (props: AirwayMarkerProps) => {
  const { airway } = props;
  const defaultColor = "#3388ff";
  const version = useVersionTag();

  const [waypoints, setWaypoints] = useState<Waypoint[]>([]);

  useEffect(() => {
    const nameAndAreas = airway.airwayPoints
      .slice()
      .sort((a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0))
      .map((ap) => {
        return { name: ap.waypointName, area: ap.waypointArea };
      });
    getWaypointsFromNameAndArea(version, nameAndAreas).then((result) => {
      setWaypoints(result);
    });
  }, [airway]);

  return (
    <Polyline
      key={`${airway.id}${airway.color ? airway.color : ""}`}
      pathOptions={{ color: airway.color ? airway.color : defaultColor, weight: 1 }}
      positions={waypoints.map((wp) => {
        return new LatLng(wp.wgs84Latitude, wp.wgs84Longitude);
      })}
    />
  );
};

export default AirwayMarker;
