import { GridColumns, GridPreProcessEditCellProps, GridRenderCellParams } from "@mui/x-data-grid-pro";
import DataGridBufferMask from "src/components/editors/DataGridBufferMask";
import DataGridLatitudeMask from "src/components/editors/DataGridLatitudeMask";
import DataGridLongitudeMask from "src/components/editors/DataGridLongitudeMask";
import DataGridTextFieldEditor from "src/components/editors/DataGridTextFieldEditor";
import DatagridCategoriesSelector from "src/components/selectors/categoriesselector/DatagridCategoriesSelector";
import DataGridUserTagSelector from "src/components/selectors/usertagselector/DataGridUserTagSelector";
import { ColumnsGenerator } from "src/types/Types";
import { customUserTagFilteringOperators } from "./customUserTagFilteringOperators";
import { userTagGridComparator } from "./customUserTagGridComparator";

const useComputedAreaColumns = () => {
  const getColumns: ColumnsGenerator = (validator, onFieldDirty) => {
    const columns: GridColumns = [
      {
        field: "name",
        headerName: "Name",
        width: 200,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("name", params.props.value, params.id);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        }
      },
      {
        field: "description",
        headerName: "Description",
        width: 200,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        }
      },
      {
        field: "category",
        headerName: "Category",
        width: 200,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DatagridCategoriesSelector {...params} />;
        }
      },
      {
        field: "buffer",
        headerName: "Buffer",
        width: 100,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("buffer", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridBufferMask {...params} />;
        }
      },
      {
        field: "emdisCenterDmsLatitude",
        headerName: "EMDIS center lat.",
        width: 200,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridLatitudeMask {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("emdisCenterDmsLatitude", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "emdisCenterDmsLongitude",
        headerName: "EMDIS center long.",
        width: 200,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridLongitudeMask {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("emdisCenterDmsLongitude", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "userTags",
        headerName: "User tags",
        editable: true,
        width: 200,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridUserTagSelector {...params} />;
        },
        filterOperators: customUserTagFilteringOperators,
        sortComparator: userTagGridComparator
      }
    ];
    return columns;
  };
  return { getColumns };
};

export default useComputedAreaColumns;
