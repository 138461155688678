import { CoordinatesList, DmsOptions, RawCoordinatesData } from "src/types/Types";
import { getAPIClient } from "../apiClient";

type CoordinatesDataResponse = {
  data: CoordinatesList;
  errorMessage: string;
};

type CoordinatesExampleResponse = {
  data: string;
  errorMessage: string;
};

export const convertCoordinates = async (rawCoordinates: RawCoordinatesData) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<CoordinatesDataResponse>("/coordinates/convert", rawCoordinates);
  return response.data.data;
};

export const getCoordinatesExample = async (coordinateType: "luciad" | "dms" | "decimal", dmsOptions?: DmsOptions) => {
  const _apiClient = getAPIClient();
  let pathVariables: string | undefined;
  if (dmsOptions) {
    pathVariables = `fixed=${dmsOptions.fixed}&delimited=${dmsOptions.delimited}&precise=${dmsOptions.precise}&oriented=${dmsOptions.oriented}`;
  }
  const response = await _apiClient!.get<CoordinatesExampleResponse>(`/coordinates/example/${coordinateType}${pathVariables ? "?" + pathVariables : ""}`);
  return response.data.data;
};
