import { useEffect, useState } from "react";
import { AdditionalInformation, DamParameter } from "src/types/Types";
import { useVersionTag } from "../hooks/useVersionTag";
import {
  getDamParameters,
  createDamParameter as _createDamParameter,
  updateDamParameter as _updateDamParameter,
  deleteDamParameter as _deleteDamParameter,
  createDamParameterInfo as _createDamParameterInfo,
  updateDamParameterInfo as _updateDamParameterInfo,
  deleteDamParameterInfo as _deleteDamParameterInfo
} from "./damParametersApi";

import * as _ from "lodash";

const useDamParameters = () => {
  const versionTag = useVersionTag();
  const [damParameters, setDamParameters] = useState<DamParameter[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedDamParameter, setSelectedDamParameter] = useState<DamParameter>();

  useEffect(() => {
    setLoading(true);
    getDamParameters(versionTag)
      .then((result) => {
        setDamParameters(result);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [versionTag]);

  const selectDamParameter = (damParameter: DamParameter) => {
    setSelectedDamParameter(damParameter);
  };

  const createDamParameter = async (damParameter: DamParameter) => {
    const newDamParameter = await _createDamParameter(versionTag, damParameter);
    setDamParameters([...damParameters, newDamParameter]);
    setSelectedDamParameter(newDamParameter);
    return newDamParameter;
  };

  const updateDamParameter = async (damParameter: DamParameter) => {
    const newDamParameter = await _updateDamParameter(versionTag, damParameter);
    setDamParameters([...damParameters.filter((dp) => dp.id !== damParameter.id), newDamParameter]);
    setSelectedDamParameter(newDamParameter);
    return newDamParameter;
  };

  const deleteDamParameter = async (damParameter: DamParameter) => {
    await _deleteDamParameter(versionTag, damParameter);
    setDamParameters([...damParameters.filter((dp) => dp.id !== damParameter.id)]);
  };

  const createDamParameterInfo = async (damParameter: DamParameter, info: AdditionalInformation) => {
    const newInfo = await _createDamParameterInfo(versionTag, damParameter, info);
    const newArea = _.cloneDeep(damParameter);
    newArea.additionalInformation.push(newInfo);
    setDamParameters([...damParameters.filter((dp) => dp.id !== damParameter.id), newArea]);
    setSelectedDamParameter(newArea);
    return newInfo;
  };

  const updateDamParameterInfo = async (damParameter: DamParameter, info: AdditionalInformation) => {
    const newInfo = await _updateDamParameterInfo(versionTag, damParameter, info);
    const copy = _.cloneDeep(damParameter);
    copy.additionalInformation.filter((i) => i.id !== info.id);
    copy.additionalInformation.push(newInfo);
    setDamParameters([...damParameters.filter((a) => a.id !== damParameter.id), copy]);
    setSelectedDamParameter(copy);
    return newInfo;
  };

  const deleteDamParameterInfo = async (damParameter: DamParameter, info: AdditionalInformation) => {
    const newDamParameterPoints = await _deleteDamParameterInfo(versionTag, damParameter, info);
    const newDamParameter = _.cloneDeep(damParameter);
    newDamParameter.additionalInformation = newDamParameterPoints;
    setDamParameters([...damParameters.filter((dp) => dp.id !== damParameter.id), newDamParameter]);
    setSelectedDamParameter(newDamParameter);
  };

  return {
    loading,
    damParameters,
    selectDamParameter,
    selectedDamParameter,
    createDamParameter,
    updateDamParameter,
    deleteDamParameter,
    createDamParameterInfo,
    updateDamParameterInfo,
    deleteDamParameterInfo
  };
};

export default useDamParameters;
