import { useVersionTag } from "src/apis/hooks/useVersionTag";
import useMajorRoads from "src/apis/swisstopo/useMajorRoads";
import MapViewer from "src/components/mapviewer/MapViewer";

const MajorRoads = () => {
  const versionTag = useVersionTag();
  const { majorRoads } = useMajorRoads();

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Major roads - {versionTag}</h2>
      <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
        <div style={{ height: "100%", width: "75%" }}>
          <div style={{ height: "90%", minWidth: "60%", marginLeft: "5px", marginRight: "5px" }}>
            <MapViewer geoPolylines={majorRoads} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MajorRoads;
