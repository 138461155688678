import { useFormik } from "formik";
import { toast } from "react-toastify";
import { getWgs84LatLng } from "src/apis/waypoints/waypointsApi";
import { MapText } from "src/types/Types";
import * as yup from "yup";

type MapTextValueTypes = {
  name: string;
  description: string | null;
  latitude: string;
  longitude: string;
  userTags: string[];
};

const useMapTextFormHandler = (createMapText: (text: MapText) => Promise<MapText>, onSuccess: () => void) => {
  const validationSchema = yup.object({
    name: yup.string().required("Name cannot be empty"),
    latitude: yup
      .string()
      .required("Latitude cannot be empty")
      .test("latitude", "Invalid Latitude", (value) => {
        return !value?.includes("_");
      }),
    longitude: yup
      .string()
      .required("Longitude cannot be empty")
      .test("longitude", "Invalid Longitude", (value) => {
        return !value?.includes("_");
      })
  });

  const mapFormValuesToText = async (values: MapTextValueTypes) => {
    const latLng = await getWgs84LatLng(values.latitude, values.longitude);
    return {
      id: -1,
      text: values.name,
      description: values.description,
      wgs84Latitude: latLng.wgs84Latitude,
      wgs84Longitude: latLng.wgs84Longitude,
      dmsLatitude: values.latitude,
      dmsLongitude: values.longitude,
      userTags: values.userTags,
      additionalInformation: []
    };
  };

  const formik = useFormik<MapTextValueTypes>({
    initialValues: { name: "", description: null, latitude: '046°56´50.8416"N', longitude: '007°27´4.0428"E', userTags: [] },
    validationSchema: validationSchema,
    onSubmit: async (values, helpers) => {
      const mapText = await mapFormValuesToText(values);
      console.log("mapText: " + JSON.stringify(mapText));
      createMapText(mapText)
        .then(() => {
          onSuccess();
        })
        .catch((error) => {
          toast.error(error.toString());
        });
    }
  });

  return { formHandler: formik };
};

export default useMapTextFormHandler;
