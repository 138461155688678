import L from "leaflet";
import { isUndefined } from "lodash";
import ReactDOMServer from "react-dom/server";
import { Marker, Tooltip } from "react-leaflet";

import { MapText } from "src/types/Types";

type TextMarkerProps = {
  text: MapText;
  color?: string;
  tooltipOn?: boolean;
};

const TextMarker = (props: TextMarkerProps) => {
  const { text, color, tooltipOn = true } = props;
  const divIcon = L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(
      <div style={{ color: isUndefined(color) ? "#1565C0" : color }}>
        <div style={{ fontFamily: "Inter, sans-serif", fontSize: 10, width: "100px" }}>{text.text}</div>
      </div>
    )
  });

  return (
    <Marker icon={divIcon} position={[text.wgs84Latitude, text.wgs84Longitude]}>
      {tooltipOn && <Tooltip direction="right">{`[${text.dmsLatitude} ${text.dmsLongitude}]`}</Tooltip>}
    </Marker>
  );
};

export default TextMarker;
