import { Autocomplete, TextField } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";

const DatagridTurnSelector = (params: GridRenderEditCellParams) => {
  const apiRef = useGridApiContext();
  const { id, value, field } = params;
  const turnLeftRight = ["right", "left"];

  const handleChangeValue = (event: any, value: string | null) => {
    const newValue = value; // The new value entered by the user
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <div style={{ width: 500 }}>
      <Autocomplete
        size="small"
        id="turn"
        options={turnLeftRight}
        value={value === true ? "right" : "left"}
        renderInput={(params) => {
          return <TextField variant="standard" {...params} />;
        }}
        onChange={handleChangeValue}
      />
    </div>
  );
};

export default DatagridTurnSelector;
