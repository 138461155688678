import { FieldsValidator } from "./Validator";

class SymbolValidator implements FieldsValidator {
  validationMap = new Map<string, boolean>();

  isEmptyString = (s: string) => {
    return s === null || s === undefined || s.trim() === "";
  };

  hasValidationErrors() {
    const values = this.validationMap.values();
    return Array.from(values).includes(true);
  }

  validateField = (fieldName: string, fieldValue: any) => {
    switch (fieldName) {
      case "name": {
        if (this.isEmptyString(fieldValue)) {
          this.validationMap.set("name", true);
          return "Name cannot be empty";
        }
        this.validationMap.set("name", false);
        return "";
      }
      case "symbol": {
        if (this.isEmptyString(fieldValue)) {
          this.validationMap.set("symbol", true);
          return "Symbol cannot be empty";
        }
        this.validationMap.set("symbol", false);
        return "";
      }
      default:
        return "";
    }
  };
}

export default SymbolValidator;
