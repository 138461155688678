import { useEffect, useState } from "react";
import { AdditionalInformation, Area, AreaPoint, Xref } from "src/types/Types";
import { useVersionTag } from "../hooks/useVersionTag";
import {
  createArea as _createArea,
  createAreaInfo as _createAreaInfo,
  createAreaPoint as _createAreaPoint,
  createAreaPoints as _createAreaPoints,
  deleteArea as _deleteArea,
  deleteAreaInfo as _deleteAreaInfo,
  deleteAreaPoint as _deleteAreaPoint,
  deleteAreaPoints as _deleteAreaPoints,
  moveSelectedAreaPoints as _moveSelectedAreaPoints,
  updateArea as _updateArea,
  updateAreaInfo as _updateAreaInfo,
  updateAreaPoint as _updateAreaPoint,
  getAreaInfo,
  getAreaPoints,
  getAreas,
  getAreaXRefs
} from "./areasApi";

import * as _ from "lodash";
import { getWgs84LatLng } from "src/apis/waypoints/waypointsApi";

export const useAreas = () => {
  const versionTag = useVersionTag();
  const [areas, setAreas] = useState<Area[]>([]);
  const [currentArea, setCurrentArea] = useState<Area>();
  const [currentAreaAssociations, setCurrentAreaAssociations] = useState<Xref[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAreas(versionTag)
      .then((areas) => {
        setAreas(areas);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [versionTag]);

  const selectArea = async (area: Area) => {
    const points = await getAreaPoints(versionTag, area);
    const info = await getAreaInfo(versionTag, area);
    const associations = await getAreaXRefs(versionTag, area);

    const copy = _.cloneDeep(area);
    copy.areaPoints = points;
    copy.additionalInformation = info;
    copy.xrefs = associations;

    setAreas([...areas.filter((a) => a.id !== area.id), copy]);
    setCurrentArea(copy);
  };

  const createArea = async (area: Area) => {
    const newArea = await _createArea(versionTag, area);
    setAreas([...areas, newArea]);
    setCurrentArea(newArea);
    return newArea;
  };

  const deleteArea = async (area: Area) => {
    await _deleteArea(versionTag, area);
    setAreas([...areas.filter((a) => a.id !== area.id)]);
    setCurrentArea(undefined);
  };

  const updateArea = async (area: Area) => {
    const latLng = await getWgs84LatLng(area.emdisCenterDmsLatitude, area.emdisCenterDmsLongitude);
    area.emdisCenterWgs84Latitude = latLng.wgs84Latitude;
    area.emdisCenterWgs84Longitude = latLng.wgs84Longitude;
    const newArea = await _updateArea(versionTag, area);
    newArea.areaPoints = area.areaPoints;
    setAreas([...areas.filter((a) => a.id !== area.id), newArea]);
    setCurrentArea(newArea);
    return newArea;
  };

  const createAreaPoint = async (area: Area, areaPoint: AreaPoint) => {
    const latLng = await getWgs84LatLng(areaPoint.dmsLatitude, areaPoint.dmsLongitude);
    areaPoint.wgs84Latitude = latLng.wgs84Latitude;
    areaPoint.wgs84Longitude = latLng.wgs84Longitude;
    const areaPoints = await _createAreaPoint(versionTag, area, areaPoint);
    const newArea = _.cloneDeep(area);
    newArea.areaPoints = areaPoints;
    setAreas([...areas.filter((a) => a.id !== area.id), newArea]);
    setCurrentArea(newArea);
    return areaPoints[areaPoints.length - 1];
  };

  const createAreaPoints = async (area: Area, afterSequence: number, newPoints: AreaPoint[]) => {
    const newAreaPoints = await _createAreaPoints(versionTag, area, afterSequence, newPoints);
    const newArea = _.clone(area);
    newArea.areaPoints = newAreaPoints;
    setAreas([...areas.filter((a) => a.id !== area.id), newArea]);
    setCurrentArea(newArea);
    return newAreaPoints;
  };

  const updateAreaPoint = async (area: Area, areaPoint: AreaPoint) => {
    const latLng = await getWgs84LatLng(areaPoint.dmsLatitude, areaPoint.dmsLongitude);
    areaPoint.wgs84Latitude = latLng.wgs84Latitude;
    areaPoint.wgs84Longitude = latLng.wgs84Longitude;
    const areaPoints = await _updateAreaPoint(versionTag, area, areaPoint);
    const newArea = _.cloneDeep(area);
    newArea.areaPoints = areaPoints;
    setAreas([...areas.filter((a) => a.id !== area.id), newArea]);
    setCurrentArea(newArea);
    return areaPoints.filter((_lp) => _lp.id === areaPoint.id)[0];
  };

  const deleteAreaPoint = async (area: Area, areaPoint: AreaPoint) => {
    const newAreaPoints = await _deleteAreaPoint(versionTag, area, areaPoint);
    const newArea = _.cloneDeep(area);
    newArea.areaPoints = newAreaPoints;
    setAreas([...areas.filter((a) => a.id !== area.id), newArea]);
    setCurrentArea(newArea);
  };

  const deleteAreaPoints = async (area: Area, areaPoints: AreaPoint[]) => {
    const newAreaPoints = await _deleteAreaPoints(versionTag, area, areaPoints);
    const newArea = _.cloneDeep(area);
    newArea.areaPoints = newAreaPoints;
    setAreas([...areas.filter((a) => a.id !== area.id), newArea]);
    setCurrentArea(newArea);
  };

  const moveSelectedAreaPoints = async (area: Area, fromIndex: number, toIndex: number, selectedPointsSequenceNumbers: number[]) => {
    return _moveSelectedAreaPoints(versionTag, area, fromIndex, toIndex, selectedPointsSequenceNumbers).then((result) => {
      const copy = _.cloneDeep(area!);
      copy.areaPoints = result;
      setAreas([...areas.filter((a) => a.id !== area.id), copy]);
      setCurrentArea(copy);
    });
  };

  const createAreaInfo = async (area: Area, info: AdditionalInformation) => {
    const newInfo = await _createAreaInfo(versionTag, area, info);
    const newArea = _.cloneDeep(area);
    newArea.additionalInformation.push(newInfo);
    setAreas([...areas.filter((a) => a.id !== area.id), newArea]);
    setCurrentArea(newArea);
    return newInfo;
  };

  const updateAreaInfo = async (area: Area, info: AdditionalInformation) => {
    const newInfo = await _updateAreaInfo(versionTag, area, info);
    const copy = _.cloneDeep(area);
    copy.additionalInformation = copy.additionalInformation.filter((i) => i.id !== info.id);
    copy.additionalInformation.push(newInfo);
    setAreas([...areas.filter((a) => a.id !== area.id), copy]);
    setCurrentArea(copy);
    return newInfo;
  };

  const deleteAreaInfo = async (area: Area, info: AdditionalInformation) => {
    const newAreaPoints = await _deleteAreaInfo(versionTag, area, info);
    const newArea = _.cloneDeep(area);
    newArea.additionalInformation = newAreaPoints;
    setAreas([...areas.filter((a) => a.id !== area.id), newArea]);
    setCurrentArea(newArea);
  };

  return {
    loading,
    areas,
    currentArea,
    selectArea,
    createArea,
    updateArea,
    deleteArea,
    createAreaPoint,
    createAreaPoints,
    updateAreaPoint,
    deleteAreaPoint,
    deleteAreaPoints,
    moveSelectedAreaPoints,
    createAreaInfo,
    updateAreaInfo,
    deleteAreaInfo
  };
};
