import { GridColumns, GridPreProcessEditCellProps, GridRenderCellParams } from "@mui/x-data-grid-pro";
import DataGridTextFieldEditor from "src/components/editors/DataGridTextFieldEditor";
import { ColumnsGenerator } from "src/types/Types";

const useUserTagsColumns = () => {
  const getColumns: ColumnsGenerator = (validator, onFieldDirty) => {
    const columns: GridColumns = [
      {
        field: "name",
        headerName: "Name",
        editable: true,
        width: 200,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("name", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        }
      },
      {
        field: "description",
        headerName: "Description",
        width: 400,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        }
      }
    ];
    return columns;
  };
  return { getColumns };
};

export default useUserTagsColumns;
