import { MapSymbol } from "src/types/Types";
import { getAPIClient } from "../apiClient";

type GetMapSymbols = {
  data: MapSymbol[];
  errorMessage: string;
};

type UpdateMapSymbolResponse = {
  data: MapSymbol;
  errorMessage: string;
};

type createMapSymbolResponse = {
  data: MapSymbol;
  errorMessage: string;
};

type GetSymbolWithColorResponse = {
  data: MapSymbol;
  errorMessage: string;
};

export const getSymbols = async (versionTag: string) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.get<GetMapSymbols>("/tables/mapsymbol", { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const getSymbolWithColor = async (versionTag: string, symbolName: string, color: string) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.get<GetSymbolWithColorResponse>(encodeURI(`/tables/mapsymbol/${symbolName}?color=${color.replace("#", "")}`), {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const getOrientationSymbol = async (versionTag: string, orientation: string, color: string) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.get<GetSymbolWithColorResponse>(encodeURI(`/tables/mapsymbol/orientation/${orientation}?color=${color.replace("#", "")}`), {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const createSymbol = async (versionTag: string, symbol: MapSymbol) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.post<createMapSymbolResponse>(`/tables/mapsymbol`, symbol, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const updateSymbol = async (versionTag: string, symbol: MapSymbol) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.put<UpdateMapSymbolResponse>(`/tables/mapsymbol/${symbol.id}`, symbol, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const deleteSymbol = async (versionTag: string, symbol: MapSymbol) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.delete<number>(`/tables/mapsymbol/${symbol.id}`, { headers: { "x-air-version": versionTag } });
};
