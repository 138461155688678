import { TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";

type ElevationFieldEditorProps = {
  onElevationChanged: (elevation: string | null) => void;
  error?: boolean;
  helperText?: string | false;
};

const ElevationFieldEditor = (props: ElevationFieldEditorProps) => {
  const { onElevationChanged, error, helperText } = props;
  const [elevation, setElevation] = useState<string>();

  useEffect(() => {
    if (elevation) {
      onElevationChanged(elevation);
    } else {
      onElevationChanged(null);
    }
  }, [elevation]);

  return (
    <div>
      <TextField
        size="small"
        variant="standard"
        inputProps={{ min: 0, style: { textAlign: "center" } }}
        id="elevation"
        name="elevation"
        label="Elevation"
        autoComplete="off"
        value={elevation}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setElevation(event.target.value);
        }}
        error={error ? error : false}
        sx={{ input: { color: error ? "red" : "" } }}
        InputLabelProps={{ shrink: true }}
        helperText={helperText}
      />
    </div>
  );
};

export default ElevationFieldEditor;
