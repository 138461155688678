import { LatLng, LatLngExpression } from "leaflet";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { MapContainer } from "react-leaflet";
import {
  Airport,
  Airway,
  Area,
  ArrivalProcedure,
  ComputedArea,
  CountryOutline,
  DamParameter,
  DepartureProcedure,
  Highway,
  HighwayRamp,
  HighwayTunnel,
  Holding,
  Lake,
  Line,
  LinePoint,
  MajorRoad,
  MapText,
  Point,
  Railway,
  River,
  UrbanArea,
  Waypoint
} from "src/types/Types";
import WaypointMarker from "./layers/WaypointMarker";
import AirportMarker from "./layers/AirportMarker";
import AirwayMarker from "./layers/AirwayMarker";
import HoldingMarker from "./layers/HoldingMarker";
import PointMarker from "./layers/PointMarker";
import { Line as LineLayer } from "./layers/Line";
import { Area as AreaLayer } from "./layers/Area";
import ComputedAreaMarker from "./layers/ComputedAreaMarker";
import TextMarker from "./layers/TextMarker";
import DamParameterMarker from "./layers/DamParameterMarker";
import Lakes from "./layers/Lakes";
import UrbanAreas from "./layers/UrbanAreas";
import Highways from "./layers/Highways";
import HighwayRamps from "./layers/HighwayRamps";
import HighwayTunnels from "./layers/HighwayTunnels";
import Railways from "./layers/Railways";
import MajorRoads from "./layers/MajorRoads";
import Fullscreen from "react-leaflet-fullscreen-plugin";
import EditMapControl from "./controls/EditMapControl";
import { Backdrop, CircularProgress } from "@mui/material";
import CountryOutlines from "./layers/CountryOutlines";
import ShowTooltipControl from "./controls/ShowTooltipControl";
import DepartureOrArrivalProcedureMarker from "./layers/DepartureOrArrivalProcedureMarker";
import { getArrivalProcedurePointsFromId, getDepartureProcedurePointsFromId } from "src/apis/airports/airportsApi";
import Rivers from "./layers/Rivers";

type RadarMapViewerProps = {
  waypoints?: Waypoint[];
  airports?: Airport[];
  departureProcedures?: DepartureProcedure[];
  arrivalProcedures?: ArrivalProcedure[];
  airways?: Airway[];
  points?: Point[];
  holdings?: Holding[];
  texts?: MapText[];
  selectedText?: MapText;
  onTextMoved?: (text: MapText, newPosition: L.LatLng) => void;
  onTextClick?: (text: MapText) => void;
  damParameters?: DamParameter[];
  selectedDamParameter?: DamParameter;
  onDamParameterMoved?: (damParameter: DamParameter, newPosition: L.LatLng) => void;
  onDamParameterClick?: (damParameter: DamParameter) => void;
  lines?: Line[];
  selectedLine?: Line;
  onLineClick?: (line: Line) => void;
  onLinePointMoved?: (linePoint: LinePoint) => void;
  areas?: Area[];
  computedAreas?: ComputedArea[];
  lakes?: Lake[];
  urbanAreas?: UrbanArea[];
  highways?: Highway[];
  highwayRamps?: HighwayRamp[];
  highwayTunnels?: HighwayTunnel[];
  majorRoads?: MajorRoad[];
  railways?: Railway[];
  countryOutlines?: CountryOutline[];
  rivers?: River[];
  onStartEdit?: () => void;
  onCancelEdit?: () => void;
  onCommitEdit?: () => void;
  isInEdit?: boolean;
  loading?: boolean;
  highlightedItemId?: number;
};

export interface RadarMapViewerApi {
  invalidateSize: () => void;
}

const RadarMapViewer: React.ForwardRefRenderFunction<RadarMapViewerApi, RadarMapViewerProps> = (props: RadarMapViewerProps, forwardedRef) => {
  const {
    waypoints,
    airports,
    departureProcedures,
    arrivalProcedures,
    holdings,
    airways,
    points,
    texts,
    damParameters,
    onDamParameterClick,
    onDamParameterMoved,
    lines,
    areas,
    computedAreas,
    lakes,
    urbanAreas,
    highways,
    highwayRamps,
    highwayTunnels,
    majorRoads,
    railways,
    countryOutlines,
    rivers,
    onTextMoved,
    onTextClick,
    onLineClick,
    onLinePointMoved,
    onStartEdit,
    onCommitEdit,
    onCancelEdit,
    isInEdit = false,
    selectedText,
    selectedDamParameter,
    selectedLine,
    loading = true,
    highlightedItemId
  } = props;

  const backgroundColor = "rgb(8,20,16)";
  const mapCenter: LatLngExpression = [46.821488, 7.99639];

  const [map, setMap] = useState<L.Map>();
  const [prioritizedAreas, setPrioritizedAreas] = useState<Area[]>([]);

  const [showToolTips, setShowTooltips] = useState<boolean>(false);

  const onToggleShowTooltips = () => {
    setShowTooltips((prevShowToolTips) => {
      return !prevShowToolTips;
    });
  };

  React.useImperativeHandle(forwardedRef, () => ({
    invalidateSize() {
      map?.invalidateSize();
    }
  }));

  const _onStartEdit = () => {
    onStartEdit?.();
  };

  const _onCancelEdit = () => {
    onCancelEdit?.();
  };

  const _onCommitEdit = () => {
    onCommitEdit?.();
  };

  const _onTextMoved = (text: MapText, newPosition: L.LatLng) => {
    onTextMoved?.(text, newPosition);
  };

  useEffect(() => {
    const highlightedLine = lines?.filter((l) => l.id === highlightedItemId)[0];
    if (highlightedLine) {
      map?.flyTo(new LatLng(highlightedLine.linePoints[0].wgs84Latitude, highlightedLine.linePoints[0].wgs84Longitude));
    }
  }, [highlightedItemId]);

  useEffect(() => {
    if (areas) {
      // make sure low priority areas are drawn first
      setPrioritizedAreas(areas.sort((a, b) => (a.priority! > b.priority! ? 1 : a.priority! < b.priority! ? -1 : 0)).reverse());
    }
  }, [areas]);

  const onMapCreated = (map: L.Map) => {
    // Create a custom pane for areas with a lower zIndex
    const areaPane = map.createPane("areaPane");
    areaPane.style.zIndex = "400"; // Lower z-index (background)

    // Ensure default zIndex for overlays (like markers, lines) is higher
    map.getPane("overlayPane")!.style.zIndex = "500";
    setMap(map);
  };

  return (
    <div style={{ height: "100%", width: "100%", position: "relative" }}>
      {loading && (
        <Backdrop sx={(theme) => ({ color: "#fff", position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: theme.zIndex.drawer + 1 })} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <MapContainer style={{ height: "100%", width: "100%", backgroundColor: backgroundColor }} center={mapCenter} zoom={8} whenCreated={onMapCreated} preferCanvas>
        {prioritizedAreas && prioritizedAreas.map((a) => <AreaLayer area={a} />)}
        {computedAreas && computedAreas?.map((ca) => <ComputedAreaMarker computedArea={ca} />)}
        {waypoints &&
          waypoints.map((wp) => {
            return <WaypointMarker waypoint={wp} showTooltip={showToolTips} />;
          })}
        {airports &&
          airports.map((a) => {
            return <AirportMarker airport={a} />;
          })}
        {airways &&
          airways.map((a) => {
            return <AirwayMarker airway={a} />;
          })}
        {holdings &&
          holdings.map((h) => {
            return <HoldingMarker holding={h} />;
          })}
        {departureProcedures &&
          departureProcedures.map((dp) => {
            return <DepartureOrArrivalProcedureMarker instrumentProcedure={dp} getInstrumentProcedurePoints={getDepartureProcedurePointsFromId} />;
          })}
        {arrivalProcedures &&
          arrivalProcedures.map((ap) => {
            return <DepartureOrArrivalProcedureMarker instrumentProcedure={ap} getInstrumentProcedurePoints={getArrivalProcedurePointsFromId} />;
          })}
        {points &&
          points.map((p) => {
            return <PointMarker point={p} />;
          })}
        {lines &&
          lines.map((l) => (
            <LineLayer
              line={l}
              highlighted={l.id === highlightedItemId}
              editable={isInEdit && selectedLine?.id === l.id}
              onClick={onLineClick}
              onLinePointMoved={onLinePointMoved}
              showTooltip={showToolTips}
            />
          ))}
        {texts &&
          texts.map((t) => {
            return (
              <TextMarker
                key={`${t.id}${t.color ? t.color : ""}`}
                text={t}
                color={t.color}
                editable={selectedText?.id === t.id && isInEdit}
                onMove={(newPosition: L.LatLng) => {
                  _onTextMoved(t, newPosition);
                }}
                onClick={() => {
                  onTextClick?.(t);
                }}
                selected={selectedText?.id === t.id && isInEdit}
              />
            );
          })}
        {damParameters &&
          damParameters.map((dp) => {
            return (
              <DamParameterMarker
                key={`${dp.id}${dp.color ? dp.color : ""}`}
                damParameter={dp}
                editable={selectedDamParameter?.id === dp.id && isInEdit}
                selected={selectedDamParameter?.id === dp.id && isInEdit}
                onMove={(newPosition: L.LatLng) => {
                  onDamParameterMoved?.(dp, newPosition);
                }}
                onClick={() => {
                  onDamParameterClick?.(dp);
                }}
              />
            );
          })}
        {lakes && <Lakes lakes={lakes} />}
        {urbanAreas && <UrbanAreas urbanAreas={urbanAreas} />}
        {highways && <Highways highways={highways} />}
        {highwayRamps && <HighwayRamps highwayRamps={highwayRamps} />}
        {highwayTunnels && <HighwayTunnels highwayTunnels={highwayTunnels} />}
        {majorRoads && <MajorRoads majorRoads={majorRoads} />}
        {railways && <Railways railways={railways} />}
        {countryOutlines && <CountryOutlines countryOutlines={countryOutlines} />}
        {rivers && <Rivers rivers={rivers} />}
        <Fullscreen
          eventHandlers={{
            enterFullscreen: (event) => console.log("entered fullscreen", event),
            exitFullscreen: (event) => console.log("exited fullscreen", event)
          }}
        />
        <EditMapControl isEditing={isInEdit} onEdit={_onStartEdit} onCommit={_onCommitEdit} onCancel={_onCancelEdit} />
        <ShowTooltipControl onChange={onToggleShowTooltips} />
      </MapContainer>
    </div>
  );
};

export default React.forwardRef(RadarMapViewer);
