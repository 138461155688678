import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import VerticalBufferEditor from "./VerticalBufferEditor";

const DataGridVerticalBufferEditor = (params: GridRenderEditCellParams) => {
  const { id, value, field, error } = params;
  const apiRef = useGridApiContext();

  const handleChangeValue = (newValue: string) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return <VerticalBufferEditor value={value} onChange={handleChangeValue} error={error} helperText={error} showHelperTextAsTooltip={true} />;
};

export default DataGridVerticalBufferEditor;
