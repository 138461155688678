import { useEffect, useState } from "react";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { getLakes } from "src/apis/swisstopo/lakesApi";
import { NamedPolygons } from "src/types/Types";

const useLakes = () => {
  const versionTag = useVersionTag();
  const [lakes, setLakes] = useState<NamedPolygons[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedLake, setSelectedLake] = useState<NamedPolygons>();

  useEffect(() => {
    setLoading(true);
    getLakes(versionTag)
      .then((response) => {
        setLakes(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [versionTag]);

  const selectLake = (lake: NamedPolygons | undefined) => {
    setSelectedLake(lake);
  };

  return {
    loading,
    lakes: lakes,
    selectedLake: selectedLake,
    selectLake: selectLake
  };
};

export default useLakes;
