import React, { useEffect, useState } from "react";

import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  GridEventListener,
  GridRenderCellParams,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowOrderChangeParams,
  GridRowParams,
  GridToolbarContainer,
  GridValueSetterParams,
  MuiEvent,
  useGridApiRef
} from "@mui/x-data-grid-pro";

import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

import { Autocomplete, AutocompleteChangeReason, Button, FormControl, LinearProgress, TextField } from "@mui/material";
import { isUndefined } from "lodash";
import { WaypointLabel, useWaypointLabels } from "src/apis/waypoints/useWaypoints";
import { Airway, AirwayPoint } from "src/types/Types";

type AirwayPointsProps = {
  selectedAirway: Airway | null | undefined;
  airwayPoints: AirwayPoint[];
  onProcessRowUpdate?: (newRow: AirwayPoint, oldRow: AirwayPoint) => Promise<AirwayPoint> | AirwayPoint;
  onDeleteAirwayPoint?: (id: GridRowId) => void;
  onCancelEdit?: (id: GridRowId) => void;
  onRowOrdersChanged?: (newRows: AirwayPoint[]) => void;
};

const AirwayPoints = (props: AirwayPointsProps) => {
  const { selectedAirway, airwayPoints, onProcessRowUpdate, onDeleteAirwayPoint, onCancelEdit, onRowOrdersChanged } = props;
  const { loading, waypointLabels } = useWaypointLabels();

  const apiRef = useGridApiRef();

  const [rows, setRows] = useState<AirwayPoint[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [addWaypointDisabled, setAddWaypointDisabled] = useState(false);

  useEffect(() => {
    setRows(airwayPoints.slice().sort((a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0)));
  }, [airwayPoints]);

  useEffect(() => {
    setAddWaypointDisabled(isUndefined(selectedAirway));
  }, [selectedAirway]);

  const columns: GridColumns = [
    { field: "sequence", headerName: "Sequence", editable: false, width: 80 },
    {
      field: "waypointName",
      headerName: "Way point",
      editable: true,
      width: 130,
      valueSetter: (params: GridValueSetterParams) => {
        let waypointName = params?.value;
        if (params.value && params.value.includes("(")) {
          const v = params.value;
          waypointName = v.substring(0, v.indexOf("(")).trim();
          const startIndex = v.indexOf("(") + 1;
          const endIndex = v.indexOf(")");
          const waypointArea = v.substring(startIndex, endIndex);
          return { ...params.row, waypointName, waypointArea };
        }

        return { ...params.row, waypointName };
      },
      renderEditCell: (props: GridRenderCellParams) => {
        const { id, value, field } = props;
        const onChange = (event: React.SyntheticEvent, value: WaypointLabel | null, reason: AutocompleteChangeReason) => {
          apiRef.current.setEditCellValue({ id, field, value: value?.label });
        };

        return (
          <FormControl fullWidth>
            {loading === true ? (
              <LinearProgress />
            ) : (
              <Autocomplete
                id="waypoints"
                options={waypointLabels}
                sx={{ width: 130 }}
                renderInput={(params) => {
                  return <TextField {...params} label="Select..." error={value === undefined} />;
                }}
                onChange={onChange}
              />
            )}
          </FormControl>
        );
      }
    },
    {
      field: "waypointArea",
      headerName: "Area",
      width: 150,
      editable: false,
      hide: true
    },
    { field: "remarks", headerName: "Remarks", editable: true, width: 200 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem icon={<SaveIcon />} label="Save" onResizeCapture={() => {}} onResize={() => {}} onClick={handleSaveClick(id)} />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onResizeCapture={() => {}}
              onResize={() => {}}
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onResizeCapture={() => {}}
            onResize={() => {}}
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onResizeCapture={() => {}} onResize={() => {}} onClick={handleDeleteClick(id)} color="inherit" />
        ];
      }
    }
  ];

  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    setAddWaypointDisabled(true);
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    setAddWaypointDisabled(false);
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });
    onCancelEdit!(id);
    setAddWaypointDisabled(false);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    onDeleteAirwayPoint!(id);
  };

  const onProcessRowUpdateError = (row: AirwayPoint) => {
    setRowModesModel({ ...rowModesModel, [row.id]: { mode: GridRowModes.Edit } });
    setAddWaypointDisabled(true);
  };

  /*
  Note : airways are handled by the ADM, reordering not possible in CAT
  const handleRowOrderChange = (params: GridRowOrderChangeParams) => {
    const updatedRows = [...rows];
    for (let i = 0; i < rows.length; i++) {
      updatedRows[i].sequence = apiRef.current.getRowIndex(rows[i].id) + 1;
    }
    onRowOrdersChanged!(updatedRows.sort((a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0)));
  };
  */

  const onAddAirwayPoint = () => {
    const id = Date.now();
    const emptyRow: AirwayPoint = { id: id, airwayId: selectedAirway?.id || -1, waypointArea: "", waypointName: "", remarks: "", sequence: rows.length + 1, isNew: true };

    setRows([...rows, emptyRow]);

    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "waypointName" }
    });
    setAddWaypointDisabled(true);
    // debounce
    setTimeout(() => {
      apiRef.current.scrollToIndexes({
        rowIndex: apiRef.current.getRowsCount() - 1
      });
    }, 200);
  };

  const Toolbar = () => {
    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} disabled={addWaypointDisabled} onClick={onAddAirwayPoint}>
          Add Waypoint
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ height: "100%" }}>
      <DataGridPro
        apiRef={apiRef}
        density="compact"
        columns={columns}
        editMode={"row"}
        rows={rows}
        // disabling reordering in the HMI (airways are handled by the ADM)
        //rowReordering
        rowModesModel={rowModesModel}
        onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={onProcessRowUpdate}
        onProcessRowUpdateError={onProcessRowUpdateError}
        components={{ Toolbar }}
        componentsProps={{
          toolbar: { setRows, setRowModesModel }
        }}
        //onRowOrderChange={handleRowOrderChange}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </div>
  );
};

export default AirwayPoints;
