import { useState } from "react";

import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import AccountBoxIcon from "@mui/icons-material/AccountBox";
import VersionIcon from "@mui/icons-material/AccountTree";
import AirwayIcon from "@mui/icons-material/AddRoad";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ArticleIcon from "@mui/icons-material/Article";
import SymbolIcon from "@mui/icons-material/Camera";
import WaypointIcon from "@mui/icons-material/ChangeHistory";
import DamParameterIcon from "@mui/icons-material/ChromeReaderMode";
import GraphicAttributes from "@mui/icons-material/ColorLens";
import ComputedAreaIcon from "@mui/icons-material/Crop";
import AreaIcon from "@mui/icons-material/CropOriginal";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import AirportIcon from "@mui/icons-material/FlightTakeoff";
import CommonIcon from "@mui/icons-material/FolderOpen";
import GroupIcon from "@mui/icons-material/Group";
import HoldingIcon from "@mui/icons-material/HourglassTop";
import UserTagIcon from "@mui/icons-material/LocalOffer";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import MapIcon from "@mui/icons-material/Map";
import MenuIcon from "@mui/icons-material/Menu";
import LineIcon from "@mui/icons-material/Polyline";
import PublicIcon from "@mui/icons-material/Public";
import PointIcon from "@mui/icons-material/RadioButtonChecked";
import RampLeftIcon from "@mui/icons-material/RampLeft";
import SailingIcon from "@mui/icons-material/Sailing";
import SpeedIcon from "@mui/icons-material/Speed";
import SubwayIcon from "@mui/icons-material/Subway";
import ClientTypeIcon from "@mui/icons-material/Terminal";
import TerrainIcon from "@mui/icons-material/Terrain";
import MapTextIcon from "@mui/icons-material/TextSnippet";
import TrainIcon from "@mui/icons-material/Train";
import WaterIcon from "@mui/icons-material/Water";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

export const useSidebarItems = () => {
  const history = useHistory();

  const [commonOpen, setCommonOpen] = useState(true);
  const [mapsOpen, setMapsOpen] = useState(false);
  const [swisstopoOpen, setSwisstopoOpen] = useState(false);
  const [usersAndRolesOpen, setUsersAndRolesOpen] = useState(false);

  const handleClick = () => {
    setMapsOpen(!mapsOpen);
  };

  const handleCommonClick = () => {
    setCommonOpen(!commonOpen);
  };

  const handleSwisstopoClick = () => {
    setSwisstopoOpen(!swisstopoOpen);
  };
  const handleUserAndRolesClick = () => {
    setUsersAndRolesOpen(!usersAndRolesOpen);
  };

  const onVersionsClick = () => {
    history.push("/versions");
  };

  const onUserTagsClick = () => {
    history.push("/user-tags");
  };

  const onWaypointsClick = () => {
    history.push("/waypoints");
  };

  const onAirportsClick = () => {
    history.push("/airports");
  };

  const onAirwaysClick = () => {
    history.push("/airways");
  };

  const onMenusClick = () => {
    history.push("/maps/menus");
  };

  const onHoldingsClick = () => {
    history.push("/maps/holdings");
  };

  const onAreasClick = () => {
    history.push("/maps/areas");
  };

  const onComputedAreasClick = () => {
    history.push("/maps/computed-areas");
  };

  const onLinesClick = () => {
    history.push("/maps/lines");
  };

  const onMapTextsClick = () => {
    history.push("/maps/texts");
  };

  const onDamParametersClick = () => {
    history.push("/maps/dam-parameters");
  };

  const onGraphicAttributesClick = () => {
    history.push("/maps/graphicattributes");
  };

  const onClientTypesClick = () => {
    history.push("/client-types");
  };

  const onPointsClick = () => {
    history.push("/maps/points");
  };

  const onSymbolsClick = () => {
    history.push("/symbols");
  };

  const onUsersClick = () => {
    history.push("/users");
  };

  const onRolesClick = () => {
    history.push("/roles");
  };

  const onAdmDataImporterClick = () => {
    history.push("/adm-importer");
  };

  const onGeoDataImporterClick = () => {
    history.push("/geo-importer");
  };

  const onGeoDataLakesClick = () => {
    history.push("/swisstopo/lakes");
  };

  const onGeoDataRiversClick = () => {
    history.push("/swisstopo/rivers");
  };

  const onGeoDataUrbanAreasClick = () => {
    history.push("/swisstopo/urban-areas");
  };

  const onGeoDataCountryOutlinesClick = () => {
    history.push("/swisstopo/country-outlines");
  };

  const onGeoDataHighwaysClick = () => {
    history.push("/swisstopo/highways");
  };

  const onGeoDataHighwayRampsClick = () => {
    history.push("/swisstopo/highway-ramps");
  };

  const onGeoDataHighwayTunnelsClick = () => {
    history.push("/swisstopo/highway-tunnels");
  };

  const onGeoDataMajorRoadsClick = () => {
    history.push("/swisstopo/major-roads");
  };

  const onGeoDataRailwaysClick = () => {
    history.push("/swisstopo/railways");
  };

  return (
    <div>
      <ListItemButton sx={{ height: "20px" }} onClick={onVersionsClick}>
        <ListItemIcon>
          <VersionIcon fontSize="inherit" />
        </ListItemIcon>
        <ListItemText primary="Versions" />
      </ListItemButton>
      <ListItemButton sx={{ height: "30px" }} onClick={handleCommonClick}>
        <ListItemIcon>
          <CommonIcon fontSize="inherit" />
        </ListItemIcon>
        <ListItemText primary="Common" />
        {commonOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={commonOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4, height: "20px" }} onClick={onUserTagsClick}>
              <ListItemIcon>
                <UserTagIcon fontSize="inherit" />
              </ListItemIcon>
              <ListItemText primary="User tags" />
            </ListItemButton>
          </List>
          <ListItemButton sx={{ pl: 4, height: "20px" }} onClick={onWaypointsClick}>
            <ListItemIcon>
              <WaypointIcon fontSize="inherit" />
            </ListItemIcon>
            <ListItemText primary="Way points" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4, height: "20px" }} onClick={onAirportsClick}>
            <ListItemIcon>
              <AirportIcon fontSize="inherit" />
            </ListItemIcon>
            <ListItemText primary="Airports" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4, height: "20px" }} onClick={onAirwaysClick}>
            <ListItemIcon>
              <AirwayIcon fontSize="inherit" />
            </ListItemIcon>
            <ListItemText primary="Airways" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton sx={{ height: "30px" }} onClick={handleClick}>
        <ListItemIcon>
          <MapIcon fontSize="inherit" />
        </ListItemIcon>
        <ListItemText primary="Maps" />
        {mapsOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={mapsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4, height: "20px" }} onClick={onMenusClick}>
            <ListItemIcon>
              <MenuIcon fontSize="inherit" />
            </ListItemIcon>
            <ListItemText primary="Menus" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4, height: "20px" }} onClick={onClientTypesClick}>
            <ListItemIcon>
              <ClientTypeIcon fontSize="inherit" />
            </ListItemIcon>
            <ListItemText primary="Client types" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4, height: "20px" }} onClick={onPointsClick}>
            <ListItemIcon>
              <PointIcon fontSize="inherit" />
            </ListItemIcon>
            <ListItemText primary="Points" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4, height: "20px" }} onClick={onHoldingsClick}>
            <ListItemIcon>
              <HoldingIcon fontSize="inherit" />
            </ListItemIcon>
            <ListItemText primary="Holdings" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4, height: "20px" }} onClick={onAreasClick}>
            <ListItemIcon>
              <AreaIcon fontSize="inherit" />
            </ListItemIcon>
            <ListItemText primary="Areas" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4, height: "20px" }} onClick={onComputedAreasClick}>
            <ListItemIcon>
              <ComputedAreaIcon fontSize="inherit" />
            </ListItemIcon>
            <ListItemText primary="Computed areas" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4, height: "20px" }} onClick={onLinesClick}>
            <ListItemIcon>
              <LineIcon fontSize="inherit" />
            </ListItemIcon>
            <ListItemText primary="Lines" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4, height: "20px" }} onClick={onMapTextsClick}>
            <ListItemIcon>
              <MapTextIcon fontSize="inherit" />
            </ListItemIcon>
            <ListItemText primary="Texts" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4, height: "20px" }} onClick={onDamParametersClick}>
            <ListItemIcon>
              <DamParameterIcon fontSize="inherit" />
            </ListItemIcon>
            <ListItemText primary="DAM parameters" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4, height: "20px" }} onClick={onGraphicAttributesClick}>
            <ListItemIcon>
              <GraphicAttributes fontSize="inherit" />
            </ListItemIcon>
            <ListItemText primary="Graphic attributes" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4, height: "20px" }} onClick={onSymbolsClick}>
            <ListItemIcon>
              <SymbolIcon fontSize="inherit" />
            </ListItemIcon>
            <ListItemText primary="Symbols" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4, height: "20px" }} onClick={handleSwisstopoClick}>
            <ListItemIcon>
              <TerrainIcon fontSize="inherit" />
            </ListItemIcon>
            <ListItemText primary="Swisstopo" />
            {swisstopoOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={swisstopoOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 6, height: "20px" }} onClick={onGeoDataLakesClick}>
                <ListItemIcon>
                  <SailingIcon fontSize="inherit" />
                </ListItemIcon>
                <ListItemText primary="Lakes" />
              </ListItemButton>
              <ListItemButton sx={{ pl: 6, height: "20px" }} onClick={onGeoDataRiversClick}>
                <ListItemIcon>
                  <WaterIcon fontSize="inherit" />
                </ListItemIcon>
                <ListItemText primary="Rivers" />
              </ListItemButton>
              <ListItemButton sx={{ pl: 6, height: "20px" }} onClick={onGeoDataUrbanAreasClick}>
                <ListItemIcon>
                  <LocationCityIcon fontSize="inherit" />
                </ListItemIcon>
                <ListItemText primary="Urban areas" />
              </ListItemButton>
              <ListItemButton sx={{ pl: 6, height: "20px" }} onClick={onGeoDataCountryOutlinesClick}>
                <ListItemIcon>
                  <PublicIcon fontSize="inherit" />
                </ListItemIcon>
                <ListItemText primary="Country outlines" />
              </ListItemButton>
              <ListItemButton sx={{ pl: 6, height: "20px" }} onClick={onGeoDataHighwaysClick}>
                <ListItemIcon>
                  <SpeedIcon fontSize="inherit" />
                </ListItemIcon>
                <ListItemText primary="Highways" />
              </ListItemButton>
              <ListItemButton sx={{ pl: 6, height: "20px" }} onClick={onGeoDataHighwayRampsClick}>
                <ListItemIcon>
                  <RampLeftIcon fontSize="inherit" />
                </ListItemIcon>
                <ListItemText primary="Highway ramps" />
              </ListItemButton>
              <ListItemButton sx={{ pl: 6, height: "20px" }} onClick={onGeoDataHighwayTunnelsClick}>
                <ListItemIcon>
                  <SubwayIcon fontSize="inherit" />
                </ListItemIcon>
                <ListItemText primary="Highway tunnels" />
              </ListItemButton>
              <ListItemButton sx={{ pl: 6, height: "20px" }} onClick={onGeoDataMajorRoadsClick}>
                <ListItemIcon>
                  <DriveEtaIcon fontSize="inherit" />
                </ListItemIcon>
                <ListItemText primary="Major roads" />
              </ListItemButton>
              <ListItemButton sx={{ pl: 6, height: "20px" }} onClick={onGeoDataRailwaysClick}>
                <ListItemIcon>
                  <TrainIcon fontSize="inherit" />
                </ListItemIcon>
                <ListItemText primary="Railways" />
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      </Collapse>
      <ListItemButton sx={{ height: "30px" }} onClick={handleUserAndRolesClick}>
        <ListItemIcon>
          <AdminPanelSettingsIcon fontSize="inherit" />
        </ListItemIcon>
        <ListItemText primary="Admin" />
        {usersAndRolesOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={usersAndRolesOpen} timeout="auto" unmountOnExit>
        <ListItemButton sx={{ pl: 4, height: "20px" }} onClick={onUsersClick}>
          <ListItemIcon>
            <AccountBoxIcon fontSize="inherit" />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItemButton>
        <ListItemButton sx={{ pl: 4, height: "20px" }} onClick={onRolesClick}>
          <ListItemIcon>
            <GroupIcon fontSize="inherit" />
          </ListItemIcon>
          <ListItemText primary="Roles" />
        </ListItemButton>
        <ListItemButton sx={{ pl: 4, height: "20px" }} onClick={onAdmDataImporterClick}>
          <ListItemIcon>
            <ArticleIcon fontSize="inherit" />
          </ListItemIcon>
          <ListItemText primary="ADM import" />
        </ListItemButton>
        <ListItemButton sx={{ pl: 4, height: "20px" }} onClick={onGeoDataImporterClick}>
          <ListItemIcon>
            <TerrainIcon fontSize="inherit" />
          </ListItemIcon>
          <ListItemText primary="Swisstopo import" />
        </ListItemButton>
      </Collapse>
    </div>
  );
};

export default useSidebarItems;
