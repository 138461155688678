import { useState } from "react";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField, Typography } from "@mui/material";

import MoveToIcon from "@mui/icons-material/MenuOpen";
import PathSelector from "src/components/selectors/pathselector/PathSelector";
import { MenuItem } from "src/types/Types";

type MoveMenuItemDialogProps = {
  paths: string[];
  initialPath: string;
  selectedMenuItem: MenuItem | undefined;
  onMoveMenuItemTo: (destinationPath: string, moveItemToTop: boolean) => Promise<void>;
  onCancelMoveMenuItemTo: () => void;
};

const MoveMenuItemToDialog = (props: MoveMenuItemDialogProps) => {
  const { paths, initialPath, onMoveMenuItemTo, onCancelMoveMenuItemTo, selectedMenuItem } = props;

  const [pathError, setPathError] = useState(false);
  const [currentPath, setCurrentPath] = useState<string | null>(initialPath);
  const [moveItemToTop, setMoveItemToTop] = useState(false);

  const onChangePath = (newPath: string | null) => {
    if (newPath === "" || newPath === null) {
      setPathError(true);
    } else {
      setPathError(false);
    }
    setCurrentPath(newPath);
  };

  const onMoveItemToTopChange = () => {
    console.log("moveItemToTop: " + moveItemToTop);
    setMoveItemToTop(!moveItemToTop);
  };

  return (
    <Dialog open={true} maxWidth="xl">
      <DialogTitle>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <MoveToIcon />
          </div>
          <div>
            <Typography style={{ marginLeft: "10px" }}>Move menu item to...</Typography>
          </div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ display: "flex", width: "400px", height: "160px", flexDirection: "column" }}>
          <div>
            <div>{selectedMenuItem ? selectedMenuItem.path.join("/") : ""}</div>
            <div style={{ marginTop: "10px" }}>Destination</div>
            <div style={{ marginTop: "10px" }}>
              <PathSelector paths={paths} value={currentPath} onChange={onChangePath} error={pathError} helperText={pathError ? "Path cannot be empty" : ""} />
            </div>
            <div style={{ marginTop: "10px" }}>
              <FormControlLabel control={<Checkbox />} label={"Move item to top"} onChange={onMoveItemToTopChange} />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onCancelMoveMenuItemTo();
          }}
        >
          Cancel
        </Button>
        <Button autoFocus variant="contained" onClick={() => onMoveMenuItemTo(currentPath!, moveItemToTop)}>
          Move
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MoveMenuItemToDialog;
