import VersionsGrid from "./datagrids/VersionsGrid";
import { toast } from "react-toastify";
import { useAppContext } from "src/appcontext/AppContext";
import { AxiosError } from "axios";
import fileDownload from "js-file-download";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { useState } from "react";

export type Version = {
  id: number;
  parentId: number;
  majorVersion: number;
  minorVersion: number;
  path: string;
  versionNumber: string;
  versionTag: string | undefined;
  description: string | undefined;
  createdOn: string | undefined;
};

export type AddVersionCommand = {
  description: "string";
};

export type VersionsResponse = {
  data: Array<Version>;
};

const Versions = () => {
  const appContext = useAppContext();
  const apiClient = appContext.state.apiClient;
  const versions = appContext.state.versions;
  const currentVersion = useVersionTag();

  const [downloading, setDownloading] = useState(false);

  const onVersionSelected = (versionId: number) => {
    const selectedVersion = versions.filter((v) => v.id === versionId);
    appContext.dispatch({ type: "setVersion", payload: selectedVersion.length > 0 ? selectedVersion[0] : undefined });
  };

  const onCreateBranch = (parentVersionId: number) => {
    apiClient
      ?.post<Version>(`/versions/${parentVersionId}`, { parentVersionId: parentVersionId })
      .then((result) => {
        toast("Created branch", { type: "success" });
        apiClient.get<VersionsResponse>("/versions").then((response) => {
          appContext.dispatch({ type: "setVersions", payload: response.data.data });
        });
      })
      .catch(() => {
        toast("Failed to create branch: " + parentVersionId, { type: "error" });
      });
  };

  const onExportEmdis = () => {
    apiClient
      ?.get<Blob>(`/tables/clienttype/export/emdis`, {
        responseType: "blob"
      })
      .then((result) => {
        fileDownload(result.data, "emdis_export.zip");
      })
      .catch((error: AxiosError | Error) => {
        toast.error(error.message);
      });
  };

  const onExportSkyvisu = (aor: string) => {
    setDownloading(true);
    apiClient
      ?.get<Blob>(`/export/skyvisu?aor=${aor}`, {
        responseType: "blob",
        headers: { "x-air-version": currentVersion }
      })
      .then((result) => {
        console.log("headers: " + JSON.stringify(result.headers));
        fileDownload(result.data, result.headers["content-disposition"].split("filename=")[1].split(";")[0]); /* extract filename from content-dispositon */
      })
      .catch((error: AxiosError | Error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Versions</h2>
      <div style={{ height: "90%" }}>
        <VersionsGrid
          versions={versions}
          loading={false}
          downloading={downloading}
          onVersionSelected={onVersionSelected}
          onCreateBranch={onCreateBranch}
          onExportSkyvisu={onExportSkyvisu}
          onExportEmdis={onExportEmdis}
        />
      </div>
    </div>
  );
};

export default Versions;
