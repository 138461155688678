import { Validator } from "./Validator";

class ProcedurePointValidator extends Validator {
  validateField = (fieldName: string, fieldValue: any, otherFields: any) => {
    this.validationMap.set(fieldName, false);
    switch (fieldName) {
      case "dmsLatitude":
        return this.validateLatitude(fieldName, fieldValue);

      case "dmsLongitude":
        return this.validateLongitude(fieldName, fieldValue);

      case "curvature":
        return this.validateCurvature(fieldName, fieldValue);

      default:
        return "";
    }
  };
}

export default ProcedurePointValidator;
