import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { useMapSymbols } from "src/apis/symbols/useMapSymbols";
import useSymbolsColumns from "src/columnsgenerators/useSymbolsColumns";
import GenericDataGrid from "src/components/datagrids/GenericDataGrid";
import { MapSymbol } from "src/types/Types";
import SymbolValidator from "src/validators/SymbolValidator";

const Symbols = () => {
  const { loading, symbols, selectedSymbol, createSymbol, updateSymbol, deleteSymbol } = useMapSymbols();
  const { getColumns } = useSymbolsColumns();
  const versionTag = useVersionTag();

  const createEmptySymbolRow = (): MapSymbol => {
    return {
      id: -1,
      name: "",
      symbol: "",
      code: "",
      symbolImage: null,
      userTags: []
    };
  };

  const onCreateMapSymbol = (symbol: MapSymbol) => {
    return createSymbol(symbol);
  };

  const onUpdateSymbol = (symbol: MapSymbol) => {
    return updateSymbol(symbol);
  };

  const onDeleteSymbol = (symbol: MapSymbol) => {
    return deleteSymbol(symbol);
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Symbols - {versionTag}</h2>
      <div style={{ height: "90%" }}>
        <GenericDataGrid<MapSymbol>
          data={symbols}
          columnsGenerator={getColumns}
          selectedRow={selectedSymbol}
          createEmptyRow={createEmptySymbolRow}
          onCreateRow={onCreateMapSymbol}
          validator={new SymbolValidator()}
          onUpdateRow={onUpdateSymbol}
          onDeleteRow={onDeleteSymbol}
          sortModel={[{ field: "name", sort: "asc" }]}
        />
      </div>
    </div>
  );
};

export default Symbols;
