import { FieldsValidator } from "./Validator";

class MapTextValidator implements FieldsValidator {
  validationMap = new Map<string, boolean>();

  hasValidationErrors() {
    const values = this.validationMap.values();
    return Array.from(values).includes(true);
  }

  validateField = (fieldName: string, fieldValue: any) => {
    this.validationMap.set(fieldName, false);
    switch (fieldName) {
      case "name":
        if (fieldValue === undefined || fieldValue === null || fieldValue.trim() === "") {
          this.validationMap.set(fieldName, true);
          return "Invalid name";
        }
        return "";
      case "dmsLatitude":
      case "dmsLongitude": {
        if (fieldValue === null || fieldValue.includes("_")) {
          this.validationMap.set(fieldName, true);
          return "Invalid latitude/longitude";
        }
        return "";
      }
      default:
        return "";
    }
  };
}

export default MapTextValidator;
