import { GridColumns, GridPreProcessEditCellProps, GridRenderCellParams } from "@mui/x-data-grid-pro";
import DataGridLatitudeMask from "src/components/editors/DataGridLatitudeMask";
import DataGridLongitudeMask from "src/components/editors/DataGridLongitudeMask";
import DataGridTextFieldEditor from "src/components/editors/DataGridTextFieldEditor";
import FrequencyNameEditor from "src/components/editors/FrequencyNameEditor";
import DatagridAorSelector from "src/components/selectors/aorselector/DatagridAorSelector";
import DataGridUserTagSelector from "src/components/selectors/usertagselector/DataGridUserTagSelector";
import { ColumnsGenerator } from "src/types/Types";
import { FieldsValidator } from "src/validators/Validator";
import { customUserTagFilteringOperators } from "./customUserTagFilteringOperators";
import { userTagGridComparator } from "./customUserTagGridComparator";

const useWaypointsColumns = () => {
  const latitudeOrLongitudeMaskEmpty = '___°__´__.___"_';

  const getColumns: ColumnsGenerator = (validator: FieldsValidator, onFieldDirty) => {
    const columns: GridColumns = [
      {
        field: "name",
        headerName: "Name",
        width: 100,
        editable: true,
        valueParser: (value: string) => {
          if (value) {
            return value.toUpperCase();
          }
          return value;
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <FrequencyNameEditor {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("name", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "shortName",
        headerName: "Short name",
        width: 100,
        editable: true,
        valueParser: (value: string) => {
          if (value) {
            return value.toUpperCase();
          }
          return value;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("shortName", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        }
      },
      {
        field: "description",
        headerName: "Description",
        width: 200,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("description", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "area",
        headerName: "Area",
        width: 100,
        editable: true,
        valueParser: (value: string) => {
          if (value) {
            return value.toUpperCase();
          }
          return value;
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("area", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "dmsLatitude",
        headerName: "Latitude",
        width: 150,
        editable: true,
        valueParser: (value: string) => {
          if (value === latitudeOrLongitudeMaskEmpty) {
            return null;
          }
          return value;
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridLatitudeMask {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("dmsLatitude", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "dmsLongitude",
        headerName: "Longitude",
        width: 150,
        editable: true,
        valueParser: (value: string) => {
          if (value === latitudeOrLongitudeMaskEmpty) {
            return null;
          }
          return value;
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridLongitudeMask {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("dmsLongitude", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "firs",
        headerName: "A.o.R.",
        width: 220,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DatagridAorSelector {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        }
      },
      {
        field: "eurocontrolVersion",
        headerName: "Eurocontrol version",
        width: 150,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        }
      },
      {
        field: "frequency",
        headerName: "Frequency",
        width: 100,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        }
      },
      {
        field: "userTags",
        headerName: "User tags",
        width: 200,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridUserTagSelector {...params} />;
        },
        filterOperators: customUserTagFilteringOperators,
        sortComparator: userTagGridComparator
      }
    ];
    return columns;
  };
  return { getColumns };
};

export default useWaypointsColumns;
