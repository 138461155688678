import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { Role } from "../Roles";

export type RolesGridProps = {
  roles: Role[];
};

const RolesGrid = (props: RolesGridProps) => {
  const { roles } = props;
  const columns: GridColumns = [
    { field: "name", headerName: "Role name", width: 200, editable: true },
    { field: "description", headerName: "Description", width: 400, editable: true }
  ];

  return (
    <div style={{ height: "100%" }}>
      <DataGridPro density="compact" columns={columns} rows={roles} editMode="row" />
    </div>
  );
};

export default RolesGrid;
