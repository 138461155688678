import { GridColumns, GridPreProcessEditCellProps, GridRenderCellParams } from "@mui/x-data-grid-pro";
import DataGridTextFieldEditor from "src/components/editors/DataGridTextFieldEditor";
import DatagridClosuresSelector from "src/components/selectors/closuresselector/DatagridClosuresSelector";
import DataGridMergedAreasSelector from "src/components/selectors/mergedareasselector/DatagridMergedAreasSelector";
import DataGridUserTagSelector from "src/components/selectors/usertagselector/DataGridUserTagSelector";
import { Closure, ColumnsGenerator, ComputedArea, MergedArea } from "src/types/Types";
import { customUserTagFilteringOperators } from "./customUserTagFilteringOperators";
import { userTagGridComparator } from "./customUserTagGridComparator";

export const renderMergedArea = (mergedArea: MergedArea) => {
  return `${mergedArea.areaType}@${mergedArea.name} `;
};

export const parseMergedArea = (mergedAreaString: string): MergedArea => {
  const parts = mergedAreaString.split("@");
  return {
    id: -1,
    areaType: parts[0].trim() == "AREA" ? "AREA" : "COMPUTED_AREA",
    name: parts[1].trim()
  };
};

export const renderClosure = (closure: Closure) => {
  return `LINE@${closure.name}`;
};

export const parseClosure = (closureString: string): Closure => {
  const parts = closureString.split("@");
  return { id: -1, name: parts[1].trim() };
};

const useComputedAreaColumns = () => {
  const getColumns: ColumnsGenerator = (validator, onFieldDirty) => {
    const columns: GridColumns = [
      {
        field: "name",
        headerName: "Name",
        width: 200,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("name", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        }
      },
      {
        field: "description",
        headerName: "Description",
        width: 200,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("description", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        }
      },
      {
        field: "mergedAreas",
        headerName: "Merged areas",
        width: 200,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("mergedAreas", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderCell: (params: GridRenderCellParams<ComputedArea>) => {
          return (
            <div>
              {params.row.mergedAreas
                .map((ma: MergedArea) => {
                  return renderMergedArea(ma);
                })
                .toString()
                .replace(",", "|")}
            </div>
          );
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridMergedAreasSelector {...params} />;
        }
      },
      {
        field: "closures",
        headerName: "Closures",
        width: 200,
        editable: true,
        renderCell: (params: GridRenderCellParams<ComputedArea>) => {
          return (
            <div>
              {params.row.closures
                .map((cl: Closure) => {
                  return renderClosure(cl);
                })
                .toString()
                .replace(",", "|")}
            </div>
          );
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DatagridClosuresSelector {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("closures", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "smoothing",
        headerName: "Smoothing",
        width: 200,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("smoothing", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        }
      },
      {
        field: "userTags",
        headerName: "User tags",
        width: 200,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridUserTagSelector {...params} />;
        },
        filterOperators: customUserTagFilteringOperators,
        sortComparator: userTagGridComparator
      }
    ];
    return columns;
  };
  return { getColumns };
};

export default useComputedAreaColumns;
