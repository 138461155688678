import L from "leaflet";
import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";

import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check"; // MUI Commit (Check) icon
import CloseIcon from "@mui/icons-material/Close";
import ReactDOM from "react-dom";

type EditMapControlProps = {
  isEditing: boolean;
  onEdit: () => void;
  onCancel: () => void;
  onCommit: () => void;
};

const EditMapControl = (props: EditMapControlProps) => {
  const { isEditing, onEdit, onCancel, onCommit } = props;
  const map = useMap();

  useEffect(() => {
    const customControl = L.Control.extend({
      options: {
        position: "topright" // Position of the control
      },
      onAdd: function () {
        const container = L.DomUtil.create("div", "custom-control-container");

        container.style.display = "flex";
        container.style.justifyContent = "space-around";
        container.style.width = "100px";

        // Edit button
        const editButton = L.DomUtil.create("button", "custom-button", container);
        const editIcon = document.createElement("div");
        ReactDOM.render(<EditIcon fontSize="inherit" />, editIcon);
        editButton.appendChild(editIcon);
        editButton.onclick = () => {
          onEdit();
        };

        // Commit button
        const commitButton = L.DomUtil.create("button", "custom-button", container);
        const commitIcon = document.createElement("div");
        ReactDOM.render(<CheckIcon fontSize="inherit" />, commitIcon);
        commitButton.appendChild(commitIcon);
        commitButton.onclick = () => {
          onCommit();
        };

        // Cancel button
        const cancelButton = L.DomUtil.create("button", "custom-button", container);
        const cancelIcon = document.createElement("div");
        ReactDOM.render(<CloseIcon fontSize="inherit" />, cancelIcon);
        cancelButton.appendChild(cancelIcon);
        cancelButton.onclick = () => {
          onCancel();
        };

        // Disable or enable buttons based on editing state
        editButton.disabled = isEditing;
        commitButton.disabled = !isEditing; // Disable when not in editing mode
        cancelButton.disabled = !isEditing; // Disable when not in editing mode

        return container;
      }
    });

    const control = new customControl();
    map.addControl(control);

    // Cleanup on unmount
    return () => {
      map.removeControl(control);
    };
  }, [isEditing, map]);
  // to make it a valid JSX element
  return null;
};

export default EditMapControl;
