import { useEffect, useState } from "react";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import {
  addAdditionalInformation as _addAdditionalInformation,
  createWaypoint as _createWaypoint,
  deleteAdditionalInformation as _deleteAdditionalInformation,
  deleteWaypoint as _deleteWaypoint,
  updateAdditionalInformation as _updateAdditionalInformation,
  updateWaypoint as _updateWaypoint,
  getAdditionalInformation,
  getWaypoints,
  getWgs84LatLng
} from "src/apis/waypoints/waypointsApi";
import { AdditionalInformation, Waypoint } from "src/types/Types";

import * as _ from "lodash";

export type WaypointLabel = {
  id: number;
  label: string;
};

export const useWaypoints = () => {
  const versionTag = useVersionTag();
  const [waypoints, setWaypoints] = useState<Waypoint[]>([]);
  const [waypointNames, setWaypointNames] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentWaypoint, setCurrentWaypoint] = useState<Waypoint>();

  const selectWaypoint = async (waypoint: Waypoint) => {
    const copy = _.cloneDeep(waypoint);
    copy.additionalInformation = await getAdditionalInformation(waypoint, versionTag);

    setWaypoints([...waypoints.filter((w) => w.id !== waypoint.id), copy]);
    setCurrentWaypoint(copy);
  };

  const createWaypoint = (waypoint: Waypoint) => {
    return _createWaypoint(waypoint, versionTag).then((waypoint) => {
      setWaypoints((prevPoints) => [...prevPoints, waypoint]);
      setCurrentWaypoint(waypoint);
      return waypoint;
    });
  };

  const updateWaypoint = async (waypoint: Waypoint) => {
    waypoint.dmsLatitude = waypoint.dmsLatitude.replace('"', "");
    waypoint.dmsLongitude = waypoint.dmsLongitude.replace('"', "");
    const latlng = await getWgs84LatLng(waypoint.dmsLatitude, waypoint.dmsLongitude);
    waypoint.wgs84Latitude = latlng.wgs84Latitude;
    waypoint.wgs84Longitude = latlng.wgs84Longitude;
    return _updateWaypoint(waypoint, versionTag).then((updated) => {
      setWaypoints([...waypoints.filter((wp) => wp.id !== updated.id), updated]);
      setCurrentWaypoint(updated);
      return updated;
    });
  };

  const deleteWaypoint = (waypoint: Waypoint) => {
    return _deleteWaypoint(waypoint, versionTag).then((id) => {
      setWaypoints((previous) => previous.filter((wp) => wp.id !== id));
    });
  };

  const addInformation = (info: AdditionalInformation) => {
    return _addAdditionalInformation(currentWaypoint!, info, versionTag).then((info) => {
      const copy = _.cloneDeep(currentWaypoint!);
      copy.additionalInformation.push(info);
      setWaypoints([...waypoints.filter((wp) => wp.id !== copy.id), copy]);
      setCurrentWaypoint(copy);
      return info;
    });
  };

  const updateInformation = (info: AdditionalInformation) => {
    return _updateAdditionalInformation(currentWaypoint!, info, versionTag).then((info) => {
      const copy = _.cloneDeep(currentWaypoint!);
      copy.additionalInformation.filter((i) => i.id !== info.id);
      copy.additionalInformation.push(info);
      setWaypoints([...waypoints.filter((wp) => wp.id !== copy.id), copy]);
      setCurrentWaypoint(copy);
      return info;
    });
  };

  const deleteInformation = (info: AdditionalInformation) => {
    return _deleteAdditionalInformation(currentWaypoint!, info, versionTag).then((result) => {
      const copy = _.cloneDeep(currentWaypoint!);
      copy.additionalInformation = result;
      setWaypoints([...waypoints.filter((wp) => wp.id !== copy.id), copy]);
      setCurrentWaypoint(copy);
    });
  };

  useEffect(() => {
    setLoading(true);
    getWaypoints(versionTag)
      .then((response) => {
        setWaypoints(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [versionTag]);

  useEffect(() => {
    setWaypointNames(waypoints.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0)).map((wp) => `${wp.name}${wp.area ? " (" + wp.area + ")" : ""}`));
  }, [waypoints]);
  return {
    loading,
    waypoints,
    waypointNames,
    currentWaypoint,
    selectWaypoint,
    createWaypoint,
    updateWaypoint,
    deleteWaypoint,
    addInformation,
    updateInformation,
    deleteInformation
  };
};

export const useWaypointLabels = () => {
  const versionTag = useVersionTag();
  const [waypointLabels, setWaypointLabels] = useState<WaypointLabel[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getWaypoints(versionTag)
      .then((waypoints) => {
        setWaypointLabels(
          waypoints
            .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
            .map((wp) => {
              return { id: wp.id, label: `${wp.name}${wp.area ? " (" + wp.area + ")" : ""}` };
            })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [versionTag]);

  return { loading, waypointLabels };
};
