import { GridRowId } from "@mui/x-data-grid-pro";
import axios, { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { getAPIClient } from "src/apis/apiClient";
import AirwaysGrid from "./datagrids/AirwaysGrid";
import AddAirwayDialog from "./dialogs/AddAirwayDialog";
import DeleteAirwayDialog from "./dialogs/DeleteAirwayDialog";
import DeleteAirwayPointDialog from "./dialogs/DeleteAirwayPointDialog";
import { useAirways } from "./hooks/useAirways";

import * as _ from "lodash";
import { Airway, AirwayPoint } from "src/types/Types";

type AirwayPointResponse = {
  data: AirwayPoint;
  errorMessage: string;
};

type AirwayPointsResponse = {
  data: AirwayPoint[];
  errorMessage: string;
};

const Airways = () => {
  const { loading, airways, versionTag } = useAirways();
  const [showAddAirwayDialog, setShowAddAirwayDialog] = useState(false);

  const [currentAirways, setCurrentAirways] = useState<Airway[]>(airways);
  const [selectedAirway, setSelectedAirway] = useState<Airway | undefined>();
  const [selectedAirwayPoints, setSelectedAirwayPoints] = useState<AirwayPoint[]>([]);

  const versionTagRef = useRef(versionTag);

  const [showDeleteAirwayDialog, setShowDeleteAirwayDialog] = useState(false);
  const [airwayToDelete, setAirwayToDelete] = useState<Airway | undefined>();

  const [showDeleteAirwayPointDialog, setShowDeleteAirwayPointDialog] = useState(false);
  const [airwayPointToDelete, setAirwayPointToDelete] = useState<AirwayPoint | undefined>();

  useEffect(() => {
    setCurrentAirways(airways);
  }, [airways]);

  const onAddAirway = () => {
    setShowAddAirwayDialog(true);
  };

  const onCancelAddAirway = () => {
    setShowAddAirwayDialog(false);
  };

  const onAirwayCreated = (airway: Airway) => {
    setCurrentAirways((prev) => [...prev, airway]);
    setShowAddAirwayDialog(false);
  };

  const onAirwaySelected = (id: GridRowId) => {
    const selection = currentAirways.filter((a) => a.id === id)[0];
    setSelectedAirway(selection);
  };

  useEffect(() => {
    if (selectedAirway !== undefined) {
      setSelectedAirwayPoints(selectedAirway.airwayPoints);
    }
  }, [selectedAirway]);

  const onUpdateAirway = (newValue: Airway, oldValue: Airway) => {
    if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
      return newValue;
    }
    const _api = getAPIClient();
    return _api!
      .put(`/tables/airway/${newValue?.id}`, newValue, { headers: { "x-air-version": versionTagRef.current } })
      .then(() => {
        toast.success("Airway updated: " + newValue.name);
        return newValue;
      })
      .catch((error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response?.data) {
          toast.error(error.response?.data.errorMessage);
        } else {
          toast.error(error.message);
        }
        return Promise.reject(newValue);
      });
  };

  const onDeleteAirway = (id: GridRowId) => {
    setAirwayToDelete(currentAirways.filter((a) => a.id === id)[0]);
    setShowDeleteAirwayDialog(true);
  };

  const onConfirmDeleteAirway = () => {
    const _api = getAPIClient();
    _api
      ?.delete(`/tables/airway/${airwayToDelete?.id}`, { headers: { "x-air-version": versionTagRef.current } })
      .then(() => {
        setCurrentAirways((prevAirways) => [...prevAirways.filter((a) => a.id !== airwayToDelete?.id)]);
        toast.success("Airway deleted: " + airwayToDelete?.name);
        setAirwayToDelete(undefined);
      })
      .catch((error: Error | AxiosError) => {
        toast.error(error.message);
      })
      .finally(() => {
        setShowDeleteAirwayDialog(false);
      });
  };

  const onCancelDeleteAirway = () => {
    setShowDeleteAirwayDialog(false);
    setAirwayToDelete(undefined);
  };

  const onUpdateAirwayPoint = (newValue: AirwayPoint, oldValue: AirwayPoint): Promise<AirwayPoint> | AirwayPoint => {
    if (newValue.waypointName === undefined || newValue.waypointName.length === 0) {
      toast.error("Update failed: missing waypoint");
      return Promise.reject(newValue);
    }
    if (newValue.isNew === true) {
      const _api = getAPIClient();
      return _api!
        .post<AirwayPointResponse>(`/tables/airway/${selectedAirway?.id}/points`, { ...newValue, id: -1 })
        .then((response) => {
          let airway = _.cloneDeep(selectedAirway);
          if (airway !== undefined) {
            airway.airwayPoints.push(response.data.data);
            setCurrentAirways([...currentAirways.filter((a) => a.id !== selectedAirway!.id), airway]);
            setSelectedAirway(airway);
            setSelectedAirwayPoints(airway.airwayPoints);
          }
          return Promise.resolve(response.data.data);
        })
        .catch((error: Error | AxiosError) => {
          if (axios.isAxiosError(error)) {
            toast.error("Failed to create airway point: " + error.response?.data.errorMessage);
          } else {
            toast.error("Failed to create airway point: " + error.message);
          }
          return Promise.reject(newValue);
        });
    } else {
      // this is an update.
      if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
        return newValue;
      } else {
        toast.error("Airway point update not implemented!");
        return Promise.reject(newValue);
      }
    }
  };

  const onDeleteAirwayPoint = (id: GridRowId) => {
    const airwayPoint = selectedAirway?.airwayPoints.filter((ap) => ap.id === id)[0];
    setAirwayPointToDelete(airwayPoint);
    setShowDeleteAirwayPointDialog(true);
  };

  const onCancelDeleteAirwayPoint = () => {
    setShowDeleteAirwayPointDialog(false);
    setAirwayPointToDelete(undefined);
  };

  const onConfirmDeleteAirwayPoint = () => {
    const _api = getAPIClient();
    _api!
      .delete<AirwayPointsResponse>(`/tables/airway/${selectedAirway?.id}/points/${airwayPointToDelete?.id}`)
      .then((response) => {
        let airway = _.clone(selectedAirway);
        if (airway !== undefined) {
          airway.airwayPoints = response.data.data;
          setCurrentAirways([...currentAirways.filter((a) => a.id !== selectedAirway!.id), airway!]);
          setSelectedAirway(airway);
          setSelectedAirwayPoints(airway.airwayPoints);
        }
      })
      .catch((error) => {
        toast.error("Failed to delete airway point: " + JSON.stringify(error.message));
      });
    setShowDeleteAirwayPointDialog(false);
    setAirwayPointToDelete(undefined);
  };

  const onCancelEditAirwayPoint = () => {
    setSelectedAirwayPoints((oldPoints) => [...oldPoints]);
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Airways - {versionTag}</h2>
      <div style={{ height: "90%" }}>
        {showAddAirwayDialog && <AddAirwayDialog open={true} onCancel={onCancelAddAirway} onAirwayCreated={onAirwayCreated} />}
        {showDeleteAirwayDialog && <DeleteAirwayDialog airway={airwayToDelete} onConfirmDelete={onConfirmDeleteAirway} onCancelDelete={onCancelDeleteAirway} />}
        {showDeleteAirwayPointDialog && (
          <DeleteAirwayPointDialog airwayPoint={airwayPointToDelete} onConfirmDelete={onConfirmDeleteAirwayPoint} onCancelDelete={onCancelDeleteAirwayPoint} />
        )}
        <AirwaysGrid
          loading={loading}
          airways={currentAirways}
          airwayPoints={selectedAirwayPoints}
          onAirwaySelected={onAirwaySelected}
          onAddAirway={onAddAirway}
          onUpdateAirway={onUpdateAirway}
          onDeleteAirway={onDeleteAirway}
          onUpdateAirwayPoint={onUpdateAirwayPoint}
          onCancelEditAirwayPoint={onCancelEditAirwayPoint}
          onDeleteAirwayPoint={onDeleteAirwayPoint}
        />
      </div>
    </div>
  );
};

export default Airways;
