import { TextField } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import { ChangeEvent } from "react";

const DataGridFrequencyEditor = (params: GridRenderEditCellParams) => {
  const { id, value, field, error } = params;
  const apiRef = useGridApiContext();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <TextField
      id="name"
      size="small"
      name="frequency"
      variant="standard"
      value={value}
      onChange={onChange}
      autoComplete="off"
      sx={{ input: { color: error ? "red" : "" } }}
      error={error}
    />
  );
};

export default DataGridFrequencyEditor;
