import { LatLng } from "leaflet";
import { Polyline } from "react-leaflet";
import { MajorRoad } from "src/types/Types";

type MajorRoadsProps = {
  majorRoads: MajorRoad[];
};

const MajorRoads = (props: MajorRoadsProps) => {
  const { majorRoads } = props;
  const defaultColor = "#1565C0";

  return (
    <>
      {majorRoads.map((mr) => {
        return mr.sections?.map((s) => {
          return (
            <Polyline
              pathOptions={{ color: mr.color ? mr.color : defaultColor, weight: 1, fill: false }}
              positions={s.slice().map((sp) => {
                return new LatLng(sp.wgs84Latitude, sp.wgs84Longitude);
              })}
            />
          );
        });
      })}
    </>
  );
};

export default MajorRoads;
