import { useEffect, useState } from "react";
import { useVersionTag } from "../hooks/useVersionTag";
import {
  getComputedAreas,
  addComputedArea as _addComputedArea,
  updateComputedArea as _updateComputedArea,
  deleteComputedArea as _deleteComputedArea,
  getComputedAreaPoints as _getComputedAreaPoints
} from "./computedAreasApis";
import { ComputedArea, LinePoint } from "src/types/Types";

import * as _ from "lodash";

const useComputedAreas = () => {
  const versionTag = useVersionTag();
  const [computedAreas, setComputedAreas] = useState<ComputedArea[]>([]);
  const [selectedComputedArea, setSelectedComputedArea] = useState<ComputedArea | undefined>(undefined);
  const [computedAreaPoints, setComputedAreaPoints] = useState<LinePoint[]>([]);
  const [loading, setLoading] = useState(false);

  const getComputedAreaPoints = async (computedArea: ComputedArea) => {
    const points: LinePoint[] = await _getComputedAreaPoints(computedArea, versionTag);
    setComputedAreaPoints(points);
  };

  useEffect(() => {
    setLoading(true);
    getComputedAreas(versionTag).then((result) => {
      setComputedAreas(result);
      setLoading(false);
    });
  }, [versionTag]);

  useEffect(() => {
    if (!_.isUndefined(selectedComputedArea)) {
      getComputedAreaPoints(selectedComputedArea);
    } else {
      setComputedAreaPoints([]);
    }
  }, [selectedComputedArea]);

  const selectComputedArea = async (computedArea: ComputedArea) => {
    setSelectedComputedArea(computedArea);
  };

  const addComputedArea = async (computedArea: ComputedArea) => {
    const result = await _addComputedArea(computedArea, versionTag);
    setComputedAreas([...computedAreas, result]);
    setSelectedComputedArea(result);
    return result;
  };

  const updateComputedArea = async (computedArea: ComputedArea) => {
    const result = await _updateComputedArea(computedArea, versionTag);
    setComputedAreas([...computedAreas.filter((ca) => ca.id !== computedArea.id), result]);
    setSelectedComputedArea(result);
    return result;
  };

  const deleteComputedArea = async (computedArea: ComputedArea) => {
    const result = await _deleteComputedArea(computedArea, versionTag);
    setComputedAreas(computedAreas.filter((ca) => ca.id !== result));
  };

  return { loading, computedAreas, selectedComputedArea, computedAreaPoints, selectComputedArea, addComputedArea, updateComputedArea, deleteComputedArea };
};

export default useComputedAreas;
