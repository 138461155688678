import { useEffect, useState } from "react";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { getHighwayTunnels } from "src/apis/swisstopo/highwayTunnelsApi";
import { PolyLineWithAttributes } from "src/types/Types";

const useHighwayTunnels = () => {
  const versionTag = useVersionTag();
  const [highwayTunnels, setHighwayTunnels] = useState<PolyLineWithAttributes[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getHighwayTunnels(versionTag)
      .then((response) => {
        setHighwayTunnels(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [versionTag]);

  return {
    loading,
    highwayTunnels: highwayTunnels
  };
};

export default useHighwayTunnels;
