import { Autocomplete, TextField } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import { SyntheticEvent } from "react";
import { GraphicAttributeType, GraphicAttributeTypeValues } from "src/types/Types";

const DatagridGraphicAttributeTypeSelector = (params: GridRenderEditCellParams) => {
  const apiRef = useGridApiContext();

  const { id, value, field, error } = params;

  const _onGraphicAttributeTypeChange = (event: SyntheticEvent, value: GraphicAttributeType) => {
    apiRef.current.setEditCellValue({ id, field, value: value });
  };

  return (
    <Autocomplete
      size="small"
      fullWidth
      options={GraphicAttributeTypeValues}
      value={value}
      renderInput={(params) => {
        return <TextField variant="standard" {...params} error={error} />;
      }}
      onChange={_onGraphicAttributeTypeChange}
    />
  );
};

export default DatagridGraphicAttributeTypeSelector;
