import { DataGridPro, GridActionsCellItem, GridColumns, GridRowId, GridRowParams, GridSelectionModel, GridToolbarContainer } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { useAppContext } from "src/appcontext/AppContext";

import { Menu, MenuItem } from "@mui/material";
import { Version } from "src/pages/versions/Versions";

import BranchIcon from "@mui/icons-material/AccountTree";
import ExportIcon from "@mui/icons-material/DownloadForOffline";
import { LoadingButton } from "@mui/lab";

type VersionsGridProps = {
  versions: Version[];
  onCreateBranch: (parentVersionId: number) => void;
  onExportEmdis: () => void;
  onExportSkyvisu: (aor: "LSAZ" | "LSAG") => void;
  onVersionSelected: (version: any) => void;
  loading: boolean;
  downloading: boolean;
};

const VersionsGrid = (props: VersionsGridProps) => {
  const { versions, loading, downloading, onCreateBranch, onVersionSelected, onExportEmdis, onExportSkyvisu } = props;
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>();
  const appContext = useAppContext();
  const currentVersion = appContext.state.currentVersion;

  useEffect(() => {
    if (currentVersion) {
      const gridSelectionModel = new Array<GridRowId>();
      gridSelectionModel.push(currentVersion.id);
      setSelectionModel(gridSelectionModel);
    }
  }, [currentVersion]);

  const columns: GridColumns = [
    { field: "description", headerName: "Description", width: 300, editable: false },
    { field: "lastUpdateBy", headerName: "Last updated by", width: 150, editable: false },
    { field: "lastUpdate", headerName: "Last update", width: 300, editable: false },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "actions",
      editable: true,
      getActions: (rowProps: GridRowParams<Version>) => {
        return [
          <GridActionsCellItem
            icon={<BranchIcon />}
            label="Create branch"
            className="textPrimary"
            onResizeCapture={() => {}}
            onResize={() => {}}
            onClick={() => {
              onCreateBranch(rowProps.row.id);
            }}
            color="inherit"
          />
        ];
      }
    }
  ];

  const onSelectionModelChange = (newSelectionModel: GridSelectionModel) => {
    const model = [...newSelectionModel];
    if (newSelectionModel.length > 1) {
      model.shift();
    }
    onVersionSelected(model[0]);
    setSelectionModel(model);
  };

  const Toolbar = () => {
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const open = Boolean(menuAnchor);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
      setMenuAnchor(event.currentTarget);
    };

    const onClose = () => {
      setMenuAnchor(null);
    };

    const onExportGvaRadarMap = () => {
      onExportSkyvisu("LSAG");
      onClose();
    };

    const onExportZrhRadarMap = () => {
      onExportSkyvisu("LSAZ");
      onClose();
    };

    return (
      <GridToolbarContainer>
        <LoadingButton loading={downloading} color="primary" loadingPosition="start" startIcon={<ExportIcon />} onClick={handleOpenMenu} disabled={selectionModel?.length === 0}>
          Export
        </LoadingButton>
        <Menu id="basic-menu" anchorEl={menuAnchor} open={open} onClose={onClose}>
          <MenuItem onClick={onExportEmdis}>EMDIS</MenuItem>
          <MenuItem onClick={onExportGvaRadarMap}>GVA Radar Map</MenuItem>
          <MenuItem onClick={onExportZrhRadarMap}>ZRH Radar Map</MenuItem>
        </Menu>
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ height: "100%" }}>
      <div style={{ height: "90%" }}>
        <DataGridPro
          loading={loading}
          selectionModel={selectionModel}
          onSelectionModelChange={onSelectionModelChange}
          treeData
          getTreeDataPath={(row: Version) => row.path.split("/")}
          groupingColDef={{
            hideDescendantCount: true,
            headerName: "Versions"
          }}
          density="compact"
          columns={columns}
          rows={versions}
          editMode="row"
          components={{ Toolbar }}
        />
      </div>
    </div>
  );
};

export default VersionsGrid;
