import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import AlertIcon from "@mui/icons-material/ErrorOutline";
import { Identifiable } from "src/types/Types";

type DeleteRowProps = {
  title?: string;
  displayModel?: [{ objectKey: string; objectLabel: string }];
  rows: Identifiable[];
  onConfirmDelete: () => void;
  onCancelDelete: () => void;
};

const DeleteRowsDialog = (props: DeleteRowProps) => {
  const { title, rows, onCancelDelete, onConfirmDelete } = props;
  return (
    <Dialog fullWidth maxWidth="sm" open={true}>
      <DialogTitle>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ color: "orange", fontSize: "32px" }}>
            <AlertIcon style={{ fontSize: "inherit" }} />
          </div>
          <div style={{ marginLeft: "10px", marginBottom: "10px" }}>{title ? title : "Delete " + (rows.length > 1 ? " rows ?" : "row ?")}</div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {rows.length > 1 && (
          <Box component="section" sx={{ overflowY: "scroll", maxHeight: "300px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {rows.map((row) => (
                <div style={{ marginLeft: "20px", marginTop: "20px", marginBottom: "20px" }}>
                  {Object.keys(row).map((key) => {
                    if (typeof row[key as keyof Object] === "object") return;
                    return (
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "200px", marginRight: "30px", textAlign: "right" }}>{key}</div>
                        <Box sx={{ fontWeight: "medium" }}>{row[key as keyof Object]}</Box>
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </Box>
        )}

        {rows.length === 1 && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ marginLeft: "20px", marginTop: "20px", marginBottom: "20px" }}>
              {Object.keys(rows[0]).map((key) => {
                if (typeof rows[0][key as keyof Object] === "object") return;
                return (
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "200px", marginRight: "30px", textAlign: "right" }}>{key}</div>
                    <Box sx={{ fontWeight: "medium" }}>{rows[0][key as keyof Object]}</Box>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="contained" onClick={onConfirmDelete}>
          Delete
        </Button>
        <Button onClick={onCancelDelete}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteRowsDialog;
