import { useEffect, useState } from "react";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { getUrbanAreas } from "src/apis/swisstopo/urbanAreasApi";
import { NamedPolygons } from "src/types/Types";

const useUrbanAreas = () => {
  const versionTag = useVersionTag();
  const [urbanAreas, setUrbanAreas] = useState<NamedPolygons[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedUrbanArea, setSelectedUrbanArea] = useState<NamedPolygons>();

  useEffect(() => {
    setLoading(true);
    getUrbanAreas(versionTag)
      .then((response) => {
        setUrbanAreas(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [versionTag]);

  const selectUrbanArea = (urbanArea: NamedPolygons | undefined) => {
    setSelectedUrbanArea(urbanArea);
  };

  return {
    loading,
    urbanAreas: urbanAreas,
    selectedUrbanArea: selectedUrbanArea,
    selectUrbanArea: selectUrbanArea
  };
};

export default useUrbanAreas;
