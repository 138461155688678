import { AdditionalInformation, Area, AreaPoint, Xref } from "src/types/Types";
import { getAPIClient } from "../apiClient";

type AreasResponse = {
  data: Area[];
  errorMessage: string;
};

type AreaPointsResponse = {
  data: AreaPoint[];
  errorMessage: string;
};

type AreaInfoResponse = {
  data: AdditionalInformation[];
  errorMessage: string;
};

type CreateAreaResponse = {
  data: Area;
  errorMessage: string;
};

type UpdateAreaResponse = {
  data: Area;
  errorMessage: string;
};

type DeleteAreaResponse = {
  data: number;
  errorMessage: string;
};

type CreateAreaInfoResponse = {
  data: AdditionalInformation;
  errorMessage: string;
};

type UpdateAreaInfoResponse = {
  data: AdditionalInformation;
  errorMessage: string;
};

type DeleteAreaInfoResponse = {
  data: AdditionalInformation[];
  errorMessage: string;
};

type XrefsResponse = {
  data: Xref[];
  errorMessage: string;
};

export const getAreas = (versionTag: string) => {
  const apiClient = getAPIClient();
  return apiClient!.get<AreasResponse>("/tables/area", { headers: { "x-air-version": versionTag } }).then((response) => {
    return response.data.data;
  });
};

export const getAreasFromIds = (versionTag: string, areaIds: string) => {
  const apiClient = getAPIClient();
  return apiClient!.get<AreasResponse>(`/tables/area?areaIds=${areaIds}`, { headers: { "x-air-version": versionTag } }).then((response) => {
    return response.data.data;
  });
};

export const getAreaXRefs = (versionTag: string, area: Area) => {
  const apiClient = getAPIClient();
  return apiClient!.get<XrefsResponse>(`/tables/area/${area.id}/x-ref`, { headers: { "x-air-version": versionTag } }).then((response) => {
    return response.data.data;
  });
};

export const getAreaPoints = (versionTag: string, area: Area) => {
  const apiClient = getAPIClient();
  return apiClient!
    .get<AreaPointsResponse>("/tables/area/" + area.id + "/points", {
      headers: { "x-air-version": versionTag }
    })
    .then((response) => {
      return response.data.data;
    });
};

export const getAreaInfo = async (versionTag: string, area: Area) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.get<AreaInfoResponse>("/tables/area/" + area.id + "/information", {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const createArea = async (versionTag: string, area: Area) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.post<CreateAreaResponse>("/tables/area", area, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const updateArea = async (versionTag: string, area: Area) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.put<UpdateAreaResponse>(`/tables/area/${area.id}`, area, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const deleteArea = async (versionTag: string, area: Area) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.delete<DeleteAreaResponse>(`/tables/area/${area.id}`, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const createAreaPoint = async (versionTag: string, area: Area, areaPoint: AreaPoint) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.post<AreaPointsResponse>(`tables/area/${area.id}/point`, areaPoint, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const createAreaPoints = async (versionTag: string, area: Area, afterSequence: number, areaPoints: AreaPoint[]) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.post<AreaPointsResponse>(`tables/area/${area.id}/points/${afterSequence}`, areaPoints, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const updateAreaPoint = async (versionTag: string, area: Area, areaPoint: AreaPoint) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.put<AreaPointsResponse>(`tables/area/${area.id}/point/${areaPoint.id}`, areaPoint, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const deleteAreaPoint = async (versionTag: string, area: Area, areaPoint: AreaPoint) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.delete<AreaPointsResponse>(`tables/area/${area.id}/point/${areaPoint.id}`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const deleteAreaPoints = async (versionTag: string, area: Area, areaPoints: AreaPoint[]) => {
  const apiClient = getAPIClient();
  const strIds = areaPoints.map((ap) => ap.id).join(",");
  const response = await apiClient!.delete<AreaPointsResponse>(`tables/area/${area.id}/points/${strIds}`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const moveSelectedAreaPoints = async (versionTag: string, area: Area, fromIndex: number, toIndex: number, selectedPointsSequenceNumbers: number[]) => {
  const _apiClient = getAPIClient();
  const selectedPointsSequenceNumbersStr = selectedPointsSequenceNumbers.join(",");
  let url = `/tables/area/${area.id}/points/move/${fromIndex}/${toIndex}`;

  if (selectedPointsSequenceNumbers.length > 0) url += `?selectedPointsSequenceNumbers=${selectedPointsSequenceNumbersStr}`;

  const response = await _apiClient!.put<AreaPointsResponse>(url, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const createAreaInfo = async (versionTag: string, area: Area, info: AdditionalInformation) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.post<CreateAreaInfoResponse>(`tables/area/${area.id}/information`, info, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const updateAreaInfo = async (versionTag: string, area: Area, info: AdditionalInformation) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.put<UpdateAreaInfoResponse>(`tables/area/${area.id}/information/${info.id}`, info, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const deleteAreaInfo = async (versionTag: string, area: Area, info: AdditionalInformation) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.delete<DeleteAreaInfoResponse>(`tables/area/${area.id}/information/${info.id}`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};
