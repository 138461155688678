import * as _ from "lodash";
import { PolyLineWithAttributes } from "src/types/Types";
import { getAPIClient } from "../apiClient";

type HighwaysResponse = {
  data: PolyLineWithAttributes[];
  errorMessage: string | null;
};

export const getHighways = async (versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<HighwaysResponse>("/swisstopo/highways", { headers: { "x-air-version": versionTag } });
  return response.data.data.map((co) => {
    return {
      ...co,
      id: _.uniqueId()
    };
  });
};

export const getHighwaysFromIds = async (versionTag: string, ids: number[]) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<HighwaysResponse>("/swisstopo/highways/search", { searchIds: ids }, { headers: { "x-air-version": versionTag } });
  return response.data.data.map((h) => {
    return {
      ...h,
      id: h.attributes.id!
    };
  });
};
