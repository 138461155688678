import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import FlightLevelEditor from "./FlightLevelEditor";

const DataGridFlightLevelEditor = (params: GridRenderEditCellParams) => {
  const { id, value, field, error } = params;
  const apiRef = useGridApiContext();

  const handleChangeValue = (newValue: string) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return <FlightLevelEditor value={value} onChange={handleChangeValue} error={error} helperText={error} showHelperTextAsTooltip={true} />;
};

export default DataGridFlightLevelEditor;
