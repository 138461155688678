import { SequentialPoint } from "src/types/Types";
import TextMarker from "./TextMarker";
import { uniqueId } from "lodash";

type SequentialPointLabelProps = {
  point: SequentialPoint;
};

const SequentialPointLabel = ({ point }: SequentialPointLabelProps) => {
  return (
    <TextMarker
      color="#666666"
      text={{
        id: uniqueId(),
        text: point.originalSequence?.toString() || "",
        dmsLatitude: "",
        dmsLongitude: "",
        userTags: [],
        additionalInformation: [],
        wgs84Latitude: point.wgs84Latitude,
        wgs84Longitude: point.wgs84Longitude,
        description: ""
      }}
      tooltipOn={false}
    />
  );
};

export default SequentialPointLabel;
