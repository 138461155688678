import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import { isUndefined } from "lodash";
import Split from "react-split";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import useMapTextColumns from "src/columnsgenerators/useMapTextColumns";
import AdditionalInformationGrid from "src/components/datagrids/AdditionalInformationGrid";
import GenericDataGrid from "src/components/datagrids/GenericDataGrid";
import MapViewer from "src/components/mapviewer/MapViewer";
import { AdditionalInformation, MapText } from "src/types/Types";
import MapTextValidator from "src/validators/MapTextValidator";
import { useMapTexts } from "../../../apis/maptexts/useMapTexts";
import AddMapTextDialog from "./dialogs/AddMapTextDialog";

const MapTexts = () => {
  const versionTag = useVersionTag();
  const {
    loading,
    mapTexts,
    selectedMapText,
    selectMapText,
    createMapText,
    updateMapText,
    deleteMapText,
    createAdditionalInformation,
    updateAdditionalInformation,
    deleteAdditionalInformation
  } = useMapTexts();
  const { getColumns } = useMapTextColumns();

  const onSelectRow = (mapText: MapText) => {
    selectMapText(mapText);
  };

  const onUpdateRow = (mapText: MapText) => {
    return updateMapText(mapText);
  };

  const onDeleteRow = (mapText: MapText) => {
    return deleteMapText(mapText);
  };

  const createDialogFactory = (onSuccess: () => void, onCancel: () => void) => {
    return <AddMapTextDialog onSuccess={onSuccess} onCancel={onCancel} createMapText={createMapText} />;
  };

  const onAddInformation = (info: AdditionalInformation) => {
    return createAdditionalInformation(info);
  };

  const onUpdateInformation = (info: AdditionalInformation) => {
    return updateAdditionalInformation(info);
  };

  const onDeleteInformation = (info: AdditionalInformation) => {
    return deleteAdditionalInformation(info);
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Texts - {versionTag}</h2>
      <Split
        style={{ height: "calc(100vh - 230px)" }}
        direction="vertical"
        onDragEnd={() => {
          /*if (radarMapRef !== null) {
            radarMapRef.current?.invalidateSize();
          }*/
        }}
      >
        <div style={{ height: "100%" }}>
          <GenericDataGrid<MapText>
            data={mapTexts}
            loading={loading}
            columnsGenerator={getColumns}
            validator={new MapTextValidator()}
            createDialogFactory={createDialogFactory}
            onUpdateRow={onUpdateRow}
            onDeleteRow={onDeleteRow}
            onSelectRow={onSelectRow}
            selectedRow={selectedMapText}
            sortModel={[{ field: "id", sort: "asc" }]}
          />
        </div>
        <div style={{ height: "100%" }}>
          <TabContext value={"additionalInformation"}>
            <Tabs value={"additionalInformation"}>
              <Tab value="additionalInformation" label="Additional information" />
            </Tabs>
            <TabPanel value="additionalInformation" style={{ height: "100%" }}>
              <div style={{ height: "100%", display: "flex", flexDirection: "row" }}>
                <div style={{ height: "100%", flex: 1.1 }}>
                  <AdditionalInformationGrid
                    infos={selectedMapText ? selectedMapText.additionalInformation : []}
                    onAddInformation={onAddInformation}
                    onUpdateInformation={onUpdateInformation}
                    onDeleteInformation={onDeleteInformation}
                    canInsertRow={!isUndefined(selectedMapText)}
                  />
                </div>
                <div style={{ height: "100%", flex: 1, marginLeft: 10 }}>
                  <MapViewer texts={!isUndefined(selectedMapText) ? [selectedMapText] : []} />
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </Split>
    </div>
  );
};

export default MapTexts;
