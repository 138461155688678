import React, { useRef, useState } from "react";
import { getWgs84Latitude, getWgs84Longitude } from "src/apis/waypoints/waypointsApi";
import usePointsColumns from "src/columnsgenerators/useBoundaryPointsColumns";
import GenericDataGrid from "src/components/datagrids/GenericDataGrid";
import LatitudeMask from "src/components/editors/LatitudeMask";
import LongitudeMask from "src/components/editors/LongitudeMask";
import TextFieldEditor from "src/components/editors/TextFieldEditor";
import MapViewer from "src/components/mapviewer/MapViewer";
import CategoriesSelector from "src/components/selectors/categoriesselector/CategoriesSelector";
import UserTagSelector from "src/components/selectors/usertagselector/UserTagSelector";
import { Area, AreaPoint, CoordinatesList } from "src/types/Types";
import BoundaryPointsValidator from "src/validators/BoundaryPointsValidator";
import useAddAreaFormHandler from "./useAddAreaFormHandler";

import { Button } from "@mui/material";
import * as _ from "lodash";
import { toast } from "react-toastify";
import PasteCoordinatesDialog from "src/components/dialogs/PasteCoordinatesDialog";
import BufferMask from "src/components/editors/BufferMask";

export type AddAreaFormHandle = {
  requestSubmit: () => void;
};

type AddAreaProps = {
  createArea: (area: Area) => Promise<Area>;
  onSuccess: () => void;
};

const AddAreaForm: React.ForwardRefRenderFunction<AddAreaFormHandle, AddAreaProps> = (props, forwardedRef) => {
  const formRef = useRef<HTMLFormElement>(null);

  const { createArea, onSuccess } = props;
  const { formHandler } = useAddAreaFormHandler(createArea, onSuccess);
  const { getColumns: getPointsColumns } = usePointsColumns(true);
  const [areaPoints, setAreaPoints] = useState<AreaPoint[]>([]);
  const [showPasteCoordinatesDialog, setShowPasteCoordinatesDialog] = useState(false);

  const onShowPasteCoordinatesDialog = () => {
    setShowPasteCoordinatesDialog(true);
  };

  const onCancelPasteCoordinates = () => {
    setShowPasteCoordinatesDialog(false);
  };

  const onCreateEmptyAreaPointRow = () => {
    return {
      id: -1 /* to make sure row is unique in grid */,
      lineId: -1,
      sequence: areaPoints.length + 1,
      wgs84Latitude: 0,
      wgs84Longitude: 0,
      dmsLatitude: "",
      dmsLongitude: "",
      curvature: "0",
      buffer: 0,
      inverted: false
    };
  };

  React.useImperativeHandle(forwardedRef, () => ({
    requestSubmit() {
      if (formRef.current) {
        formHandler.setFieldValue("points", areaPoints);
        formRef.current.requestSubmit();
      }
    }
  }));

  const onCreateRow = async (row: AreaPoint) => {
    row.id = _.uniqueId(); /* to make sure row is unique in grid */
    row.wgs84Latitude = await getWgs84Latitude(row.dmsLatitude);
    row.wgs84Longitude = await getWgs84Longitude(row.dmsLongitude);

    setAreaPoints([...areaPoints, row]);
    return Promise.resolve(row);
  };

  const onUpdateRow = async (row: AreaPoint) => {
    row.wgs84Latitude = await getWgs84Latitude(row.dmsLatitude);
    row.wgs84Longitude = await getWgs84Longitude(row.dmsLongitude);

    let copy = _.cloneDeep(areaPoints);
    copy = copy.filter((lp) => lp.id !== row.id);
    copy = [...copy, row];
    setAreaPoints(copy);
    return Promise.resolve(row);
  };

  const onDeleteRow = (row: AreaPoint) => {
    let copy = _.cloneDeep(areaPoints);
    copy = copy.filter((lp) => lp.id !== row.id);
    for (let i = 0; i < copy.length; i++) {
      copy[i].sequence = i + 1;
    }
    setAreaPoints(copy);
    return Promise.resolve();
  };

  const onAddPasteCoordinates = (coordinatesList: CoordinatesList) => {
    const pastedPoints: AreaPoint[] = coordinatesList.coordinates.map((c) => {
      return {
        id: _.uniqueId(),
        lineId: -1,
        wgs84Latitude: c.wgs84Latitude,
        wgs84Longitude: c.wgs84Longitude,
        dmsLatitude: c.dmsLatitude,
        dmsLongitude: c.dmsLongitude,
        sequence: -1,
        curvature: "0",
        inverted: false,
        buffer: 0
      };
    });
    for (let i = 0; i < pastedPoints.length; i++) {
      pastedPoints[i].sequence = areaPoints.length + i + 1;
    }
    setAreaPoints([...areaPoints, ...pastedPoints]);
    setShowPasteCoordinatesDialog(false);
    toast.success("Coordinates added");
  };

  return (
    <form ref={formRef} onSubmit={formHandler.handleSubmit}>
      {showPasteCoordinatesDialog && <PasteCoordinatesDialog onCancel={onCancelPasteCoordinates} onAddCoordinates={onAddPasteCoordinates} />}
      <div style={{ width: "1400px", height: "700px", display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 0.9, display: "flex", flexDirection: "column" }}>
          <div style={{ flex: 1.2, display: "flex", flexDirection: "column" }}>
            <div style={{ flex: 1.2, display: "flex", flexDirection: "column" }}>
              <h3>General information</h3>
              <div style={{ display: "flex" }}>
                <div>
                  <TextFieldEditor
                    label={"Name"}
                    hasError={formHandler.touched.name && Boolean(formHandler.errors.name)}
                    helperText={formHandler.touched.name && formHandler.errors.name}
                    onChange={(newValue) => {
                      formHandler.setFieldValue("name", newValue);
                    }}
                  />
                </div>
                <div style={{ marginLeft: 10, flex: 1.5 }}>
                  <TextFieldEditor
                    label={"Description"}
                    onChange={(newValue) => {
                      formHandler.setFieldValue("description", newValue);
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1 }}>
                  <CategoriesSelector
                    onChange={(newValue) => {
                      formHandler.setFieldValue("category", newValue);
                    }}
                  />
                </div>
                <div style={{ marginLeft: 20, maxWidth: 30 }}>
                  <BufferMask
                    label={"Buffer"}
                    value={formHandler.values.buffer}
                    onChange={(newValue: string) => {
                      formHandler.setFieldValue("buffer", newValue);
                    }}
                    error={formHandler.touched.buffer && Boolean(formHandler.errors.buffer)}
                    helperText={formHandler.touched.buffer && formHandler.errors.buffer}
                  />
                </div>
              </div>
              <div style={{ marginTop: 5 }}>
                <UserTagSelector
                  currentSelection={formHandler.values.userTags}
                  onChange={(newValue) => {
                    formHandler.setFieldValue("userTags", newValue);
                  }}
                />
              </div>
              <h3>EMDIS center latitude/longitude</h3>
              <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                <div style={{ maxWidth: 110 }}>
                  <LatitudeMask
                    error={formHandler.touched.emdisCenterDmsLatitude && Boolean(formHandler.errors.emdisCenterDmsLatitude)}
                    helperText={formHandler.touched.emdisCenterDmsLatitude && formHandler.errors.emdisCenterDmsLatitude}
                    value={formHandler.values.emdisCenterDmsLatitude}
                    onChange={(value) => formHandler.setFieldValue("emdisCenterDmsLatitude", value)}
                  />
                </div>
                <div style={{ marginLeft: 10, maxWidth: 110 }}>
                  <LongitudeMask
                    error={formHandler.touched.emdisCenterDmsLongitude && Boolean(formHandler.errors.emdisCenterDmsLongitude)}
                    helperText={formHandler.touched.emdisCenterDmsLongitude && formHandler.errors.emdisCenterDmsLongitude}
                    value={formHandler.values.emdisCenterDmsLongitude}
                    onChange={(value) => formHandler.setFieldValue("emdisCenterDmsLongitude", value)}
                  />
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h3>Area points</h3>
                <div style={{ marginBottom: "5px" }}>
                  <Button onClick={onShowPasteCoordinatesDialog}>Paste coordinates...</Button>
                </div>
              </div>
              <div style={{ flexGrow: 1 }}>
                <GenericDataGrid
                  columnsGenerator={getPointsColumns}
                  createEmptyRow={onCreateEmptyAreaPointRow}
                  data={areaPoints}
                  validator={new BoundaryPointsValidator()}
                  onCreateRow={onCreateRow}
                  onUpdateRow={onUpdateRow}
                  onDeleteRow={onDeleteRow}
                  sortModel={[{ field: "sequence", sort: "asc" }]}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ flex: 1, height: "100%", marginLeft: "20px" }}>
          <MapViewer areaPoints={areaPoints} />
        </div>
      </div>
    </form>
  );
};

export default React.forwardRef(AddAreaForm);
