import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "src/CatApp.css";
import Layout from "src/components/layout/Layout";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { AppContextProvider } from "src/appcontext/AppContext";
import SignIn from "src/pages/signin/SignIn";

import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("e55fc9edb4f3215ea158b1b42ac90fb9Tz04MjUyNSxFPTE3Mzc3MDk2MzUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");

// fontFamily: "ui-sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Apple Color Emoji, Arial, sans-serif, Segoe UI Emoji, Segoe UI Symbol",

function CatApp() {
  const mdTheme = createTheme({
    typography: {
      fontWeightRegular: 400,
      fontFamily: "Inter, sans-serif",
      fontSize: 11,
      body1: {
        color: "#707070"
      }
    },
    components: {
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 40
          }
        }
      }
    }
  });

  return (
    <AppContextProvider>
      <ThemeProvider theme={mdTheme}>
        <Router>
          <Route path="/login" component={SignIn} />
          <Layout />
        </Router>
      </ThemeProvider>
    </AppContextProvider>
  );
}

export default CatApp;
