import { FieldsValidator } from "./Validator";

class AreaValidator implements FieldsValidator {
  validationMap = new Map<string, boolean>();

  hasValidationErrors() {
    const values = this.validationMap.values();
    return Array.from(values).includes(true);
  }

  hasNameError = (name: string) => {
    if (name === undefined || name === null) {
      this.validationMap.set("name", true);
      return "Invalid name";
    } else if (name.trim().length === 0) {
      this.validationMap.set("name", true);
      return "Invalid name";
    }
    return "";
  };

  hasBufferError = (buffer: string) => {
    if (Number.isNaN(+buffer)) {
      this.validationMap.set("buffer", true);
      return "Invalid buffer";
    }
    return "";
  };

  hasEmdisCenterLatitudeError = () => {};
  hasEmdisCenterLongitudeError = () => {};

  validateField = (fieldName: string, fieldValue: any) => {
    this.validationMap.set(fieldName, false);
    switch (fieldName) {
      case "name":
        return this.hasNameError(fieldValue);
      case "buffer":
        return this.hasBufferError(fieldValue);
      case "emdisCenterDmsLatitude": {
        if (fieldValue) {
          if (fieldValue.includes("_")) {
            this.validationMap.set(fieldName, true);
            return "Invalid latitude";
          }
          const degrees = fieldValue?.substring(0, 3);
          if (+degrees < 0 || +degrees > 90) {
            this.validationMap.set(fieldName, true);
            return "Degrees must be between 0 and 90";
          }
          const minutes = fieldValue?.substring(4, 6);
          if (+minutes < 0 || +minutes > 59) {
            this.validationMap.set(fieldName, true);
            return "Minutes must be between 0 and 59";
          }
          const seconds = fieldValue?.substring(7, 9);
          if (seconds < 0 || +seconds > 59) {
            this.validationMap.set(fieldName, true);
            return "Seconds must be between 0 and 59";
          }
        }
        this.validationMap.set(fieldName, false);
        return "";
      }
      case "emdisCenterDmsLongitude": {
        if (fieldValue) {
          if (fieldValue.includes("_")) {
            this.validationMap.set(fieldName, true);
            return "Invalid longitude";
          }
          const degrees = fieldValue?.substring(0, 3);
          if (+degrees < 0 || +degrees > 180) {
            this.validationMap.set(fieldName, true);
            return "Degrees must be between 0 and 180";
          }
          const minutes = fieldValue?.substring(4, 6);
          if (+minutes < 0 || +minutes > 59) {
            this.validationMap.set(fieldName, true);
            return "Minutes must be between 0 and 59";
          }
          const seconds = fieldValue?.substring(7, 9);
          if (seconds < 0 || +seconds > 59) {
            this.validationMap.set(fieldName, true);
            return "Seconds must be between 0 and 59";
          }
        }
        this.validationMap.set(fieldName, false);
        return "";
      }
      default:
        return "";
    }
  };
}

export default AreaValidator;
