import { useEffect, useState } from "react";
import { Polygon } from "react-leaflet";
import { Area as AreaType, AreaPoint } from "src/types/Types";

import useArc from "src/apis/lines/useArc";
import { LatLng } from "leaflet";

type AreaProps = {
  area: AreaType;
};

export const Area = (props: AreaProps) => {
  const { expandedLinePoints, setSortedLinePointsForExpansion } = useArc(100);
  const { area } = props;
  const [areaPoints, setAreaPoints] = useState<AreaPoint[]>([]);

  useEffect(() => {
    const areaPoints = area?.areaPoints || [];
    setAreaPoints(areaPoints.slice().sort((a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0)) || []);
  }, [area]);

  useEffect(() => {
    setSortedLinePointsForExpansion(areaPoints);
  }, [areaPoints]);

  return (
    <Polygon
      key={`${area.id} ${area.color} ${area.fillColor}`}
      pathOptions={{
        color: area.color,
        weight: area.color ? 0.6 : 0,
        fill: area.fillColor ? true : false,
        fillColor: area.fillColor,
        fillOpacity: area.fillColor ? 1 : 0
      }}
      interactive={false} /* prevent the area from "swallowing" the event */
      positions={expandedLinePoints.map((lp) => {
        return new LatLng(lp.wgs84Latitude, lp.wgs84Longitude);
      })}
      pane={"areaPane"}
    />
  );
};

export default Area;
