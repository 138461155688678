import { LatLng } from "leaflet";
import { Polyline } from "react-leaflet";
import { HighwayTunnel } from "src/types/Types";

type HighwayTunelsProps = {
  highwayTunnels: HighwayTunnel[];
};

const HighwayTunnels = (props: HighwayTunelsProps) => {
  const { highwayTunnels } = props;
  const defaultColor = "#1565C0";

  return (
    <>
      {highwayTunnels.map((ht) => {
        return ht.sections?.map((s) => {
          return (
            <Polyline
              pathOptions={{ color: ht.color ? ht.color : defaultColor, weight: 1, fill: false }}
              positions={s.slice().map((sp) => {
                return new LatLng(sp.wgs84Latitude, sp.wgs84Longitude);
              })}
            />
          );
        });
      })}
    </>
  );
};

export default HighwayTunnels;
