import * as _ from "lodash";
import { PolyLineWithAttributes } from "src/types/Types";
import { getAPIClient } from "../apiClient";

type MajorRoadsResponse = {
  data: PolyLineWithAttributes[];
  errorMessage: string | null;
};

export const getMajorRoads = async (versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<MajorRoadsResponse>("/swisstopo/major_roads", { headers: { "x-air-version": versionTag } });
  return response.data.data.map((co) => {
    return {
      ...co,
      id: _.uniqueId()
    };
  });
};

export const getMajorRoadsFromIds = async (versionTag: string, ids: number[]) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<MajorRoadsResponse>("/swisstopo/major_roads/search", { searchIds: ids }, { headers: { "x-air-version": versionTag } });
  return response.data.data.map((mr) => {
    return {
      ...mr,
      id: mr.attributes.id!
    };
  });
};
