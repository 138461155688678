import { Autocomplete, Button, Popover, TextField } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import { useState } from "react";

const DataGridAltitudeCorrectionSelector = (params: GridRenderEditCellParams) => {
  const { id, value, field } = params;
  const altitudeCorrections = ["qnh", "flc", "uhbu", "lhbu", "unbu", "lnbu"];

  const apiRef = useGridApiContext();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  const handleChangeValue = (event: any, value: string[]) => {
    const newValue = value; // The new value entered by the user
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <div style={{ width: 500 }}>
      <Button fullWidth onClick={handleClick}>
        {value.length > 0 ? value.join(",") : "AC"}
      </Button>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <div style={{ width: 500 }}>
          <Autocomplete
            size="small"
            fullWidth
            options={altitudeCorrections}
            multiple
            value={value}
            onChange={handleChangeValue}
            renderInput={(params) => <TextField multiline {...params} />}
          />
        </div>
      </Popover>
    </div>
  );
};

export default DataGridAltitudeCorrectionSelector;
