import { AdditionalInformation, Line, LinePoint } from "src/types/Types";
import { getAPIClient } from "../apiClient";
import { Wgs84LatLng } from "../waypoints/waypointsApi";

type LinesResponse = {
  data: Line[];
  errorMessage: string;
};

type LineResponse = {
  data: Line;
  errorMessage: string;
};

type AddLineResponse = {
  data: Line;
  errorMessage: string;
};

type DeleteLineResponse = {
  data: number;
  errorMessage: string;
};

type LinePointsResponse = {
  data: LinePoint[];
  errorMessage: string;
};

type Wgs84LatLngsResponse = {
  data: Wgs84LatLng[];
  errorMessage: string;
};

type CreateAdditionalInformationResponse = {
  data: AdditionalInformation;
  errorMessage: string;
};

type UpdateAdditionalInformationResponse = {
  data: AdditionalInformation;
  errorMessage: string;
};

type DeleteAdditionalInformationResponse = {
  data: AdditionalInformation[];
  errorMessage: string;
};

export const getLines = async (versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<LinesResponse>("/tables/line", { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const getLine = async (versionTag: string, lineId: number) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<LineResponse>(`/tables/line/${lineId}`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const getLinesFromIds = async (versionTag: string, lineIds: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<LinesResponse>(`/tables/line?lineIds=${lineIds}`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const createLine = async (versionTag: string, line: Line) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<AddLineResponse>("/tables/line", line, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const updateLine = async (versionTag: string, line: Line) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.put<AddLineResponse>(`/tables/line/${line?.id}`, line, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const deleteLine = async (versionTag: string, line: Line) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.delete<DeleteLineResponse>(`/tables/line/${line?.id}`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const createLinePoint = async (versionTag: string, line: Line, linePoint: LinePoint) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<LinePointsResponse>(`/tables/line/${line.id}/point`, linePoint, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const createLinePoints = async (versionTag: string, line: Line, afterSequence: number, linePoints: LinePoint[]) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<LinePointsResponse>(`/tables/line/${line.id}/points/${afterSequence}`, linePoints, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const updateLinePointPositions = async (versionTag: string, line: Line, linePoints: LinePoint[]) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.put<LinePointsResponse>(`/tables/line/${line.id}/points/positions`, linePoints, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const updateLinePoint = async (versionTag: string, line: Line, linePoint: LinePoint) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.put<LinePointsResponse>(`/tables/line/${line.id}/point/${linePoint.id}`, linePoint, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const deleteLinePoint = async (versionTag: string, line: Line, linePoint: LinePoint) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.delete<LinePointsResponse>(`/tables/line/${line.id}/point/${linePoint.id}`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const deleteLinePoints = async (versionTag: string, line: Line, linePoints: LinePoint[]) => {
  const _apiClient = getAPIClient();
  const strIds = linePoints.map((lp) => lp.id).join(",");
  const response = await _apiClient!.delete<LinePointsResponse>(`/tables/line/${line.id}/points/${strIds}`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const moveSelectedLinePoints = async (versionTag: string, line: Line, fromIndex: number, toIndex: number, selectedPointsSequenceNumbers: number[]) => {
  const _apiClient = getAPIClient();
  const selectedPointsSequenceNumbersStr = selectedPointsSequenceNumbers.join(",");
  let url = `/tables/line/${line.id}/points/move/${fromIndex}/${toIndex}`;

  if (selectedPointsSequenceNumbers.length > 0) url += `?selectedPointsSequenceNumbers=${selectedPointsSequenceNumbersStr}`;

  const response = await _apiClient!.put<LinePointsResponse>(url, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const getExpandedArcPoints = async (versionTag: string, from: Wgs84LatLng, to: Wgs84LatLng, curvature: string, inverted: boolean, n?: number) => {
  const _apiClient = getAPIClient();
  const fromStr = from.wgs84Latitude + "," + from.wgs84Longitude;
  const toStr = to.wgs84Latitude + "," + to.wgs84Longitude;
  const response = await _apiClient!.get<Wgs84LatLngsResponse>(`/arcPoints?from=${fromStr}&to=${toStr}&curvature=${curvature}&inverted=${inverted}&n=${n ? n : 30}`, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const createAdditionalInformation = async (versionTag: string, line: Line, additionalInformation: AdditionalInformation) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<CreateAdditionalInformationResponse>(`/tables/line/${line.id}/info`, additionalInformation, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const updateAdditionalInformation = async (versionTag: string, line: Line, additionalInformation: AdditionalInformation) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.put<UpdateAdditionalInformationResponse>(`/tables/line/${line.id}/info/${additionalInformation.id}`, additionalInformation, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const deleteAdditionalInformation = async (versionTag: string, line: Line, additionalInformation: AdditionalInformation) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.delete<DeleteAdditionalInformationResponse>(`/tables/line/${line.id}/info/${additionalInformation.id}`, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};
