import GenericDataGrid from "src/components/datagrids/GenericDataGrid";

import Split from "react-split";
import { UserTag } from "src/types/Types";
import { useUserTags } from "src/apis/usertags/useUserTags";
import useUserTagsColumns from "src/columnsgenerators/useUserTagsColumns";

import { useVersionTag } from "src/apis/hooks/useVersionTag";
import UserTagValidator from "src/validators/UserTagValidator";
import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import XRefGrid from "src/components/datagrids/XRefGrid";

const UserTags = () => {
  const { userTags, selectedUserTag, createUserTag, updateUserTag, deleteUserTag, selectUserTag } = useUserTags();
  const versionTag = useVersionTag();

  const { getColumns } = useUserTagsColumns();

  const onUpdateUserTag = async (userTag: UserTag) => {
    return updateUserTag(userTag);
  };

  const onDeleteUserTag = (userTag: UserTag) => {
    return deleteUserTag(userTag);
  };

  const onCreateUserTag = (userTag: UserTag) => {
    return createUserTag(userTag);
  };

  const onSelectUserTag = (userTag: UserTag) => {
    selectUserTag(userTag);
  };

  const onCreateEmptyUserTag = () => {
    return {
      id: -1 /* to make sure row is unique in grid */,
      name: "",
      description: "",
      lastUpdate: undefined,
      lastUpdateBy: undefined,
      xrefs: []
    };
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>User tags - {versionTag}</h2>
      <Split style={{ height: "calc(100vh - 230px)" }} direction="vertical" sizes={[60, 40]}>
        <div style={{ height: "100%" }}>
          <GenericDataGrid<UserTag>
            columnsGenerator={getColumns}
            data={userTags}
            validator={new UserTagValidator()}
            createEmptyRow={onCreateEmptyUserTag}
            onCreateRow={onCreateUserTag}
            onUpdateRow={onUpdateUserTag}
            onDeleteRow={onDeleteUserTag}
            sortModel={[{ field: "name", sort: "asc" }]}
            selectedRow={selectedUserTag}
            onSelectRow={onSelectUserTag}
          />
        </div>
        <div style={{ height: "100%" }}>
          <TabContext value={"xref"}>
            <Tabs value={"xref"}>
              <Tab value="xref" label="XREF"></Tab>
            </Tabs>
            <TabPanel value="xref" style={{ height: "100%" }}>
              <XRefGrid xrefs={selectedUserTag && selectedUserTag.xrefs ? selectedUserTag.xrefs : []} />
            </TabPanel>
          </TabContext>
        </div>
      </Split>
    </div>
  );
};

export default UserTags;
