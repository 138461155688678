import { TabContext, TabPanel } from "@mui/lab";
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs, TextField } from "@mui/material";
import AirwayPoints from "../datagrids/AirwayPoints";

import { GridRowId, GridRowModel } from "@mui/x-data-grid-pro";
import { AxiosError } from "axios";
import { FormikHelpers, useFormik } from "formik";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { getAPIClient } from "src/apis/apiClient";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { useUserTags } from "src/apis/usertags/useUserTags";
import MapViewer from "src/components/mapviewer/MapViewer";
import { Airway, AirwayPoint } from "src/types/Types";
import * as yup from "yup";

type FieldError = {
  fieldId: string;
  message: string;
};

type AirwayResponse = {
  data: Airway;
  errorMessage: string;
  fieldErrors: FieldError[];
};

type DialogAddAirwayProps = {
  open: boolean;
  onAirwayCreated: (airway: Airway) => void;
  onCancel: () => void;
};

const validationSchema = yup.object({
  name: yup.string().required("Airway name is required")
});

const AddAirwayDialog = (props: DialogAddAirwayProps) => {
  const { open, onCancel, onAirwayCreated } = props;
  const versionTag = useVersionTag();
  const versionTagRef = useRef(versionTag);

  const { userTagNames } = useUserTags();

  const [airwayPoints, setAirwayPoints] = useState<AirwayPoint[]>([]);

  const [addAirwayDisabled, setAddAirwayDisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: undefined,
      fullName: undefined,
      type: undefined,
      userTags: undefined
    },
    validationSchema: validationSchema,
    onSubmit: (values, helpers: FormikHelpers<any>) => {
      const _apiClient = getAPIClient();
      _apiClient
        ?.post<AirwayResponse>(
          "/tables/airway",
          {
            name: values.name.toUpperCase(),
            fullName: values.fullName,
            type: values.type,
            userTags: values.userTags,
            airwayPoints: airwayPoints.map((p) => {
              const nameAndArea = p.waypointName.replace(/\s+/g, "").split("-");
              return {
                waypointName: nameAndArea[0],
                area: nameAndArea.length > 1 ? nameAndArea[1] : undefined,
                sequence: p.sequence,
                remarks: p.remarks
              };
            })
          },
          { headers: { "x-air-version": versionTagRef.current } }
        )
        .then((response) => {
          toast.success("Airway created: " + response.data.data.name);
          onAirwayCreated(response.data.data);
        })
        .catch((error: AxiosError<AirwayResponse>) => {
          if (error.response) {
            if (error.response.data.fieldErrors) {
              error.response.data.fieldErrors.map((f) => {
                helpers.setFieldError(f.fieldId, f.message);
                if (f.fieldId === "airwayPoints") {
                  toast.error(f.message);
                }
              });
            }
            if (error.response.data.errorMessage) {
              toast.error(error.response.data.errorMessage);
            }
          }
        });
    }
  });

  const onProcessRowUpdate = (row: GridRowModel<AirwayPoint>) => {
    if (row.waypointName === undefined || row.waypointName.length === 0) {
      toast.error("Update failed: missing waypoint");
      return Promise.reject(row);
    }
    const updatedRow = { ...row, isNew: false };
    setAirwayPoints((oldPoints) => {
      return [...oldPoints.filter((a) => a.id !== row.id), updatedRow];
    });
    return updatedRow;
  };

  const onDeleteWaypoint = (rowId: GridRowId) => {
    // note this is a shallow copy. Probably better off using cloneDeep()
    const points = airwayPoints.slice().filter((p) => p.id !== rowId);
    for (let i = 0; i < points.length; i++) {
      points[i].sequence = i + 1;
    }
    setAirwayPoints(points);
  };

  const onCancelEdit = () => {
    // force reset the grid to its initial state
    setAirwayPoints((oldPoints) => {
      return [...oldPoints];
    });
  };

  const onRowOrdersChanged = (newRows: AirwayPoint[]) => {
    setAirwayPoints(newRows);
  };

  useEffect(() => {
    if (airwayPoints.length < 2) setAddAirwayDisabled(true);
    else setAddAirwayDisabled(false);
  }, [airwayPoints]);

  return (
    <Dialog maxWidth="xl" open={open}>
      <DialogTitle>Add Airway</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers>
          <div style={{ width: "1200px", height: "600px", display: "flex", flexDirection: "column" }}>
            {/*<div style={{ color: "red", height: "5%" }}>{"General error message"}</div>*/}
            <div style={{ display: "flex" }}>
              <div>
                <TextField
                  id="name"
                  name="name"
                  value={formik.values.name}
                  label="Airway name"
                  autoComplete="off"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue("name", event.target.value.toUpperCase());
                  }}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </div>
              <div style={{ marginLeft: "10px" }}>
                <TextField
                  id="fullName"
                  name="fullName"
                  value={formik.values.fullName}
                  label="Airway full name"
                  onChange={formik.handleChange}
                  error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                  helperText={formik.touched.fullName && formik.errors.fullName}
                />
              </div>
              <div style={{ marginLeft: "10px" }}>
                <Autocomplete
                  sx={{ width: 200 }}
                  options={["LFN"]}
                  onChange={(event, value) => {
                    formik.setFieldValue("type", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Airway type"
                      value={formik.values.type}
                      onChange={formik.handleChange}
                      error={formik.touched.type && Boolean(formik.errors.type)}
                      helperText={formik.touched.type && formik.errors.type}
                    />
                  )}
                />
              </div>
              <div style={{ marginLeft: "10px" }}>
                <Autocomplete
                  options={userTagNames}
                  multiple
                  sx={{ width: 300 }}
                  onChange={(event, value) => {
                    formik.setFieldValue("userTags", value);
                  }}
                  renderInput={(params) => <TextField {...params} label="User tags" value={formik.values.userTags} onChange={formik.handleChange} />}
                />
              </div>
            </div>
            <div style={{ height: "75%" }}>
              <TabContext value={"airwayPoints"}>
                <Tabs value={"airwayPoints"}>
                  <Tab value="airwayPoints" label={"Airway points"} />
                </Tabs>
                <TabPanel value="airwayPoints" style={{ height: "100%" }}>
                  <div style={{ height: "100%", display: "flex" }}>
                    <div style={{ flex: 1, height: "100%", marginRight: "5px" }}>
                      <AirwayPoints
                        selectedAirway={null}
                        airwayPoints={airwayPoints}
                        onProcessRowUpdate={onProcessRowUpdate}
                        onDeleteAirwayPoint={onDeleteWaypoint}
                        onCancelEdit={onCancelEdit}
                        onRowOrdersChanged={onRowOrdersChanged}
                      />
                    </div>
                    <div style={{ flex: 1, height: "100%" }}>
                      <MapViewer airwayPoints={airwayPoints.slice()} />
                    </div>
                  </div>
                </TabPanel>
              </TabContext>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant="contained" type="submit" disabled={addAirwayDisabled}>
            Add
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddAirwayDialog;
