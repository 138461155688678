import { useEffect, useState } from "react";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { getRailways } from "src/apis/swisstopo/railwaysApi";
import { PolyLineWithAttributes } from "src/types/Types";

const useRailways = () => {
  const versionTag = useVersionTag();
  const [railways, setRailways] = useState<PolyLineWithAttributes[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getRailways(versionTag)
      .then((response) => {
        setRailways(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [versionTag]);

  return {
    loading,
    railways: railways
  };
};

export default useRailways;
