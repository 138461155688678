import { useRef, useState } from "react";
import { Button, Checkbox, Divider, FormControlLabel, InputAdornment, Popover, TextField, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ClientType } from "src/pages/clienttypes/ClientTypes";

type ClientTypesSelectorProps = {
  clientTypes: ClientType[];
  currentSelection?: ClientType[];
  onChangeSelection?: (newSelection: ClientType[]) => void;
};

const ClientTypesSelector = (props: ClientTypesSelectorProps) => {
  const { clientTypes, currentSelection = [], onChangeSelection } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
  const textFieldRef = useRef<HTMLInputElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(textFieldRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  const handleChangeAor = (event: React.ChangeEvent<HTMLInputElement>, aorName: string | null) => {
    if (event.target.checked) {
      let newSelection = [...currentSelection];
      newSelection = [...newSelection.filter((ct) => ct.fir !== aorName)];
      newSelection = [...newSelection, ...clientTypes.filter((ct) => ct.fir === aorName)];
      onChangeSelection?.(newSelection);
    } else {
      onChangeSelection?.(currentSelection.filter((ct) => ct.fir !== aorName));
    }
  };

  const handleChangeClientType = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    if (event.target.checked) {
      onChangeSelection?.([...currentSelection, ...clientTypes.filter((ct) => ct.name === name)]);
    } else {
      onChangeSelection?.(currentSelection.filter((ct) => ct.name !== name));
    }
  };

  const isChecked = (aorName: string | null) => {
    const aorClientTypes = [
      ...clientTypes
        .filter((ct) => ct.fir === aorName)
        .map((ct) => ct.name)
        .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
    ];
    const selectedAorClientTypes = [
      ...currentSelection
        .filter((ct) => ct.fir === aorName)
        .map((ct) => ct.name)
        .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
    ];
    return JSON.stringify(aorClientTypes) === JSON.stringify(selectedAorClientTypes);
  };

  const isIndeterminate = (aorName: string | null) => {
    const aorClientTypes = [
      ...clientTypes
        .filter((ct) => ct.fir === aorName)
        .map((ct) => ct.name)
        .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
    ];
    const selectedAorClientTypes = [
      ...currentSelection
        .filter((ct) => ct.fir === aorName)
        .map((ct) => ct.name)
        .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
    ];
    if (selectedAorClientTypes.length > 0) {
      return JSON.stringify(aorClientTypes) !== JSON.stringify(selectedAorClientTypes);
    }
    return false;
  };

  return (
    <div style={{ width: "100%" }}>
      <Tooltip title={currentSelection.length > 0 ? currentSelection.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0)).map((ct) => ct.name + ", ") : "No values"}>
        <TextField
          ref={textFieldRef}
          label="Client types"
          value={currentSelection.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0)).map((ct) => ct.name)}
          size="small"
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button onClick={handleClick} style={{ marginBottom: "4px" }}>
                  <SearchIcon />
                </Button>
              </InputAdornment>
            )
          }}
        />
      </Tooltip>

      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <div style={{ width: "450px", height: "200px", display: "flex", flexDirection: "row" }}>
          <div style={{ flex: 1, marginLeft: "5px" }}>
            <FormControlLabel
              label="LSAG"
              control={<Checkbox onChange={(event) => handleChangeAor(event, "LSAG")} checked={isChecked("LSAG")} indeterminate={isIndeterminate("LSAG")} />}
            />
            <Divider />
            <div style={{ marginLeft: "10px" }}>
              {clientTypes
                .filter((ct) => ct.fir === "LSAG")
                .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                .map((ct) => {
                  return (
                    <FormControlLabel
                      key={ct.name}
                      label={ct.name}
                      control={
                        <Checkbox
                          checked={currentSelection.map((t) => t.name).includes(ct.name) ? true : false}
                          onChange={(event) => {
                            handleChangeClientType(event, ct.name);
                          }}
                        />
                      }
                    />
                  );
                })}
            </div>
          </div>
          <div style={{ flex: 1, marginLeft: "10px" }}>
            <FormControlLabel
              label="LSAZ"
              control={<Checkbox onChange={(event) => handleChangeAor(event, "LSAZ")} checked={isChecked("LSAZ")} indeterminate={isIndeterminate("LSAZ")} />}
            />
            <Divider />
            <div style={{ marginLeft: "10px" }}>
              {clientTypes
                .filter((ct) => ct.fir === "LSAZ")
                .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                .map((ct) => {
                  return (
                    <FormControlLabel
                      key={ct.name}
                      label={ct.name}
                      control={
                        <Checkbox
                          checked={currentSelection.map((t) => t.name).includes(ct.name) ? true : false}
                          onChange={(event) => {
                            handleChangeClientType(event, ct.name);
                          }}
                        />
                      }
                    />
                  );
                })}
            </div>
          </div>
          <div style={{ flex: 1, marginLeft: "10px" }}>
            <FormControlLabel
              label="Other"
              control={<Checkbox onChange={(event) => handleChangeAor(event, null)} checked={isChecked(null)} indeterminate={isIndeterminate(null)} />}
            />
            <Divider />
            <div style={{ marginLeft: "10px" }}>
              <div style={{ marginLeft: "10px" }}>
                {clientTypes
                  .filter((ct) => ct.fir === null)
                  .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                  .map((ct) => {
                    return (
                      <FormControlLabel
                        label={ct.name}
                        control={
                          <Checkbox
                            checked={currentSelection.map((t) => t.name).includes(ct.name) ? true : false}
                            onChange={(event) => {
                              handleChangeClientType(event, ct.name);
                            }}
                          />
                        }
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default ClientTypesSelector;
