import { NamedPolygons } from "src/types/Types";
import { getAPIClient } from "../apiClient";

type LakesResponse = {
  data: NamedPolygons[];
  errorMessage: string | null;
};

export const getLakes = async (versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<LakesResponse>("/swisstopo/lakes", { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const getLakesFromIds = async (versionTag: string, lakeIds: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<LakesResponse>(`/swisstopo/lakes?lakeIds=${lakeIds}`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};
