import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import AlertIcon from "@mui/icons-material/ErrorOutline";
import { MapElementReferencesUpdates } from "src/types/Types";

type ConfirmChangesDialogProps = {
  mapElementUpdates: MapElementReferencesUpdates | undefined;
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmChangesDialog = (props: ConfirmChangesDialogProps) => {
  const { mapElementUpdates, onConfirm, onCancel } = props;
  return (
    <Dialog maxWidth="xl" open={true}>
      <DialogTitle>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ color: "orange", fontSize: "32px" }}>
            <AlertIcon style={{ fontSize: "inherit" }} />
          </div>
          <div style={{ marginLeft: "10px", marginBottom: "10px" }}>{"Confirm changes"}</div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ display: "flex", flexDirection: "column", width: "800px", height: "400px", overflowY: "scroll" }}>
          <h3>Path</h3>
          {mapElementUpdates?.oldPath === mapElementUpdates?.newPath ? (
            <div style={{ marginLeft: "20px" }}>No Changes</div>
          ) : (
            <div style={{ marginLeft: "20px" }}>
              <div>{`New: ${mapElementUpdates?.newPath}`}</div>
              <div>{`Old: ${mapElementUpdates?.oldPath}`}</div>
            </div>
          )}
          <h3>Code</h3>
          {mapElementUpdates?.oldCode === mapElementUpdates?.newCode ? (
            <div style={{ marginLeft: "20px" }}>No Changes</div>
          ) : (
            <div style={{ marginLeft: "20px" }}>
              <div>{`New: ${mapElementUpdates?.newCode}`}</div>
              <div>{`Old: ${mapElementUpdates?.oldCode}`}</div>
            </div>
          )}
          <h3>Name</h3>
          {mapElementUpdates?.oldName === mapElementUpdates?.newName ? (
            <div style={{ marginLeft: "20px" }}>No Changes</div>
          ) : (
            <div style={{ marginLeft: "20px" }}>
              <div>{`New: ${mapElementUpdates?.newName}`}</div>
              <div>{`Old: ${mapElementUpdates?.oldName}`}</div>
            </div>
          )}
          <h3>User tags</h3>
          {mapElementUpdates?.addedUserTags.length === 0 && mapElementUpdates?.deletedUserTags.length === 0 ? <div style={{ marginLeft: "20px" }}>No Changes</div> : <></>}
          {mapElementUpdates?.addedUserTags.map((ref) => {
            return (
              <div style={{ display: "flex", marginLeft: "20px" }}>
                <div style={{ flex: 1 }}>{ref}</div>
                <div style={{ flex: 1, color: "green" }}>Added</div>
              </div>
            );
          })}
          {mapElementUpdates?.deletedUserTags.map((ref) => {
            return (
              <div style={{ display: "flex", marginLeft: "20px" }}>
                <div style={{ flex: 1 }}>{ref}</div>
                <div style={{ flex: 1, color: "red" }}>Deleted</div>
              </div>
            );
          })}
          <h3>Client types</h3>
          {mapElementUpdates?.addedClientTypes.length === 0 && mapElementUpdates?.deletedClientTypes.length === 0 ? <div style={{ marginLeft: "20px" }}>No Changes</div> : <></>}
          {mapElementUpdates?.addedClientTypes.map((ref) => {
            return (
              <div style={{ display: "flex", marginLeft: "20px" }}>
                <div style={{ flex: 1 }}>{ref}</div>
                <div style={{ flex: 1, color: "green" }}>Added</div>
              </div>
            );
          })}
          {mapElementUpdates?.deletedClientTypes.map((ref) => {
            return (
              <div style={{ display: "flex", marginLeft: "20px" }}>
                <div style={{ flex: 1 }}>{ref}</div>
                <div style={{ flex: 1, color: "red" }}>Deleted</div>
              </div>
            );
          })}
          <h3>References</h3>
          {mapElementUpdates?.addedReferences.length === 0 && mapElementUpdates?.deletedReferences.length === 0 ? <div style={{ marginLeft: "20px" }}>No Changes</div> : <></>}
          {mapElementUpdates?.addedReferences.map((ref) => {
            return (
              <div style={{ display: "flex", marginLeft: "20px" }}>
                <div style={{ flex: 1 }}>{ref.name}</div>
                <div style={{ flex: 1 }}>{ref.referenceType}</div>
                <div style={{ flex: 1, color: "green" }}>Added</div>
              </div>
            );
          })}
          {mapElementUpdates?.deletedReferences.map((ref) => {
            return (
              <div style={{ display: "flex", marginLeft: "20px" }}>
                <div style={{ flex: 1 }}>{ref.name}</div>
                <div style={{ flex: 1 }}>{ref.referenceType}</div>
                <div style={{ flex: 1, color: "red" }}>Deleted</div>
              </div>
            );
          })}
          <h3>Graphic attribute</h3>
          {mapElementUpdates?.refAttributeUpdates
            .filter((ref) => ref.updateType === "GRAPHIC_ATTRIBUTE")
            .map((ref) => {
              return (
                <div style={{ display: "flex", marginLeft: "20px" }}>
                  <div style={{ flex: 0.8 }}>{`${ref.mapElementReference.name} (${ref.mapElementReference.referenceType})`}</div>
                  <div style={{ flex: 1 }}>{`New: ${ref.newValue}`}</div>
                  <div style={{ flex: 1 }}>{`Old: ${ref.oldValue}`}</div>
                </div>
              );
            })}
          <h3>Aux. graphic attribute</h3>
          {mapElementUpdates?.refAttributeUpdates
            .filter((ref) => ref.updateType === "AUX_GRAPHIC_ATTRIBUTE")
            .map((ref) => {
              return (
                <div style={{ display: "flex", marginLeft: "20px" }}>
                  <div style={{ flex: 0.8 }}>{`${ref.mapElementReference.name} (${ref.mapElementReference.referenceType})`}</div>
                  <div style={{ flex: 1 }}>{`New: ${ref.newValue}`}</div>
                  <div style={{ flex: 1 }}>{`Old: ${ref.oldValue}`}</div>
                </div>
              );
            })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button autoFocus variant="contained" onClick={onConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmChangesDialog;
