import React from "react";

const Home = () => {
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <div style={{ fontSize: "40px", marginBottom: "10px" }}>Home</div>
    </div>
  );
};

export default Home;
