import { FieldsValidator } from "./Validator";

const airbandRegex = /^(118|119|12[0-9]|13[0-6])\.(\d{1,3})$/;

class FrequencyValidator implements FieldsValidator {
  validationMap = new Map<string, boolean>();

  hasValidationErrors() {
    const values = this.validationMap.values();
    return Array.from(values).includes(true);
  }

  validateFrequency(frequency: string) {
    const match = frequency.match(airbandRegex);
    if (!match) {
      return false;
    }
    // Ensure the fractional part is valid and divisible by 5
    const fractionalPart = match[2];
    const paddedFractionalPart = fractionalPart.padEnd(3, "0"); // Pad to 3 digits
    const fractionalValue = parseInt(paddedFractionalPart, 10);

    return fractionalValue >= 0 && fractionalValue <= 975 && fractionalValue % 5 === 0;
  }

  validateField = (fieldName: string, fieldValue: any) => {
    this.validationMap.set(fieldName, false);
    switch (fieldName) {
      case "name":
        if (fieldValue === undefined || fieldValue === null) {
          this.validationMap.set("name", true);
          return "Invalid name";
        } else if (fieldValue.trim().length === 0) {
          this.validationMap.set("name", true);
          return "Invalid name";
        }
        return "";

      case "frequency":
        if (fieldName === undefined || fieldName === null) {
          this.validationMap.set("frequency", true);
          return "Invalid frequency";
        } else if (fieldName.trim().length === 0) {
          this.validationMap.set("frequency", true);
          return "Invalid frequency";
        } else if (!this.validateFrequency(fieldValue)) {
          this.validationMap.set("frequency", true);
          return "Invalid frequency";
        }
        return "";
      default:
        return "";
    }
  };
}

export default FrequencyValidator;
