import { Autocomplete, TextField } from "@mui/material";

type AirportTypeSelectorProps = {
  value?: string;
  onChange?: (newValue: string | null) => void;
};

const AirportTypeSelector = (props: AirportTypeSelectorProps) => {
  const { value, onChange } = props;
  const airportTypes = ["IFR Main", "IFR Regional", "VFR Concrete", "VFR Grass", "VFR Private", "Civil-Military", "Military", "Glider", "Heliport"];

  const _onChange = (event: any, value: string | null) => {
    onChange?.(value);
  };

  return (
    <Autocomplete
      onChange={_onChange}
      size="small"
      value={value}
      fullWidth
      options={airportTypes}
      renderInput={(params) => <TextField variant="standard" {...params} label="Airport type" />}
    />
  );
};

export default AirportTypeSelector;
