import { GraphicAttribute } from "src/types/Types";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import useGraphicAttributes from "src/apis/graphicattributes/useGraphicAttributes";
import GenericDataGrid from "src/components/datagrids/GenericDataGrid";
import useGraphicAttributeColumns from "src/columnsgenerators/useGraphicAttributeColumns";
import GraphicsAttributeValidator from "src/validators/GraphicAttributeValidator";

const GraphicAttributes = () => {
  const currentVersion = useVersionTag();
  const { loading, graphicAttributes, selectedGraphicAttribute, selectGraphicAttribute, createGraphicAttribute, updateGraphicAttribute, deleteGraphicAttribute } =
    useGraphicAttributes();

  const { getColumns } = useGraphicAttributeColumns();

  const createEmptyGraphicAttribute = (): GraphicAttribute => {
    return {
      id: -1,
      priority: 0,
      name: "",
      type: "LINE",
      emdisCode: 0,
      color: "#000000",
      linePattern: "",
      fillPattern: "",
      fillPatternNumber: 0,
      symbol: "",
      size: 12,
      font: "",
      fontSize: 10,
      displayedInformation: [],
      legalColor: "#000000",
      legalSymbol: 0,
      legalLineStyle: 0,
      userTags: []
    };
  };

  const onCreateGraphicAttribute = (graphicAttribute: GraphicAttribute) => {
    return createGraphicAttribute(graphicAttribute);
  };

  const onUpdateGraphicAttribute = (graphicAttribute: GraphicAttribute) => {
    return updateGraphicAttribute(graphicAttribute);
  };

  const onDeleteGraphicAttribute = (graphicAttribute: GraphicAttribute) => {
    return deleteGraphicAttribute(graphicAttribute);
  };

  const onSelectGraphicAttribute = (graphicAttribute: GraphicAttribute) => {
    selectGraphicAttribute(graphicAttribute);
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Graphic attributes - {currentVersion}</h2>
      <div style={{ height: "90%" }}>
        <GenericDataGrid<GraphicAttribute>
          columnsGenerator={getColumns}
          createEmptyRow={createEmptyGraphicAttribute}
          validator={new GraphicsAttributeValidator()}
          data={graphicAttributes}
          loading={loading}
          sortModel={[{ field: "name", sort: "asc" }]}
          onCreateRow={onCreateGraphicAttribute}
          onUpdateRow={onUpdateGraphicAttribute}
          selectedRow={selectedGraphicAttribute}
          onSelectRow={onSelectGraphicAttribute}
          onDeleteRow={onDeleteGraphicAttribute}
        />
      </div>
    </div>
  );
};

export default GraphicAttributes;
