import { LatLng } from "leaflet";
import { Polygon } from "react-leaflet";
import { UrbanArea } from "src/types/Types";

type UrbanAreasProps = {
  urbanAreas: UrbanArea[];
};

const UrbanAreas = (props: UrbanAreasProps) => {
  const { urbanAreas } = props;
  const defaultColor = "#1565C0";

  return (
    <>
      {urbanAreas.map((ua) => {
        return ua.polygons.map((p) => {
          return (
            <Polygon
              pathOptions={{ color: ua.color ? ua.color : defaultColor, weight: 1, fill: false }}
              positions={p.coordinates.slice().map((c) => {
                return new LatLng(c.wgs84Latitude, c.wgs84Longitude);
              })}
            />
          );
        });
      })}
    </>
  );
};

export default UrbanAreas;
