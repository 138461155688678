import ClockwiseIcon from "@mui/icons-material/Refresh";
import AntiClockwiseIcon from "@mui/icons-material/Replay";
import { GridColumns, GridPreProcessEditCellProps, GridRenderCellParams } from "@mui/x-data-grid-pro";
import DataGridTextFieldEditor from "src/components/editors/DataGridTextFieldEditor";
import DatagridTurnSelector from "src/components/selectors/turnselector/DatagridTurnSelector";
import DataGridUserTagSelector from "src/components/selectors/usertagselector/DataGridUserTagSelector";
import DatagridWaypointSelector from "src/components/selectors/waypointselector/DatagridWaypointSelector";
import { ColumnsGenerator, Holding } from "src/types/Types";
import { customUserTagFilteringOperators } from "./customUserTagFilteringOperators";
import { userTagGridComparator } from "./customUserTagGridComparator";

const useHoldingColumns = () => {
  const getColumns: ColumnsGenerator = (validator, onFieldDirty) => {
    const columns: GridColumns = [
      {
        field: "name",
        headerName: "Name",
        width: 100,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("name", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        }
      },
      { field: "description", headerName: "Description", width: 150, editable: true },
      {
        field: "fix",
        headerName: "Fix",
        width: 120,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("fix", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DatagridWaypointSelector {...params} />;
        }
      },
      {
        field: "inboundTrack",
        headerName: "Inbound track",
        width: 100,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("inboundTrack", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        }
      },
      { field: "outboundLeg", headerName: "Outbound leg", width: 100, editable: true },
      {
        field: "radial",
        headerName: "Radial",
        width: 100,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("radial", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        }
      },
      {
        field: "navaid",
        headerName: "Nav. aid",
        width: 120,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DatagridWaypointSelector {...params} />;
        }
      },
      {
        field: "frequency",
        headerName: "Frequency",
        width: 100,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("frequency", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        }
      },
      {
        field: "legTimeMinuteSeconds",
        headerName: "Leg time",
        width: 100,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("legTimeMinuteSeconds", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        }
      },
      {
        field: "turnClockwise",
        headerName: "Turn",
        width: 70,
        editable: true,
        renderCell: (props: GridRenderCellParams<Holding>) => {
          return <div>{props.row.turnClockwise === true ? <ClockwiseIcon /> : <AntiClockwiseIcon />}</div>;
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DatagridTurnSelector {...params} />;
        },
        valueParser: (value: string) => {
          if (value === "right") {
            return true;
          } else {
            return false;
          }
        }
      },
      {
        field: "minimumLevel",
        headerName: "Min. level",
        width: 80,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("minimumLevel", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        }
      },
      {
        field: "maximumLevel",
        headerName: "Max. level",
        width: 80,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("maximumLevel", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        }
      },
      {
        field: "maximumAirspeed",
        headerName: "Max. IAS",
        width: 100,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("maximumAirspeed", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        }
      },
      {
        field: "minimumReceptionAltitude",
        headerName: "MRA",
        width: 80,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("minimumReceptionAltitude", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        }
      },
      {
        field: "userTags",
        headerName: "User Tags",
        width: 140,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridUserTagSelector {...params} />;
        },
        filterOperators: customUserTagFilteringOperators,
        sortComparator: userTagGridComparator
      }
    ];
    return columns;
  };
  return { getColumns };
};

export default useHoldingColumns;
