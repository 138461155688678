import { ChangeEvent } from "react";

import { TextField } from "@mui/material";
import InputMask from "react-input-mask";

import useBufferMask from "./hooks/useBufferMask";

type BufferMaskProps = {
  label?: string;
  value?: string;
  onChange?: (newValue: string) => void;
  error?: boolean;
  helperText?: string | false;
};

const BufferMask = (props: BufferMaskProps) => {
  const { label, value, onChange, error, helperText } = props;
  const bufferMask = useBufferMask();
  return (
    <div>
      <InputMask
        alwaysShowMask
        mask={bufferMask}
        value={value?.toString()}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          onChange?.(event.target.value.toUpperCase());
        }}
      >
        <TextField
          label={label}
          size="small"
          variant="standard"
          id="elevation"
          name="elevation"
          autoComplete="off"
          error={error}
          helperText={helperText}
          sx={{ input: { color: error ? "red" : "" } }}
        />
      </InputMask>
    </div>
  );
};

export default BufferMask;
