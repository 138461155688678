import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import AreaTypeSelector from "./AreaTypeSelector";

const DatagridAreaTypeSelector = (params: GridRenderEditCellParams) => {
  const { id, value, field, error } = params;
  const apiRef = useGridApiContext();

  const handleChangeValue = (newValue: string | null) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };
  return <AreaTypeSelector value={value} onChange={handleChangeValue} />;
};

export default DatagridAreaTypeSelector;
