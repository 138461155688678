import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useAppContext } from "src/appcontext/AppContext";
import { toast } from "react-toastify";
import ClientTypesGrid from "./datagrids/ClientTypesGrid";
import axios, { AxiosError } from "axios";

export type ClientType = {
  id: number;
  name: string;
  targetSystem: string;
  fir: string | undefined;
  local: boolean | undefined;
  swiss: boolean | undefined;
  userTags: Array<string>;
  lastUpdate: string | undefined;
  lastUpdateBy: string | undefined;
};

export type ClientTypeResponse = {
  data: ClientType;
  errorMessage: string;
};

export type ClientTypesResponse = {
  data: ClientType[];
  errorMessage: string;
};

const ClientTypes = () => {
  const [clientTypes, setClientTypes] = useState<ClientType[]>(new Array());
  const [showDialogConfirmation, setShowDialogConfirmation] = useState(false);
  const [lastDeletedClientType, setLastDeletedClientType] = useState<ClientType | undefined>();
  const appContext = useAppContext();
  const currentVersion = appContext.state.currentVersion;

  useEffect(() => {
    const apiClient = appContext.state.apiClient;
    const versionTag = appContext.state.currentVersion?.versionTag
      ? appContext.state.currentVersion.versionTag
      : "trunk";
    apiClient
      ?.get<ClientTypesResponse>("/tables/clienttype", { headers: { "x-air-version": versionTag } })
      .then((axiosResponse) => {
        setClientTypes(axiosResponse.data.data);
      });
  }, [currentVersion]);

  const onCreateClientType = async (clientType: ClientType) => {
    const apiClient = appContext.state.apiClient;
    const versionTag = appContext.state.currentVersion?.versionTag
      ? appContext.state.currentVersion.versionTag
      : "trunk";

    return apiClient!
      .post<ClientTypeResponse>("/tables/clienttype", clientType, { headers: { "x-air-version": versionTag } })
      .then((response) => {
        setClientTypes([...clientTypes, response.data.data]);
        toast.success(`Client type created: ${clientType.name}`);
      });
  };

  const onDeleteClientType = (id: number) => {
    const clientType = clientTypes.find((o) => o.id === id);
    if (clientType) {
      setLastDeletedClientType(clientType);
      setShowDialogConfirmation(true);
    }
  };

  const onUpdateClientType = async (clientType: ClientType) => {
    const apiClient = appContext.state.apiClient;
    const versionTag = appContext.state.currentVersion?.versionTag ? appContext.state.currentVersion.versionTag : "";
    return apiClient!
      .put<ClientTypeResponse>(`/tables/clienttype/${clientType.id}`, clientType, {
        headers: { "x-air-version": versionTag }
      })
      .then((response) => {
        let updatedClientTypes = [...clientTypes];
        updatedClientTypes = updatedClientTypes.filter((o) => {
          return o.id !== clientType.id;
        });
        setClientTypes([...updatedClientTypes, response.data.data]);
        toast.success(`Client type updated: ${clientType.name}`);
      });
  };

  const onDeleteConfirmationNo = () => {
    setShowDialogConfirmation(false);
  };

  const onDeleteConfirmationYes = () => {
    const apiClient = appContext.state.apiClient;
    const versionTag = appContext.state.currentVersion?.versionTag
      ? appContext.state.currentVersion.versionTag
      : "trunk";
    if (lastDeletedClientType !== undefined) {
      apiClient!
        .delete<void>(`/tables/clienttype/${lastDeletedClientType?.id}`, {
          headers: { "x-air-version": versionTag }
        })
        .then(() => {
          let updatedClientTypes = [...clientTypes];
          updatedClientTypes = updatedClientTypes.filter((o) => {
            return o.id !== lastDeletedClientType.id;
          });
          setClientTypes([...updatedClientTypes]);
          toast.info(`Client type deleted: ${lastDeletedClientType.name}`);
        })
        .catch((error: Error | AxiosError) => {
          if (axios.isAxiosError(error)) {
            toast.error(error.response?.data.errorMessage);
          }
        });
    }
    setLastDeletedClientType(undefined);
    setShowDialogConfirmation(false);
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Client types - {appContext.state.currentVersion?.versionTag}</h2>
      <div style={{ height: "90%" }}>
        <Dialog maxWidth="xs" open={showDialogConfirmation}>
          <DialogTitle>Please confirm</DialogTitle>
          <DialogContent dividers>{`Delete client type: ${lastDeletedClientType?.name}`}</DialogContent>
          <DialogActions>
            <Button onClick={onDeleteConfirmationNo}>No</Button>
            <Button onClick={onDeleteConfirmationYes}>Yes</Button>
          </DialogActions>
        </Dialog>
        <ClientTypesGrid
          clientTypes={clientTypes}
          onCreateClientType={onCreateClientType}
          onUpdateClientType={onUpdateClientType}
          onDeleteClientType={onDeleteClientType}
        />
      </div>
    </div>
  );
};

export default ClientTypes;
