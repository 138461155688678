import { Autocomplete, TextField } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import { useMapSymbols } from "src/apis/symbols/useMapSymbols";

const SymbolSelector = (params: GridRenderEditCellParams) => {
  const apiRef = useGridApiContext();
  const { id, value, field } = params;
  const { symbols } = useMapSymbols();

  const handleChangeValue = (event: any, value: string | null) => {
    const newValue = value; // The new value entered by the user
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <div style={{ width: 500 }}>
      <Autocomplete
        size="small"
        id="turn"
        options={symbols.map((s) => s.name)}
        value={value}
        renderInput={(params) => {
          return <TextField variant="standard" {...params} />;
        }}
        onChange={handleChangeValue}
      />
    </div>
  );
};

export default SymbolSelector;
