import { MapElementReference, MapElementReferencesUpdates, MapElementType, MenuItem } from "src/types/Types";
import { getAPIClient } from "../apiClient";

export type SearchCriteria = {
  mapElementTypes: MapElementType[];
  userTag: string | null;
};

type MenuItemsResponse = {
  data: MenuItem[];
  errorMessage: string;
};

type GetPathsResponse = {
  data: string[];
  errorMessage: string;
};

type CreateMapResponse = {
  data: MenuItem;
  errorMessage: string;
};

type UpdateMapResponse = {
  data: MenuItem;
  errorMessage: string;
};

type CreateMenuResponse = {
  data: MenuItem;
  errorMessage: string;
};

type MoveMenuItemResponse = {
  data: MenuItem;
  errorMessage: string;
};

type DeleteMenuResponse = {
  data: number;
  errorMessage: string;
};

type NextSequenceValueResponse = {
  data: number;
  errorMessage: string;
};

type MapElementsReferenceResponse = {
  data: MapElementReference[];
  errorMessage: string;
};

type DeleteMapResponse = {
  data: number;
  errorMessage: string;
};

type MapElementReferencesResponse = {
  data: MapElementReference[];
  errorMessage: string;
};

type MapElementReferencesUpdatesResponse = {
  data: MapElementReferencesUpdates;
  errorMessage: string;
};

export const getMenuItems = async (versionTag: string) => {
  const _apiClient = getAPIClient();
  const result = await _apiClient!.get<MenuItemsResponse>("/tables/menu", { headers: { "x-air-version": versionTag } });
  return result.data.data;
};

export const getMapReferences = async (versionTag: string, menu: MenuItem) => {
  const _apiClient = getAPIClient();
  const result = await _apiClient!.get<MapElementReferencesResponse>("/tables/map/" + menu.id + "/references", {
    headers: { "x-air-version": versionTag }
  });
  return result?.data.data;
};

export const getPaths = async (versionTag: string) => {
  const _apiClient = getAPIClient();
  const result = await _apiClient!.get<GetPathsResponse>("/tables/menu/paths", { headers: { "x-air-version": versionTag } });
  return result.data.data;
};

export const getNextSequenceValue = async () => {
  const _apiClient = getAPIClient();
  const result = await _apiClient!.get<NextSequenceValueResponse>("/sequence/next-value");
  return result.data.data;
};

export const createMap = async (versionTag: string, map: MenuItem) => {
  const _apiClient = getAPIClient();
  const result = await _apiClient!.post<CreateMapResponse>("/tables/map", map, { headers: { "x-air-version": versionTag } });
  return result.data.data;
};

export const updateMap = async (versionTag: string, map: MenuItem) => {
  const _apiClient = getAPIClient();
  const result = await _apiClient!.put<UpdateMapResponse>(`/tables/map/${map.id}`, map, { headers: { "x-air-version": versionTag } });
  return result.data.data;
};

export const deleteMap = async (versionTag: string, map: MenuItem) => {
  const _apiClient = getAPIClient();
  const result = await _apiClient!.delete<DeleteMapResponse>(`/tables/map/${map.id}`, { headers: { "x-air-version": versionTag } });
  return result.data.data;
};

export const deleteMapReference = async (versionTag: string, map: MenuItem, mapElementReference: MapElementReference) => {
  const _apiClient = getAPIClient();
  await _apiClient!.delete(`/tables/map/${map.id}/references/${mapElementReference.id}`, { headers: { "x-air-version": versionTag } });
};

export const createMenu = async (versionTag: string, menu: MenuItem) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<CreateMenuResponse>("/tables/menu/", menu, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const moveMenuItemUp = async (versionTag: string, menuItem: MenuItem) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.put<MoveMenuItemResponse>(`/tables/menu/up/${menuItem.id}`, menuItem, { headers: { "x-air-version": versionTag } });
};

export const moveMenuItemDown = async (versionTag: string, menuItem: MenuItem) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.put<MoveMenuItemResponse>(`/tables/menu/down/${menuItem.id}`, menuItem, { headers: { "x-air-version": versionTag } });
};

export const moveMenuItemTo = async (versionTag: string, menuItem: MenuItem, path: string, moveItemToTop: boolean) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.put<MoveMenuItemResponse>(
    `/tables/menu/to`,
    { menuItemId: menuItem.id, destinationPath: path, moveItemToTop: moveItemToTop },
    { headers: { "x-air-version": versionTag } }
  );
};

export const deleteMenu = async (versionTag: string, menu: MenuItem) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.delete<DeleteMenuResponse>(`/tables/menu/${menu.id}`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const searchAvailableReferences = async (versionTag: string, searchCriteria: SearchCriteria) => {
  const _apiClient = getAPIClient();

  let url = "/tables/map/available-references?";

  if (searchCriteria.mapElementTypes.length > 0) {
    url = url + "referenceTypes=" + searchCriteria.mapElementTypes.join(";") + "&";
  }
  if (searchCriteria.userTag) {
    url = url + "userTag=" + searchCriteria.userTag;
  }
  const references = await _apiClient!.get<MapElementsReferenceResponse>(url, {
    headers: { "x-air-version": versionTag }
  });
  return references.data.data;
};

export const computeReferenceUpdates = async (versionTag: string, map: MenuItem) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.put<MapElementReferencesUpdatesResponse>(`/tables/map/${map.id}/computeReferenceUpdates`, map, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};
