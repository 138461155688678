import { ClientType } from "src/pages/clienttypes/ClientTypes";
import { getAPIClient } from "../apiClient";

type ClientTypesResponse = {
  data: ClientType[];
  errorMessage: string;
};

export const getClientTypes = async (versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<ClientTypesResponse>("/tables/clienttype", { headers: { "x-air-version": versionTag } });
  return response.data.data;
};
