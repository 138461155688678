import { AdditionalInformation, DamParameter } from "src/types/Types";
import { getAPIClient } from "../apiClient";

type DamParametersResponse = {
  data: DamParameter[];
  errorMessage: string;
};

type CreateDamParameterResponse = {
  data: DamParameter;
  errorMessage: string;
};

type DeleteDamParameterResponse = {
  data: number;
  errorMessage: string;
};

type CreateDamParameterInfoResponse = {
  data: AdditionalInformation;
  errorMessage: string;
};

type UpdateDamParameterInfoResponse = {
  data: AdditionalInformation;
  errorMessage: string;
};

type DeleteDamParameterInfoResponse = {
  data: AdditionalInformation[];
  errorMessage: string;
};

export const getDamParameters = async (versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<DamParametersResponse>("/tables/dam_parameter", { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const getDamParametersFromIds = async (versionTag: string, ids: number[]) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<DamParametersResponse>("/tables/dam_parameter/search", { searchIds: ids }, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const createDamParameter = async (versionTag: string, damParameter: DamParameter) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<CreateDamParameterResponse>("/tables/dam_parameter", damParameter, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const updateDamParameter = async (versionTag: string, damParameter: DamParameter) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.put<CreateDamParameterResponse>(`/tables/dam_parameter/${damParameter.id}`, damParameter, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const deleteDamParameter = async (versionTag: string, damParameter: DamParameter) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.delete<DeleteDamParameterResponse>(`/tables/dam_parameter/${damParameter.id}`);
  return response.data.data;
};

export const createDamParameterInfo = async (versionTag: string, damParameter: DamParameter, info: AdditionalInformation) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.post<CreateDamParameterInfoResponse>(`tables/dam_parameter/${damParameter.id}/information`, info, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const updateDamParameterInfo = async (versionTag: string, damParameter: DamParameter, info: AdditionalInformation) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.put<UpdateDamParameterInfoResponse>(`tables/dam_parameter/${damParameter.id}/information/${info.id}`, info, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const deleteDamParameterInfo = async (versionTag: string, damParameter: DamParameter, info: AdditionalInformation) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.delete<DeleteDamParameterInfoResponse>(`tables/dam_parameter/${damParameter.id}/information/${info.id}`, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};
