import { useEffect, useState } from "react";
import { UserTag } from "src/types/Types";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { getUserTags, getUserTagXrefs, createUserTag as _createUserTag, updateUserTag as _updateUserTag, deleteUserTag as _deleteUserTag } from "./userTagsApi";

import * as _ from "lodash";

export const useUserTags = () => {
  const versionTag = useVersionTag();
  const [userTags, setUserTags] = useState<UserTag[]>([]);
  const [userTagNames, setUserTagNames] = useState<string[]>([]);
  const [selectedUserTag, setSelectedUserTag] = useState<UserTag | undefined>(undefined);

  useEffect(() => {
    getUserTags(versionTag).then((tags) => {
      setUserTags(tags);
    });
  }, [versionTag]);

  useEffect(() => {
    setUserTagNames(userTags.map((ut) => ut.name).sort((a, b) => (a > b ? 1 : a < b ? -1 : 0)));
  }, [userTags]);

  const createUserTag = async (userTag: UserTag) => {
    const result = await _createUserTag(versionTag, userTag);
    setUserTags([...userTags, result]);
    setSelectedUserTag(result);
    return result;
  };

  const updateUserTag = async (userTag: UserTag) => {
    const result = await _updateUserTag(versionTag, userTag);
    setUserTags([...userTags.filter((ut) => ut.id !== userTag.id), result]);
    setSelectedUserTag(result);
    return result;
  };

  const deleteUserTag = async (userTag: UserTag) => {
    const result = await _deleteUserTag(versionTag, userTag);
    setSelectedUserTag(undefined);
    setUserTags(userTags.filter((ut) => ut.id !== userTag.id));
  };

  const selectUserTag = async (userTag: UserTag) => {
    const copy = _.cloneDeep(userTag);
    const xrefs = await getUserTagXrefs(versionTag, userTag);
    copy.xrefs = xrefs;
    setUserTags([...userTags.filter((ut) => ut.id !== userTag.id), copy]);
    setSelectedUserTag(copy);
  };

  return { userTags, userTagNames, selectedUserTag, createUserTag, updateUserTag, deleteUserTag, selectUserTag };
};
