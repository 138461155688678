import { Validator } from "./Validator";

class RunwayValidator extends Validator {
  validateDesignator = (fieldName: string, fieldValue: string, otherFields: any) => {
    if (otherFields?.heliport.value === true) {
      if (!/HB[0-9]/.test(fieldValue)) {
        this.validationMap.set(fieldName, true);
        return "Invalid designator";
      }
    } else if (Number.isNaN(+fieldValue) || +fieldValue < 1 || +fieldValue > 36) {
      this.validationMap.set(fieldName, true);
      return "Invalid designator";
    }

    this.validationMap.set(fieldName, false);
    return "";
  };

  validateField = (fieldName: string, fieldValue: any, otherFields?: any) => {
    switch (fieldName) {
      case "designator": {
        return this.validateDesignator(fieldName, fieldValue, otherFields);
      }
      case "dmsThresholdLatitude":
        let latVal = this.validateOtherCoordinate(fieldName, fieldValue, otherFields.dmsThresholdLongitude.value);
        if (latVal.length > 0) return latVal;
        latVal = this.validateLatitude(fieldName, fieldValue, true);
        return latVal;
      case "dmsThresholdLongitude":
        let longVal = this.validateOtherCoordinate(fieldName, fieldValue, otherFields.dmsThresholdLatitude.value);
        if (longVal.length > 0) return longVal;
        longVal = this.validateLatitude(fieldName, fieldValue, true);
        return longVal;
      default:
        return "";
    }
  };
}

export default RunwayValidator;
