import {
  AdditionalInformation,
  Airport,
  AirportFrequency,
  ArrivalProcedure,
  DepartureProcedure,
  ProceduralPoint,
  Runway,
  StandardArrival,
  StandardInstrumentDeparture
} from "src/types/Types";
import { getAPIClient } from "../apiClient";

type AirportsResponse = {
  data: Airport[];
  errorMessage: string;
};

type AddAirportResponse = {
  data: Airport;
  errorMessage: string;
};

type AddRunwayResponse = {
  data: Runway;
  errorMessage: string;
};

type UpdateRunwayResponse = {
  data: Runway;
  errorMessage: string;
};

type GetSidResponse = {
  data: StandardInstrumentDeparture[];
  errorMessage: string;
};

type AddDepProcedureResponse = {
  data: DepartureProcedure;
  errorMessage: string;
};

type GetDepProceduresResponse = {
  data: DepartureProcedure[];
  errorMessage: string;
};

type ProcedurePointsResponse = {
  data: ProceduralPoint[];
  errorMessage: string;
};

type UpdateDepProcedureResponse = {
  data: DepartureProcedure;
  errorMessage: string;
};

type GetStarResponse = {
  data: StandardArrival[];
  errorMessage: string;
};

type GetArrProceduresResponse = {
  data: ArrivalProcedure[];
  errorMessage: string;
};

type AddArrProcedureResponse = {
  data: ArrivalProcedure;
  errorMessage: string;
};
type UpdateArrProcedureResponse = {
  data: ArrivalProcedure;
  errorMessage: string;
};

type DeleteArrProcedureResponse = {
  data: ArrivalProcedure[];
  errorMessage: string;
};

type GetFrequenciesResponse = {
  data: AirportFrequency[];
  errorMessage: string;
};

type AddFrequencyResponse = {
  data: AirportFrequency;
  errorMessage: string;
};

type UpdateFrequencyResponse = {
  data: AirportFrequency;
  errorMessage: string;
};

type DeleteFrequencyResponse = {
  data: AirportFrequency[];
  errorMessage: string;
};

type AdditionalInformationListResponse = {
  data: AdditionalInformation[];
  errorMessage: string;
};

type AdditionalInformationResponse = {
  data: AdditionalInformation;
  errorMessage: string;
};

type UpdateInformationResponse = {
  data: AdditionalInformation;
  errorMessage: string;
};

type DeleteAdditionalInformationResponse = {
  data: AdditionalInformation[];
  errorMessage: string;
};

type GetRunwaysResponse = {
  data: Runway[];
  errorMessage: string;
};

export const createAirport = async (airport: Airport, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<AddAirportResponse>("/tables/airport", airport, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const updateAirport = async (airport: Airport, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.put<AddAirportResponse>(`/tables/airport/${airport.id}`, airport, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const deleteAirport = async (airport: Airport, versionTag: string) => {
  const _apiClient = getAPIClient();
  await _apiClient!.delete(`/tables/airport/${airport.id}`, { headers: { "x-air-version": versionTag } });
};

export const getRunways = async (airport: Airport, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<GetRunwaysResponse>(`/tables/airport/${airport.id}/runways`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const createRunway = async (airport: Airport, runway: Runway, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<AddRunwayResponse>(`/tables/airport/${airport.id}/runways`, runway, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const updateRunway = async (airport: Airport, runway: Runway, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.put<UpdateRunwayResponse>(`/tables/airport/${airport.id}/runways/${runway.id}`, runway, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const deleteRunway = async (airport: Airport, runway: Runway, versionTag: string) => {
  const _apiClient = getAPIClient();
  await _apiClient!.delete(`/tables/airport/${airport.id}/runways/${runway.id}`, { headers: { "x-air-version": versionTag } });
};

export const getSids = async (airport: Airport, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<GetSidResponse>(`/tables/airport/${airport.id}/sids`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const getStars = async (airport: Airport, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<GetStarResponse>(`/tables/airport/${airport.id}/stars`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const getDepartureProcedures = async (airport: Airport, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<GetDepProceduresResponse>(`/tables/airport/${airport.id}/dep-procedures`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const getDepartureProceduresFromIds = async (ids: number[], versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.put<GetDepProceduresResponse>(`/tables/airport/dep-procedures/search`, { searchIds: ids }, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const createDepartureProcedure = async (airport: Airport, depProcedure: DepartureProcedure, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<AddDepProcedureResponse>(`/tables/airport/${airport.id}/dep-procedure`, depProcedure, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const updateDepartureProcedure = (airport: Airport, depProcedure: DepartureProcedure, versionTag: string) => {
  const _apiClient = getAPIClient();
  return _apiClient!
    .put<UpdateDepProcedureResponse>(`/tables/airport/${airport.id}/dep-procedure/${depProcedure.id}`, depProcedure, { headers: { "x-air-version": versionTag } })
    .then((response) => {
      return response.data.data;
    });
};

export const deleteDepartureProcedure = async (airport: Airport, depProcedure: DepartureProcedure, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.delete<ProcedurePointsResponse>(`/tables/airport/${airport.id}/dep-procedure/${depProcedure.id}`, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const getDepartureProcedurePoints = async (airport: Airport, depProcedure: DepartureProcedure, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<ProcedurePointsResponse>(`/tables/airport/${airport.id}/dep-procedure/${depProcedure.id}/points`, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const getDepartureProcedurePointsFromId = async (airportId: number, departureProcedureId: number, versionTag: string) => {
  console.log("departure procedure: " + departureProcedureId);
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<ProcedurePointsResponse>(`/tables/airport/${airportId}/dep-procedure/${departureProcedureId}/points`, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const getArrivalProcedurePoints = async (airport: Airport, arrProcedure: ArrivalProcedure, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<ProcedurePointsResponse>(`/tables/airport/${airport.id}/arr-procedure/${arrProcedure.id}/points`, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const createDepartureProcedurePoint = async (airport: Airport, depProcedure: DepartureProcedure, point: ProceduralPoint, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<ProcedurePointsResponse>(`/tables/airport/${airport.id}/dep-procedure/${depProcedure.id}/point`, point, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const createArrivalProcedurePoint = async (airport: Airport, arrProcedure: ArrivalProcedure, point: ProceduralPoint, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<ProcedurePointsResponse>(`/tables/airport/${airport.id}/arr-procedure/${arrProcedure.id}/point`, point, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const updateDepartureProcedurePoint = async (airport: Airport, depProcedure: DepartureProcedure, point: ProceduralPoint, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.put<ProcedurePointsResponse>(`/tables/airport/${airport.id}/dep-procedure/${depProcedure.id}/point/${point.id}`, point, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const updateArrivalProcedurePoint = async (airport: Airport, arrProcedure: ArrivalProcedure, point: ProceduralPoint, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.put<ProcedurePointsResponse>(`/tables/airport/${airport.id}/arr-procedure/${arrProcedure.id}/point/${point.id}`, point, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const deleteDepartureProcedurePoint = async (airport: Airport, depProcedure: DepartureProcedure, point: ProceduralPoint, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.delete<ProcedurePointsResponse>(`/tables/airport/${airport.id}/dep-procedure/${depProcedure.id}/point/${point.id}`, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const moveDepartureProcedurePoints = async (
  airport: Airport,
  depProcedure: DepartureProcedure,
  fromIndex: number,
  toIndex: number,
  selectedPointsSequenceNumbers: number[],
  versionTag: string
) => {
  const _apiClient = getAPIClient();
  const selectedPointsSequenceNumbersStr = selectedPointsSequenceNumbers.join(",");
  let url = `/tables/airport/${airport.id}/dep-procedure/${depProcedure.id}/points/move/${fromIndex}/${toIndex}`;

  if (selectedPointsSequenceNumbers.length > 0) url += `?selectedPointsSequenceNumbers=${selectedPointsSequenceNumbersStr}`;

  const response = await _apiClient!.put<ProcedurePointsResponse>(url, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const deleteArrivalProcedurePoint = async (airport: Airport, arrProcedure: ArrivalProcedure, point: ProceduralPoint, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.delete<ProcedurePointsResponse>(`/tables/airport/${airport.id}/arr-procedure/${arrProcedure.id}/point/${point.id}`, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const getArrivalProcedurePointsFromId = async (airportId: number, arrivalProcedureId: number, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<ProcedurePointsResponse>(`/tables/airport/${airportId}/arr-procedure/${arrivalProcedureId}/points`, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const moveArrivalProcedurePoints = async (
  airport: Airport,
  arrProcedure: ArrivalProcedure,
  fromIndex: number,
  toIndex: number,
  selectedPointsSequenceNumbers: number[],
  versionTag: string
) => {
  const _apiClient = getAPIClient();
  const selectedPointsSequenceNumbersStr = selectedPointsSequenceNumbers.join(",");
  let url = `/tables/airport/${airport.id}/arr-procedure/${arrProcedure.id}/points/move/${fromIndex}/${toIndex}`;

  if (selectedPointsSequenceNumbers.length > 0) url += `?selectedPointsSequenceNumbers=${selectedPointsSequenceNumbersStr}`;

  const response = await _apiClient!.put<ProcedurePointsResponse>(url, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const getArrivalProcedures = async (airport: Airport, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<GetArrProceduresResponse>(`/tables/airport/${airport.id}/arr-procedures`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const getArrivalProceduresFromIds = async (ids: number[], versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.put<GetArrProceduresResponse>(`/tables/airport/arr-procedures/search`, { searchIds: ids }, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const createArrivalProcedure = async (airport: Airport, arrProcedure: ArrivalProcedure, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<AddArrProcedureResponse>(`/tables/airport/${airport.id}/arr-procedure`, arrProcedure, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const updateArrivalProcedure = (airport: Airport, arrProcedure: ArrivalProcedure, versionTag: string) => {
  const _apiClient = getAPIClient();
  return _apiClient!
    .put<UpdateArrProcedureResponse>(`/tables/airport/${airport.id}/arr-procedure/${arrProcedure.id}`, arrProcedure, { headers: { "x-air-version": versionTag } })
    .then((response) => {
      return response.data.data;
    });
};

export const deleteArrivalProcedure = async (airport: Airport, arrProcedure: ArrivalProcedure, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.delete<DeleteArrProcedureResponse>(`/tables/airport/${airport.id}/arr-procedure/${arrProcedure.id}`, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const getFrequencies = async (airport: Airport, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<GetFrequenciesResponse>(`/tables/airport/${airport.id}/frequencies/`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const createFrequency = async (airport: Airport, frequency: AirportFrequency, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<AddFrequencyResponse>(`/tables/airport/${airport.id}/frequencies/`, frequency, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const updateFrequency = (airport: Airport, frequency: AirportFrequency, versionTag: string) => {
  const _apiClient = getAPIClient();
  return _apiClient!
    .put<UpdateFrequencyResponse>(`/tables/airport/${airport.id}/frequencies/${frequency.id}`, frequency, { headers: { "x-air-version": versionTag } })
    .then((response) => {
      return response.data.data;
    });
};

export const deleteFrequency = async (airport: Airport, frequency: AirportFrequency, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.delete<DeleteFrequencyResponse>(`/tables/airport/${airport.id}/frequencies/${frequency.id}`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const getAdditionalInformationList = async (airport: Airport, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<AdditionalInformationListResponse>(`/tables/airport/${airport.id}/additional-information/`, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const createAdditionalInformation = async (airport: Airport, additionalInformation: AdditionalInformation, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<AdditionalInformationResponse>(`/tables/airport/${airport.id}/additional-information/`, additionalInformation, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const updateAdditionalInformation = async (airport: Airport, additionalInformation: AdditionalInformation, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.put<UpdateInformationResponse>(`/tables/airport/${airport.id}/additional-information/${additionalInformation.id}`, additionalInformation, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const deleteAdditionalInformation = async (airport: Airport, additionalInformation: AdditionalInformation, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.delete<DeleteAdditionalInformationResponse>(`/tables/airport/${airport.id}/additional-information/${additionalInformation.id}`, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const getAirports = async (versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<AirportsResponse>("/tables/airport", { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const getAirportsFromIds = async (versionTag: string, airportIds: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<AirportsResponse>(`/tables/airport?airportIds=${airportIds}`);
  return response.data.data;
};

export const getAirportsAndSidsOnly = async (versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<AirportsResponse>("/tables/airport?airportsAndSidsOnly=true", { headers: { "x-air-version": versionTag } });
  return response.data.data;
};
