import { AxiosInstance } from "axios";
import { Version } from "src/pages/versions/Versions";

export type AuthenticationToken = {
  token: string;
};

export type UserAuthentication = {
  token: string;
  versions: Array<Version>;
  apiClient: AxiosInstance | undefined;
  ws: WebSocket | undefined;
};

export type AppState = {
  versions: Array<Version>;
  currentVersion: Version | undefined;
  authenticationToken: AuthenticationToken | undefined;
  apiClient: AxiosInstance | undefined;
  ws: WebSocket | undefined;
  newMenuGridRowId: number | undefined;
};

export const initialState: AppState = {
  versions: new Array<Version>(),
  authenticationToken: undefined,
  currentVersion: undefined,
  apiClient: undefined,
  ws: undefined,
  newMenuGridRowId: undefined
};
