import { useEffect, useState } from "react";
import { CatUser } from "src/types/Types";

import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { getCatUsers, createCatUser as _createCatUser, deleteCatUser as _deleteCatUser } from "./catUsersApi";

const useCatUsers = () => {
  const versionTag = useVersionTag();
  const [catUsers, setCatUsers] = useState<CatUser[]>([]);

  useEffect(() => {
    getCatUsers(versionTag).then((users) => {
      setCatUsers(users);
    });
  }, []);

  const deleteCatUser = async (catUser: CatUser) => {
    await _deleteCatUser(catUser);
    setCatUsers(catUsers.filter((u) => u.id !== catUser.id));
  };

  const createCatUser = async (catUser: CatUser) => {
    const newUser = await _createCatUser(versionTag, catUser);
    setCatUsers([...catUsers, newUser]);
    return newUser;
  };

  return { catUsers, createCatUser, deleteCatUser };
};

export default useCatUsers;
