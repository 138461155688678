import { Validator } from "./Validator";

class UserTagValidator extends Validator {
  validateField = (fieldName: string, fieldValue: any) => {
    this.validationMap.set(fieldName, false);
    if (fieldName === "name") {
      if (fieldValue.length === 0) {
        this.validationMap.set(fieldName, true);
        return "Invalid name";
      }
      return "";
    }
    return "";
  };
}

export default UserTagValidator;
