import { styled, TextField, Tooltip, tooltipClasses, TooltipProps, withStyles } from "@mui/material";
import { ChangeEvent } from "react";

type TextFieldEditor = {
  value?: string;
  label?: string;
  onChange?: (newValue: string) => void;
  hasError?: boolean;
  helperText?: string | false;
  showHelperTextAsTooltip?: boolean;
};

const TextFieldEditor = (props: TextFieldEditor) => {
  const { label, value, hasError, helperText, showHelperTextAsTooltip = false, onChange } = props;

  return (
    <Tooltip
      open={hasError === true && showHelperTextAsTooltip}
      title={helperText ? helperText : "Error"}
      componentsProps={{
        tooltip: {
          sx: {
            color: "white",
            backgroundColor: "red"
          }
        }
      }}
    >
      <TextField
        id={label}
        name={label}
        label={label}
        size="small"
        variant="standard"
        sx={{ input: { color: hasError ? "red" : "" } }}
        fullWidth
        value={value}
        autoComplete="off"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          onChange?.(event.target.value);
        }}
        error={hasError}
        helperText={showHelperTextAsTooltip === true ? undefined : helperText}
      />
    </Tooltip>
  );
};

export default TextFieldEditor;
