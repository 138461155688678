import { getExpandedArcPoints as _getExpandedArcPointsFor } from "src/apis/lines/linesApi";
import { LinePoint } from "src/types/Types";
import { useVersionTag } from "../hooks/useVersionTag";

import * as _ from "lodash";
import { useEffect, useState } from "react";

const useArc = (n: number = 30) => {
  const versionTag = useVersionTag();

  const [sortedLinePointsForExpansion, setSortedLinePointsForExpansion] = useState<LinePoint[]>([]);

  const [expandedLinePoints, setExpandedLinePoints] = useState<LinePoint[]>([]);

  const sortAndExpandLinePoints = async (points: LinePoint[]) => {
    const copy = _.cloneDeep(points);

    //pass through all the line points and ask for expantion of points for the ones with curvature > 0
    let offset = 0;
    for (let i = 0; i < points.length; i++) {
      const p1 = points[i];
      if (p1.curvature !== "0.0") {
        let p2 = i < points.length - 1 ? points[i + 1] : points[0];
        const expanded: LinePoint[] = await getExpandedArcPoints(p1, p2, n);
        copy.splice(i + 1 + offset, 0, ...expanded);
        offset += expanded.length;
      }
    }
    // if anything has been added
    if (copy.length > points.length) {
      for (let i = 0, j = 0; i < copy.length; i++) {
        copy[i].sequence = i + 1;
        if (!copy[i].computed) {
          copy[i].originalSequence = j + 1;
          j++;
        }
      }
    } else {
      for (let i = 0; i < copy.length; i++) {
        copy[i].originalSequence = i + 1;
      }
    }

    setExpandedLinePoints(copy);
  };

  useEffect(() => {
    sortAndExpandLinePoints(sortedLinePointsForExpansion);
  }, [sortedLinePointsForExpansion]);

  const getExpandedArcPoints = async (from: LinePoint, to: LinePoint, n?: number) => {
    let result = await _getExpandedArcPointsFor(versionTag, from, to, from.curvature, from.inverted, n);
    return result.map((el) => {
      return {
        id: _.uniqueId(),
        sequence: -1,
        computed: true,
        lineId: from.lineId,
        curvature: "",
        inverted: false,
        wgs84Latitude: el.wgs84Latitude,
        wgs84Longitude: el.wgs84Longitude,
        dmsLatitude: "",
        dmsLongitude: ""
      };
    });
  };

  return { expandedLinePoints, setSortedLinePointsForExpansion };
};

export default useArc;
