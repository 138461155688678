import { Checkbox } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import { isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import useUrbanAreas from "src/apis/swisstopo/useUrbanAreas";
import useLakeColumns from "src/columnsgenerators/useLakeColumns";
import GenericDataGrid from "src/components/datagrids/GenericDataGrid";
import MapViewer from "src/components/mapviewer/MapViewer";
import { NamedPolygons } from "src/types/Types";
import { EmptyValidator } from "src/validators/EmptyValidator";

const UrbanAreas = () => {
  const versionTag = useVersionTag();
  const { loading, urbanAreas, selectedUrbanArea, selectUrbanArea } = useUrbanAreas();

  const { getColumns: getLakeColumns } = useLakeColumns();

  const [displayAll, setDisplayAll] = useState<boolean>(true);

  useEffect(() => {
    !isUndefined(selectedUrbanArea) && setDisplayAll(false);
  }, [selectedUrbanArea]);

  const Toolbar = () => {
    return (
      <GridToolbarContainer>
        <Checkbox
          checked={displayAll}
          onChange={(event) => {
            setDisplayAll(event.target.checked);
            event.target.checked && selectUrbanArea(undefined);
          }}
        />
        Display all
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Urban areas - {versionTag}</h2>
      <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
        <div style={{ height: "100%", width: "16%" }}>
          <GenericDataGrid<NamedPolygons>
            data={urbanAreas}
            loading={loading}
            columnsGenerator={getLakeColumns}
            validator={new EmptyValidator()}
            onSelectRow={(row) => {
              selectUrbanArea(row);
            }}
            sortModel={[{ field: "name", sort: "asc" }]}
            selectedRow={selectedUrbanArea}
            canInsertRow={false}
            disableActions={true}
            customComponent={{ Toolbar }}
          />
        </div>
        <div style={{ height: "100%", width: "75%" }}>
          <div style={{ height: "90%", minWidth: "60%", marginLeft: "5px", marginRight: "5px" }}>
            <MapViewer geoSelectedNamedPolygon={selectedUrbanArea} geoNamedPolygons={displayAll ? urbanAreas : []} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UrbanAreas;
