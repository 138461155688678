import { AdditionalInformation, MapText } from "src/types/Types";
import { getAPIClient } from "../apiClient";

type GetMapTextsResponse = {
  data: MapText[];
  errorMessage: string;
};

type CreateMapTextResponse = {
  data: MapText;
  errorMessage: string;
};

type UpdateMapTextResponse = {
  data: MapText;
  errorMessage: string;
};

type DeleteMapTextResponse = {
  data: number;
  errorMessage: string;
};

type CreateAdditionalInformationResponse = {
  data: AdditionalInformation;
  errorMessage: string;
};

type UpdateAdditionalInformationResponse = {
  data: AdditionalInformation;
  errorMessage: string;
};

type DeleteAdditionalInformationResponse = {
  data: AdditionalInformation[];
  errorMessage: string;
};

export const getMapTexts = async (versionTag: string) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.get<GetMapTextsResponse>("/tables/text", { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const getMapTextsFromIds = async (versionTag: string, textIds: string) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.get<GetMapTextsResponse>(`/tables/text?textIds=${textIds}`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const createMapText = async (versionTag: string, text: MapText) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.post<CreateMapTextResponse>("/tables/text", text, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const updateMapText = async (versionTag: string, text: MapText) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.put<UpdateMapTextResponse>(`/tables/text/${text.id}`, text, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const deleteMapText = async (versionTag: string, text: MapText) => {
  const apiClient = getAPIClient();
  const response = await apiClient!.delete<DeleteMapTextResponse>(`/tables/text/${text.id}`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const createAdditionalInformation = async (versionTag: string, mapText: MapText, additionalInformation: AdditionalInformation) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<CreateAdditionalInformationResponse>(`/tables/text/${mapText.id}/info`, additionalInformation, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const updateAdditionalInformation = async (versionTag: string, mapText: MapText, additionalInformation: AdditionalInformation) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.put<UpdateAdditionalInformationResponse>(`/tables/text/${mapText.id}/info/${additionalInformation.id}`, additionalInformation, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const deleteAdditionalInformation = async (versionTag: string, mapText: MapText, additionalInformation: AdditionalInformation) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.delete<DeleteAdditionalInformationResponse>(`/tables/text/${mapText.id}/info/${additionalInformation.id}`, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};
