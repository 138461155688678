import { FormikHelpers, useFormik } from "formik";
import { toast } from "react-toastify";
import { getWgs84LatLng } from "src/apis/waypoints/waypointsApi";
import { ApiResponse, Waypoint } from "src/types/Types";
import { latitudeSchemaValidation, longitudeSchemaValidation } from "src/validators/yup/latlngValidation";
import * as yup from "yup";

type FormValueTypes = {
  name: string;
  shortName: string | null;
  description: string | null;
  area: string | null;
  aor: string[];
  latitude: string;
  longitude: string;
  userTags: string[];
  frequency: string | null;
};

const useAddWaypointFormHandler = (createWaypoint: (waypoint: Waypoint) => Promise<Waypoint>, onWaypointCreated: () => void) => {
  const validationSchema = yup.object({
    name: yup.string().required("Name cannot be empty"),
    shortName: yup
      .string()
      .nullable()
      .test("shortName", "Must be 3 characters", (value) => {
        if (value === null || value === undefined) {
          return true;
        }
        return value.length === 3;
      }),
    area: yup
      .string()
      .nullable()
      .test("area", "Must be 2 characters", (value) => {
        if (value === undefined || value === null) {
          return true;
        }
        return value.length === 2;
      }),
    latitude: latitudeSchemaValidation.required(),
    longitude: longitudeSchemaValidation.required(),
    frequency: yup.number().nullable()
  });

  const formik = useFormik<FormValueTypes>({
    initialValues: {
      name: "",
      shortName: null,
      description: null,
      latitude: "",
      longitude: "",
      area: null,
      aor: [],
      userTags: [],
      frequency: null
    },
    validationSchema: validationSchema,
    onSubmit: async (values, formikHelpers: FormikHelpers<any>) => {
      const latLng = await getWgs84LatLng(values.latitude, values.longitude);
      createWaypoint({
        id: -1,
        name: values.name,
        shortName: values.shortName,
        description: values.description,
        wgs84Latitude: latLng.wgs84Latitude,
        wgs84Longitude: latLng.wgs84Longitude,
        dmsLatitude: values.latitude.replace('"', "") as string,
        dmsLongitude: values.longitude.replace('"', "") as string,
        area: values.area,
        firs: values.aor,
        userTags: values.userTags,
        frequency: values.frequency,
        additionalInformation: [],
        usedInMap: false,
        eurocontrolVersion: null
      })
        .then(() => {
          onWaypointCreated();
        })
        .catch((error: ApiResponse<Waypoint> | string) => {
          if (typeof error === "object") {
            const fieldErrors = error.fieldErrors;
            for (let i = 0; i < fieldErrors.length; i++) {
              formikHelpers.setFieldError(fieldErrors[i].fieldId, fieldErrors[i].message);
            }
          } else {
            toast.error("Failed to create airport");
          }
        });
    }
  });

  return { formHandler: formik };
};

export default useAddWaypointFormHandler;
