import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useRef } from "react";
import { createMapText } from "src/apis/maptexts/mapTextsApi";
import { MapText } from "src/types/Types";
import AddMapTextForm, { AddMapTextFormHandle } from "../forms/AddMapTextForm";

type AddMapTextDialogProps = {
  createMapText: (text: MapText) => Promise<MapText>;
  onCancel: () => void;
  onSuccess: () => void;
};

const AddMapTextDialog = (props: AddMapTextDialogProps) => {
  const { onCancel, onSuccess, createMapText } = props;
  const addMapTextFormRef = useRef<AddMapTextFormHandle>(null);

  return (
    <Dialog maxWidth="xl" open={true}>
      <DialogTitle>
        <div style={{ display: "flex" }}>
          <div></div>
          <div style={{ marginLeft: "10px" }}>Add text</div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <AddMapTextForm ref={addMapTextFormRef} createMapText={createMapText} onSuccess={onSuccess} />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="contained"
          onClick={() => {
            addMapTextFormRef.current?.requestSubmit();
          }}
        >
          Add
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMapTextDialog;
