import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Area } from "src/types/Types";
import AreaIcon from "@mui/icons-material/CropOriginal";
import AddAreaForm, { AddAreaFormHandle } from "../forms/AddAreaForm";
import { useRef } from "react";

type AddAreaDialogProps = {
  createArea: (area: Area) => Promise<Area>;
  onCancel: () => void;
  onSuccess: () => void;
};

const AddAreaDialog = (props: AddAreaDialogProps) => {
  const { createArea, onCancel, onSuccess } = props;
  const addAreaFormRef = useRef<AddAreaFormHandle>(null);

  return (
    <Dialog maxWidth="xl" open={true}>
      <DialogTitle>
        <div style={{ display: "flex" }}>
          <div>
            <AreaIcon />
          </div>
          <div style={{ marginLeft: "10px" }}>Add area</div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <AddAreaForm ref={addAreaFormRef} createArea={createArea} onSuccess={onSuccess} />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="contained"
          onClick={() => {
            {
              addAreaFormRef.current?.requestSubmit();
            }
          }}
        >
          Add
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAreaDialog;
