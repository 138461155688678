import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Airport, ApiResponse } from "src/types/Types";
import { latitudeOrLongitudeMaskEmpty, latitudeSchemaValidation, longitudeSchemaValidation } from "src/validators/yup/latlngValidation";
import { orientationSchemaValidation } from "src/validators/yup/orientationValidation";
import * as yup from "yup";

export type FormValueTypes = {
  name: string;
  fullName: string;
  description: string;
  type: string | null;
  manuallyUpdated: boolean;
  latitude: string;
  longitude: string;
  orientation: string;
  elevation: number | null;
  userTags: string[];
  points: string[];
};

const useAddAirportFormHandler = (createAirportApi: (airport: Airport) => Promise<Airport>, onAirportCreated: () => void) => {
  const _createAirportApi = createAirportApi;

  const validationSchema = yup.object({
    name: yup.string().required("Name cannot be empty"),
    orientation: orientationSchemaValidation,
    elevation: yup
      .number()
      .typeError("Invalid elevation")
      .nullable()
      .test("elevation", "Invalid elevation", (value) => {
        if (value) {
          return !isNaN(+value) && +value >= 0;
        }
        return true;
      }),
    latitude: latitudeSchemaValidation,
    longitude: longitudeSchemaValidation
  });

  const initialValues: FormValueTypes = {
    name: "",
    fullName: "",
    description: "",
    latitude: latitudeOrLongitudeMaskEmpty,
    longitude: latitudeOrLongitudeMaskEmpty,
    type: null,
    manuallyUpdated: true,
    orientation: "",
    elevation: null,
    userTags: [],
    points: []
  };

  const mapFormValues = (formValues: FormValueTypes): Airport => {
    return {
      id: -1,
      name: formValues.name,
      fullName: formValues.fullName.length > 0 ? formValues.fullName : null,
      description: formValues.description.length > 0 ? formValues.description : null,
      wgs84Latitude: null,
      wgs84Longitude: null,
      dmsLatitude: formValues.latitude.length > 0 && !formValues.latitude.includes("_") ? formValues.latitude.replace('"', "") : null,
      dmsLongitude: formValues.longitude.length > 0 && !formValues.longitude.includes("_") ? formValues.longitude.replace('"', "") : null,
      type: formValues.type,
      manuallyUpdated: formValues.manuallyUpdated,
      category: null,
      eurocontrolVersion: null,
      orientation: formValues.orientation,
      elevation: formValues.elevation,
      userTags: formValues.userTags,
      points: formValues.points,
      frequencies: [],
      infos: [],
      runways: [],
      sids: [],
      departureProcedures: [],
      stars: [],
      arrivalProcedures: []
    };
  };

  const formik = useFormik<FormValueTypes>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, formikHelpers) => {
      _createAirportApi(mapFormValues(values))
        .then(() => {
          onAirportCreated();
        })
        .catch((error: ApiResponse<Airport> | string) => {
          if (typeof error === "object") {
            const fieldErrors = error.fieldErrors;
            for (let i = 0; i < fieldErrors.length; i++) {
              formikHelpers.setFieldError(fieldErrors[i].fieldId, fieldErrors[i].message);
            }
          } else {
            toast.error("Failed to create airport");
          }
        });
    }
  });

  return { formHandler: formik };
};

export default useAddAirportFormHandler;
