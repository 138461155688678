import { GridColumns, GridPreProcessEditCellProps, GridRenderCellParams } from "@mui/x-data-grid-pro";
import DataGridTextFieldEditor from "src/components/editors/DataGridTextFieldEditor";
import DatagridColorSelector from "src/components/selectors/colorselector/DatagridColorSelector";
import DatagridGraphicAttributeTypeSelector from "src/components/selectors/graphicattributetypeselector/DataGridGraphicAttributeTypeSelector";
import DatagridSymbolSelector from "src/components/selectors/symbolselector/DatagridSymbolSelector";
import DataGridUserTagSelector from "src/components/selectors/usertagselector/DataGridUserTagSelector";
import { ColumnsGenerator, GraphicAttribute } from "src/types/Types";

const useGraphicAttributeColumns = () => {
  const getColumns: ColumnsGenerator = (validator, onFieldDirty) => {
    const columns: GridColumns<GraphicAttribute> = [
      {
        field: "priority",
        headerName: "Priority",
        width: 60,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("priority", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        }
      },
      {
        field: "name",
        headerName: "Name",
        width: 150,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("name", params.props.value);
          return { ...params.props, error: hasError };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        }
      },
      {
        field: "type",
        headerName: "Type",
        width: 120,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DatagridGraphicAttributeTypeSelector {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("type", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      { field: "emdisCode", headerName: "EmdisCode", width: 80, editable: true },
      {
        field: "color",
        headerName: "Color",
        width: 80,
        editable: true,
        renderCell: (params: GridRenderCellParams<GraphicAttribute>) => {
          return <div style={{ backgroundColor: params.row.color, width: "100%", height: "100%" }}></div>;
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DatagridColorSelector {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        }
      },
      { field: "linePattern", headerName: "Line pattern", width: 100, editable: true },
      { field: "fillPatternString", headerName: "Fill pattern", width: 100, editable: true },
      {
        field: "symbol",
        headerName: "Symbol",
        width: 150,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DatagridSymbolSelector {...params} />;
        }
      },
      { field: "size", headerName: "Size", width: 80, editable: true },
      {
        field: "font",
        headerName: "Font",
        width: 140,
        editable: false,
        renderCell: (params: GridRenderCellParams<GraphicAttribute>) => {
          return (
            <div>
              {params.row.font ? params.row.font : ""} {params.row.fontSize ? params.row.fontSize : ""}
            </div>
          );
        }
      },
      { field: "displayedInformation", headerName: "Show", width: 60, editable: false },
      {
        field: "legalColor",
        headerName: "Legal color",
        width: 80,
        editable: true,
        renderCell: (params: GridRenderCellParams<GraphicAttribute>) => {
          return <div style={{ backgroundColor: params.row.legalColor, width: "100%", height: "100%" }}></div>;
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DatagridColorSelector {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        }
      },
      { field: "legalLineStyle", headerName: "Legal line style", width: 100, editable: false },
      { field: "legalSymbol", headerName: "Legal symbol", width: 100, editable: false },
      {
        field: "userTags",
        headerName: "User tags",
        width: 140,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridUserTagSelector {...params} />;
        }
      }
    ];
    return columns;
  };

  return { getColumns };
};

export default useGraphicAttributeColumns;
