import usePoints from "src/apis/points/usePoints";
import { useAppContext } from "src/appcontext/AppContext";
import usePointColumns from "src/columnsgenerators/usePointColumns";
import GenericDataGrid from "src/components/datagrids/GenericDataGrid";
import CreateEntityDialog from "src/components/dialogs/CreateEntityDialog";
import { AdditionalInformation, Point } from "src/types/Types";
import PointValidator from "src/validators/PointValidator";
import AddPointForm from "./forms/AddPointForm";

import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import { isUndefined } from "lodash";
import Split from "react-split";
import AdditionalInformationGrid from "src/components/datagrids/AdditionalInformationGrid";
import XRefGrid from "src/components/datagrids/XRefGrid";
import { useState } from "react";

const Points = () => {
  const appContext = useAppContext();
  const versionTag = appContext.state.currentVersion?.versionTag ? appContext.state.currentVersion.versionTag : "trunk";
  const { getColumns } = usePointColumns();
  const { loading, points, currentPoint, addPoint, updatePoint, deletePoint, selectPoint, addInformation, deleteInformation, updateInformation } = usePoints();

  const [currentTab, setCurrentTab] = useState("additionalInformation");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  const onUpdateRow = (row: Point) => {
    if (row.referenceType === "SID") {
      row.reference = row.reference!.substring(5);
    }
    return updatePoint(row);
  };

  const onDeleteRow = (row: Point) => {
    return deletePoint(row);
  };

  const onSelectRow = (row: Point) => {
    selectPoint(row);
  };

  const createDialogFactory = (onSuccess: () => void, onCancel: () => void) => {
    return <CreateEntityDialog<Point> title="Add point" onCancel={onCancel} createEntity={addPoint} onEntityCreated={onSuccess} CreateEntityFormComponent={AddPointForm} />;
  };

  const onAddInformation = (info: AdditionalInformation) => {
    return addInformation(info);
  };

  const onUpdateInformation = (info: AdditionalInformation) => {
    return updateInformation(info);
  };

  const onDeleteInformation = (info: AdditionalInformation) => {
    return deleteInformation(info);
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Points - {versionTag}</h2>
      <Split style={{ height: "calc(100vh - 230px)" }} direction="vertical">
        <div style={{ height: "100%" }}>
          <GenericDataGrid<Point>
            loading={loading}
            data={points}
            selectedRow={currentPoint}
            columnsGenerator={getColumns}
            validator={new PointValidator()}
            createDialogFactory={createDialogFactory}
            onUpdateRow={onUpdateRow}
            onDeleteRow={onDeleteRow}
            onSelectRow={onSelectRow}
            sortModel={[{ field: "name", sort: "asc" }]}
            deleteDialogTitle={"Delete point?"}
          />
        </div>
        <div style={{ height: "100%" }}>
          <TabContext value={currentTab}>
            <Tabs value={currentTab} onChange={handleChange}>
              <Tab value="additionalInformation" label="Additional information" />
              <Tab value="xref" label="xref" />
            </Tabs>
            <TabPanel value="additionalInformation" style={{ height: "100%" }}>
              <div style={{ height: "90%", display: "flex", flexDirection: "row" }}>
                <div style={{ height: "90%", flex: 1, marginRight: "5px" }}>
                  <AdditionalInformationGrid
                    infos={currentPoint ? currentPoint.additionalInformation : []}
                    onAddInformation={onAddInformation}
                    onUpdateInformation={onUpdateInformation}
                    onDeleteInformation={onDeleteInformation}
                    canInsertRow={!isUndefined(currentPoint)}
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel value="xref" style={{ height: "100%" }}>
              <div style={{ height: "90%", display: "flex", flexDirection: "row" }}>
                <div style={{ height: "90%", flex: 1, marginRight: "5px" }}>
                  <XRefGrid xrefs={currentPoint && currentPoint.xrefs ? currentPoint.xrefs : []} />
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </Split>
    </div>
  );
};

export default Points;
