import { GridColumns } from "@mui/x-data-grid-pro";
import { FieldsValidator } from "src/validators/Validator";

export type FieldError = {
  fieldId: string;
  message: string;
};

export type ApiResponse<T> = {
  data: T;
  errorMessage: string;
  fieldErrors: FieldError[];
};

/**
 * Defines an entity which can be uniquely referenced using an id.
 * This type is used by data grids to display their content
 */
export interface Identifiable {
  id: number | string;
}

export type FieldValidationCallback = (fieldName: string, fieldValue: any) => boolean;
export type ColumnsGenerator = (validator: FieldsValidator, onFieldDirty: () => void) => GridColumns;

export type CatUser = Identifiable & {
  username: string;
  firstname: string;
  lastname: string;
  password: string;
  roleName: "Admin" | "MapEditor" | "Reader";
};

export type UserTag = {
  id: string | number;
  name: string;
  description: string | undefined;
  lastUpdate: string | undefined;
  lastUpdateBy: string | undefined;
  xrefs: Xref[];
};

export type Xref = {
  sourceId: string;
  sourceType: string;
  sourceName: string;
  destinationId: string;
  destinationType: string;
  destinationName: string;
};

export type NameAndArea = {
  name: string;
  area: string;
};

export type Waypoint = {
  id: number;
  name: string;
  shortName: string | null;
  description: string | null;
  area: string | null;
  frequency: string | null;
  wgs84Latitude: number;
  wgs84Longitude: number;
  dmsLatitude: string;
  dmsLongitude: string;
  eurocontrolVersion: string | null;
  userTags: string[];
  firs: string[];
  usedInMap: boolean | null;
  additionalInformation: AdditionalInformation[];
  symbolImage?: string /* base64 encoded image */;
};

export type Airway = {
  id: number;
  name: string;
  fullName: string;
  type: string;
  userTags: string[];
  airwayPoints: AirwayPoint[];
  color?: string;
};

export type AirwayPoint = {
  id: number;
  isNew?: boolean;
  airwayId: number;
  sequence: number;
  waypointName: string;
  waypointArea: string;
  remarks: string;
};

export type Airport = {
  id: number;
  name: string;
  fullName: string | null;
  description: string | null;
  type: string | null;
  wgs84Latitude: number | null;
  wgs84Longitude: number | null;
  dmsLatitude: string | null;
  dmsLongitude: string | null;
  manuallyUpdated: boolean;
  category: string | null;
  eurocontrolVersion: string | null;
  orientation: string | null;
  elevation: number | null;
  userTags: string[];
  points: string[];
  frequencies: AirportFrequency[];
  infos: AdditionalInformation[];
  runways: Runway[];
  sids: StandardInstrumentDeparture[];
  departureProcedures: DepartureProcedure[];
  stars: StandardArrival[];
  arrivalProcedures: ArrivalProcedure[];
  color?: string;
  symbolImage?: string /* base64 encoded image */;
};

export type Runway = {
  id: string | number;
  airportId: number | null;
  designator: string | null;
  thresholdLatitude: number | null;
  thresholdLongitude: number | null;
  dmsThresholdLatitude: string | null;
  dmsThresholdLongitude: string | null;
  surface: string | null;
  width: number | null;
  availableLandingDistance: number | null;
  remarks: string | null;
  heliport: boolean;
};

export type AirportFrequency = {
  id: number;
  airportId: number;
  sequence: number;
  name: string | null;
  frequency: string | null;
  remarks: string | null;
  onSameLine: boolean;
};

export type SidStarPoint = {
  id: number;
  sequence: number;
  waypointName: string;
  waypointArea: string;
  elevation: string;
  nearPointName: string;
  nearPointArea: string;
  userTags: string[];
};

export type ProceduralPoint = SequentialPoint & {
  waypointName?: string;
  waypointArea?: string;
  dmsLatitude?: string;
  dmsLongitude?: string;
};

export type Departure = {
  id: number;
  airportId: number;
  name: string;
  runwayDesignator: string;
  userTags: string[];
};

export type StandardInstrumentDeparture = Departure & {
  shortName: string;
  equipment: string;
  entryLevel: string;
  flightLevelCorrection: string;
  noLevelAssignment: boolean;
  initialTurn: string;
  towerEnabled: boolean;
  approachDescription: string;
  runwayLossTime: number;
  propsAlternative: boolean;
  displayOrder: number;
  climbClearance: number;
  nonStandard: boolean;
  dclCapable: boolean;
  points: SidStarPoint[];
};

export type DepartureProcedure = Departure & {
  description: string;
  points: ProceduralPoint[];
  color?: string;
  dashArray?: string | number[];
  weight?: number;
};

export type Arrival = {
  id: number;
  airportId: number;
  name: string;
  runwayDesignator: string;
  userTags: string[];
};

export type StandardArrival = Arrival & {
  sequence: number;
  shortName: string;
  equipment: string;
  xfl: string;
  xflAsMaxXfl: boolean;
  dvoOn: boolean;
  iaf: string;
  autoDiversion: boolean;
  notForRerouting: string;
  points: SidStarPoint[];
};

export type ArrivalProcedure = Arrival & {
  description: string;
  points: ProceduralPoint[];
  color?: string;
  dashArray?: string | number[];
  weight?: number;
};

export type AdditionalInformation = {
  id: string | number;
  sequence: number;
  topic: string | null;
  contents: string | null;
  onSameLine: boolean;
};

export type MapText = {
  id: number | string;
  text: string;
  description: string | null;
  wgs84Latitude: number;
  wgs84Longitude: number;
  dmsLatitude: string;
  dmsLongitude: string;
  userTags: string[];
  additionalInformation: AdditionalInformation[];
  color?: string;
  font?: string;
};

export type Line = {
  id: number | string;
  name: string;
  description: string | null;
  category: string | null;
  userTags: string[];
  linePoints: LinePoint[];
  additionalInformation: AdditionalInformation[];
  color?: string;
  weight?: number;
  dashArray?: string | number[];
};

export interface SequentialPoint extends Identifiable, Wgs84LatLng {
  sequence: number;
  originalSequence?: number;
  curvature: string;
  distanceToNextPointNm?: number;
  inverted: boolean;
  computed?: boolean;
}

export type LinePoint = SequentialPoint & {
  lineId: number | string;
  dmsLatitude: string;
  dmsLongitude: string;
};

export type Area = {
  id: number;
  name: string;
  description: string | null;
  category: string | null;
  buffer: number;
  emdisCenterWgs84Latitude: number;
  emdisCenterWgs84Longitude: number;
  emdisCenterDmsLatitude: string;
  emdisCenterDmsLongitude: string;
  areaPoints: AreaPoint[];
  additionalInformation: AdditionalInformation[];
  xrefs: Xref[];
  userTags: string[];
  color?: string;
  fillColor?: string;
  priority?: number;
};

export type AreaPoint = LinePoint & {
  buffer: number;
};

export type Point = {
  id: number;
  name: string;
  description: string;
  frequency: string;
  referenceType: "AIRPORT" | "WAYPOINT" | "SID" | null;
  reference: string | null;
  wgs84Latitude: number;
  wgs84Longitude: number;
  dmsLatitude: string;
  dmsLongitude: string;
  orientation: number | null;
  areasOfResponsibility: string[];
  exportShortName: boolean | null;
  userTags: string[];
  additionalInformation: AdditionalInformation[];
  xrefs: Xref[];
  symbolImage?: string /* base64 encoded image */;
};

export type Holding = {
  id: number | string;
  name: string;
  description: string;
  fix: string;
  fixWgs84Latitude?: number;
  fixWgs84Longitude?: number;
  inboundTrack: number | null;
  outboundLeg: string | null;
  radial: number | null;
  navAid: string | null;
  frequency: string | null;
  legTime: number | null;
  legTimeMinuteSeconds: string | null;
  turnClockWise: boolean;
  minimumLevel: string | null;
  maximumLevel: string | null;
  maximumAirSpeed: number | null;
  minimumReceptionAltitude: string | null;
  additionalInformation: AdditionalInformation[];
  xrefs: Xref[];
  symbolImage?: string /* base64 encoded image */;
};

export type MapSymbol = {
  id: number | string;
  name: string;
  code: string;
  symbol: string;
  symbolImage: string | null /* base64 encoded */;
  userTags: string[];
};

export type DamParameter = {
  id: number;
  description: string | null;
  wgs84Latitude: number;
  wgs84Longitude: number;
  dmsLatitude: string;
  dmsLongitude: string;
  lowerFlightLevel: string;
  lowerLevelInFeet: boolean | null;
  higherFlightLevel: string;
  upperLevelInFeet: boolean | null;
  startTime: string | null;
  endTime: string | null;
  trainingArea: string | null;
  areaType: string | null;
  displayLevel: boolean | null;
  verticalBuffer: number | null;
  damStatic: boolean | null;
  distributionList: string[];
  altitudeCorrections: [];
  userTags: string[];
  additionalInformation: AdditionalInformation[];
  remarks?: string;
  color?: string;
};

export type MapElementReference = {
  id: string | number;
  elementId: number;
  name: string;
  description: string;
  referenceType: MapElementType;
  attributes: string;
  auxiliaryAttributes: string;
  merged: boolean;
  wgs84Latitude: number;
  wgs84Longitude: number;
  points: LinePoint[];
  airwayPoints: AirwayPoint[];
  airwayStartPoint: string;
  airwayEndPoint: string;
  airwayStretch: string[];
  persistent: boolean;
};

export type RefAttributeUpdate = {
  updateType: "GRAPHIC_ATTRIBUTE" | "AUX_GRAPHIC_ATTRIBUTE" | "AIRWAY_UPDATE";
  oldValue: string;
  newValue: string;
  mapElementReference: MapElementReference;
};

export type MapElementReferencesUpdates = {
  oldPath: string;
  newPath: string;
  oldName: string;
  newName: string;
  oldCode: number;
  newCode: number;
  addedClientTypes: string[];
  deletedClientTypes: string[];
  addedUserTags: string[];
  deletedUserTags: string[];
  addedReferences: MapElementReference[];
  deletedReferences: MapElementReference[];
  refAttributeUpdates: RefAttributeUpdate[];
};

export type MenuItem = {
  id: number;
  menuItemType: "MENU" | "MAP";
  parentId: number | null;
  sequence: number;
  name: string;
  path: string[];
  code: number | undefined;
  clientTypes: string[];
  mapElementReferences: MapElementReference[];
  userTags: string[];
};

export type MergedArea = {
  id: number;
  name: string;
  areaType: "AREA" | "COMPUTED_AREA";
};

export type Closure = {
  id: number;
  name: string;
};

export type ComputedArea = {
  id: number;
  name: string;
  description: string;
  mergedAreas: MergedArea[];
  closures: Closure[];
  smoothing: number;
  userTags: string[];
  color?: string;
  fillColor?: string;
  xrefs: Xref[];
};

export const GraphicAttributeTypeValues = ["AIRPORT", "LINE", "POINT", "TEXT", "AREA", "NONE"] as const;
export type GraphicAttributeType = (typeof GraphicAttributeTypeValues)[number];

export type MapElementType =
  | "WAYPOINT"
  | "POINT"
  | "LINE"
  | "AIRWAY"
  | "AREA"
  | "COMPUTED_AREA"
  | "TEXT"
  | "DAM_PARAMETER"
  | "HOLDING"
  | "AIRPORT"
  | "SID"
  | "STAR"
  | "DEPARTURE_PROCEDURE"
  | "ARRIVAL_PROCEDURE"
  | "SWISSTOPO_LAKES"
  | "SWISSTOPO_URBAN_AREAS"
  | "SWISSTOPO_RAILWAYS"
  | "SWISSTOPO_MAJOR_ROADS"
  | "SWISSTOPO_RIVERS"
  | "SWISSTOPO_COUNTRY_OUTLINES"
  | "SWISSTOPO_HIGHWAYS"
  | "SWISSTOPO_HIGHWAY_RAMPS"
  | "SWISSTOPO_HIGHWAY_TUNNELS";

export type DefaultGraphicAttribute = {
  referenceType: MapElementType;
  graphicAttribute: string;
};

export type GraphicAttribute = {
  id: number;
  priority: number;
  name: string;
  type: GraphicAttributeType;
  emdisCode: number;
  color: string;
  linePattern: string;
  fillPattern: string;
  fillPatternNumber: number;
  symbol: string;
  size: number;
  font: string;
  fontSize: number;
  displayedInformation: string[];
  legalColor: string;
  legalSymbol: number;
  legalLineStyle: number;
  userTags: string[];
};

export interface DmsLatLng {
  dmsLatitude: string;
  dmsLongitude: string;
}

export type LatLng = {
  wgs84Latitude: number;
  wgs84Longitude: number;
  dmsLatitude: string;
  dmsLongitude: string;
};
export interface Wgs84LatLng {
  wgs84Latitude: number;
  wgs84Longitude: number;
}

export interface Wgs84AndDmsLatLng extends Wgs84LatLng, DmsLatLng {}

export type CoordinateSystem = "decimal" | "dms" | "luciad";

export type DmsOptions = {
  fixed: boolean;
  delimited: boolean;
  oriented: boolean;
  precise: boolean;
};

export type RawCoordinatesData = {
  format: CoordinateSystem;
  dmsOptions?: DmsOptions;
  rawData: string;
};

export type CoordinatesList = {
  coordinates: LatLng[];
  conversionErrors: string[];
};

// swisstopo types
export type Polygon = {
  coordinates: LatLng[];
};

export type NamedPolygons = Identifiable & {
  name: string;
  polygons: Polygon[];
};

export type GeoAttributes = {
  id?: string | number;
  uuid?: string;
  name?: string;
  cntryname?: string;
};

export type PolyLineWithAttributes = Identifiable & {
  attributes: GeoAttributes;
  coordinates?: LatLng[];
  sections?: LatLng[][];
};

export type NamedPolyLineWithAttributes = PolyLineWithAttributes & {
  name: string;
};

export type Lake = NamedPolygons & {
  color?: string;
  weight?: number;
};

export type UrbanArea = NamedPolygons & {
  color?: string;
  weight?: number;
};

export type Highway = PolyLineWithAttributes & {
  color?: string;
  weight?: number;
};

export type HighwayRamp = PolyLineWithAttributes & {
  color?: string;
  weight?: number;
};

export type HighwayTunnel = PolyLineWithAttributes & {
  color?: string;
  weight?: number;
};

export type MajorRoad = PolyLineWithAttributes & {
  color?: string;
  weight?: number;
};

export type Railway = PolyLineWithAttributes & {
  color?: string;
  weight?: number;
};

export type CountryOutline = PolyLineWithAttributes & {
  color?: string;
  weight?: number;
};

export type River = NamedPolyLineWithAttributes & {
  color?: string;
  weight?: number;
};

export type MoveMenuItem = {
  menuItemId: number;
  destinationPath: string;
  moveItemToTop: boolean;
};
