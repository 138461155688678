import { AdditionalInformation, Holding } from "src/types/Types";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { useEffect, useState } from "react";
import {
  getHoldings,
  getInformation,
  getHoldingXrefs,
  createHolding as _createHolding,
  updateHolding as _updateHolding,
  deleteHolding as _deleteHolding,
  addInformation as _addInformation,
  updateInformation as _updateInformation,
  deleteInformation as _deleteInformation
} from "./holdingsApi";

import * as _ from "lodash";

const useHoldings = () => {
  const versionTag = useVersionTag();
  const [holdings, setHoldings] = useState<Holding[]>([]);
  const [selectedHolding, setSelectedHolding] = useState<Holding | undefined>(undefined);

  useEffect(() => {
    getHoldings(versionTag).then((result) => {
      setHoldings(result);
    });
  }, [versionTag]);

  const convertLegTimeMinuteSeconds = (legTimeMinuteSeconds: string): number => {
    const minutesAndSeconds = legTimeMinuteSeconds.split(":");
    return +minutesAndSeconds[0] * 60 * 1000 + +minutesAndSeconds[1] * 1000;
  };

  const selectHolding = async (holding: Holding) => {
    const copy = _.cloneDeep(holding);
    copy.additionalInformation = await getInformation(holding, versionTag);
    copy.xrefs = await getHoldingXrefs(holding, versionTag);
    setHoldings([...holdings.filter((h) => h.id !== holding.id), copy]);
    setSelectedHolding(copy);
  };

  const createHolding = async (holding: Holding) => {
    if (holding.legTimeMinuteSeconds) {
      holding.legTime = convertLegTimeMinuteSeconds(holding.legTimeMinuteSeconds);
    }
    const result = await _createHolding(holding, versionTag);
    setHoldings([...holdings, result]);
    setSelectedHolding(result);
    return result;
  };

  const updateHolding = async (holding: Holding) => {
    if (holding.legTimeMinuteSeconds) {
      holding.legTime = convertLegTimeMinuteSeconds(holding.legTimeMinuteSeconds);
    }
    const result = await _updateHolding(holding, versionTag);
    setHoldings([...holdings.filter((h) => h.id !== holding.id), result]);
    setSelectedHolding(result);
    return result;
  };

  const deleteHolding = async (holding: Holding) => {
    const result = await _deleteHolding(holding, versionTag);
    setHoldings([...holdings.filter((h) => h.id !== result)]);
    setSelectedHolding(undefined);
  };

  const addInformation = async (holding: Holding, info: AdditionalInformation) => {
    const result = await _addInformation(holding, info, versionTag);
    const copy = _.cloneDeep(holding);
    copy.additionalInformation.push(result);
    setHoldings([...holdings.filter((h) => h.id !== holding.id), copy]);
    setSelectedHolding(copy);
    return result;
  };

  const updateInformation = async (holding: Holding, info: AdditionalInformation) => {
    const result = await _updateInformation(holding, info, versionTag);
    const copy = _.cloneDeep(holding!);
    copy.additionalInformation = copy.additionalInformation.filter((i) => i.id !== info.id);
    copy.additionalInformation.push(result);
    setHoldings([...holdings.filter((h) => h.id !== selectedHolding!.id), copy]);
    setSelectedHolding(copy);
    return result;
  };

  const deleteInformation = async (info: AdditionalInformation) => {
    return _deleteInformation(selectedHolding!, info, versionTag).then((result) => {
      const copy = _.cloneDeep(selectedHolding!);
      copy.additionalInformation = result;
      setHoldings([...holdings.filter((h) => h.id !== copy.id), copy]);
      setSelectedHolding(copy);
    });
  };

  return { holdings, selectedHolding, selectHolding, createHolding, updateHolding, deleteHolding, addInformation, updateInformation, deleteInformation };
};

export default useHoldings;
