import React, { useEffect, useState } from "react";
import { useAppContext } from "src/appcontext/AppContext";
import RolesGrid from "./datagrids/RolesGrid";

export type Role = {
  id: number | undefined;
  name: string;
  description: string;
};

export type RolesResponse = {
  data: Role[];
  errorMessage: string;
};

const Roles = () => {
  const [roles, setRoles] = useState<Role[]>(new Array());
  const appContext = useAppContext();
  const apiClient = appContext.state.apiClient;

  useEffect(() => {
    apiClient?.get<RolesResponse>("/roles").then((response) => {
      setRoles(response.data.data);
    });
  }, []);

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Roles</h2>
      <div style={{ height: "90%" }}>
        <RolesGrid roles={roles} />
      </div>
    </div>
  );
};

export default Roles;
