import { useVersionTag } from "src/apis/hooks/useVersionTag";
import useHighways from "src/apis/swisstopo/useHighways";
import MapViewer from "src/components/mapviewer/MapViewer";

const Highways = () => {
  const versionTag = useVersionTag();
  const { highways } = useHighways();

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Highways - {versionTag}</h2>
      <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
        <div style={{ height: "100%", width: "75%" }}>
          <div style={{ height: "90%", minWidth: "60%", marginLeft: "5px", marginRight: "5px" }}>
            <MapViewer geoPolylines={highways} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Highways;
