import { Button } from "@mui/material";

import MapElementTypesSelector from "src/components/selectors/elementtypeselector/MapElementTypeSelector";
import UserTagSelector from "src/components/selectors/usertagselector/UserTagSelector";

import ClearIcon from "@mui/icons-material/Clear";
import { MapElementType } from "src/types/Types";

type SearchFiltersProps = {
  currentElementTypesSelection?: MapElementType[];
  currentUserTagSelection?: string | null;
  onChangeElementTypes?: (newValues: MapElementType[]) => void;
  onChangeUserTag?: (newValue: string) => void;
  onClear?: () => void;
};

const SearchFilters = (props: SearchFiltersProps) => {
  const { currentUserTagSelection, currentElementTypesSelection, onClear, onChangeElementTypes, onChangeUserTag } = props;

  const _onElementTypesChange = (referenceTypes: MapElementType[]) => {
    onChangeElementTypes?.(referenceTypes);
  };

  const _onChangeUserTags = (newValue: string[]) => {
    onChangeUserTag?.(newValue[0]);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", alignItems: "center" }}>
      <div style={{ flex: 1.5, marginLeft: "10px" }}>
        <MapElementTypesSelector currentSelection={currentElementTypesSelection} onChange={_onElementTypesChange} />
      </div>
      <div style={{ flex: 1, marginLeft: "10px", marginRight: "10px" }}>
        <UserTagSelector currentSelection={currentUserTagSelection} multiple={false} onChange={_onChangeUserTags} />
      </div>
      <div>
        <Button style={{ marginTop: 15, marginRight: 5 }} size="small" startIcon={<ClearIcon />} onClick={onClear}>
          Clear
        </Button>
      </div>
    </div>
  );
};

export default SearchFilters;
