import { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CoordinateSystemSelector from "src/components/selectors/coordinatesystemselector/CoordinateSystemSelector";
import { useCoordinatesConversion } from "src/apis/coordinates/useCoordinatesConversionApi";
import { CoordinateSystem, CoordinatesList, DmsOptions } from "src/types/Types";
import { toast } from "react-toastify";
import { getCoordinatesExample } from "src/apis/coordinates/coordinatesConversionApi";

type PasteCoordinatesDialogProps = {
  onAddCoordinates: (coordinatesList: CoordinatesList) => void;
  onCancel: () => void;
};

const PasteCoordinatesDialog = (props: PasteCoordinatesDialogProps) => {
  const { convertCoordinates } = useCoordinatesConversion();

  const { onAddCoordinates, onCancel } = props;
  const [rawCoordinatesData, setRawCoordinatesData] = useState("");

  const [format, setFormat] = useState<CoordinateSystem>("decimal");
  const [formatExample, setFormatExample] = useState<string>("No available");

  const [dmsOptions, setDmsOptions] = useState<DmsOptions>({ fixed: true, delimited: false, oriented: true, precise: true });

  const [conversionErrors, setConversionErrors] = useState<string[]>([]);
  const [hasConversionErrors, setHasConversionErrors] = useState(false);

  useEffect(() => {
    if (format === "dms") {
      getCoordinatesExample(format, dmsOptions).then((result) => {
        setFormatExample(result);
      });
    } else {
      getCoordinatesExample(format).then((result) => {
        setFormatExample(result);
      });
    }
  }, [format, dmsOptions]);

  const onRawCoordinatesDataChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRawCoordinatesData(event.target.value);
  };

  const onCoordinateSystemChange = (format: CoordinateSystem, dmsOptions?: DmsOptions) => {
    setFormat(format);
    if (dmsOptions) {
      setDmsOptions(dmsOptions);
    }
  };

  const _onAddCoordinates = async () => {
    convertCoordinates({ format: format, dmsOptions: dmsOptions, rawData: rawCoordinatesData })
      .then((result) => {
        if (result.conversionErrors.length === 0) {
          onAddCoordinates(result);
        } else {
          setHasConversionErrors(true);
          setConversionErrors(result.conversionErrors);
        }
      })
      .catch((error: unknown) => {
        toast.error("Conversion error: " + JSON.stringify(error));
      });
  };

  return (
    <Dialog open={true} maxWidth="xl">
      <DialogTitle>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ marginLeft: "10px" }}>Paste Coordinates</div>
          <div style={{ marginLeft: "10px" }}>
            <CoordinateSystemSelector format={format} dmsOptions={dmsOptions} onCoordinateSystemChange={onCoordinateSystemChange} formatExample={formatExample} />
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <textarea value={rawCoordinatesData} style={{ height: "400px", width: "500px", overflowY: "scroll" }} onChange={onRawCoordinatesDataChange} />
          {hasConversionErrors &&
            conversionErrors.map((conversionError) => {
              return <div style={{ color: "red" }}>{conversionError}</div>;
            })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="contained" onClick={_onAddCoordinates}>
          Add
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PasteCoordinatesDialog;
