import { TabContext, TabPanel } from "@mui/lab";
import { Button, Tab, Tabs } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Split from "react-split";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import useLines from "src/apis/lines/useLines";
import { getWgs84Latitude, getWgs84Longitude } from "src/apis/waypoints/waypointsApi";
import useLinePointsColumns from "src/columnsgenerators/useLinePointsColumns";
import useLineColumns from "src/columnsgenerators/useLineColumns";
import AdditionalInformationGrid from "src/components/datagrids/AdditionalInformationGrid";
import GenericDataGrid, { GenericDataGridHandle } from "src/components/datagrids/GenericDataGrid";
import PasteCoordinatesDialog from "src/components/dialogs/PasteCoordinatesDialog";
import MapViewer from "src/components/mapviewer/MapViewer";
import AddLineDialog from "src/pages/maps/lines/dialogs/AddLineDialog";
import { AdditionalInformation, CoordinatesList, Line, LinePoint } from "src/types/Types";
import BoundaryPointsValidator from "src/validators/BoundaryPointsValidator";
import LineValidator from "src/validators/LineValidator";
import * as _ from "lodash";
import { toast } from "react-toastify";
import { GridRowOrderChangeParams } from "@mui/x-data-grid-pro";
import useArc from "src/apis/lines/useArc";

const Lines = () => {
  const versionTag = useVersionTag();
  const {
    loading,
    lines,
    selectedLine,
    selectLine,
    createLine,
    updateLine,
    deleteLine,
    createLinePoint,
    createLinePoints,
    updateLinePoint,
    deleteLinePoint,
    deleteLinePoints,
    moveSelectedLinePoints,
    createAdditionalInformation,
    updateAdditionalInformation,
    deleteAdditionalInformation
  } = useLines();

  const { expandedLinePoints, setSortedLinePointsForExpansion } = useArc();

  const { getColumns: getLineColumns } = useLineColumns();
  const { getColumns: getLinePointsColumns } = useLinePointsColumns();

  const [currentTab, setCurrentTab] = useState<string>("points");
  const [showPasteCoordinatesDialog, setShowPasteCoordinatesDialog] = useState(false);

  const [linePoints, setLinePoints] = useState<LinePoint[]>([]);
  const [selectedLinePoints, setSelectedLinePoints] = useState<LinePoint[]>([]);

  const linePointsGridRef = useRef<GenericDataGridHandle>(null);

  const setSortedLinePoints = () => {
    setLinePoints(selectedLine?.linePoints.slice().sort((a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0)) || []);
  };

  useEffect(() => {
    setSortedLinePointsForExpansion(linePoints);
  }, [linePoints]);

  useEffect(() => {
    if (_.isUndefined(selectedLine) || (!_.isEmpty(selectedLinePoints) && selectedLinePoints[0].lineId !== selectedLine!.id)) {
      setSelectedLinePoints([]);
    }

    setSortedLinePoints();
  }, [selectedLine]);

  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  const onUpdateLine = (line: Line) => {
    return updateLine(line);
  };

  const onDeleteLine = (line: Line) => {
    return deleteLine(line);
  };

  const onSelectLine = (line: Line) => {
    if (linePointsGridRef.current?.isInEditMode) {
      linePointsGridRef.current.cancelEdit();
    }
    selectLine(line);
  };

  const onCreateLinePoint = async (linePoint: LinePoint) => {
    linePoint.wgs84Latitude = await getWgs84Latitude(linePoint.dmsLatitude);
    linePoint.wgs84Longitude = await getWgs84Longitude(linePoint.dmsLongitude);
    return createLinePoint(linePoint);
  };
  const onUpdateLinePoint = (linePoint: LinePoint) => {
    return updateLinePoint(linePoint);
  };

  const onDeleteLinePoint = (linePoint: LinePoint) => {
    return deleteLinePoint(selectedLine!, linePoint);
  };

  const onDeleteLinePoints = (linePoints: LinePoint[]) => {
    return deleteLinePoints(selectedLine!, linePoints);
  };

  const createEmptyLinePoint = (): LinePoint => {
    return {
      id: -1,
      lineId: +selectedLine!.id,
      sequence: selectedLine!.linePoints.length + 1,
      wgs84Latitude: 0,
      wgs84Longitude: 0,
      dmsLatitude: "",
      dmsLongitude: "",
      curvature: "0",
      inverted: false
    };
  };

  const createDialogFactory = (onSuccess: () => void, onCancel: () => void) => {
    return <AddLineDialog createLine={createLine} onSuccess={onSuccess} onCancel={onCancel} />;
  };

  const onAddInformation = (information: AdditionalInformation) => {
    return createAdditionalInformation(information);
  };

  const onUpdateInformation = (information: AdditionalInformation) => {
    return updateAdditionalInformation(information);
  };

  const onDeleteInformation = (information: AdditionalInformation) => {
    return deleteAdditionalInformation(information);
  };

  const onSelectedLinePoints = (linePoints: LinePoint[]) => {
    let selectedPoints = [...linePoints];
    selectedPoints.sort((a, b) => a.sequence - b.sequence);
    setSelectedLinePoints(selectedPoints);
  };

  const onSelectedLinePointsOrderChange = (params: GridRowOrderChangeParams) => {
    moveSelectedLinePoints(
      selectedLine!,
      params.oldIndex,
      params.targetIndex,
      selectedLinePoints.map((lp) => lp.sequence)
    ).catch((error: any) => {
      if (error.errorMessage) {
        toast.error(error.errorMessage);
      } else {
        toast.error(error);
      }
      // reset line points
      setSortedLinePoints();
    });
  };

  const onShowPasteCoordinatesDialog = () => {
    setShowPasteCoordinatesDialog(true);
  };

  const onCancelPasteCoordinates = () => {
    setShowPasteCoordinatesDialog(false);
  };

  const onAddPastedCoordinates = (coordinatesList: CoordinatesList) => {
    if (selectedLine) {
      const linePoints: LinePoint[] = coordinatesList.coordinates.map((c) => {
        return {
          id: _.uniqueId(),
          lineId: -1,
          wgs84Latitude: c.wgs84Latitude,
          wgs84Longitude: c.wgs84Longitude,
          dmsLatitude: c.dmsLatitude,
          dmsLongitude: c.dmsLongitude,
          sequence: -1,
          curvature: "0",
          inverted: false,
          buffer: 0
        };
      });
      createLinePoints(selectedLine, selectedLinePoints.length > 0 ? selectedLinePoints[selectedLinePoints.length - 1].sequence : 0, linePoints)
        .then(() => {
          toast.success("Line points created");
          setShowPasteCoordinatesDialog(false);
        })
        .catch((error: any) => {
          if (error.errorMessage) {
            toast.error(error.errorMessage);
          } else {
            toast.error(error);
          }
        });
    }
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>Lines - {versionTag}</h2>
      <Split
        style={{ height: "calc(100vh - 230px)" }}
        direction="vertical"
        onDragEnd={() => {
          /*if (radarMapRef !== null) {
            radarMapRef.current?.invalidateSize();
          }*/
        }}
      >
        <div style={{ height: "100%" }}>
          <GenericDataGrid<Line>
            data={lines}
            loading={loading}
            columnsGenerator={getLineColumns}
            validator={new LineValidator()}
            onUpdateRow={onUpdateLine}
            onDeleteRow={onDeleteLine}
            createDialogFactory={createDialogFactory}
            onSelectRow={onSelectLine}
            sortModel={[{ field: "name", sort: "asc" }]}
            selectedRow={selectedLine}
          />
        </div>
        <div style={{ height: "100%" }}>
          <TabContext value={currentTab}>
            <Tabs value={currentTab} onChange={onTabChange}>
              <Tab value="points" label={"Line points"} />
              <Tab value="additionalInformation" label="Additional information" />
            </Tabs>
            <TabPanel value="points" style={{ height: "95%" }}>
              {showPasteCoordinatesDialog && <PasteCoordinatesDialog onCancel={onCancelPasteCoordinates} onAddCoordinates={onAddPastedCoordinates} />}
              <div style={{ height: "100%" }}>
                <div style={{ height: "100%", display: "flex", flexDirection: "row" }}>
                  <div style={{ height: "90%", flex: 1, marginRight: "5px" }}>
                    <div style={{ marginBottom: "5px" }}>
                      <Button disabled={_.isUndefined(selectedLine)} onClick={onShowPasteCoordinatesDialog}>
                        Paste coordinates...
                      </Button>
                    </div>
                    <GenericDataGrid<LinePoint>
                      data={linePoints}
                      ref={linePointsGridRef}
                      columnsGenerator={getLinePointsColumns}
                      validator={new BoundaryPointsValidator()}
                      onCreateRow={onCreateLinePoint}
                      onUpdateRow={onUpdateLinePoint}
                      onDeleteRow={onDeleteLinePoint}
                      onDeleteRows={onDeleteLinePoints}
                      onSelectedRows={onSelectedLinePoints}
                      onRowOrderChange={onSelectedLinePointsOrderChange}
                      createEmptyRow={createEmptyLinePoint}
                      canInsertRow={!_.isUndefined(selectedLine)}
                    />
                  </div>
                  <div style={{ height: "90%", flex: 1 }}>
                    <MapViewer linePoints={expandedLinePoints} />
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value="additionalInformation" style={{ height: "100%" }}>
              <div style={{ height: "90%" }}>
                <AdditionalInformationGrid
                  infos={selectedLine ? selectedLine.additionalInformation : []}
                  onAddInformation={onAddInformation}
                  onUpdateInformation={onUpdateInformation}
                  onDeleteInformation={onDeleteInformation}
                  canInsertRow={!_.isUndefined(selectedLine)}
                />
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </Split>
    </div>
  );
};

export default Lines;
