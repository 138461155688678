import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { CatUser } from "src/types/Types";
import useAddCatUserFormHandler from "../forms/useAddCatUserFormHandler";
import { ChangeEvent } from "react";

type AddUserDialogProps = {
  createUser: (catUser: CatUser) => Promise<CatUser>;
  onUserCreated: () => void;
  onCancel: () => void;
};

const AddUserDialog = (props: AddUserDialogProps) => {
  const { onCancel, onUserCreated, createUser } = props;
  const { formHandler } = useAddCatUserFormHandler(createUser, onUserCreated);

  const onUsernameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    formHandler.setFieldValue("username", event.target.value);
  };

  const onFirstnameChange = (event: ChangeEvent<HTMLInputElement>) => {
    formHandler.setFieldValue("firstname", event.target.value);
  };

  const onLastnameChange = (event: ChangeEvent<HTMLInputElement>) => {
    formHandler.setFieldValue("lastname", event.target.value);
  };

  const onPasswordChanged = (event: ChangeEvent<HTMLInputElement>) => {
    formHandler.setFieldValue("password", event.target.value);
  };

  const onConfirmPasswordChanged = (event: ChangeEvent<HTMLInputElement>) => {
    formHandler.setFieldValue("confirmpassword", event.target.value);
  };

  return (
    <Dialog maxWidth="xl" open={true}>
      <DialogTitle>
        <div style={{ display: "flex" }}>
          <div></div>
          <div style={{ marginLeft: "10px" }}>Create User</div>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div style={{ display: "flex", flexDirection: "column", height: "280px", width: "370px" }}>
          <div>
            <h3>User information</h3>
          </div>
          <TextField
            size="small"
            variant="standard"
            id="username"
            name="username"
            label="Username"
            autoComplete="off"
            value={formHandler.values.username}
            onChange={onUsernameChanged}
            error={formHandler.touched.username && Boolean(formHandler.errors.username)}
            helperText={formHandler.touched.username && formHandler.errors.username}
          />
          <TextField
            style={{ marginTop: "10px" }}
            size="small"
            variant="standard"
            id="firstname"
            name="firstname"
            label="Firstname"
            autoComplete="off"
            onChange={onFirstnameChange}
            error={formHandler.touched.firstname && Boolean(formHandler.errors.firstname)}
            helperText={formHandler.touched.lastname && formHandler.errors.lastname}
          />
          <TextField
            style={{ marginTop: "10px" }}
            size="small"
            variant="standard"
            id="lastname"
            name="lastname"
            label="Lastname"
            autoComplete="off"
            onChange={onLastnameChange}
            error={formHandler.touched.lastname && Boolean(formHandler.errors.lastname)}
            helperText={formHandler.touched.lastname && formHandler.errors.lastname}
          />
          <div style={{ marginTop: "10px" }}>
            <TextField
              size="small"
              variant="standard"
              id="password"
              name="password"
              label="Password"
              autoComplete="off"
              value={formHandler.values.password}
              onChange={onPasswordChanged}
              error={formHandler.touched.password && Boolean(formHandler.errors.password)}
              helperText={formHandler.touched.password && formHandler.errors.password}
            />
            <TextField
              style={{ marginLeft: "28px" }}
              size="small"
              variant="standard"
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm password"
              autoComplete="off"
              value={formHandler.values.confirmpassword}
              onChange={onConfirmPasswordChanged}
              error={formHandler.touched.confirmpassword && Boolean(formHandler.errors.confirmpassword)}
              helperText={formHandler.touched.confirmpassword && formHandler.errors.confirmpassword}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus variant="contained" onClick={formHandler.submitForm}>
          Add
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserDialog;
