import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import TextFieldEditor from "./TextFieldEditor";

const DataGridTextFieldEditor = (params: GridRenderEditCellParams) => {
  const { id, value, field, error } = params;
  const apiRef = useGridApiContext();

  const handleChangeValue = (newValue: string) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return <TextFieldEditor value={value} onChange={handleChangeValue} showHelperTextAsTooltip hasError={Boolean(error)} helperText={error} />;
};

export default DataGridTextFieldEditor;
