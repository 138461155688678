import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import { AirwayPoint } from "src/types/Types";

const DatagridAirwayStretchSelector = (params: GridRenderEditCellParams) => {
  const { id, value, field, row } = params;
  const apiRef = useGridApiContext();

  const airwayPoints: AirwayPoint[] = row.airwayPoints;

  const handleChangeValue = (event: SelectChangeEvent<string[]>) => {
    const newValues = event.target.value;
    let selectedPoints = airwayPoints.filter((ap) => newValues.includes(ap.waypointName));
    if (selectedPoints.length > 0) {
      selectedPoints = airwayPoints.filter((ap) => ap.sequence >= selectedPoints[0].sequence && ap.sequence <= selectedPoints[selectedPoints.length - 1].sequence);
    }

    apiRef.current.setEditCellValue({ id, field, value: selectedPoints.map((p) => p.waypointName) });
  };
  return (
    <FormControl fullWidth>
      <Select multiple value={value} onChange={handleChangeValue}>
        {airwayPoints.map((p: AirwayPoint) => (
          <MenuItem value={p.waypointName}>{p.waypointName}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default DatagridAirwayStretchSelector;
