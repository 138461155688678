import { Autocomplete, Button, Popover, TextField } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import useLines from "src/apis/lines/useLines";
import { parseClosure, renderClosure } from "src/columnsgenerators/useComputedAreaColumns";
import { Closure, Line } from "src/types/Types";

const DatagridClosuresSelector = (params: GridRenderEditCellParams<Closure[]>) => {
  const { id, value, field, error } = params;
  const apiRef = useGridApiContext();

  const { lines } = useLines();
  const [closures, setClosures] = useState<Closure[]>([]);

  const style = error ? { color: "red" } : {};

  useEffect(() => {
    if (lines.length > 0) {
      const cl: Closure[] = lines.map((l) => {
        return { id: +l.id, name: l.name };
      });
      setClosures(cl);
    }
  }, [lines]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;

  const handleChangeValue = (event: any, values: string[]) => {
    const newValues = values.map((cl) => {
      return parseClosure(cl);
    }); // The new value entered by the user
    apiRef.current.setEditCellValue({ id, field, value: newValues });
  };

  return (
    <div style={{ width: 500 }}>
      <Button onClick={handleClick} style={style}>
        {value !== undefined && value.length > 0
          ? value
              .map((c: Closure) => {
                return renderClosure(c);
              })
              .toString()
              .replace(",", "|")
          : "Closures"}
      </Button>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        <div style={{ width: 500 }}>
          <Autocomplete
            size="small"
            fullWidth
            options={closures.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0)).map((cl) => renderClosure(cl))}
            multiple
            value={value?.map((cl: Closure) => renderClosure(cl))}
            onChange={handleChangeValue}
            renderInput={(params) => <TextField multiline {...params} />}
          />
        </div>
      </Popover>
    </div>
  );
};

export default DatagridClosuresSelector;
