import { LatLng } from "leaflet";
import { Polyline } from "react-leaflet";
import { Railway } from "src/types/Types";

type RailwaysProps = {
  railways: Railway[];
};

const Railways = (props: RailwaysProps) => {
  const { railways } = props;
  const defaultColor = "#1565C0";

  return (
    <>
      {railways.map((rw) => {
        return (
          <Polyline
            pathOptions={{ color: rw.color ? rw.color : defaultColor, weight: 1 }}
            positions={rw.coordinates!.slice().map((lp) => {
              return new LatLng(lp.wgs84Latitude, lp.wgs84Longitude);
            })}
          />
        );
      })}
    </>
  );
};

export default Railways;
