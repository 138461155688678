import { GridColumns, GridPreProcessEditCellProps, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { ColumnsGenerator } from "src/types/Types";

import ContentEditor from "src/components/editors/ContentEditor";
import TopicEditor from "src/components/editors/TopicEditor";
import { FieldsValidator } from "src/validators/Validator";

const useAdditionalInformationColumns = () => {
  const getColumns: ColumnsGenerator = (validator: FieldsValidator, onFieldDirty: () => void) => {
    const columns: GridColumns = [
      { field: "sequence", headerName: "Sequence", width: 100, editable: false },
      {
        field: "topic",
        headerName: "Topic",
        width: 200,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <TopicEditor {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("topic", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "contents",
        headerName: "Contents",
        width: 300,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <ContentEditor {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("contents", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "onSameLine",
        headerName: "Same line",
        width: 100,
        editable: true,
        type: "boolean",
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("onSameLine", params.props.value);
          return { ...params.props, error: hasError };
        }
      }
    ];
    return columns;
  };

  return { getColumns };
};

export default useAdditionalInformationColumns;
