import { getAPIClient } from "src/apis/apiClient";
import { AdditionalInformation, Holding, Xref } from "src/types/Types";

export type HoldingsResponse = {
  data: Holding[];
  errorMessage: string;
};

export type CreateHoldingResponse = {
  data: Holding;
  errorMessage: string;
};

export type updateHoldingResponse = {
  data: Holding;
  errorMessage: string;
};

export type DelteHoldingResponse = {
  data: number;
  errorMessage: string;
};

type GetInformationResponse = {
  data: AdditionalInformation[];
  errorMessage: string;
};

type AddInformationResponse = {
  data: AdditionalInformation;
  errorMessage: string;
};

type UpdateInformationResponse = {
  data: AdditionalInformation;
  errorMessage: string;
};

type DeleteInformationResponse = {
  data: AdditionalInformation[];
  errorMessage: string;
};

type XrefsResponse = {
  data: Xref[];
  errorMessage: string;
};

export const getHoldings = async (versionTag: string) => {
  const apiClient = getAPIClient();
  const result = await apiClient!.get<HoldingsResponse>("/tables/holding", { headers: { "x-air-version": versionTag } });
  return result.data.data;
};

export const getHoldingsFromIds = async (versionTag: string, ids: number[]) => {
  const apiClient = getAPIClient();
  const result = await apiClient!.post<HoldingsResponse>("/tables/holding/search", { searchIds: ids }, { headers: { "x-air-version": versionTag } });
  return result.data.data;
};

export const getHoldingXrefs = async (holding: Holding, versionTag: string) => {
  const apiClient = getAPIClient();
  const result = await apiClient!.get<XrefsResponse>(`/tables/holding/${holding.id}/x-ref`, { headers: { "x-air-version": versionTag } });
  return result.data.data;
};

export const getInformation = async (holding: Holding, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.get<GetInformationResponse>(`/tables/holding/${holding.id}/additional-information`, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const createHolding = async (holding: Holding, versionTag: string) => {
  const apiClient = getAPIClient();
  const result = await apiClient!.post<CreateHoldingResponse>("/tables/holding", holding, { headers: { "x-air-version": versionTag } });
  return result.data.data;
};

export const updateHolding = async (holding: Holding, versionTag: string) => {
  const apiClient = getAPIClient();
  const result = await apiClient!.put<updateHoldingResponse>(`/tables/holding/${holding.id}`, holding, { headers: { "x-air-version": versionTag } });
  return result.data.data;
};

export const deleteHolding = async (holding: Holding, versionTag: string) => {
  const apiClient = getAPIClient();
  const result = await apiClient!.delete<DelteHoldingResponse>(`/tables/holding/${holding.id}`, { headers: { "x-air-version": versionTag } });
  return result.data.data;
};

export const addInformation = async (holding: Holding, info: AdditionalInformation, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.post<AddInformationResponse>(`/tables/holding/${holding.id}/additional-information`, info, { headers: { "x-air-version": versionTag } });
  return response.data.data;
};

export const updateInformation = async (holding: Holding, info: AdditionalInformation, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.put<UpdateInformationResponse>(`/tables/holding/${holding.id}/additional-information/${info.id}`, info, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};

export const deleteInformation = async (holding: Holding, info: AdditionalInformation, versionTag: string) => {
  const _apiClient = getAPIClient();
  const response = await _apiClient!.delete<DeleteInformationResponse>(`/tables/holding/${holding.id}/additional-information/${info.id}`, {
    headers: { "x-air-version": versionTag }
  });
  return response.data.data;
};
