import { FieldsValidator, Validator } from "./Validator";

const orientationMaskEmpty = "___";

class AirportValidator extends Validator {
  validateField = (fieldName: string, fieldValue: any, otherFields: any) => {
    this.validationMap.set(fieldName, false);
    switch (fieldName) {
      case "name": {
        if (fieldValue.length === 0) {
          this.validationMap.set(fieldName, true);
          return "Invalid name";
        }
        return "";
      }
      case "orientation": {
        if (fieldValue) {
          if (isNaN(+fieldValue) || +fieldValue < 0 || +fieldValue > 360) {
            this.validationMap.set(fieldName, true);
            return "Invalid orientation";
          }
        }
        return "";
      }
      case "elevation":
        if (fieldValue !== orientationMaskEmpty) {
          if (isNaN(+fieldValue) || +fieldValue < 0) {
            this.validationMap.set(fieldName, true);
            return "Invalid elevation";
          }
        }
        return "";
      case "dmsLatitude": {
        let validation = this.validateOtherCoordinate(fieldName, fieldValue, otherFields.dmsLongitude.value);
        if (validation.length > 0) return validation;
        return this.validateLatitude(fieldName, fieldValue, true);
      }
      case "dmsLongitude": {
        let validation = this.validateOtherCoordinate(fieldName, fieldValue, otherFields.dmsLatitude.value);
        if (validation.length > 0) return validation;

        return this.validateLongitude(fieldName, fieldValue, true);
      }
      default:
        return "";
    }
  };
}

export default AirportValidator;
