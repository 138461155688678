import { FieldsValidator } from "./Validator";

class AdditionalInformationValidator implements FieldsValidator {
  validationMap = new Map<string, boolean>();
  isEmptyString = (s: string) => {
    return s === null || s === undefined || s.trim() === "";
  };

  hasValidationErrors() {
    const values = this.validationMap.values();
    return Array.from(values).includes(true);
  }
  validateField = (fieldName: string, fieldValue: any) => {
    if (fieldName === "topic") {
      if (this.isEmptyString(fieldValue)) {
        this.validationMap.set("topic", true);
        return "Topic cannot be empty";
      }
      this.validationMap.set("topic", false);
      return "";
    }
    return "";
  };
}

export default AdditionalInformationValidator;
