import {
  DataGridPro,
  GridActionsCellItem,
  GridApi,
  GridColumns,
  GridEventListener,
  GridEvents,
  GridRenderCellParams,
  GridRowId,
  GridRowParams,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  MuiEvent,
  useGridApiRef
} from "@mui/x-data-grid-pro";
import React, { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { Button, FormControl, InputLabel, SelectChangeEvent } from "@mui/material";

import { MenuItem, Select } from "@mui/material";
import { AxiosError } from "axios";
import { Prompt } from "react-router-dom";
import { toast } from "react-toastify";
import { ClientType } from "../ClientTypes";

type ClientTypesGridProps = {
  clientTypes: Array<ClientType>;
  onCreateClientType: (clientType: ClientType) => Promise<void>;
  onUpdateClientType: (clientType: ClientType) => Promise<void>;
  onDeleteClientType: (id: number) => void;
};

type EditToolbarProps = {
  apiRef: React.MutableRefObject<GridApi>;
};

const ClientTypesGrid = (props: ClientTypesGridProps) => {
  const { clientTypes, onCreateClientType, onUpdateClientType, onDeleteClientType } = props;
  const apiRef = useGridApiRef();
  const [isInEditMode, setInEditMode] = useState(false);

  const handleEditClick = (id: GridRowId) => (event: React.MouseEvent) => {
    event.stopPropagation();
    setInEditMode(true);
    apiRef.current.startRowEditMode({ id });
  };

  const handleSaveClick = (id: GridRowId) => async (event: React.MouseEvent) => {
    event.stopPropagation();
    setInEditMode(false);
    await apiRef.current.stopRowEditMode({ id });
  };

  const handleDeleteClick = (id: GridRowId) => (event: React.MouseEvent) => {
    event.stopPropagation();
    //apiRef.current.updateRows([{ id, _action: "delete" }]);
    onDeleteClientType(+id);
  };

  const handleCancelClick = (id: GridRowId) => async (event: React.MouseEvent) => {
    event.stopPropagation();
    setInEditMode(false);
    await apiRef.current.stopRowEditMode({ id, ignoreModifications: true });
    const row = apiRef.current.getRow(id);
    if (row!.isNew) {
      apiRef.current.updateRows([{ id, _action: "delete" }]);
    }
  };

  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<GridEvents.rowEditStop> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = async (row: ClientType, oldRow: ClientType) => {
    if (JSON.stringify(row) === JSON.stringify(oldRow)) {
      return oldRow;
    }
    if (row.id === -1) {
      await onCreateClientType({
        id: -1,
        name: row.name,
        swiss: row.swiss,
        local: row.local,
        targetSystem: row.targetSystem,
        userTags: row.userTags,
        fir: row.fir,
        lastUpdate: undefined,
        lastUpdateBy: undefined
      }).catch((error: AxiosError) => {
        toast.error(error.response?.data.errorMessage);
        apiRef.current.startRowEditMode({ id: -1 });
      });
    } else {
      await onUpdateClientType({
        id: row.id,
        name: row.name,
        swiss: row.swiss,
        local: row.local,
        targetSystem: row.targetSystem,
        userTags: row.userTags,
        fir: row.fir,
        lastUpdate: undefined,
        lastUpdateBy: undefined
      }).catch((error: AxiosError) => {
        toast.error("error");
        apiRef.current.startRowEditMode({ id: row.id });
      });
    }
    return { ...row, isNew: false };
  };

  const columns: GridColumns = [
    { field: "id", headerName: "Id", editable: true, width: 200 },

    { field: "name", headerName: "Name", editable: true, width: 200 },
    {
      field: "fir",
      headerName: "A.o.R",
      editable: true,
      width: 100,
      renderEditCell: (props: GridRenderCellParams) => {
        const { id, value, field } = props;
        return (
          <FormControl fullWidth>
            <InputLabel id="fir">Fir</InputLabel>
            <Select
              labelId="fir"
              id="fir"
              value={props.value}
              label="Fir"
              onChange={(event: SelectChangeEvent) => {
                const newValue = event.target.value;
                apiRef.current.setEditCellValue({ id, field, value: newValue });
              }}
            >
              <MenuItem value={"LSAZ"}>LSAZ</MenuItem>
              <MenuItem value={"LSAG"}>LSAG</MenuItem>
            </Select>
          </FormControl>
        );
      }
    },
    { field: "targetSystem", headerName: "Target system", editable: true, width: 200 },
    {
      field: "local",
      headerName: "Local area",
      editable: true,
      width: 80,
      type: "boolean"
    },
    {
      field: "swiss",
      headerName: "Swiss area",
      editable: true,
      width: 80,
      type: "boolean"
    },
    {
      field: "userTags",
      headerName: "User tags",
      editable: true,
      width: 80
      /*
      renderEditCell: (props: GridRenderEditCellParams) => {
        const { id, value, field } = props;
        const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = event.target.value; // The new value entered by the user
          apiRef.current.setEditCellValue({ id, field, value: newValue });
        };
        return "";
      }*/
    },
    { field: "lastUpdate", headerName: "Last update", width: 200, editable: false },
    { field: "lastUpdateBy", headerName: "Last update by", width: 200, editable: false },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (rowProps: GridRowParams<ClientType>) => {
        const isInEditMode = apiRef.current.getRowMode(rowProps.id) === "edit";
        if (isInEditMode) {
          return [
            <GridActionsCellItem icon={<SaveIcon />} label="Save" onResizeCapture={() => {}} onResize={() => {}} onClick={handleSaveClick(rowProps.id)} color="primary" />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onResizeCapture={() => {}}
              onResize={() => {}}
              onClick={handleCancelClick(rowProps.id)}
              color="inherit"
            />
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onResizeCapture={() => {}}
            onResize={() => {}}
            onClick={handleEditClick(rowProps.id)}
            color="inherit"
          />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onResizeCapture={() => {}} onResize={() => {}} onClick={handleDeleteClick(rowProps.id)} color="inherit" />
        ];
      }
    }
  ];

  const EditToolbar = (props: EditToolbarProps) => {
    const { apiRef } = props;

    const handleClientType = () => {
      setInEditMode(true);
      const id = -1;
      apiRef.current.updateRows([{ id, isNew: true }]);
      apiRef.current.startRowEditMode({ id });

      // Wait for the grid to render with the new row
      // kinda dangerous! This actually comes from the
      // MUI grid crud example. However the API has changed so this code needs to be updated!
      setTimeout(() => {
        apiRef.current.scrollToIndexes({
          rowIndex: apiRef.current.getRowsCount() - 1
        });
        apiRef.current.setCellFocus(id, "name");
      }, 200);
    };

    return (
      <GridToolbarContainer>
        <div style={{ marginRight: 15 }}>
          <GridToolbarQuickFilter />
        </div>

        <Button color="primary" startIcon={<AddIcon />} onClick={handleClientType}>
          Add Client type
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ height: "100%" }}>
      <Prompt when={isInEditMode} message="You have unsaved changes, are you sure you want to leave?" />
      <DataGridPro
        density="compact"
        editMode="row"
        apiRef={apiRef}
        columns={columns}
        rows={clientTypes}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        components={{
          Toolbar: EditToolbar
        }}
        componentsProps={{
          toolbar: { apiRef }
        }}
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </div>
  );
};

export default ClientTypesGrid;
