import { useEffect, useState } from "react";

import { useFormik } from "formik";
import { GraphicAttribute, GraphicAttributeType, MapElementReference, MapElementReferencesUpdates, MapElementType, MenuItem } from "src/types/Types";

import { ClientType } from "src/pages/clienttypes/ClientTypes";

import useMaps from "src/apis/maps/useMaps";

import { GridSelectionModel } from "@mui/x-data-grid-pro";
import { toast } from "react-toastify";
import { SearchCriteria, computeReferenceUpdates as _computeReferenceUpdates } from "src/apis/maps/mapsApi";

import * as _ from "lodash";
import useGraphicAttributes from "src/apis/graphicattributes/useGraphicAttributes";
import * as yup from "yup";

import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { useAppContext } from "src/appcontext/AppContext";
import { v4 as uuidv4 } from "uuid";

type FormValues = {
  path: string;
  code: string;
  name: string;
  userTags: string[];
  clientTypes: ClientType[];
  mapElementReferences: MapElementReference[];
};

const useMapEditorHandler = (
  createMap: (map: MenuItem) => Promise<MenuItem>,
  updateMap: (map: MenuItem) => Promise<MenuItem>,
  onSuccess: (map: MenuItem) => void,
  map?: MenuItem
) => {
  const appContext = useAppContext();
  const versionTag = useVersionTag();
  const { searchReferences: __searchReferences } = useMaps();
  const { graphicAttributes, defaultGraphicAttributes } = useGraphicAttributes();

  /* Search filter */
  const [selectedMapElementTypes, setSelectedMapElementTypes] = useState<MapElementType[]>([]);
  const [selectedUserTag, setSelectedsUserTag] = useState<string | null>(null);

  /* elements returned by the search filter */
  const [availableReferences, setAvailableReferences] = useState<MapElementReference[]>([]);
  const [loadingReferences, setLoadingReferences] = useState(false);

  /* elements added to the map*/
  const [addedReferences, setAddedReferences] = useState<MapElementReference[]>([]);
  const [addedReferencesSelectionModel, setAddedReferencesSelectionModel] = useState<GridSelectionModel>([]);

  const [graphicAttributeType, setGraphicAttributeType] = useState<GraphicAttributeType>("NONE");
  const [auxiliaryGraphicAttributeType, setAuxiliaryGraphicAttributeType] = useState<GraphicAttributeType>("NONE");

  const [saving, setSaving] = useState(false);

  const validationSchema = yup.object({
    path: yup.string().nullable().required(),
    name: yup.string().required("Name cannot be empty"),
    code: yup
      .string()
      .required("Code cannot be empty")
      .test("code", "Invalid code", (value) => {
        if (value === undefined || value === null) return false;
        if (+value <= 0 || Number.isNaN(+value)) {
          return false;
        }
        return true;
      })
  });

  const mapFormValuesToRadarMap = (values: FormValues): MenuItem => {
    return {
      id: map ? map.id : -1,
      menuItemType: "MAP",
      parentId: null,
      sequence: -1,
      path: values.path === "/" ? [] : values.path.split("/"),
      name: values.name,
      code: +values.code,
      clientTypes: values.clientTypes.map((ct) => ct.name),
      /*  @todo fix this logic! */
      mapElementReferences: addedReferences.map((r) => {
        r.id = r.persistent ? r.id : -1;
        if (r.airwayStretch.length > 1) {
          r.airwayStartPoint = r.airwayStretch[0];
          r.airwayEndPoint = r.airwayStretch[r.airwayStretch.length - 1];
        }
        return r;
      }),
      userTags: values.userTags
    };
  };

  const formHandler = useFormik<FormValues>({
    validationSchema: validationSchema,
    initialValues: { path: "", code: "", name: "", userTags: [], clientTypes: [], mapElementReferences: [] },
    onSubmit: (values, formikHelpers) => {
      if (map) {
        //update map
        const radarMap = mapFormValuesToRadarMap(values);
        setSaving(true);
        updateMap(radarMap)
          .then((result) => {
            onSuccess(result);
          })
          .catch((error) => {
            toast.error(error.errorMessage);
          })
          .finally(() => {
            setSaving(false);
          });
      } else {
        // create new map
        setSaving(true);
        const radarMap = mapFormValuesToRadarMap(values);
        createMap(radarMap)
          .then((result) => {
            appContext.dispatch({
              type: "newMenusGridRowCreated",
              payload: result.id
            });

            onSuccess(result);
          })
          .catch((error) => {
            toast.error(error.errorMessage);
          })
          .finally(() => {
            setSaving(false);
          });
      }
    }
  });

  const computeMapElementReferencesUpdates = async (): Promise<MapElementReferencesUpdates> => {
    if (map) {
      const result = await _computeReferenceUpdates(versionTag, mapFormValuesToRadarMap(formHandler.values));
      return result;
    } else {
      return {
        oldPath: "",
        newPath: "",
        oldName: "",
        newName: "",
        oldCode: 0,
        newCode: 0,
        addedClientTypes: [],
        deletedClientTypes: [],
        addedUserTags: [],
        deletedUserTags: [],
        addedReferences: [],
        refAttributeUpdates: [],
        deletedReferences: []
      };
    }
  };

  const onAddedReferencesSelectionModelChange = (newModel: GridSelectionModel) => {
    setAddedReferencesSelectionModel(newModel);
  };

  const onAirwayStretchChange = (rowId: string | number, airwayStretch: string[]) => {
    const elementReference = addedReferences.filter((ref) => ref.id === rowId)[0];
    if (elementReference) {
      const _copy = _.cloneDeep(elementReference);
      _copy.airwayStretch = airwayStretch;
      setAddedReferences([...addedReferences.filter((ref) => ref.id !== rowId), _copy]);
    }
  };

  const onGraphicAttributeChange = (newValue: GraphicAttribute) => {
    const _copy = _.cloneDeep(addedReferences);
    for (let i = 0; i < addedReferencesSelectionModel.length; i++) {
      const currentReference = _copy.filter((cr) => cr.id === addedReferencesSelectionModel[i])[0];
      currentReference.attributes = newValue.name;
    }
    setAddedReferences(_copy);
    setAddedReferencesSelectionModel([]);
  };

  const onAuxiliaryGraphicAttributeChange = (newValue: GraphicAttribute) => {
    const _copy = _.cloneDeep(addedReferences);
    for (let i = 0; i < addedReferencesSelectionModel.length; i++) {
      const currentReference = _copy.filter((cr) => cr.id === addedReferencesSelectionModel[i])[0];
      currentReference.auxiliaryAttributes = newValue.name;
    }
    setAddedReferences(_copy);
    setAddedReferencesSelectionModel([]);
  };

  const updateGraphicAttributeType = () => {
    const selectedReferences = addedReferences.filter((ref) => addedReferencesSelectionModel.includes(ref.id));

    const pointsWaypointsorHoldings = selectedReferences.filter((ref) => ref.referenceType === "POINT" || ref.referenceType === "WAYPOINT" || ref.referenceType === "HOLDING");

    const textsOrDamParameters = selectedReferences.filter((ref) => ref.referenceType === "TEXT" || ref.referenceType === "DAM_PARAMETER");

    const areasOrComputedAreas = selectedReferences.filter((ref) => ref.referenceType === "AREA" || ref.referenceType === "COMPUTED_AREA");

    const lineTypeReferences = selectedReferences.filter(
      (ref) => ref.referenceType === "LINE" || ref.referenceType === "DEPARTURE_PROCEDURE" || ref.referenceType === "ARRIVAL_PROCEDURE" || ref.referenceType === "AIRWAY"
    );

    const airports = selectedReferences.filter((ref) => ref.referenceType === "AIRPORT");

    if (
      lineTypeReferences.length > 0 &&
      textsOrDamParameters.length === 0 &&
      areasOrComputedAreas.length === 0 &&
      pointsWaypointsorHoldings.length === 0 &&
      airports.length === 0
    ) {
      setGraphicAttributeType("LINE");
      setAuxiliaryGraphicAttributeType("NONE");
    } else if (
      pointsWaypointsorHoldings.length > 0 &&
      textsOrDamParameters.length === 0 &&
      areasOrComputedAreas.length === 0 &&
      lineTypeReferences.length === 0 &&
      airports.length === 0
    ) {
      setGraphicAttributeType("POINT");
      setAuxiliaryGraphicAttributeType("NONE");
    } else if (
      textsOrDamParameters.length > 0 &&
      pointsWaypointsorHoldings.length === 0 &&
      areasOrComputedAreas.length === 0 &&
      lineTypeReferences.length === 0 &&
      airports.length === 0
    ) {
      setGraphicAttributeType("TEXT");
      setAuxiliaryGraphicAttributeType("NONE");
    } else if (
      areasOrComputedAreas.length > 0 &&
      pointsWaypointsorHoldings.length === 0 &&
      textsOrDamParameters.length === 0 &&
      lineTypeReferences.length === 0 &&
      airports.length === 0
    ) {
      setGraphicAttributeType("AREA");
      setAuxiliaryGraphicAttributeType("LINE");
    } else if (
      airports.length > 0 &&
      areasOrComputedAreas.length == 0 &&
      pointsWaypointsorHoldings.length == 0 &&
      textsOrDamParameters.length == 0 &&
      lineTypeReferences.length == 0
    ) {
      setGraphicAttributeType("AIRPORT");
      setAuxiliaryGraphicAttributeType("NONE");
    } else {
      setGraphicAttributeType("NONE");
      setAuxiliaryGraphicAttributeType("NONE");
    }
  };

  useEffect(() => {
    updateGraphicAttributeType();
  }, [addedReferencesSelectionModel]);

  const _searchAvailableReferences = (searchCriteria: SearchCriteria) => {
    if (searchCriteria) {
      setLoadingReferences(true);
      __searchReferences(searchCriteria)
        .then((result) => {
          setAvailableReferences(result);
        })
        .catch((error) => {
          setAvailableReferences([]);
        })
        .finally(() => {
          setLoadingReferences(false);
        });
    }
  };

  const onSelectedMapElementTypesChange = (newValues: MapElementType[]) => {
    setSelectedMapElementTypes(newValues);
  };

  const onSelectedUserTagChange = (newValue: string | null) => {
    setSelectedsUserTag(newValue);
  };

  useEffect(() => {
    const searchCriteria = {
      mapElementTypes: selectedMapElementTypes,
      userTag: selectedUserTag
    };
    _searchAvailableReferences(searchCriteria);
  }, [selectedMapElementTypes, selectedUserTag]);

  const clearCurrentReferences = () => {
    setAddedReferences([]);
  };

  const addExistingReferences = (references: MapElementReference[]) => {
    setAddedReferences([...addedReferences, ...references]);
  };

  const addReferencesToMap = async (references: MapElementReference[]) => {
    const addedReferenceIds = addedReferences.map((r) => r.id);

    const _addedReferencesClone = _.cloneDeep(addedReferences);
    let _availableReferencesClone = _.cloneDeep(availableReferences);

    for (let i = 0; i < references.length; i++) {
      if (!addedReferenceIds.includes(references[i].id)) {
        const defaultGraphicAttribute = defaultGraphicAttributes.filter((a) => a.referenceType === references[i].referenceType)[0];
        if (defaultGraphicAttribute) {
          // note should we also clone references?
          references[i].attributes = defaultGraphicAttribute.graphicAttribute;
        }
        if (references[i].referenceType === "AIRWAY") {
          references[i].id = uuidv4();
        }
        _addedReferencesClone.push(references[i]);
        _availableReferencesClone = _availableReferencesClone.filter((r) => r.id !== references[i].id);
      }
    }
    setAddedReferences(_addedReferencesClone);
    setAvailableReferences(_availableReferencesClone);
  };

  const removeElementReference = (referenceId: string | number) => {
    const _removedReference = addedReferences.filter((r) => r.id === referenceId)[0];
    const _availableReferencesClone = _.cloneDeep(availableReferences);
    const _availableReferencesIds = _availableReferencesClone.map((r) => r.id);

    if (!_availableReferencesIds.includes(_removedReference.id)) _availableReferencesClone.push(_removedReference);

    setAddedReferences([...addedReferences.filter((r) => r.id !== referenceId)]);
    setAvailableReferences([..._availableReferencesClone]);
  };

  const removeElementReferences = (referenceIds: Array<string | number>) => {
    const _removedReferences = addedReferences.filter((r) => referenceIds.includes(r.id));
    const _availableReferencesClone = _.cloneDeep(availableReferences);
    const _availableReferencesIds = _availableReferencesClone.map((r) => r.id);

    setAddedReferences([...addedReferences.filter((r) => !referenceIds.includes(r.id))]);
    setAvailableReferences([..._availableReferencesClone, ..._removedReferences.filter((r) => !_availableReferencesIds.includes(r.id))]);
  };

  const clearSearch = () => {
    setAvailableReferences([]);
    setSelectedMapElementTypes([]);
    setSelectedsUserTag(null);
  };

  return {
    formHandler,
    availableReferences,
    loadingReferences,
    addedReferences,
    saving,
    addedReferencesSelectionModel,
    graphicAttributes,
    graphicAttributeType,
    auxiliaryGraphicAttributeType,
    selectedMapElementTypes,
    selectedUserTag,
    onGraphicAttributeChange,
    onAuxiliaryGraphicAttributeChange,
    clearSearch,
    clearCurrentReferences,
    addExistingReferences,
    addReferencesToMap,
    removeElementReference,
    removeElementReferences,
    onAddedReferencesSelectionModelChange,
    onSelectedMapElementTypesChange,
    onSelectedUserTagChange,
    onAirwayStretchChange,
    computeMapElementReferencesUpdates
  };
};

export default useMapEditorHandler;
