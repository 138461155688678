import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import CategoriesSelector from "./CategoriesSelector";

const DatagridCategoriesSelector = (params: GridRenderEditCellParams) => {
  const { id, value, field, error } = params;
  const apiRef = useGridApiContext();

  const handleChangeValue = (newValue: string | null) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };
  return <CategoriesSelector value={value} onChange={handleChangeValue} showLabel={false} />;
};

export default DatagridCategoriesSelector;
