import { GridPreProcessEditCellProps } from "@mui/x-data-grid-pro";
import { ColumnsGenerator } from "src/types/Types";
import { FieldsValidator } from "src/validators/Validator";

const useLakeColumns = () => {
  const getColumns: ColumnsGenerator = (validator: FieldsValidator, onFieldDirty: () => void) => {
    return [
      {
        field: "name",
        headerName: "Name",
        editable: false,
        width: 300,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("name", params.props.value);
          return { ...params.props, error: hasError };
        }
      }
    ];
  };
  return { getColumns };
};

export default useLakeColumns;
