import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import { Point } from "src/types/Types";

import PointIcon from "@mui/icons-material/CropSquare";
import { Marker, Tooltip } from "react-leaflet";

type PointMarkerProps = {
  point: Point;
};

const PointMarker = (props: PointMarkerProps) => {
  const { point } = props;
  const divIcon = L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(
      <div style={{ color: "#1565C0" }}>
        {point.symbolImage ? (
          <div style={{ width: "15px", height: "15px", fontWeight: "bold" }}>
            <img src={`data:image/png;base64,${point.symbolImage}`} />
          </div>
        ) : (
          <PointIcon style={{ width: "15px", height: "15px", fontWeight: "bold" }} />
        )}
      </div>
    )
  });

  return (
    <Marker icon={divIcon} position={[point.wgs84Latitude, point.wgs84Longitude]}>
      <Tooltip direction="right">{`[${point.dmsLatitude} ${point.dmsLongitude}]`}</Tooltip>
    </Marker>
  );
};

export default PointMarker;
