import useDamParameters from "src/apis/damparameters/useDamParameters";
import { useVersionTag } from "src/apis/hooks/useVersionTag";
import { getWgs84LatLng } from "src/apis/waypoints/waypointsApi";
import useDamParameterColumns from "src/columnsgenerators/useDamParameterColumns";
import GenericDataGrid from "src/components/datagrids/GenericDataGrid";
import { AdditionalInformation, DamParameter } from "src/types/Types";
import DamParameterValidator from "src/validators/DamParameterValidator";
import AddDamParameterDialog from "./dialogs/AddDamParameterDialog";

import { TabContext, TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import { isUndefined } from "lodash";
import { useState } from "react";
import Split from "react-split";
import AdditionalInformationGrid from "src/components/datagrids/AdditionalInformationGrid";

export type DamParametersResponse = {
  data: DamParameter[];
  errorMessage: string;
};

const DamParameters = () => {
  const { getColumns } = useDamParameterColumns();
  const versionTag = useVersionTag();
  const {
    loading,
    damParameters,
    selectedDamParameter,
    selectDamParameter,
    createDamParameter,
    updateDamParameter,
    deleteDamParameter,
    createDamParameterInfo,
    updateDamParameterInfo,
    deleteDamParameterInfo
  } = useDamParameters();
  const [currentTab, setCurrentTab] = useState("additionalInformation");

  const onSelectDamParameter = (damParameter: DamParameter) => {
    selectDamParameter(damParameter);
  };

  const onUpdateDamParameter = async (damParameter: DamParameter) => {
    const latLng = await getWgs84LatLng(damParameter.dmsLatitude, damParameter.dmsLongitude);
    damParameter.wgs84Latitude = latLng.wgs84Latitude;
    damParameter.wgs84Longitude = latLng.wgs84Longitude;
    return updateDamParameter(damParameter);
  };

  const onDeleteDamParameter = (damParameter: DamParameter) => {
    return deleteDamParameter(damParameter!);
  };

  const createDialogFactory = (onSuccess: () => void, onCancel: () => void) => {
    return <AddDamParameterDialog createDamParameter={createDamParameter} onDamParameterCreated={onSuccess} onCancel={onCancel} />;
  };

  const onAddInformation = (info: AdditionalInformation) => {
    return createDamParameterInfo(selectedDamParameter!, info);
  };

  const onUpdateInformation = (info: AdditionalInformation) => {
    return updateDamParameterInfo(selectedDamParameter!, info);
  };

  const onDeleteInformation = (info: AdditionalInformation) => {
    return deleteDamParameterInfo(selectedDamParameter!, info);
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <h2>DAM parameters - {versionTag}</h2>
      <Split style={{ height: "calc(100vh - 230px)" }} direction="vertical">
        <div style={{ height: "80%" }}>
          <GenericDataGrid<DamParameter>
            columnsGenerator={getColumns}
            validator={new DamParameterValidator()}
            loading={loading}
            data={damParameters}
            onSelectRow={onSelectDamParameter}
            createDialogFactory={createDialogFactory}
            onUpdateRow={onUpdateDamParameter}
            onDeleteRow={onDeleteDamParameter}
            selectedRow={selectedDamParameter}
            pinnedColumns={{ left: ["id", "description"], right: ["actions"] }}
            sortModel={[{ field: "id", sort: "asc" }]}
          />
        </div>
        <div style={{ height: "20%" }}>
          <TabContext value={currentTab}>
            <Tabs value={currentTab}>
              <Tab value="additionalInformation" label="Additional information" />
            </Tabs>
            <TabPanel value="additionalInformation" style={{ height: "100%" }}>
              <div style={{ height: "100%" }}>
                <AdditionalInformationGrid
                  infos={selectedDamParameter?.additionalInformation ? selectedDamParameter.additionalInformation : []}
                  onAddInformation={onAddInformation}
                  onUpdateInformation={onUpdateInformation}
                  onDeleteInformation={onDeleteInformation}
                  canInsertRow={!isUndefined(selectedDamParameter)}
                />
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </Split>
    </div>
  );
};

export default DamParameters;
