import { List } from "@mui/material";

import useSidebarItems from "./hooks/useSidebarItems";

const Sidebar = () => {
  const sideBarItems = useSidebarItems();

  return (
    <List sx={{ width: "100%", fontWeight: "500px" }} component="nav" aria-labelledby="nested-list-subheader">
      {sideBarItems}
    </List>
  );
};

export default Sidebar;
