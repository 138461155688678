import React, { useRef } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Identifiable } from "src/types/Types";

import DeleteIcon from "@mui/icons-material/Delete";

export type CreateEntityFormHandle = {
  requestSubmit: () => void;
};

export type CreateEntityFormProps<T extends Identifiable> = {
  createEntity: (entity: T) => Promise<T>;
  onEntityCreated: () => void;
};

export type CreateEntityForm<T extends Identifiable> = React.ForwardRefRenderFunction<CreateEntityFormHandle, CreateEntityFormProps<T>>;
type CreateEntityFormRef<T extends Identifiable> = React.ForwardRefExoticComponent<CreateEntityFormProps<T> & React.RefAttributes<CreateEntityFormHandle>>;

export type CreateEntityDialogProps<T extends Identifiable> = {
  title?: string;
  onCancel: () => void;
  createEntity: (entity: T) => Promise<T>;
  onEntityCreated: () => void;
  CreateEntityFormComponent: CreateEntityFormRef<T>;
};

const CreateEntityDialog = <T extends Identifiable>(props: CreateEntityDialogProps<T>) => {
  const { title, createEntity, onEntityCreated, onCancel, CreateEntityFormComponent } = props;
  const addEntityFormRef = useRef<CreateEntityFormHandle>(null);

  return (
    <Dialog maxWidth="xl" open={true}>
      <DialogTitle>{title ? title : "Create entity"}</DialogTitle>
      <DialogContent dividers>
        <CreateEntityFormComponent ref={addEntityFormRef} createEntity={createEntity} onEntityCreated={onEntityCreated} />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="contained"
          onClick={() => {
            addEntityFormRef.current?.requestSubmit();
          }}
        >
          Add
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEntityDialog;
