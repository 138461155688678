import { Autocomplete, TextField } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import { useWaypoints } from "src/apis/waypoints/useWaypoints";

const DatagridWaypointSelector = (params: GridRenderEditCellParams) => {
  const apiRef = useGridApiContext();
  const { id, value, field, error } = params;
  const { waypointNames } = useWaypoints();

  const handleChangeValue = (event: any, value: string) => {
    const newValue = value; // The new value entered by the user
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <div style={{ width: 500 }}>
      <Autocomplete
        size="small"
        id="waypoints"
        options={waypointNames}
        value={value}
        renderInput={(params) => {
          return <TextField variant="standard" {...params} error={error} />;
        }}
        onChange={handleChangeValue}
      />
    </div>
  );
};

export default DatagridWaypointSelector;
