import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useRef } from "react";
import { DamParameter } from "src/types/Types";
import AddDamParameterForm, { AddDamParameterFormHandle } from "../forms/AddDamParameterForm";

type AddDamParameterDialogProps = {
  createDamParameter: (damParameter: DamParameter) => Promise<DamParameter>;
  onCancel: () => void;
  onDamParameterCreated: () => void;
};

const AddDamParameterDialog = (props: AddDamParameterDialogProps) => {
  const { createDamParameter, onCancel, onDamParameterCreated } = props;
  const addDamParameterFormRef = useRef<AddDamParameterFormHandle>(null);

  return (
    <Dialog maxWidth="xl" open={true}>
      <DialogTitle>Create DAM parameter</DialogTitle>
      <DialogContent dividers>
        <AddDamParameterForm ref={addDamParameterFormRef} createDamParameter={createDamParameter} onDamParameterCreated={onDamParameterCreated} />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="contained"
          onClick={() => {
            addDamParameterFormRef.current?.requestSubmit();
          }}
        >
          Add
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDamParameterDialog;
