import { Validator } from "./Validator";

class PointValidator extends Validator {
  validateReference = (fieldName: string, fieldValue: string, row: any) => {
    if (row["referenceType"]) {
      if (!fieldValue) {
        this.validationMap.set(fieldName, true);
        return "Invalid reference";
      }
    }
    this.validationMap.set(fieldName, false);
    return "";
  };

  validateOrientation = (fieldName: string, fieldValue: string) => {
    console.log("orientation");
    if (fieldValue !== undefined || fieldValue !== null) {
      if (isNaN(+fieldValue) || +fieldValue < 0 || +fieldValue > 360) {
        this.validationMap.set(fieldName, true);
        return "Invalid reference";
      }
    }
    this.validationMap.set(fieldName, false);
    return "";
  };

  validateField = (fieldName: string, fieldValue: any, row?: Object) => {
    switch (fieldName) {
      case "name":
        return this.validateName(fieldName, fieldValue);
      case "dmsLatitude":
        return this.validateLatitude(fieldName, fieldValue);
      case "dmsLongitude":
        return this.validateLongitude(fieldName, fieldValue);
      case "reference":
        return this.validateReference(fieldName, fieldValue, row!);
      case "orientation":
        return this.validateOrientation(fieldName, fieldValue);
      default:
        return "";
    }
  };
}

export default PointValidator;
