import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useWaypoints } from "src/apis/waypoints/useWaypoints";

type WaypointSelectorProps = {
  onSelectionChanged?: (selection: string[]) => void;
  width?: string;
};

const WaypointSelector = (props: WaypointSelectorProps) => {
  const { width, onSelectionChanged } = props;
  const { loading, waypointNames } = useWaypoints();
  const [selectedWaypoints, setSelectedWaypoints] = useState<string[]>([]);

  useEffect(() => {
    if (onSelectionChanged) {
      onSelectionChanged(selectedWaypoints);
    }
  }, [selectedWaypoints]);
  return (
    <div>
      <Autocomplete
        size="small"
        multiple
        id="waypoints"
        options={waypointNames}
        sx={{ width: width ? width : "200px" }}
        renderInput={(params) => {
          return <TextField variant="standard" {...params} label="Waypoints" />;
        }}
        onChange={(event, value) => {
          setSelectedWaypoints(value);
        }}
      />
    </div>
  );
};

export default WaypointSelector;
