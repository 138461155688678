import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import { ChangeEvent } from "react";

const DatagridColorSelector = (params: GridRenderEditCellParams) => {
  const apiRef = useGridApiContext();

  const { id, value, field } = params;
  const _onColorChange = (event: ChangeEvent<HTMLInputElement>) => {
    apiRef.current.setEditCellValue({ id, field, value: event.target.value });
  };
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <input style={{ width: "100%", height: "100%" }} type="color" id="favcolor" name="favcolor" value={value} onChange={_onColorChange}></input>
    </div>
  );
};

export default DatagridColorSelector;
