import { Tooltip } from "@mui/material";
import { GridPreProcessEditCellProps, GridRenderCellParams } from "@mui/x-data-grid-pro";
import DataGridTextFieldEditor from "src/components/editors/DataGridTextFieldEditor";
import DataGridUserTagSelector from "src/components/selectors/usertagselector/DataGridUserTagSelector";
import { ColumnsGenerator } from "src/types/Types";
import { customUserTagFilteringOperators } from "./customUserTagFilteringOperators";
import { userTagGridComparator } from "./customUserTagGridComparator";

const useArrProceduresColumns = () => {
  const getColumns: ColumnsGenerator = (validator, onFieldDirty) => {
    const columns = [
      {
        field: "name",
        headerName: "Name",
        width: 200,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("name", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "runwayDesignator",
        headerName: "Runway",
        width: 120,
        editable: true,
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridTextFieldEditor {...params} />;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          const hasError = validator.validateField("runwayDesignator", params.props.value);
          return { ...params.props, error: hasError };
        }
      },
      {
        field: "description",
        headerName: "description",
        width: 600,
        editable: true,
        valueParser: (value: string) => {
          return value;
        },
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        },
        renderCell: (props: GridRenderCellParams) => {
          const { value } = props;
          return (
            <Tooltip title={value} placement="top-start">
              <div>{value}</div>
            </Tooltip>
          );
        }
      },
      {
        field: "userTags",
        headerName: "User tags",
        width: 200,
        editable: true,
        preProcessEditCellProps: async (params: GridPreProcessEditCellProps) => {
          onFieldDirty();
          return { ...params.props, error: false };
        },
        renderEditCell: (params: GridRenderCellParams) => {
          return <DataGridUserTagSelector {...params} />;
        },
        filterOperators: customUserTagFilteringOperators,
        sortComparator: userTagGridComparator
      }
    ];
    return columns;
  };
  return { getColumns };
};

export default useArrProceduresColumns;
