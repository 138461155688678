import { isUndefined } from "lodash";

export interface FieldsValidator {
  hasValidationErrors: () => boolean;
  validateField: (fieldName: string, fieldValue: any, row?: Object) => string;
  validate?: (values: any) => void | object;
}

export abstract class Validator implements FieldsValidator {
  validationMap = new Map<string, boolean>();
  latitudeOrLongitudeMaskEmpty = '___°__´__.___"_';

  hasValidationErrors() {
    const values = this.validationMap.values();
    return Array.from(values).includes(true);
  }

  abstract validateField: (fieldName: string, fieldValue: any, otherFields?: Record<string, any>) => string;

  validateName = (fieldName: string, fieldValue: any) => {
    if (isUndefined(fieldValue) || isUndefined(fieldValue)) {
      this.validationMap.set(fieldName, true);
      return "Invalid name";
    } else if (fieldValue.trim().length === 0) {
      this.validationMap.set(fieldName, true);
      return "Invalid name";
    }
    this.validationMap.set(fieldName, false);
    return "";
  };

  validateOptional = (fieldName: string, fieldValue: string, isOptional: boolean) => {
    if (isOptional !== true) {
      if (fieldValue === null || fieldValue === this.latitudeOrLongitudeMaskEmpty) {
        this.validationMap.set(fieldName, true);
        return "Value is not optional";
      }
    }
    this.validationMap.set(fieldName, false);
    return "";
  };

  validateOtherCoordinate = (fieldName: string, fieldValue: string, otherFieldValue: string) => {
    if (fieldValue === null && otherFieldValue === null) {
      this.validationMap.set(fieldName, false);
      return "";
    }
    if (fieldValue === null && otherFieldValue !== null) {
      this.validationMap.set(fieldName, true);
      return "Invalid value";
    }
    if (fieldValue.includes("_") && otherFieldValue !== this.latitudeOrLongitudeMaskEmpty) {
      this.validationMap.set(fieldName, true);
      return "Invalid value";
    }
    this.validationMap.set(fieldName, false);
    return "";
  };

  validateLatitude = (fieldName: string, fieldValue: string, optional?: boolean) => {
    const optionalValidation = this.validateOptional(fieldName, fieldValue, isUndefined(optional) ? true : optional);
    if (optionalValidation.length > 0) {
      return optionalValidation;
    }
    if (fieldValue) {
      if (fieldValue !== this.latitudeOrLongitudeMaskEmpty && fieldValue.includes("_")) {
        this.validationMap.set(fieldName, true);
        return "Invalid latitude";
      }
      const degrees = fieldValue?.substring(0, 3);
      if (+degrees < 0 || +degrees > 90) {
        this.validationMap.set(fieldName, true);
        return "Degrees must be between 0 and 90";
      }
      const minutes = fieldValue?.substring(4, 6);
      if (+minutes < 0 || +minutes > 59) {
        this.validationMap.set(fieldName, true);
        return "Minutes must be between 0 and 59";
      }
      const seconds = fieldValue?.substring(7, 9);
      if (+seconds < 0 || +seconds > 59) {
        this.validationMap.set(fieldName, true);
        return "Seconds must be between 0 and 59";
      }
    }
    this.validationMap.set(fieldName, false);
    return "";
  };

  validateLongitude = (fieldName: string, fieldValue: string, optional?: boolean) => {
    const optionalValidation = this.validateOptional(fieldName, fieldValue, isUndefined(optional) ? true : optional);
    if (optionalValidation.length > 0) {
      return optionalValidation;
    }
    if (fieldValue) {
      if (fieldValue !== this.latitudeOrLongitudeMaskEmpty && fieldValue.includes("_")) {
        this.validationMap.set(fieldName, true);
        return "Invalid longitude";
      }
      const degrees = fieldValue?.substring(0, 3);
      if (+degrees < 0 || +degrees > 180) {
        this.validationMap.set(fieldName, true);
        return "Degrees must be between 0 and 180";
      }
      const minutes = fieldValue?.substring(4, 6);
      if (+minutes < 0 || +minutes > 59) {
        this.validationMap.set(fieldName, true);
        return "Minutes must be between 0 and 59";
      }
      const seconds = fieldValue?.substring(7, 9);
      if (+seconds < 0 || +seconds > 59) {
        this.validationMap.set(fieldName, true);
        return "Seconds must be between 0 and 59";
      }
    }
    this.validationMap.set(fieldName, false);
    return "";
  };

  validateAngle = (fieldName: string, fieldValue: string, optional?: boolean) => {
    if (fieldValue) {
      const angleValue = +fieldValue;
      if (Number.isNaN(angleValue)) {
        this.validationMap.set(fieldName, true);
        return "Invalid angle";
      }
      if (angleValue < 0 || angleValue > 360) {
        this.validationMap.set(fieldName, true);
        return "Invalid angle";
      }
      this.validationMap.set(fieldName, false);
      return "";
    }
    this.validationMap.set(fieldName, false);
    return "";
  };

  validateMinuteSeconds = (fieldName: string, fieldValue: string) => {
    if (fieldValue) {
      let isValid = /^(0[0-5]|[1-5][0-9]):(0[0-5]|[1-5][0-9])$/.test(fieldValue);
      if (!isValid) {
        this.validationMap.set(fieldName, true);
        return "Invalid time";
      }
      this.validationMap.set(fieldName, false);
      return "";
    }
    this.validationMap.set(fieldName, false);
    return "";
  };

  validateAltitude = (fieldName: string, fieldValue: string) => {
    if (fieldValue) {
      if (fieldValue.startsWith("a")) {
        if (fieldValue.length !== 4) {
          this.validationMap.set(fieldName, true);
          return "Invalid altitude";
        }
        const altitudeValue = +fieldValue.substring(1);
        if (Number.isNaN(altitudeValue)) {
          this.validationMap.set(fieldName, true);
          return "Invalid altitude";
        }
        this.validationMap.set(fieldName, false);
        return "";
      } else {
        const altitudeValue = +fieldValue;
        if (Number.isNaN(altitudeValue)) {
          this.validationMap.set(fieldName, true);
          return "Invalid altitude";
        }
        if (altitudeValue < 0) {
          this.validationMap.set(fieldName, true);
          return "Invalid altitude";
        }
        this.validationMap.set(fieldName, false);
        return "";
      }
    }
    this.validationMap.set(fieldName, false);
    return "";
  };

  validatePositiveNumber = (fieldName: string, fieldValue: string, mandatory: boolean = false) => {
    if (fieldValue) {
      const val = +fieldValue;
      if (Number.isNaN(val) || val < 0) {
        this.validationMap.set(fieldName, true);
        return "Invalid value";
      }
      this.validationMap.set(fieldName, false);
      return "";
    }
    this.validationMap.set(fieldName, false);
    return "";
  };

  validateFrequency = (fieldName: string, fieldValue: string) => {
    return this.validatePositiveNumber(fieldName, fieldValue);
  };

  validateCurvature = (fieldName: string, fieldValue: string) => {
    this.validationMap.set(fieldName, false);

    console.log("curvature validator fieldValue: " + fieldValue);
    if (fieldValue) {
      let matchesPatternCurvature = /^-?\d*(\.\d+)?\s*(nm|km)?$/g.test(fieldValue);

      if (!matchesPatternCurvature) {
        let matchesPatternCenter = /^[CP]\s*\d{6}\.\d{1,3}\s+\d{6}\.\d{1,3}$/g.test(fieldValue);

        if (!matchesPatternCenter) {
          this.validationMap.set(fieldName, true);
          return "Invalid curvature";
        }
        this.validationMap.set(fieldName, false);
      }
      return "";
    } else {
      this.validationMap.set(fieldName, true);
      return "Invalid curvature";
    }
  };
}
