import { Autocomplete, TextField } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";

const DatagridSurfaceSelector = (params: GridRenderEditCellParams) => {
  const { id, value, field } = params;
  const apiRef = useGridApiContext();

  return (
    <div style={{ marginLeft: "10px" }}>
      <Autocomplete
        sx={{ width: 200 }}
        options={["ASPH", "CONC", "GRASS", "WATER", "MISC"]}
        value={value}
        onChange={(event, value) => {
          apiRef.current.setEditCellValue({ id, field, value: value });
        }}
        renderInput={(params) => <TextField {...params} label="Surface" />}
      />
    </div>
  );
};

export default DatagridSurfaceSelector;
