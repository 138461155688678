import React, { useRef } from "react";
import LatitudeMask from "src/components/editors/LatitudeMask";
import LongitudeMask from "src/components/editors/LongitudeMask";
import TextFieldEditor from "src/components/editors/TextFieldEditor";
import { useDmsToWgs64 } from "src/components/editors/hooks/useLatLngConversions";
import MapViewer from "src/components/mapviewer/MapViewer";
import UserTagSelector from "src/components/selectors/usertagselector/UserTagSelector";
import { MapText } from "src/types/Types";
import useMapTextFormHandler from "./useMapTextFormHandler";

export type AddMapTextFormHandle = {
  requestSubmit: () => void;
};

type AddMapTextProps = {
  createMapText: (text: MapText) => Promise<MapText>;
  onSuccess: () => void;
};

const AddMapTextForm: React.ForwardRefRenderFunction<AddMapTextFormHandle, AddMapTextProps> = (props, forwardedRef) => {
  const { createMapText, onSuccess } = props;
  const formRef = useRef<HTMLFormElement>(null);

  const { formHandler } = useMapTextFormHandler(createMapText, onSuccess);
  const { latLng } = useDmsToWgs64(formHandler.values.latitude, formHandler.values.longitude);

  React.useImperativeHandle(forwardedRef, () => ({
    requestSubmit() {
      if (formRef.current) {
        console.log("request submit");
        formRef.current.requestSubmit();
      }
    }
  }));

  return (
    <form ref={formRef} onSubmit={formHandler.handleSubmit}>
      <div style={{ width: "1200px", height: "600px", display: "flex", flexDirection: "row" }}>
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <h3>General information</h3>
          <div style={{ display: "flex" }}>
            <div>
              <TextFieldEditor
                label={"Name"}
                hasError={formHandler.touched.name && Boolean(formHandler.errors.name)}
                helperText={formHandler.touched.name && formHandler.errors.name}
                onChange={(newValue) => {
                  formHandler.setFieldValue("name", newValue);
                }}
              />
            </div>
            <div style={{ marginLeft: 10, flex: 1.5 }}>
              <TextFieldEditor
                label={"Description"}
                onChange={(newValue) => {
                  formHandler.setFieldValue("description", newValue);
                }}
              />
            </div>
          </div>
          <div style={{ marginTop: 5 }}>
            <UserTagSelector
              currentSelection={formHandler.values.userTags}
              onChange={(newValue) => {
                formHandler.setFieldValue("userTags", newValue);
              }}
            />
          </div>
          <h3>Coordinates</h3>
          <div style={{ display: "flex" }}>
            <div>
              <LatitudeMask
                value={formHandler.values.latitude}
                error={formHandler.touched.latitude && Boolean(formHandler.errors.latitude)}
                helperText={formHandler.touched.name && formHandler.errors.latitude}
                onChange={(newValue) => {
                  formHandler.setFieldValue("latitude", newValue);
                }}
              />
            </div>
            <div style={{ marginLeft: 10 }}>
              <LongitudeMask
                value={formHandler.values.longitude}
                error={formHandler.touched.longitude && Boolean(formHandler.errors.longitude)}
                helperText={formHandler.touched.name && formHandler.errors.longitude}
                onChange={(newValue) => {
                  formHandler.setFieldValue("longitude", newValue);
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ flex: 1, height: "100%", marginLeft: "20px" }}>
          <MapViewer namedCoordinate={latLng ? { wgs84LatLng: latLng, name: formHandler.values.name } : undefined} />
        </div>
      </div>
    </form>
  );
};

export default React.forwardRef(AddMapTextForm);
