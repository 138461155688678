import { TabContext, TabPanel } from "@mui/lab";
import { Autocomplete, Button, Menu, MenuItem, Tab, Tabs, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  GridEventListener,
  GridRenderCellParams,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  MuiEvent,
  useGridApiRef
} from "@mui/x-data-grid-pro";

import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CSVLink } from "react-csv";
import Split from "react-split";
import { useUserTags } from "src/apis/usertags/useUserTags";
import { customUserTagFilteringOperators } from "src/columnsgenerators/customUserTagFilteringOperators";
import { userTagGridComparator } from "src/columnsgenerators/customUserTagGridComparator";
import MapViewer from "src/components/mapviewer/MapViewer";
import { Airway, AirwayPoint } from "src/types/Types";
import AirwayPoints from "./AirwayPoints";

type AirwaysGridProps = {
  loading: boolean;
  airways: Airway[];
  airwayPoints: AirwayPoint[];
  onAddAirway: () => void;
  onUpdateAirway: (newValue: Airway, oldValue: Airway) => Promise<Airway> | Airway;
  onDeleteAirway: (id: GridRowId) => void;
  onUpdateAirwayPoint: (newValue: AirwayPoint, oldValue: AirwayPoint) => Promise<AirwayPoint> | AirwayPoint;
  onCancelEditAirwayPoint: () => void;
  onDeleteAirwayPoint: (id: GridRowId) => void;
  onAirwaySelected: (id: GridRowId) => void;
};

const AirwaysGrid = (props: AirwaysGridProps) => {
  const { loading, airways, airwayPoints, onAddAirway, onUpdateAirway, onDeleteAirway, onUpdateAirwayPoint, onCancelEditAirwayPoint, onDeleteAirwayPoint, onAirwaySelected } =
    props;
  const { userTagNames } = useUserTags();

  const [rows, setRows] = useState<Airway[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const [selectedAirway, setSelectedAirway] = useState<Airway>();

  const [airwaysCSV, setAirwaysCSV] = useState<Array<Object>>([]);
  const csvLinkRef = useRef<any>();

  useEffect(() => {
    if (airwaysCSV.length > 0) {
      csvLinkRef.current.link.click();
    }
  }, [airwaysCSV]);

  useEffect(() => {
    setRows(airways);
  }, [airways]);

  const apiRef = useGridApiRef();

  const columns: GridColumns = [
    { field: "name", headerName: "Name", editable: true, width: 200 },
    { field: "fullName", headerName: "Full name", editable: true, width: 200 },
    {
      field: "type",
      headerName: "Type",
      editable: true,
      width: 200,
      renderEditCell: (props: GridRenderCellParams) => {
        const { id, value, field } = props;
        return (
          <Autocomplete
            sx={{ width: 200 }}
            options={["LFN"]}
            defaultValue={value}
            onChange={(event, value) => {
              console.log("on change: " + value);
              apiRef.current.setEditCellValue({
                id,
                field,
                value: value
              });
            }}
            renderInput={(params) => <TextField {...params} label="Select type" />}
          />
        );
      }
    },
    {
      field: "userTags",
      headerName: "User tags",
      editable: true,
      width: 500,
      renderEditCell: (props: GridRenderCellParams) => {
        const { id, value, field } = props;
        return (
          <Autocomplete
            sx={{ width: 500 }}
            multiple
            options={userTagNames}
            defaultValue={value}
            onChange={(event, value) => {
              apiRef.current.setEditCellValue({
                id,
                field,
                value: value
              });
            }}
            renderInput={(params) => <TextField {...params} label="Select user tags" />}
          />
        );
      },
      filterOperators: customUserTagFilteringOperators,
      sortComparator: userTagGridComparator
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem icon={<SaveIcon />} label="Save" onResizeCapture={() => {}} onResize={() => {}} onClick={handleSaveClick(id)} />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onResizeCapture={() => {}}
              onResize={() => {}}
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onResizeCapture={() => {}}
            onResize={() => {}}
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onResizeCapture={() => {}} onResize={() => {}} onClick={handleDeleteClick(id)} color="inherit" />
        ];
      }
    }
  ];

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    onDeleteAirway(id);
  };

  const _onAirwaySelected = (rowParams: GridRowParams<Airway>) => {
    setSelectedAirway(rowParams.row);
    onAirwaySelected(rowParams.id);
  };

  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const onProcessRowUpdateError = (row: AirwayPoint) => {
    setRowModesModel({ ...rowModesModel, [row.id]: { mode: GridRowModes.Edit } });
  };

  const Toolbar = () => {
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const open = Boolean(menuAnchor);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
      setMenuAnchor(event.currentTarget);
    };

    const onExportAirways = () => {
      setAirwaysCSV(
        airways
          .map((a) => {
            return { Name: a.name, Type: a.type, "User tags": a.userTags.toString() };
          })
          .sort((a, b) => (a.Name > b.Name ? 1 : a.Name < b.Name ? -1 : 0))
      );
      setMenuAnchor(null);
    };

    const onExportAirwaysAndPoints = () => {
      setAirwaysCSV(
        airways
          .flatMap((a) => {
            const result = a.airwayPoints
              .map((wp) => {
                return { Name: a.name, sequence: wp.sequence, waypoint: wp.waypointName, remarks: wp.remarks };
              })
              .sort((a, b) => (a.sequence > b.sequence ? 1 : a.sequence < b.sequence ? -1 : 0));
            return result;
          })
          .sort((a, b) => (a.Name > b.Name ? 1 : a.Name < b.Name ? -1 : 0))
      );
    };

    const onClose = () => {
      setMenuAnchor(null);
    };

    return (
      <GridToolbarContainer>
        <CSVLink data={airwaysCSV} target="_blank" ref={csvLinkRef} className="hidden" />
        <div style={{ marginRight: 15 }}>
          <GridToolbarQuickFilter debounceMs={600} />
        </div>
        <Button color="primary" startIcon={<AddIcon />} onClick={onAddAirway}>
          Add airway
        </Button>
        <Button color="primary" startIcon={<FileDownloadIcon />} onClick={handleOpenMenu}>
          Export
        </Button>
        <Menu id="basic-menu" anchorEl={menuAnchor} open={open} onClose={onClose}>
          <MenuItem onClick={onExportAirways}>Export Airways</MenuItem>
          <MenuItem onClick={onExportAirwaysAndPoints}>Export Airways+Points</MenuItem>
        </Menu>
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ height: "90%" }}>
      <Split style={{ height: "calc(100vh - 230px)" }} direction="vertical">
        <div style={{ height: "100%", marginBottom: "2px" }}>
          <DataGridPro
            loading={loading}
            apiRef={apiRef}
            density="compact"
            editMode={"row"}
            columns={columns}
            rows={rows}
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            onRowClick={_onAirwaySelected}
            processRowUpdate={onUpdateAirway}
            onProcessRowUpdateError={onProcessRowUpdateError}
            experimentalFeatures={{ newEditingApi: true }}
            initialState={{
              sorting: {
                sortModel: [{ field: "name", sort: "asc" }]
              }
            }}
            components={{ Toolbar }}
            componentsProps={{
              toolbar: { setRows, setRowModesModel }
            }}
          />
        </div>
        <div style={{ height: "100%" }}>
          <TabContext value={"airwayPoints"}>
            <Tabs value={"airwayPoints"}>
              <Tab value="airwayPoints" label={"Airway points"} />
            </Tabs>
            <TabPanel value="airwayPoints" style={{ height: "100%" }}>
              <div style={{ height: "90%", display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1, height: "100%", marginRight: "5px" }}>
                  <AirwayPoints
                    selectedAirway={selectedAirway}
                    airwayPoints={airwayPoints}
                    onProcessRowUpdate={onUpdateAirwayPoint}
                    onCancelEdit={onCancelEditAirwayPoint}
                    onDeleteAirwayPoint={onDeleteAirwayPoint}
                  />
                </div>
                <div style={{ flex: 1, height: "100%" }}>
                  <MapViewer airwayPoints={airwayPoints.slice()} />
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </div>
      </Split>
    </div>
  );
};

export default AirwaysGrid;
