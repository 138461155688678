import axios, { AxiosInstance } from "axios";

let _apiClient: AxiosInstance | undefined;

export const initAPIClient = (apiUrl: string, timeout: number, token: String) => {
  _apiClient = axios.create({
    baseURL: `${apiUrl}`,
    timeout: timeout,
    headers: { Authorization: `Bearer ${token}` }
  });
  _apiClient.interceptors.response.use(
    (success) => {
      return success;
    },
    (rejected) => {
      if (rejected.response) {
        if (rejected.response.status === 401) {
          window.location.href = "/login";
        } else if (rejected.response.status === 400 && rejected.response.data) {
          // Backend API sent a response
          return Promise.reject(rejected.response.data);
        } else {
          return Promise.reject(rejected.response.data.error);
        }
      } else {
        return Promise.reject(rejected.message);
      }
    }
  );
  return _apiClient;
};

export const getAPIClient = () => {
  return _apiClient;
};
